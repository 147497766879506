<template>
  <div id="bankTransfer">
    <loader v-if="loading" />
    <div class="container" v-if="order.id">
      <a class="orderCard" :href="'/listing/' + order.listing.id">
        <div class="carInformation">
          <loader v-if="!carPictureIsLoaded" />
          <img v-show="carPictureIsLoaded" class="carImage" :src="imageUrl" loading="lazy" @error="imageError = true" :alt="fullName" @load="carPictureIsLoaded = true" />
          <div class="details">
            <span class="name">{{ fullName }}</span>
            <span class="variant">{{ order.listing.variant }} {{ order.listing.year }}</span>
            <span class="price">{{ retailPriceString }}</span>
          </div>
        </div>
      </a>
      <div class="paymentTitle">
        <h2>{{ t['payment'] }}</h2>
        <div>{{ t['transferRequiredAmount'] }}</div>
      </div>
      <div class="carPrice">
        <div v-if="order.paymentResponse.bankTransfer.pricing.vehiclePrice">
          <span>{{ t['carPrice'] }}</span>
          <span
            ><b>{{ getPrice('vehiclePrice') }}</b></span
          >
        </div>

        <div v-if="getPrice('downPayment')">
          <span>{{ t['downPayment'] }}</span>
          <span
            ><b>{{ getPrice('downPayment') }}</b></span
          >
        </div>

        <div v-if="getPrice('tradeInOffer')">
          <span>{{ t['tradeInOffer'] }}</span>
          <span
            ><b>{{ getPrice('tradeInOffer') }}</b></span
          >
        </div>

        <div v-if="getPrice('tradeInFinancing')">
          <span>{{ t['outstandingAmount'] }}</span>
          <span
            ><b>{{ getPrice('tradeInFinancing') }}</b></span
          >
        </div>

        <div v-if="getPrice('tradeInAmount')">
          <span>{{ t['tradeInAmount'] }}</span>
          <span
            ><b>{{ getPrice('tradeInAmount') }}</b></span
          >
        </div>

        <div v-if="getPrice('final')">
          <span>{{ t['amountToBePaid'] }}</span>
          <span
            ><b>{{ getPrice('final') }}</b></span
          >
        </div>
      </div>

      <div class="transferDetails">
        <h4>{{ t['transferDetails'] }}</h4>
        <div class="values">
          <div>
            <span>{{ t['bankTransferTo'] }}:</span> <span class="value">{{ dealershipName }}<img :src="copyIcon" @click="copy(dealershipName)" /></span>
          </div>
          <div>
            <span>{{ t['bankTransferRegistrationNumber'] }}:</span> <span class="value">{{ showroomBankRegistration }}<img :src="copyIcon" @click="copy(showroomBankRegistration)" /></span>
          </div>
          <div>
            <span>{{ t['bankTransferAccountNumber'] }}:</span> <span class="value">{{ showroomBankAccount }}<img :src="copyIcon" @click="copy(showroomBankAccount)" /></span>
          </div>
          <div>
            <span>{{ t['bankTransferBankName'] }}:</span> <span class="value">{{ dealershipBankName }}</span>
          </div>
          <div>
            <span>{{ t['bankTransferReference'] }}:</span> <span class="value">{{ referenceNumber }}</span>
          </div>
          <div>
            <span>{{ t['bankTransferTotalAmount'] }}:</span> <span class="value">{{ getPrice('final') }}</span>
          </div>
        </div>
      </div>
      <div class="completeTransfer">
        <SeezButton @click="transferComplete">{{ t['transferComplete'] }}</SeezButton>
        <div class="notice">
          <div>
            <b>{{ t['bankTransferNotice1'] }}</b
            >{{ t['bankTransferNotice2'] }}
          </div>
          <div>{{ t['decideToReturn'] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { lang } from '../lang'
import Loader from '../Loader.ce.vue'
import { copyIcon } from '../../assets/images'
import SeezButton from './components/SeezButton.ce.vue'

export default {
  name: 'BankTransfer',
  components: { Loader, SeezButton },
  mixins: [lang],
  props: {
    order: { type: Object, required: true },
    details: {
      type: Object,
      default: () => {
        return { currency: 'kr' }
      }
    }
  },
  data() {
    return {
      loading: false,
      carPictureIsLoaded: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    retailPriceString() {
      if (this.order.listing.priceRetail == null) return null
      return this.order.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    getPrice() {
      return key => {
        const value = this.order.paymentState?.bankTransfer?.pricing?.[key]
        if (!value && value !== 0) return null
        return value.toLocaleString(this.language) + ' ' + this.currency
      }
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    imageUrl() {
      if (this.imageError || this.order?.listing?.pictures?.[0] == null) return ''
      return import.meta.env.VITE_IMAGES_URL + this.order.listing.pictures[0]
    },
    fullName() {
      if (this.order?.name) return this.order.name

      const brand = this.order?.listing?.brand?.name
      const model = this.order?.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    dealershipName() {
      return this.order.listing?.dealership?.name
    },
    showroomBankAccount() {
      return this.order.listing?.showroom?.bankaccount
    },
    dealershipBankName() {
      return this.order.listing?.dealership?.bankName
    },
    showroomBankRegistration() {
      return this.order.listing?.showroom?.bankRegistration
    },
    referenceNumber() {
      return this.order.listing?.referenceNumber
    },
    copyIcon() {
      return copyIcon
    }
  },
  methods: {
    async transferComplete() {
      this.$emit('transferComplete')
    },
    async copy(value) {
      try {
        await navigator.clipboard.writeText(value)
        // TODO: toast copied
      } catch ($e) {
        // TODO: toast error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#bankTransfer {
  overflow: auto;
  height: 100%;
  > .loader {
    position: absolute;
    z-index: 100;
  }
  .container {
    max-width: 60rem;
    margin: auto;
  }
  .paymentTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    > div {
      line-height: 22px;
      font-size: 0.8em;
    }
  }
  
  .carPrice {
    padding: 1rem;
    background: #f6f6f6b3;
    margin: 1em 0;
    > div {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
      padding: 12px 0;
    }
    > div:last-child {
      border-bottom: none;
    }
  }
  .transferDetails {
    box-shadow: 1px 4px 12px rgb(204 225 255 / 34%);
    padding: 12px;
    .values {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
      > div {
        width: max-content;
        display: flex;
        flex-direction: column;
        > .value {
          font-weight: 700;
          > img {
            width: 1rem;
            margin-left: 6px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .completeTransfer {
    > button {
      width: 100%;
    }
    .notice {
      display: flex;
      font-size: 0.8em;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0.75rem 0;
    }
  }
}
</style>
