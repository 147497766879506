<template>
  <li class="savedSearch" @click="$emit('select')">
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.81954 6.17578L3.05 2.66016C3.54219 1.28906 4.80782 0.375 6.24922 0.375H12.6125C14.0539 0.375 15.3195 1.28906 15.8117 2.66016L17.0422 6.17578C17.8508 6.52734 18.4484 7.33594 18.4484 8.25V15C18.4484 15.6328 17.9211 16.125 17.3234 16.125H16.1984C15.5656 16.125 15.0734 15.6328 15.0734 15V13.3125H3.82344V15C3.82344 15.6328 3.2961 16.125 2.69844 16.125H1.57344C0.94063 16.125 0.448442 15.6328 0.448442 15V8.25C0.448442 7.33594 1.01094 6.52734 1.81954 6.17578ZM4.28047 6H14.5813L13.6672 3.39844C13.5266 2.94141 13.1047 2.625 12.6125 2.625H6.24922C5.75704 2.625 5.33516 2.94141 5.19454 3.39844L4.28047 6ZM3.82344 8.25C3.19063 8.25 2.69844 8.77734 2.69844 9.375C2.69844 10.0078 3.19063 10.5 3.82344 10.5C4.4211 10.5 4.94844 10.0078 4.94844 9.375C4.94844 8.77734 4.4211 8.25 3.82344 8.25ZM15.0734 10.5C15.6711 10.5 16.1984 10.0078 16.1984 9.375C16.1984 8.77734 15.6711 8.25 15.0734 8.25C14.4406 8.25 13.9484 8.77734 13.9484 9.375C13.9484 10.0078 14.4406 10.5 15.0734 10.5Z"
        fill="#0068FF"
      />
    </svg>
    <h2>{{ search.name }}</h2>
    <button @click.stop="$emit('remove')">
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5 2.5H10.9062L9.84375 0.75C9.5625 0.28125 9.09375 0 8.5625 0H5.40625C4.875 0 4.40625 0.28125 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14C1 15.125 1.875 16 3 16H11C12.0938 16 13 15.125 13 14V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.4375 1.5 5.53125 1.5H8.46875C8.53125 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11 14.5H3C2.71875 14.5 2.5 14.2812 2.5 14V4H11.5V14C11.5 14.2812 11.25 14.5 11 14.5ZM7 13C7.25 13 7.5 12.7812 7.5 12.5V6C7.5 5.75 7.25 5.5 7 5.5C6.71875 5.5 6.5 5.75 6.5 6V12.5C6.5 12.7812 6.71875 13 7 13ZM4.5 13C4.75 13 5 12.7812 5 12.5V6C5 5.75 4.75 5.5 4.5 5.5C4.21875 5.5 4 5.75 4 6V12.5C4 12.7812 4.21875 13 4.5 13ZM9.5 13C9.75 13 10 12.7812 10 12.5V6C10 5.75 9.75 5.5 9.5 5.5C9.21875 5.5 9 5.75 9 6V12.5C9 12.7812 9.21875 13 9.5 13Z"
          fill="#AFAFAF"
        />
      </svg>
    </button>
    <dl>
      <dt>Brand</dt>
      <dd>{{ brandsNames }}</dd>
      <dt>Model</dt>
      <dd>{{ modelsNames }}</dd>
      <dt>Year</dt>
      <dd>{{ years }}</dd>
      <dt>Budget</dt>
      <dd>{{ budget }}</dd>
    </dl>
  </li>
</template>

<script>
import { lang } from '../lang'

export default {
  name: 'SeezSavedSearch',
  mixins: [lang],
  props: {
    search: { type: Object, required: true },
    brands: { type: Object, default: () => {} },
    models: { type: Object, default: () => {} }
  },
  computed: {
    brandsNames() {
      if (this.search.filterParameters.brands == null) return 'Any'
      const names = this.search.filterParameters.brands.map(id => this.brands[id.toString()])
      names.sort((a, b) => (a > b ? 1 : -1))
      return names.join(', ')
    },
    modelsNames() {
      if (this.search.filterParameters.models == null) return 'Any'
      const names = this.search.filterParameters.models.map(id => this.models[id.toString()])
      names.sort((a, b) => (a > b ? 1 : -1))
      return names.join(', ')
    },
    years() {
      const f = this.search.filterParameters
      if (f.yearMin && f.yearMax) return `${f.yearMin} - ${f.yearMax}`
      else if (f.yearMin) return `${f.yearMin} or newer`
      else if (f.yearMax) return `${f.yearMax} or older`
      else return 'Any'
    },
    budget() {
      const f = this.search.filterParameters
      if (f.priceMin && f.priceMax) return `${f.priceMin.toLocaleString(this.language)} - ${f.priceMax.toLocaleString(this.language)}`
      else if (f.priceMin) return `${f.priceMin.toLocaleString(this.language)} or more`
      else if (f.priceMax) return `${f.priceMax.toLocaleString(this.language)} or less`
      else return 'Any'
    }
  }
}
</script>

<style lang="scss">
li.savedSearch {
  border-radius: 0 2em 2em 0;
  border-left: 0.5em solid #0068ff;
  padding: 1em;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  align-items: center;
  width: 70ch;
  margin-bottom: 1em;
  gap: 1em;
  box-shadow: 0 0 4px hsla(0, 0%, 70%, 0.5);
  cursor: pointer;
  text-align: left;

  > h2 {
    margin: 0;
  }

  > button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  > dl {
    grid-column-end: span 3;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 5fr 5fr 3fr 4fr;
    grid-template-rows: auto auto;
    margin: 0;

    > dt {
      color: #afafaf;
    }

    > dd {
      margin: 0;
      color: #1a2634;
    }
  }

  @media screen and (max-width: 50rem) {
    border-radius: 0 2em 2em 0;
    border-left: 0.5em solid #0068ff;
    padding: 1em;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;
    width: auto;
    margin-bottom: 1em;
    gap: 1em;
    box-shadow: 0 0 4px hsla(0, 0%, 70%, 0.5);
    cursor: pointer;
    text-align: left;

    > dl {
      grid-column-end: span 3;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 5fr 4fr 4fr 3fr;
      grid-template-rows: auto auto;
      margin: 0;
      column-gap: 0.5em;
      font-size: 0.9em;
    }
  }
}
</style>
