<template>
  <div class="seezModal">
    <div :class="position">
      <slot />
      <button class="closer" aria-label="Close" @click="close" v-if="closable">&times;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeezModal',
  props: {
    closable: { type: Boolean, default: true },
    position: { type: String, default: 'center' }
  },
  methods: {
    close() {
      if (!this.closable) return
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
@import '../base';

.seezModal {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(2px) brightness(75%) saturate(50%);
  display: grid;
  place-items: center;
  z-index: 3;

  --br: 0.5em;

  > div {
    background-color: white;
    height: 100vh;
    padding: 2em;
    max-width: 90vmin;
  
    position: relative;

     @media (min-width: 768px) {
    height: auto;
    border-radius: var(--br);
     }

    &.right {
      place-self: stretch end;
      border-radius: var(--br) 0 0 var(--br);
    }

    &.left {
      place-self: stretch start;
      border-radius: 0 var(--br) var(--br) 0;
    }

    > .closer {
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      inset: 0.1em 0.1em auto auto;
      font-size: 2em;
      line-height: 0.5em;
      margin: 0;
      padding: 1rem;
      z-index: 2;
    }
  }
}
</style>
