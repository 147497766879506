<template>
  <div id="congratulations">
    <loader v-if="loading" />
    <div class="container" v-if="order.id">
      <div class="congratulations">
        <img :src="congratulationsHeader" />
      </div>
      <!-- <img v-show="carPictureIsLoaded" class="carImage" :src="imageUrl" loading="lazy" @error="imageError = true" :alt="fullName" @load="carPictureIsLoaded = true" /> -->
      <div class="congratsMessage">
        <span class="title">{{ t['youAreNowOwner'] }}</span>
        <span class="desc">{{ t['ourTeamWillPrepareCar'] }}</span>
      </div>
      <div class="actions">
        <SeezButton outline @click="gotoHome">{{ t['backToHome'] }}</SeezButton>
        <SeezButton @click="gotoReview">{{ t['orderSummary'] }}</SeezButton>
      </div>
    </div>
  </div>
</template>
<script>
import { lang } from '../lang'
import Loader from '../Loader.ce.vue'
import SeezButton from './components/SeezButton.ce.vue'
import { congratulations, congratulationsDa } from '../../assets/images'

export default {
  name: 'CongratulationsPage',
  components: { Loader, SeezButton },
  mixins: [lang],
  props: {
    order: { type: Object, required: true }
  },
  data() {
    return {
      loading: false,
      carPictureIsLoaded: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    imageUrl() {
      if (this.imageError || this.order?.listing?.pictures?.[0] == null) return ''
      return import.meta.env.VITE_IMAGES_URL + this.order.listing.pictures[0]
    },
    fullName() {
      if (this.order?.name) return this.order.name
      const brand = this.order?.listing?.brand?.name
      const model = this.order?.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    congratulationsHeader() {
      if (this.language === 'en') return congratulations
      else return congratulationsDa
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    gotoReview() {
      this.$emit('setSummary', true)
    },
    gotoHome() {
      window.location.href = '/'
    }
  }
}
</script>
<style lang="scss" scoped>
#congratulations {
  overflow: auto;
  height: 100%;
  > .loader {
    position: absolute;
    z-index: 100;
  }
  .container {
    max-width: 60rem;
    margin: auto;
  }
  .congratulations {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: #0068ff;
    margin: 0.625rem 0;
    border-radius: 0.625rem;
    color: white;
    > img {
      width: 100%;
    }
  }
  .carImage {
    width: 100%;
  }
  .congratsMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
    margin: 32px 0;
    > .title {
      font-weight: 700;
      line-height: 22px;
      font-size: 1.4em;
    }
    .desc {
      font-weight: 400;
      line-height: 22px;
      font-size: 1.4em;
    }
  }
  .actions {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>
