<template>
  <div>
    <ul class="searches" v-if="!loading && !error && savedSearches.length > 0">
      <SavedSearch v-for="s in savedSearches" :key="s.id" :search="s" :brands="brands" :models="models" :to="to" @select="$emit('select', s)" @remove="removeSearch(s.id)" />
    </ul>
    <EmptySearch v-if="savedSearches.length === 0 && !loading" :to="to" :description="t['you_have_not_saved_search']" :lg="lg" />
    <p v-if="!loading && error" class="error">{{ error }}</p>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { lang } from '../lang'
import Loader from '../Loader.ce.vue'
import SavedSearch from './SavedSearch.ce.vue'
import EmptySearch from '../EmptySearch.ce.vue'

export default {
  name: 'SeezSavedSearches',
  components: { Loader, SavedSearch, EmptySearch },
  mixins: [lang, SeezSdk.vueQueryMixin, SeezSdk.vueSavedSearchesMixin],
  props: { to: { type: String, default: null } },
  data: () => ({
    brands: {},
    models: {},
    loadingOptions: false,
    error: null
  }),
  computed: {
    t() {
      return this.languageResources.EMPTY_SEARCH_COMPONENT_TRANSLATIONS
    },
    loading() {
      return this.loadingOptions
    }
  },
  mounted() {
    this.loadSearches()
  },
  methods: {
    async loadSearches() {
      this.loadingOptions = true
      this.error = null
      try {
        const query = '{brands{id,name},models{id,name}}'
        const { brands, models } = await this.queryApi(query)
        this.brands = brands.reduce((a, x) => ({ ...a, [x.id]: x.name }), {})
        this.models = models.reduce((a, x) => ({ ...a, [x.id]: x.name }), {})
      } catch (e) {
        console.error(e)
        this.error = 'Error getting Saved Searches'
      }
      this.loadingOptions = false
    }
  }
}
</script>

<style lang="scss" scoped>
.searches {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 2em 0.5em;

  > .error {
    color: red;
    text-align: center;
    margin: 0;
    font-size: 2em;
  }
}
</style>
