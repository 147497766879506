<template>
  <div id="tradeIn" class="fixedHeight" ref="tradeIn" data-test-id="trade-in-section">
    <div v-if="orderId">
      <loader v-if="loading" />
      <div class="title">{{ t['tradeIn'] }}</div>
      <div class="subTitle">{{ t['tradeInSubtitle'] }}</div>

      <div class="tradeInInfoBox">
        <div class="boxTitle">
          <div><tooltip />{{ t['tradeInInfoTitle'] }}</div>
          <div class="collapseIcon" @click="tradeInInfoToggle = !tradeInInfoToggle"><img :src="plusIcon" /></div>
        </div>
        <div class="tradeInInformation" v-show="tradeInInfoToggle">
          <div class="flexGrow">
            <h4>{{ t['tradeInInfoTitle'] }}</h4>
            <h5>{{ t['tradeInInfoStep1'] }}</h5>
            <p>{{ t['tradeInInfoStep1Description'] }}</p>
            <h5>{{ t['tradeInInfoStep2'] }}</h5>
            <p>{{ t['tradeInInfoStep2Description'] }}</p>
            <h5>{{ t['tradeInInfoStep3'] }}</h5>
            <p>{{ t['tradeInInfoStep4Description'] }}</p>
            <h5>{{ t['tradeInInfoStep4'] }}</h5>
            <p>{{ t['tradeInInfoStep4Description'] }}</p>
          </div>
        </div>
      </div>

      <div class="tradeInQuestion">
        <h3 class="title">{{ t['tradeInWouldYouLikeToAdd'] }}</h3>
        <Radio
          :options="[
            { placeholder: 'yes', value: true },
            { placeholder: 'no', value: false }
          ]"
          name="show-trade-inform"
          v-model="tradeInModel.willTradeIn"
          data-test-id="tradein-will-trade-in"
        />
      </div>
      <hr />

      <div v-if="tradeInModel.willTradeIn">
        <form class="vehicleForm" @submit.prevent="createTradeIn" ref="vehicleForm">
          <h5 class="addTradeInTitle">{{ t['addANewTradeIn'] }}</h5>
          <p class="addTradeInSubTitle">{{ t['tradeInAddPlateNumber'] }}</p>
          <div class="licensePlateForm">
            <form id="licensePlateForm" ref="licensePlateForm" @submit.prevent="vehicleLookup" data-test-id="tradein-license-plate-form">
              <label class="licensePlate">
                <span>
                  <span class="prefix"><img :src="euFlag" width="21" height="21" /> <span>DK</span></span>
                  <InputWError
                    form="licensePlateForm"
                    :placeholder="t['enterPlateNumber']"
                    v-model="tradeInModel.licensePlate"
                    type="text"
                    ref="licenseplate"
                    minlength="5"
                    maxlength="10"
                    is-clear-input
                    :error="inputErrors.licensePlate"
                    data-test-id="tradein-license-plate-input"
                  />
                  <button type="button" class="search" @click="vehicleLookup()" data-test-id="tradein-license-plate-submit"><img :src="searchIcon" />{{ t['search'] }}</button>
                </span>
              </label>
            </form>
          </div>
          <div class="carDetails">
            <h5>{{ t['carDetails'] }}</h5>
            <p>{{ t['carDetailsFillInfo'] }}</p>
            <div class="carDetailsInputs">
              <InputWError class="radiusInput" :label="t['make']" :placeholder="t['make']" v-model="tradeInModel.make" :error="inputErrors.make" data-test-id="tradein-form-make" />
              <InputWError class="radiusInput" :label="t['model']" :placeholder="t['model']" v-model="tradeInModel.model" :error="inputErrors.model" data-test-id="tradein-form-model" />
              <InputWError
                class="radiusInput"
                :label="t['variant']"
                :placeholder="t['variant']"
                v-model="tradeInModel.variant"
                ref="variant"
                :error="inputErrors.variant"
                data-test-id="tradein-form-variant"
              />
              <InputWError
                class="radiusInput"
                :label="t['year']"
                :placeholder="t['year']"
                v-model="tradeInModel.year"
                type="number"
                ref="year"
                :error="inputErrors.year"
                data-test-id="tradein-form-year"
              />
              <InputWError
                class="radiusInput calendarIcon"
                :placeholder="t['registrationDate']"
                v-model="tradeInModel.registrationDate"
                type="date"
                :max="new Date().toISOString().split('T')[0]"
                ref="registrationdate"
                :error="inputErrors.registration_date"
                data-test-id="tradein-form-registration-date"
                :label="t['registrationDate']"
              />
              <MultiSelectWithError
                class="radiusInputSelect"
                :multi="false"
                :clear-placeholder="t['registration_type']"
                :options="registrationTypes"
                v-model="tradeInModel.registrationType"
                :placeholder="t['registrationType']"
                test-id="registration_type"
                :error="inputErrors.registration_type"
                data-test-id="tradein-form-registration-type"
                :label="t['registrationType']"
              />
              <InputWError
                class="radiusInput"
                :placeholder="t['color']"
                v-model="tradeInModel.color"
                type="text"
                ref="color"
                :error="inputErrors.color"
                data-test-id="tradein-form-color"
                :label="t['color']"
              />
              <InputWError
                class="radiusInput"
                :placeholder="t['mileage']"
                v-model="tradeInModel.kilometrage"
                type="number"
                ref="kmtrage"
                :error="inputErrors.kmtrage"
                data-test-id="tradein-form-mileage"
                :postfix-text="t['km']"
                :label="t['mileage']"
              />
              <MultiSelectWithError
                class="radiusInputSelect"
                :clear-placeholder="t['fuelType']"
                :multi="false"
                :options="fuelTypes"
                v-model="tradeInModel.fuelType"
                :placeholder="t['fuelType']"
                test-id="fuel_type"
                ref="fuel_type"
                :error="inputErrors.fuel_type"
                data-test-id="tradein-form-fuel-type"
                :label="t['fuelType']"
              />
            </div>
          </div>
          <div class="detailQuestions">
            <h3>{{ t['moreDetails'] }}</h3>
            <div class="detailQuestion">
              <span>
                {{ t['accidentQuestion'] }}
                <tooltip :text="t['accidentMajorAccidents']" />
              </span>
              <Radio
                :options="[
                  { placeholder: 'yes', value: true },
                  { placeholder: 'no', value: false }
                ]"
                name="car-accident"
                v-model="tradeInModel.accident"
                data-test-id="tradein-form-accident"
              />
            </div>

            <div class="detailQuestion">
              <span>{{ t['oustandingFinancingQuestion'] }} <tooltip :text="t['outstandingFinancingInfo']" /></span>
              <Radio
                :options="[
                  { placeholder: 'yes', value: true },
                  { placeholder: 'no', value: false }
                ]"
                name="car-outstanding"
                v-model="tradeInModel.hasFinancing"
                data-test-id="tradein-form-has-financing"
              />
            </div>

            <div class="detailQuestion" v-if="tradeInModel.hasFinancing">
              <span>{{ t['oustandingFinancingDisclaimer'] }}{{ t['oustandingFinancingDisclaimer1'] }}</span>
              <div>
                <InputWError class="radiusInput" v-model="tradeInModel.outstandingFinanceAmount" :placeholder="t['outstandingAmount']" data-test-id="tradein-form-outstanding-amount" />
              </div>
            </div>

            <div class="detailQuestion">
              <span>{{ t['howManyKeys'] }}</span>
              <div>
                <InputWError
                  class="radiusInput"
                  name="number-of-keys"
                  v-model="tradeInModel.numberOfKeys"
                  id="number-of-keys"
                  :placeholder="t['numberOfKeys']"
                  ref="number_of_keys"
                  :error="inputErrors.number_of_keys"
                  data-test-id="tradein-form-number-of-keys"
                />
              </div>
            </div>

            <div class="detailQuestion">
              <span>
                {{ t['serviceHistoryQuestion'] }}
                <tooltip :text="t['serviceHistoryComplete']" />
              </span>
              <MultiSelectWithError
                :multi="false"
                :clear-placeholder="t['serviceHistory']"
                :options="serviceHistories"
                v-model="tradeInModel.serviceType"
                :placeholder="t['serviceHistory']"
                test-id="service_history"
                ref="service_type"
                :error="inputErrors.service_type"
                data-test-id="tradein-form-service-type"
              />
            </div>

            <div class="detailQuestion">
              <span>{{ t['carConditionQuestion'] }}</span>
              <div>
                <MultiSelectWithError
                  :multi="false"
                  :clear-placeholder="t['carConditionSelect']"
                  :options="conditions"
                  v-model="tradeInModel.carCondition"
                  :placeholder="t['carConditionSelect']"
                  test-id="car_condition"
                  ref="condition"
                  :error="inputErrors.condition"
                  data-test-id="tradein-form-condition"
                />
              </div>
            </div>

            <div class="conditionDetails" v-if="tradeInModel.carCondition" data-test-id="tradein-form-condition-explanation">
              <div v-if="tradeInModel.carCondition === 'Good'" class="goodCarText">{{ t['carConditionGoodDef'] }}</div>
              <div v-if="tradeInModel.carCondition === 'Poor'" class="poorCarText">{{ t['carConditionPoorDef'] }}</div>
              <div v-if="tradeInModel.carCondition === 'Average'" class="averageCarText">{{ t['carConditionAverageDef'] }}</div>
              <div v-if="tradeInModel.carCondition === 'Great'" class="greatCarText">{{ t['carConditionGreatDef'] }}</div>
            </div>

            <h3>{{ t['carQuirks'] }}</h3>
            <textarea class="carQuirks" v-model="tradeInModel.customerComment" :placeholder="t['carQuirks']" data-test-id="tradein-form-quirk" />
            <p>{{ t['carQuirksHint'] }}</p>
          </div>

          <div class="carPhotos centerAligned">
            <h3>{{ t['carPhotos'] }}</h3>
            <p>{{ t['carPhotosBestOfferPossible'] }}</p>
            <tooltip :text="t['serviceHistoryComplete']" />
            <div class="uploadCarPhotos">
              <UploadImage title="addFrontPhoto" type="front" @uploadImage="uploadImage" :current-image="getCurrentImage('front')" data-test-id="tradein-form-image-front" />
              <UploadImage title="addBackPhoto" type="back" @uploadImage="uploadImage" :current-image="getCurrentImage('back')" data-test-id="tradein-form-image-back" />
              <UploadImage title="addInteriorPhoto" type="interior" @uploadImage="uploadImage" :current-image="getCurrentImage('interior')" data-test-id="tradein-form-image-interior" />
            </div>

            <p>{{ t['damagePhotos'] }}</p>

            <div class="uploadCarPhotos">
              <UploadImage title="damages1Field" type="damage" @uploadImage="uploadImage" :current-image="getCurrentImage('damage', 0)" data-test-id="tradein-form-image-other1" />
              <UploadImage title="damages2Field" type="damage" @uploadImage="uploadImage" :current-image="getCurrentImage('damage', 1)" data-test-id="tradein-form-image-other2" />
              <UploadImage title="damages3Field" type="damage" @uploadImage="uploadImage" :current-image="getCurrentImage('damage', 2)" data-test-id="tradein-form-image-other3" />
            </div>
            <p>{{ t['carPhotosMissing'] }}</p>
            <p>{{ t['tradeInDiscrepanciesDisclaimer'] }}</p>
          </div>
          <img class="scrollDown" :src="scrollDown" @click="gotoNextSection" />
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { lang } from '../../lang'
import { euFlag, searchIcon, plusIcon, scrollDown } from '../../../assets/images.js'
import SeezSdk from '../../../sdk.js'
import MultiSelectWithError from '../components/MultiSelectWError.ce.vue'
import Loader from '../../Loader.ce.vue'
import Tooltip from '../components/Tooltip.ce.vue'
import InputWError from '../components/InputWError.ce.vue'
import Radio from '../components/Radio.ce.vue'
import UploadImage from '../components/Upload.ce.vue'

const tradeInModelSchema = {
  willTradeIn: null,
  licensePlate: '',
  make: '',
  model: '',
  variant: '',
  year: '',
  registrationDate: '',
  registrationType: '',
  kilometrage: '',
  accident: null,
  hasFinancing: null,
  outstandingFinanceAmount: '',
  carCondition: '',
  fullName: '',
  phone: '',
  email: '',
  color: '',
  vin: null,
  fuelType: null,
  customerComment: null,
  numberOfKeys: null,
  serviceType: null,
  pictures: []
}

const inputFieldErrorsDefaultState = {
  licenseplate: '',
  kmtrage: '',
  variant: '',
  make: '',
  model: '',
  registrationdate: '',
  year: '',
  fuel_type: '',
  condition: '',
  registration_type: '',
  color: '',
  number_of_keys: '',
  service_type: ''
}

export default {
  name: 'CheckoutTradeIn',
  components: { MultiSelectWithError, Loader, Tooltip, InputWError, Radio, UploadImage },
  mixins: [lang, SeezSdk.vueQueryMixin],
  props: {
    orderId: { type: String, default: '' },
    currentStage: { type: String, default: '' },
    errors: { type: Array, default: () => [] },
    state: {
      type: Object,
      default: () => {
        return tradeInModelSchema
      }
    }
  },
  data() {
    return {
      loading: false,
      tradeInModel: tradeInModelSchema,
      fuelTypes: ['Diesel', 'El', 'Benzin', 'Hybrid (Benzin)', 'Hybrid (Diesel)'],
      registrationTypes: [],
      serviceHistories: [],
      conditions: [],
      inputErrors: { ...inputFieldErrorsDefaultState },
      elementHeight: null,
      tradeInInfoToggle: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    euFlag() {
      return euFlag
    },
    searchIcon() {
      return searchIcon
    },
    plusIcon() {
      return plusIcon
    },
    scrollDown() {
      return scrollDown
    }
  },
  watch: {
    state: {
      handler(value) {
        if (value) this.tradeInModel = { ...value, registrationDate: value?.registrationDate?.split('T')?.[0], pictures: JSON.parse(JSON.stringify(value.pictures)) }
      },
      deep: true
    },
    currentStage: {
      handler(newValue, oldValue) {
        if (oldValue === 'tradeIn' && newValue !== 'tradeIn') {
          this.validateForm()
          this.$emit('update', 'tradeIn', this.tradeInModel)
        }
      }
    },
    errors: {
      handler(errors) {
        this.inputErrors = { ...inputFieldErrorsDefaultState }
        errors.forEach(error => {
          const field = error.split(' ')[0]
          this.inputErrors[field] = this.t.errors[field] || error
        })
      }
    },
    'tradeInModel.willTradeIn'() {
      const height = this.$refs.tradeIn.clientHeight * 2.3
      if (this.tradeInModel.willTradeIn) this.elementHeight = height * 1.1 + 'px'
    }
  },
  mounted() {
    if (this.state) this.tradeInModel = { ...this.state, registrationDate: this.state?.registrationDate?.split('T')?.[0], pictures: JSON.parse(JSON.stringify(this.state.pictures)) }
    this.registrationTypes = [this.t.regTypeVanWithoutVat, this.t.regTypeVanWithVat, this.t.regTypePersonal, this.t.regTypeLeasing]
    this.serviceHistories = [this.t.serviceHistoryFull, this.t.serviceHistoryPartial, this.t.serviceHistoryNone]
    this.conditions = [this.t.carConditionPoor, this.t.carConditionGood, this.t.carConditionAverage, this.t.carConditionGreat]
  },
  methods: {
    async vehicleLookup() {
      this.inputErrors.licensePlate = ''
      this.loading = true
      try {
        const response = await this.queryApi(
          `query vehicleLookup {vehicleLookup(licensePlate: "${this.tradeInModel.licensePlate}") {brand,model,variant,vin,enginePower,registrationDate,fuelEfficiency,fuelType,inspectionDate,licensePlate,weightTotal}}`
        )
        this.tradeInModel.model = response.vehicleLookup.model
        this.tradeInModel.variant = response.vehicleLookup.variant
        this.tradeInModel.make = response.vehicleLookup.brand
        this.tradeInModel.vin = response.vehicleLookup.vin
        this.tradeInModel.fuelType = response.vehicleLookup.fuelType
        this.tradeInModel.registrationDate = response.vehicleLookup.registrationDate?.split('T')[0]
        this.showFullForm = true
        this.$nextTick(() => this.$refs.year.$el.focus())
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error(err)
        }
        this.inputErrors.licensePlate = this.t.errors.licenseplate
      } finally {
        this.loading = false
      }
      return false
    },
    async uploadImage(file, type) {
      try {
        const result = await this.uploadFile('trade-in/' + this.orderId, file, true)
        this.tradeInModel.pictures.push({ url: result, type: type })
        this.$emit('update', 'tradeIn', this.tradeInModel)
      } catch (err) {
        console.error('Error uploading image', err)
      }
    },
    validateForm() {
      this.$refs.licensePlateForm?.reportValidity()
      this.$refs.vehicleForm?.reportValidity()
    },
    getCurrentImage(title, suffix = 0) {
      return this.tradeInModel.pictures?.filter(img => img.type === title)?.[suffix]?.url
    },
    gotoNextSection() {
      this.$emit('gotoComponent', 'personalInfo')
    }
  }
}
</script>
<style lang="scss">
#tradeIn {
  &.fixedHeight {
    min-height: v-bind(elementHeight);
  }
  .centerAligned {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .loader {
    position: fixed;
    top: 0;
    z-index: 100;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 4px;
  }
  .subTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #4f4f4f;
  }
  .tradeInInfoBox {
    background: #f5f9ff;
    border-radius: 12px;
    padding: 25px;

    > .boxTitle {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .tradeInInformation {
      animation: fadeIn 1s;
    }
  }
  .tradeInQuestion {
    display: flex;
    justify-content: space-between;
    margin: 2.125rem 0;
    align-items: center;
    input[type='radio']:checked + label {
      background: #009a33;
    }
    > h3.title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }
  hr {
    margin: 1.875rem 0;
    border: 0;
    width: 100%;
    background: #0068ff;
    height: 2px;
  }

  .licensePlate > span {
    display: flex;
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    margin: 0 0 30px 0;
    > span.prefix {
      padding: 0.5em 1em;
      position: relative;
      background: #003e99;
      border-radius: 2em 0 0 2em;
      display: flex;
      flex-direction: column;
      margin: -0.4px -1px;
      > span {
        font-family: 'Biennale';
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 11px;
        text-align: center;
        padding-top: 4px;
        color: white;
      }
    }

    &.error {
      > input {
        border-left-color: red;
        border-top-color: red;
        border-bottom-color: red;
      }

      > span {
        border-color: red;

        &::before {
          content: '!';
          color: red;
          position: absolute;
          top: 0.5em;
          left: -4em;
          border: 1px solid red;
          border-radius: 2em;
          height: 1em;
          width: 1em;
          padding: 0.125em;
          text-align: center;
          line-height: 1em;
          font-weight: bold;
        }
      }
    }
  }
  .licensePlateForm {
    display: flex;
    .licensePlate {
      .seezInput {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 5px;
      }
    }
    button.search {
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      overflow: hidden;
      outline: none;
      display: flex;
      justify-self: center;
      align-items: center;
      color: white;
      text-decoration: none;
      cursor: pointer;
      width: 124px;
      height: 2.8125rem;
      padding: 1rem;
      background: #0068ff;
      border-radius: 24px;
      margin: 3px;
      font-family: 'Biennale';
      font-weight: 900;
      font-size: 1rem;
      line-height: 22px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      > img {
        position: relative;
        left: -2px;
      }
    }
  }

  .detailQuestions {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .detailQuestion {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    > span {
      flex: 2;
    }
    > div {
      flex: 1;
    }
  }

  .carDetails {
    width: 100%;
    .carDetailsInputs {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
      gap: 14px;
      width: 100%;
    }
  }

  .radiusInput {
    @media screen and (max-width: 42rem) {
      grid-column-end: span 2;
    }
  }

  .radiusInputSelect {
    width: 100%;
    & span {
      line-height: 29px;
      font-size: 14px;
    }
    & button svg {
      top: 46%;
    }

    @media screen and (max-width: 42rem) {
      grid-column-end: span 2;
    }
  }

  .conditionDetails {
    > div {
      padding: 0.75rem;
      border-radius: 5px;
    }
    .goodCarText {
      color: #0068ff;
      background: #cce1ff;
    }

    .poorCarText {
      color: #ff0009;
      background: #ffb3b5;
    }

    .averageCarText {
      color: #ffa200;
      background: #ffeccc;
    }

    .greatCarText {
      color: #009a33;
      background: #b3e1c2;
    }
  }

  textarea.carQuirks {
    max-height: 7.5rem;
    min-height: 5rem;
    border: 1px solid #cbcbcb;
    border-radius: 12px;
    resize: none;
    padding: 0.5rem;
  }
  .uploadSection {
    display: flex;
    flex-direction: column;
  }
  .uploadCarPhotos {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    label {
      max-width: 12rem;
    }
    button {
      max-width: 5rem;
    }
  }
  .vehicleForm {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    .addTradeInTitle {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
    .addTradeInSubTitle {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #545454;
    }
    .scrollDown {
      margin: 4rem auto;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 56rem) {
    .tradeInQuestion,
    .detailQuestion {
      flex-direction: column;
    }
    .detailQuestion {
      > div {
        width: 100%;
      }
    }
  }
}
</style>
