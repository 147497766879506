<template>
  <div class="resultsList">
    <div class="listingsList" v-if="!loading && error == null && listings.length > 0" ref="listingsList">
      <ListingCard v-for="(l, i) in listings" :key="l.id" :index="i" :details="l" @click="listingClicked" :to="to" :target="target" />
      <div class="pages" v-if="paging && !loading">
        <button @click="jumpToPrev()">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9L1 5L5 1" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <button v-for="i in pagesList" :key="i" :class="{ selected: paging.page === i, separator: i < 0 }" @click="jumpToPage(i)">{{ i > 0 ? i : '...' }}</button>
        <button @click="jumpToNext()">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9L5 5L1 1" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>

      <p v-if="brandData" class="SEO">{{ brandData.description }}</p>
    </div>
    <EmptySearch v-if="isEmptySearch" :is-button-action="true" :title="t['no_results_found']" :description="t['please_try_different_search']" :lg="lg" />
    <div>
      <p v-if="brandData && listings.length === 0" class="SEO">
        {{ brandData.description }}
      </p>
    </div>
    <p v-if="error != null" class="error">{{ error }}</p>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import { formatFilterForApi } from '../../logic.js'
import SeezSdk from '../../sdk.js'
import { lang } from '../lang'
import ListingCard from '../ListingCard.ce.vue'
import EmptySearch from '../EmptySearch.ce.vue'
import Loader from '../Loader.ce.vue'

export default {
  name: 'SeezResultsPanel',
  components: { ListingCard, Loader, EmptySearch },
  mixins: [lang, SeezSdk.vueQueryMixin],
  inheritAttrs: false,
  props: {
    modelValue: { type: Object, required: true },
    options: { type: Object, required: true },
    // sorting: { type: String, default: '-created_date' },
    to: { type: String, default: null },
    target: { type: String, default: null }
  },
  data() {
    return {
      listings: [],
      loading: false,
      error: null,
      paging: {}
    }
  },
  computed: {
    t() {
      return this.languageResources.RESULT_PANEL_COMPONENT_TRANSLATIONS
    },
    brandData() {
      if (this.modelValue.brands == null) return null
      const brandOption = this.options.brands.find(o => o.id === this.modelValue.brands)
      if (brandOption == null) return null
      const brandSeo = this.languageResources.SEO_DATA[this.$root.slugify(brandOption.name)]
      return brandSeo
    },
    listingsFilter() {
      return formatFilterForApi(this.modelValue)
    },
    isEmptySearch() {
      return this.listings.length === 0 && this.error == null && !this.loading
    },
    pagesList() {
      if (this.paging?.pages > 0 && this.paging?.page > 0) {
        const c = this.paging.page
        const t = this.paging?.pages
        const pagesWithDuplicates = [1, 2, 3, c - 2, c - 1, c, c + 1, c + 2, t - 2, t - 1, t]
        let pages = [...new Set(pagesWithDuplicates)].filter(i => i >= 1 && i <= t)
        pages.sort((a, b) => (parseInt(a) > parseInt(b) ? 1 : -1))
        let index = 0
        while (index < pages.length - 1) {
          if (pages[index] > 0 && pages[index] != pages[index + 1] - 1) {
            pages.splice(index + 1, 0, -index)
          }
          index++
        }

        return pages
        //return [...Array(this.paging.pages).keys()].map((i) => i + 1)
      }
      return []
    },
    title() {
      if (this.modelValue?.brands && this.options?.brands) return this.options?.brands?.find(b => b.id === this.modelValue.brands.toString())?.name
      return 'Search results'
    },
    description() {
      return null
    }
  },
  watch: {
    listingsFilter() {
      this.loadListings()
    }
  },
  mounted() {
    this.loadListings()
  },
  methods: {
    async loadListings() {
      this.abortController?.abort()
      this.abortController = new AbortController()

      const listingsQuery =
        'query filteredListings($filter: ListingFiltersInput, $page: Int){listings(filter:$filter,page:$page,perPage:24){nodes{id,name, model{id, name},variant,brand{id, name},year,currency,priceRetail,priceMonthly,pictures,kilometrage,dealership{id,name,logoUrl},fuelType{name}, bodyType {name}, transmission {name} reserved, state, registrationDate,color,reservationExpires},pageInfo{total,page,perPage,pages}}}'
      const page = this.modelValue.page ?? 1
      const payload = { filter: JSON.parse(JSON.stringify(this.listingsFilter)), page: page }
      this.loading = true
      this.error = null
      try {
        const result = await this.queryApi(listingsQuery, payload)
        this.listings = result.listings.nodes
        this.paging = result.listings.pageInfo
        this.$emit('results', result.listings)
        this.loading = false
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          this.error = this.t.error_listing
          this.loading = false
        }
      }
    },
    listingClicked(e, details) {
      this.$emit('click', e, details)
    },
    sortDictionary(unordered) {
      return Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unordered[key]
          return obj
        }, {})
    },
    jumpToPage(i) {
      // se o numero do index for menor ou igual a 0 OU se o index for menor que pages, PARE
      if (i <= 0 || i > this.paging.pages) return

      const oldValue = this.modelValue.page ?? 1
      if (i === oldValue) return

      const newFilter = this.sortDictionary({ ...this.modelValue, page: i })

      if (i <= 1) delete newFilter.page
      this.$emit('update:modelValue', newFilter)
    },
    jumpToNext() {
      const activePage = this.paging.page
      const totalPossiblePages = this.paging.total / this.paging.perPage
      const lastPage = Math.ceil(totalPossiblePages)

      if (activePage === lastPage) return

      const newFilter = this.sortDictionary({ ...this.modelValue, page: activePage + 1 })
      this.$emit('update:modelValue', newFilter)
    },
    jumpToPrev() {
      if (this.paging.page === 1) return

      const newFilter = this.sortDictionary({ ...this.modelValue, page: this.paging.page - 1 })
      this.$emit('update:modelValue', newFilter)
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.resultsList {
  @include theme;

  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'results' 'pages' 'SEO';
  overflow: hidden;

  .SEO {
    display: flex;
    flex-direction: column;
    color: #757575;
    font-size: 1rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }

  > .listingsList {
    @include scrollBars;
    grid-area: results;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
    justify-content: flex-start;
    gap: 1em;
    overflow: hidden auto;
    padding: 1em;

    @media screen and (max-width: 28rem) {
      grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
    }

    > .pages {
      display: flex;
      flex-direction: row;
    }

    > .pages,
    .SEO {
      grid-column: 1 / -1;
      place-self: center;

      gap: 0.35em;

      > button {
        border: none;
        background-color: transparent;
        border-radius: 2em;
        padding: 0.25em;
        cursor: pointer;
        color: #333333;
        width: 2em;
        height: 2em;
        font-family: 'Biennale', sans-serif;

        &:hover,
        &:focus {
          background-color: #fafafa;
        }

        &.selected {
          background-color: #fafafa;
          color: var(--highlight);
          cursor: default;
          font-weight: 500;
        }

        &.separator {
          cursor: default;
          background-color: transparent;
          width: auto;
          padding: 0.5em 0;
        }
      }
    }
  }

  > p {
    grid-area: results;
    font-size: 2em;

    &.error {
      color: red;
    }
  }

  @media screen and (max-width: 42rem) {
    padding: 0;
  }

  .loader {
    grid-area: results;
    align-self: center;
    justify-self: center;
  }
}
</style>
