export const StepsStatus = {
  tradeIn: {
    pending: {
      status: 'pending',
      title: 'tradeIn',
      statusText: 'pendingTradeInOffer',
      boxBody: 'ourAgentsAreReviewingYourTradeInDetails',
      buttonText: 'viewOffer',
      disabledAction: true
    },
    action: {
      status: 'action',
      title: 'tradeIn',
      statusText: 'pendingCustomerDecision',
      boxBody: 'reviewYourTradeInOffer',
      buttonText: 'viewOffer',
      disabledAction: false
    },
    complete: {
      status: 'complete',
      title: 'tradeIn',
      statusText: 'tradeInCompleted',
      boxBody: 'theTradeInAmountWillBeDeductedFromYour',
      buttonText: null,
      disabledAction: false
    },
    rejected: {
      status: 'rejected',
      title: 'tradeIn',
      statusText: 'tradeInCompleted',
      boxBody: 'tradeInRejecteed',
      buttonText: null,
      disabledAction: false
    }
  },
  financing: {
    action: {
      status: 'action',
      title: 'financing',
      statusText: 'pendingFinancingApplication',
      boxBody: 'meanwhilePleaseCompleteYourFinancingApplication',
      buttonText: 'goToApplication',
      disabledAction: false
    },
    complete: {
      status: 'complete',
      title: 'financing',
      statusText: 'financingApplicationSubmitted',
      boxBody: 'financingYouCanReachOut',
      buttonText: 'returnToFinancingApplication',
      disabledAction: false
    }
  },
  insurance: {
    action: {
      status: 'action',
      title: 'insurance',
      statusText: 'insuranceYouHaveSelected',
      boxBody: 'insuranceTrygYouCanStill',
      buttonText: 'insuranceApplyNow',
      disabledAction: false,
      iconBg: '#DC0000'
    }
  },
  spa: {
    pending: {
      status: 'pending',
      title: 'spaTitle',
      statusText: 'spaPendingSteps',
      boxBody: 'spaWaitingText',
      buttonText: 'spaSignAgreement',
      disabledAction: true
    },
    action: {
      status: 'action',
      title: 'spaTitle',
      statusText: 'spaAwaitingSignature',
      boxBody: 'spaWaitingText',
      buttonText: 'spaSignAgreement',
      disabledAction: false
    }
  }
}
