import SeezSdk from './sdk.js'
window.seezSdk = new SeezSdk()
window.onload = () => window.seezSdk.injectSeezOnlineBuying()

import { defineCustomElement } from 'vue'

import ListingCard from './components/ListingCard.ce.vue'
import EmptySearch from './components/EmptySearch.ce.vue'
import SavedSearch from './components/SavedSearches/SavedSearch.ce.vue'
import MultiSelect from './components/MultiSelect.ce.vue'
import FilterPanel from './components/Search/FilterPanel.ce.vue'
import ResultsPanel from './components/Search/ResultsPanel.ce.vue'
import OrderCard from './components/Orders/OrderCard.ce.vue'
import InputCode from './components/Session/InputCode.ce.vue'
import Loader from './components/Loader.ce.vue'
import Toggle from './components/Toggle.ce.vue'

import Modal from './components/Modal.ce.vue'
customElements.define('seez-sdk-modal', defineCustomElement(Modal))

import TradeInDrawer from './components/ListingDetails/TradeInDrawer.ce.vue'
TradeInDrawer.styles = [...TradeInDrawer.styles, ...MultiSelect.styles, ...Loader.styles]
customElements.define('seez-sdk-trade-in', defineCustomElement(TradeInDrawer))

import Calculator from './components/Calculator/Calculator.ce.vue'
Calculator.styles = [...Calculator.styles, ...Loader.styles]
customElements.define('seez-sdk-calculator', defineCustomElement(Calculator))

import Carousel from './components/Carousel/Carousel.ce.vue'
Carousel.styles = [...Carousel.styles, ...Loader.styles, ...ListingCard.styles]
customElements.define('seez-sdk-carousel', defineCustomElement(Carousel))

import Favorites from './components/Favorites/Favorites.ce.vue'
Favorites.styles = [...Favorites.styles, ...Loader.styles, ...ListingCard.styles, ...EmptySearch.styles]
customElements.define('seez-sdk-favorites', defineCustomElement(Favorites))

import Orders from './components/Orders/Orders.ce.vue'
Orders.styles = [...Orders.styles, ...OrderCard.styles, ...Loader.styles]
customElements.define('seez-sdk-orders', defineCustomElement(Orders))

import ActiveOrderCancellation from './components/Orders/ActiveOrderCancellation.ce.vue'
ActiveOrderCancellation.styles = [...ActiveOrderCancellation.styles, ...Modal.styles, ...Loader.styles]
customElements.define('seez-sdk-active-order-cancellation', defineCustomElement(ActiveOrderCancellation))

import UnavailableListingModal from './components/Orders/UnavailableListingModal.ce.vue'
UnavailableListingModal.styles = [...UnavailableListingModal.styles, ...Modal.styles, ...Carousel.styles, ...Loader.styles]
customElements.define('seez-sdk-unavailable-listing-modal', defineCustomElement(UnavailableListingModal))

import SavedSearches from './components/SavedSearches/SavedSearches.ce.vue'
SavedSearches.styles = [...SavedSearches.styles, ...SavedSearch.styles, ...Loader.styles, ...EmptySearch.styles]
customElements.define('seez-sdk-saved-searches', defineCustomElement(SavedSearches))

import Search from './components/Search/Search.ce.vue'
Search.styles = [...Search.styles, ...FilterPanel.styles, ...Loader.styles, ...MultiSelect.styles, ...ResultsPanel.styles, ...ListingCard.styles, ...EmptySearch.styles]
customElements.define('seez-sdk-search', defineCustomElement(Search))

import LoginModal from './components/Session/LoginModal.ce.vue'
LoginModal.styles = [...LoginModal.styles, ...InputCode.styles, ...Modal.styles, ...Loader.styles]
customElements.define('seez-sdk-login', defineCustomElement(LoginModal))

import UserProfile from './components/Session/Profile.ce.vue'
UserProfile.styles = [...UserProfile.styles, ...Loader.styles, ...Toggle.styles]
customElements.define('seez-sdk-user-profile', defineCustomElement(UserProfile))

import CheckoutReview from './components/Checkout/Review.ce.vue'
import TradeInOffer from './components/Checkout/TradeInOffer.ce.vue'
import SpaSign from './components/Checkout/SpaSign.ce.vue'
import BankTransfer from './components/Checkout/BankTransfer.ce.vue'
import Congratulations from './components/Checkout/Congratulations.ce.vue'
import CheckoutForm from './components/Checkout/form/CheckoutForm.ce.vue'
import Checkout from './components/Checkout/Checkout.ce.vue'
import CheckoutOverview from './components/Checkout/Overview/Overview.ce.vue'
import StepCard from './components/Checkout/Overview/StepCard.ce.vue'
import CheckoutHeader from './components/Checkout/Checkout.Header.ce.vue'

import CheckOutSideNav from './components/Checkout/form/SideNav.ce.vue'
import SeezSideNav from './components/Checkout/form/SeezSideNav.ce.vue'
import CheckoutTradeIn from './components/Checkout/form/1-TradeIn.ce.vue'
import Payment from './components/Checkout/form/4-Payment.ce.vue'
import Personal from './components/Checkout/form/2-Personal.ce.vue'
import CarDelivery from './components/Checkout/form/3-CarDelivery.ce.vue'
import ImageSlider from './components/Checkout/form/Image.Slider.ce.vue'

import Tooltip from './components/Checkout/components/Tooltip.ce.vue'
import Input from './components/Checkout/components/InputWError.ce.vue'
import Radio from './components/Checkout/components/Radio.ce.vue'
import UploadImage from './components/Checkout/components/Upload.ce.vue'
import Step from './components/Checkout/components/Step.ce.vue'
import SeezButton from './components/Checkout/components/SeezButton.ce.vue'
import MultiSelectWithError from './components/Checkout/components/MultiSelectWError.ce.vue'
import OrderInfo from './components/Checkout/components/OrderInfo.ce.vue'
import CheckoutOrderCard from './components/Checkout/components/CheckoutOrderCard.ce.vue'
import FormTab from './components/Checkout/components/Form.Tab.ce.vue'
import StepsOverview from './components/Checkout/components/StepsOverview.ce.vue'
import Summary from './components/Checkout/components/Summary.ce.vue'

CheckoutOverview.styles = [...CheckoutOverview.styles, ...Loader.styles, ...StepCard.styles]

CheckoutTradeIn.styles = [
  ...CheckoutTradeIn.styles,
  ...Loader.styles,
  ...Tooltip.styles,
  ...Radio.styles,
  ...Input.styles,
  ...MultiSelect.styles,
  ...UploadImage.styles,
  ...SeezButton.styles,
  ...MultiSelectWithError.styles,
  ...StepsOverview.styles,
  ...Summary.styles
]

CheckOutSideNav.styles = [...CheckOutSideNav.styles, ...Loader.styles, ...Step.styles, ...OrderInfo.styles, ...CheckoutOrderCard.styles]
CheckoutForm.styles = [
  ...CheckoutForm.styles,
  ...CheckOutSideNav.styles,
  ...CheckoutTradeIn.styles,
  ...Payment.styles,
  ...Personal.styles,
  ...CarDelivery.styles,
  ...Loader.styles,
  ...ImageSlider.styles,
  ...FormTab.styles
]

Checkout.styles = [
  ...Checkout.styles,
  ...CheckoutReview.styles,
  ...CheckoutOverview.styles,
  ...TradeInOffer.styles,
  ...SpaSign.styles,
  ...BankTransfer.styles,
  ...Congratulations.styles,
  ...Congratulations.styles,
  ...CheckoutForm.styles,
  ...Loader.styles,
  ...CheckoutHeader.styles,
  ...SeezSideNav.styles
]

customElements.define('check-out', defineCustomElement(Checkout))
