<template>
  <div id="slider">
    <transition-group name="slider" tag="div">
      <div v-for="index in [current]" :key="index">
        <img :src="images[index]" />
      </div>
    </transition-group>
    <a class="previous" @click="changeToPrevious()" v-show="isPreviousAvailable"><img :src="chevronLeft" /></a>
    <a class="next" @click="changeToNext()" v-show="isNextAvailable"><img :src="chevronRight" /></a>
  </div>
</template>
<script>
import { chevBlueLeft, chevBlueRight } from '../../../assets/images'
export default {
  name: 'ImageSlider',
  props: { images: { type: Array, default: () => [] } },
  data() {
    return {
      current: 0
    }
  },
  computed: {
    chevronLeft() {
      return chevBlueLeft
    },
    chevronRight() {
      return chevBlueRight
    },
    isPreviousAvailable() {
      return this.current > 0
    },
    isNextAvailable() {
      return this.current + 1 < this.images.length
    }
  },
  methods: {
    changeToNext() {
      if (this.isNextAvailable) {
        this.current++
      }
    },
    changeToPrevious() {
      if (this.isPreviousAvailable) {
        this.current--
      }
    }
  }
}
</script>
<style lang="scss">
#slider {
  position: relative;
  height: 15rem;
  div{
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0px 0px;
  }
  .slider-enter-active,
  .slider-leave-active {
    transition: all 0.9s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;
    width: 100%;
    opacity: 1;
  }

  .slider-enter,
  .slider-leave-to {
    visibility: hidden;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }

  .previous,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transition: 0.7s ease;
    background: #ffffff;
    opacity: 0.9;
    border-radius: 50%;
    padding: 0.5625rem;
    height: 0.75rem;
    width: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #ffffffb0;
    }
  }

  .next {
    right: 0.625rem;
    > img {
      padding-left: 0.125rem;
    }
  }

  .previous {
    left: 10px;
    > img {
      padding-right: 0.125rem;
    }
  }
}
</style>
