<template>
  <a class="orderCard" :href="'/listing/' + orderId" data-test-id="card">
    <div class="carInformation">
      <loader v-if="!carPictureIsLoaded" />
      <img
        :class="carPictureIsLoaded ? 'carImage' : 'loading carImage'"
        :src="imageUrl"
        loading="lazy"
        @error="imageError = true"
        :alt="fullName"
        @load="carPictureIsLoaded = true"
        data-test-id="card-image"
      />
      <div class="details">
        <span class="name" data-test-id="card-name">{{ fullName }}</span>
        <span class="variant" data-test-id="card-variant-year">{{ variant }} {{ year }}</span>
        <span class="price" data-test-id="car-price">{{ retailPriceString }}</span>
      </div>
    </div>
  </a>
</template>
<script>
import Loader from '../../Loader.ce.vue'

export default {
  name: 'OrderCard',
  components: { Loader },
  props: {
    orderId: { type: String, default: '' },
    imageSrc: { type: String, default: '' },
    fullName: { type: String, default: '' },
    variant: { type: String, default: '' },
    year: { type: Number, default: null },
    retailPriceString: { type: String, default: '' }
  },
  data() {
    return {
      carPictureIsLoaded: false,
      imageError: false
    }
  },
  computed: {
    imageUrl() {
      if (this.imageError || !this.imageSrc) return ''
      return import.meta.env.VITE_IMAGES_URL + this.imageSrc
    }
  }
}
</script>
<style lang="scss">
a.orderCard {
  cursor: pointer;
  text-decoration: none;
  color: initial;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 10px hsla(0, 0%, 70%, 0.6);
  }

  .carInformation {
    display: flex;
    background: #ffffff;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
    border-radius: 0.75rem;

    .details {
      margin: 10px;
      display: flex;
      flex-direction: column;
      > .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
      }
      .price {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-top: auto;
        margin-left: auto;
      }
    }
    > .carImage {
      max-width: 9rem;
      width: 100%;
      border-radius: 0.75rem 0 0 0.75rem;
    }
    > .loading.carImage {
      opacity: 0;
    }
    > .loader {
      margin: auto;
    }
  }
}
</style>
