import { orderStateQuery, dQuoteWrapper as dq, paymentQuery } from './queries'
const stages = ['tradeIn', 'customerInfo', 'delivery', 'payment']
const deliveryTypeDefault = 'home'

export class OrderState {
  constructor() {
    this.previousStage = null
    this.currentStage = 'tradeIn'
    this.tradeInState = {
      willTradeIn: null,
      vin: null,
      licensePlate: null,
      make: null,
      model: null,
      year: null,
      variant: null,
      registrationDate: null,
      kilometrage: null,
      hasFinancing: null,
      outstandingFinanceAmount: null,
      customerComment: null,
      fuelType: null,
      carCondition: null,
      registrationType: null,
      color: null,
      numberOfKeys: null,
      accident: null,
      serviceType: null,
      pictures: []
    }
    this.personalInfoState = {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      nationalID: '',
      insurance: '',
      street: '',
      street2: '',
      postalCode: '',
      city: ''
    }
    this.listing = {
      id: null,
      brand: {
        name: null
      },
      model: {
        name: null
      },
      variant: null,
      priceRetail: null,
      primaryImageUrl: null,
      year: null,
      reservationExpires: null,
      referenceNumber: null,
      showroom: {
        registry: null,
        bankaccount: null,
        bankRegistration: null
      },
      dealership: {
        name: null,
        bankName: null
      },
      pictures: [],
      addons: [],
      vehicleId: null,
      kilometrage: null,
      color: null,
      registrationDate: null,
      family: null,
      fuelType: {
        name: null
      },
      transmission: {
        name: null
      },
      bodyType: {
        name: null
      }
    }

    this.paymentState = {
      type: null,
      financingState: null,
      downPayment: null,
      paymentTerms: null
    }
    this.deliveryState = {
      type: deliveryTypeDefault,
      preferredDate: null,
      date: null,
      time: null,
      street: null,
      street2: null,
      city: null,
      postalCode: null
    }
    this.appState = {
      current: null,
      customerInfo: {
        sequence: null,
        state: null,
        errors: []
      },
      tradeIn: {
        sequence: 1,
        state: null,
        errors: []
      },
      payment: {
        sequence: 6,
        state: null,
        errors: []
      },
      customization: {
        sequence: 4,
        state: null,
        errors: []
      },
      delivery: {
        sequence: 5,
        state: null,
        errors: []
      }
    }
    this.personalInfoResponse = { ...this.personalInfoState }
    this.paymentResponse = { ...this.paymentState }
    this.deliveryResponse = { ...this.deliveryState }
    this.appResponse = { ...this.appState }
    this.tradeInResponse = JSON.parse(JSON.stringify(this.tradeInState))
    this.spaResponse = { ...this.spa }
  }
  setOrder(order) {
    this.id = order.id
    this.state = order.state
    this.customerId = order.customerId
    this.listing = order.listing
    this.personalInfoState = order.customerInfo
    this.paymentState = order.payment
    this.deliveryState = order.delivery
    if (!this.deliveryState.type) this.deliveryState.type = deliveryTypeDefault
    this.appState = order.appState
    if (order.tradeIn) this.tradeInState = order.tradeIn
    if (this.tradeInState) this.tradeInState.willTradeIn = true
    this.spaState = order.spa

    this.personalInfoResponse = Object.freeze({ ...order.customerInfo })
    this.paymentResponse = Object.freeze({ ...order.payment })
    this.deliveryResponse = Object.freeze({ ...order.delivery })
    this.appResponse = Object.freeze({ ...order.appState })
    this.tradeInResponse = Object.freeze(JSON.parse(JSON.stringify(order.tradeIn)))
    this.spaResponse = Object.freeze(order.spa)
  }
  personalInfoStateToQuery() {
    return `
      {
        firstName: ${dq(this.personalInfoState?.firstName)},
        middleName: ${dq(this.personalInfoState?.middleName)},
        lastName: ${dq(this.personalInfoState?.lastName)},
        email: ${dq(this.personalInfoState?.email)},
        phone: ${dq(this.personalInfoState?.phone)},
        nationalID: ${dq(this.personalInfoState?.nationalID)},
        insurance: ${dq(this.personalInfoState?.insurance)}
        address: {
          street: ${dq(this.personalInfoState?.address?.street)},
          street2: ${dq(this.personalInfoState?.address?.street2)},
          postalCode: ${dq(this.personalInfoState?.address?.postalCode)},
          city: ${dq(this.personalInfoState?.address?.city)},
          country: "Denmark",
        }
      }
      `
  }
  tradeInStateToQuery() {
    // Weird eslint error: reading range!
    // https://stackoverflow.com/questions/48391913/eslint-error-cannot-read-property-range-of-null
    const picturesQuery = Array.isArray(this.tradeInState?.pictures)
      ? this.tradeInState.pictures?.map(
        e =>
          `
              {
                url: "` +
            e.url +
            `",
                type: ` +
            e.type +
            `
              }
              `
      )
      : []

    return `
      {
        orderId: ${this.id},
        vin: ${dq(this.tradeInState.vin)},
        licensePlate: ${dq(this.tradeInState.licensePlate)},
        make: ${dq(this.tradeInState.make)},
        model: ${dq(this.tradeInState.model)},
        year: ${this.tradeInState.year || null},
        variant: ${dq(this.tradeInState.variant)},
        registrationDate: ${dq(this.tradeInState.registrationDate)},
        kilometrage: ${this.tradeInState.kilometrage || null},
        hasFinancing: ${this.tradeInState.hasFinancing || 'false'},
        outstandingFinanceAmount: ${this.tradeInState.outstandingFinanceAmount || 0},
        customerComment: ${dq(this.tradeInState.customerComment)?.replaceAll('\n', ' ') || '""'},
        fuelType: ${dq(this.tradeInState.fuelType)},
        carCondition: ${dq(this.tradeInState.carCondition)},
        registrationType: ${dq(this.tradeInState.registrationType)},
        color: ${dq(this.tradeInState.color)},
        numberOfKeys: ${this.tradeInState.numberOfKeys || null},
        accident: ${this.tradeInState.accident ?? false},
        serviceType: ${dq(this.tradeInState.serviceType)},
        pictures: [${picturesQuery.join(',')}]
      }
      `
  }
  checkIfUpdateIsRequired(force) {
    if (force) return true
    switch (this.previousStage) {
      case 'customerInfo':
        return JSON.stringify(this.personalInfoState) !== JSON.stringify(this.personalInfoResponse)
      case 'tradeIn':
        return JSON.stringify(this.tradeInState) !== JSON.stringify({ ...this.tradeInResponse, registrationDate: this.tradeInResponse?.registrationDate?.split('T')?.[0] })
      case 'payment':
        return JSON.stringify(this.paymentState) !== JSON.stringify(this.paymentResponse)
      case 'delivery':
        return (
          JSON.stringify(this.deliveryState) !==
          JSON.stringify({
            ...this.deliveryResponse,
            date: this.deliveryResponse?.preferredDate?.split('T')?.[0] || null,
            time: this.deliveryResponse?.preferredDate?.split('T')?.[1]?.split('.')?.[0] || null
          })
        )
    }
    return true
  }
  paymentStateToQuery() {
    return `
        {
        type: ${this.paymentState.type},
        financing: {
          state: ${this.paymentState.financingState || null},
          downPayment: ${this.paymentState.financing?.downPayment || null},
          paymentTerms: ${this.paymentState.financing?.paymentTerms || null},
          returnUrl: "${import.meta.env.VITE_SEEZ_BASE_URL}/checkout/?order=${this.id}&",
          interestType: ${this.paymentState.financing?.interestType || null},
        }
      }
      `
  }
  prepareSubmitPaymentToQuery(id) {
    return `
      mutation {
        submitPayment(
          orderId: ${id}
        ) {
          ${paymentQuery}
        }
      }
    `
  }
  prepareSetPaymentSubmittedToQuery(id) {
    return `
      mutation {
        updateOrder(orderId: ${id}, payment: {financing:{state: submitted}}) {
          payment {
            financing {
              state
            }
          }
        }
      }
    `
  }
  customizationStateToQuery() {
    return `
      {
        addonIds: ${this.customizationState.selectedAddOnIds}
      }
      `
  }
  deliveryStateToQuery() {
    let preferredDate
    if (this.deliveryState.date && this.deliveryState.time) {
      try {
        const [year, month, day] = this.deliveryState.date.split('-')
        const [hour, minute] = this.deliveryState.time.split(':')
        preferredDate = new Date(year, month, day, hour ?? 9, minute, 0).toISOString()
      } catch (e) {
        console.error('Error parsing date and time', e)
      }
    }
    return `
    {
      type: ${this.deliveryState.type},
      preferredDate: ${dq(preferredDate)},
      address: {
        street: ${dq(this.deliveryState?.address?.street)},
        street2: ${dq(this.deliveryState?.address?.street2)},
        city: ${dq(this.deliveryState?.address?.city)},
        postalCode: ${dq(this.deliveryState?.address?.postalCode)},
        country: "Denmark"
      }
    }
    `
  }
  prepareCancelOrderMutation(id) {
    return `
      mutation {
        cancelOrder(orderId: ${id}) {
          ${orderStateQuery}
        }
      }
    `
  }
  prepareRejectTradeInOfferMutation(id) {
    return `
      mutation {
        rejectOrderTradeInOffer(
          orderId: ${id}
        ) {
          id
        }
      }
    `
  }
  prepareAcceptTradeInOfferMutation(id) {
    return `
      mutation {
        acceptOrderTradeInOffer(
          orderId: ${id}
        ) {
          id
        }
      }
    `
  }
  prepareUpdateMutation(summary) {
    let previousStage = this.previousStage
    const currentStage = this.currentStage
    if (!previousStage) return
    var stepInput = ''
    if (previousStage == 'customerInfo') {
      this.appState.customerInfo.loading = true
      stepInput += 'customerInfo: ' + this.personalInfoStateToQuery() + ','
    }
    if (previousStage == 'tradeIn') {
      if (this.tradeInState.willTradeIn != null) {
        this.appState.tradeIn.loading = true
        stepInput += 'tradeIn: ' + this.tradeInStateToQuery() + ','
        stepInput += 'willTradeIn: ' + this.tradeInState.willTradeIn
      } else {
        previousStage = ''
      }
    }
    if (previousStage == 'payment') {
      if (this.paymentState.type != null) {
        this.appState.payment.loading = true
        stepInput += 'payment: ' + this.paymentStateToQuery() + ','
      } else {
        previousStage = ''
      }
    }
    if (previousStage == 'customization') {
      stepInput += 'customization: ' + this.customizationStateToQuery() + ','
    }
    if (previousStage == 'delivery') {
      this.appState.delivery.loading = true
      stepInput += 'delivery: ' + this.deliveryStateToQuery() + ','
    }

    const updateOrder = `
      mutation {
        updateOrder(
          orderId: ${this.id},
          appState: ${summary || currentStage},
          ${stepInput}
          validateSteps: [${previousStage}]
        ) {
          ${orderStateQuery}
        }
      }
      `
    return updateOrder
  }
  prepareSignSpaMutation(id, returnUrl) {
    return `
      mutation {
        createSigningView(orderId: ${id}, returnUrl: "${returnUrl}") {
          documentId
          redirectUrl
        }
      }
    `
  }
  prepareCompleteTransferMutation(id) {
    return `
      mutation {
        initiateBankTransfer(
          orderId: ${id}
        ) {
          ${paymentQuery}
        }
      }
    `
  }
  updateTradeInState(state) {
    this.tradeInState = state
  }
  updatePersonalState(state) {
    this.personalInfoState = state
  }
  updateCarDeliveryState(state) {
    this.deliveryState = state
  }
  updatePaymentState(state) {
    this.paymentState = state
  }
  isStageDone(stage) {
    if (stage === 'tradeIn' && !this.tradeInState) return true
    if (!this.appState) return false
    return this.appState?.[stage]?.state === 'complete'
  }
  isAllStagesDone() {
    return this.isStageDone('customerInfo') && this.isStageDone('delivery') && this.isStageDone('payment') && this.isStageDone('tradeIn')
  }
  allStepsComplete(currentStage) {
    if (this.appState.tradeIn?.state !== 'complete' && this.tradeInState?.willTradeIn) return false
    if (!this.paymentState?.type) return false

    // if (this.paymentState && this.paymentState.type != 'bankTransfer' && this.paymentState?.financing?.state != 'submitted') return false
    for (let stage of stages) {
      if (stage === currentStage) continue
      if (!this.isStageDone(stage)) {
        return false
      }
    }
    return true
  }
}
