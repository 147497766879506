export const CAR_PLACEHOLDER = 'data:image/webp;base64,UklGRuQEAABXRUJQVlA4WAoAAAAIAAAAjwEAzgAAVlA4IOYDAABQNACdASqQAc8APoFAm0iioieno1No0PYQCelu4XShG87WB9DwaieeMWXz7gvcF7gvcF7gvcF7gvcF7gvcF7gvcF7gvcF7gvcF7gvIkQH+k+FfnWonnjFl8+Xesp13YLVvT4iT9VfwUyITxbPcq6LvAaC9wXuC9qkm1ju8tsqCCbuOeEdPi3iJSkjdT9I81mpAzGqvzqduj8XKEMVQCwGAuzYiK+CFVE88YiwraNzhSCbFBBSVgZhvcdD8TfdiMXrlFZsCv3eSE1sDN4ftdDDJnwE4iFYGruKP+zB3s1Mi4VZY0B+7RgvN9418bsLrOb8tN/auEeiM5yVvbeASVxzSV96YfDw9aY2aeXGizC6UMJ4XM4LvD+kBSnFVEFHRcR2/46Rn1VOtUU0XbNYuW4u8RaZcTmvUhpq8UVBIg7527iRZZV7UxLIDnoxq15ahPRNM3bsirJLa/hd+crOv6d9z6nnjFl8+4QiQ79D8TUR/ERdGARrRlIUVSIu7/+w+eYEh4NRPPGLL5AQYiH6CMWXz7gvcF7gvcF7gvcF7gvcF7gvcF7gvcF7gvaAAAP7/U1AAABgYl2Ex48aW1yaN4FvqQMTqtuZUAHcAl8dKGu8FyuH/cqiE0aNNEX0+cmAdZoHRM8NMa/xDSoZn0Rl4SW1XlOttc6uRdoGhUQlU6RTLJNLiektdoH4MNxjmXBRWRN9zDjxQgGsfvPzPEnRv3rzKTZouYrinxiChtVarNxlm0Ce7J4qAUr7jONHjm/yAMbzPJNyPulW6RoCaoYXC3w/S6qNDLOHaosf+eLaPUCXtTsoSkoFjKOl9Ef048dLOitFowexa+CWojI4y7iOfymx2hw/l5mZvQwOuRmPOmKre/H57aFZOBAg7FAECZbq1pk6W2LOj/TR7vRu2Tn4I1Y6G3H8WDCEkWJM5dgZy96L9YYe7B14fWBaKzhfh1a8gmDnwC3bNGknxCwIdiHVBkJI3dO9CDiT88DisXg5gIrN/XARwlMEYV6LyZLyuu6hyq71IXRfZxXYtOX5ScFBPm+/5GkNmNlICOmp4vnHWZlKf11L/u13ghYC7qJ695J7OqCCux2t+rKi+4EU08NBPBWDM4wv0hdKysJTHQL7xzxpYr6tgGXYkPLBj1sHhU7iwqYU3UhXEV4v5EvsmzMxQ85/GyRgprHOgWp1XKoGc1eSqPAC6wDYpmkIQFM041kyn18YSWxCcR/I2t/Ctz3bgnGBqPODtIQ4yZvkAAH7BrchQVXuYDIb+eFyF6DahOlrUiWPuZZDhmB+geJ9dPsPhDM1AHxsUhcVzIYXSRwUYnRoBclAAAAAAAEVYSUbYAAAASUkqAAgAAAAGABIBAwABAAAAAQAAABoBBQABAAAAVgAAABsBBQABAAAAXgAAACgBAwABAAAAAgAAADEBAgARAAAAZgAAAGmHBAABAAAAeAAAAAAAAABgAAAAAQAAAGAAAAABAAAAcGFpbnQubmV0IDQuMy4xMQAABQAAkAcABAAAADAyMzABoAMAAQAAAAEAAAACoAQAAQAAAJABAAADoAQAAQAAAM8AAAAFoAQAAQAAALoAAAAAAAAAAgABAAIABAAAAFI5OAACAAcABAAAADAxMDAAAAAA'

export const EU_FLAG = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABACAYAAACjgtGkAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAfFSURBVHgB7ZtbbxvHFcf/Z3aXF10s2rALtw7qddoAiVHAVC/
PovLUAgUs9a1PkvoBKvkLVNQnkPwJHOcLyHloiyIwSAEthMRtKaWN1YdGpNu0aW3HXF1428ucnt0mcmSLFkXSlqjwBwi2BrN7tGdnz5z/nBngBMC1S1Po8xRd+dYml+0UTgAKxww/SmXAuOI19DROAIRjgD9KTcCiRQzFgCRSnDBS2PYcChoOXD/ssUTfqd1EL8O7l67xZ7bdcv9cwua7ySJ/MMR6I8W8FmdejW/
yvUSm1XvUihdtt2zPoot07ZPxPMwFnjvfan8ar5cQ4xnIIKH/eHIDaax679KP6vlW72EoY4IMYwJdxESbcCEVBsG9QOgCGWI4/PBCGpbnRI15ODTpOE1vMmBMYUs5qDQWkOBZnFFjEmKb2yyKvYtity6/1OvQPk8FRGn300tpywscJBLS7jh05QU2D6HtEUKjYjShpzjORY6rTWM3sCnmp1BxC/hHUMSGd/hQDgwC
u6P008YS6u4otLHGqwm7qc0rcLBdn2LXLXLCLGKgkVZ1lw0vKHDdLepHT7Jo2xVf2ECH8IY8gDYKqOgUdnQJDXmLRJP0k9aH/r77yciLnH2YTU/nMMS23pJRUfahyJyk8Z076JCOYwi9JbHAZQf/8hhDhjQE4XBeQ5sc5ow9m3FdhktQzAxLfhwjjy7QsUP4LzIrfC6fikc3ZGQsYMRy0OVA95zNImwYJCOT51Dx36bB
+APvXGMaXaDtoLrH48DGI2+Ufo5SOFVIDMhjJAyOAV4agTUGz/g+Xa3/32YhMW5FAblPn2PHf2hP4xTgP7YnWhGQLwyqtfKbtiRKizgFsB/MBpZx/bB+L3QIWTzBoBT/82JPB6xQ88iMlNFKTR/W97nEjDdS0wzMM4uvBpMjepDOGrGqQ3WZ9x97W5JZz9D3Km3nGa8KLkuKX6kvw7JsNhUCf8imQLFy6g/kA5IVh2DJuLr9
nKI+MFONMkEYObnqMirinpomaMqDeSYSZT0CF0KtFc9K9jaLZAxc10yGJPdk/IKuHpzVNk3d+X5yFk/0kuTEMu8HLHJ2nN7GCnoMXpUkbsDclLxFdFOMA3JXzB82xpv1bx5DXMzh82ANJs1h2HogAWUSvcigkZHRviUvdQFVvEuw0l8o9dbhD+Wi31l7s4tkgjbfiy9yDidi3fMo8F/l7y48VdD84ZlZLgwcOtvsv8lyqucevE
+fPn369ALuv1/rap3jpOLuvpE+qH1fYhaqW0nUl46cuPQgxJyq7Xw382z7PocYlh4jIjSS5jROPaYsy9Jza7/kFxLTBJrXbNgwRQCdS8LYbshA0USsHTJ0lt7c7u06K8KM9UKalbcsY0C+AqMUnB9cwFbtltqpMimfdEAL1g+q2UjciSqUVWzzlvggoytxKEtLSYFWpKQwEy35nxKiUBD3pCiGKeygrIfds/LCS6TVJI160ZJG9M
nQKEqeS7d5V1ZBRCLDMQmb+r3T5IyQqObzSfAO/iu/aPMs7YiQd807XzojZC+GKB9TVNPrsgbyM8TMEkzjaAKoVzCtaakBl8Q9k1T1bivvgH0pYT2V76pFXn6qZjl3bp5zp2+24ffPLH71uYL31Zz7eyuNPn369OnTp21a3Qd76P4Q9+G3T4X69awBu+PabiSRXZ09DeqX2E15Hdd2wVOsdcofMq+h5zFBbdV21+OzWqusqN8Ux+
IIdxurqicdNYhECEFqu285eZxwpEZtM+ucLGrY8t7LfH54QWt/EU8qpFxPdCzftK5V5p697uDabljYUV6ONV3GdpwoKY0DdBOemW1lU9xJgjeG5lHVWVSk4J3woXa5JGs+YY06f1D/Az8ZGq2XtG/ewW64qVB85hhA0b/Ta86IKPm3Rd0ydhiqISL3DErNnBHSPIbU9QRV/BUEGI/Ur2X05pmWAGMg00Fd1nZcvimqfuzotd0c7CCn9
k23fDeV7cUSZ6Rul1P23u+5xDTfi53OpY0+vURYwqh99loGr5CaTK3dttm18zKG5Y0Z1eClbuk+wGrGiJmv2GaLeNU3cu4n33iCV4j7t/M5b/v1rtps+3hIOHUxNW75WqXBFvDNYZsrDVgelxBltYH8W+9qGSOyGQ9uSRqelqwCWtFlvjAsFYJyCUYAU/kl4tpMWEXAccHr5rxeNzn4Y5z1n0aYPxrUfH9w8ShzvcSBLI4AfzyU5Y8HtP
7gDOtCUut1KSStm10pwXblVKb+cyKHLT9DGGRU/S241pUXHi17BvfTS2UaSU5aw3/Pt3oN/yaZQzyeAe8iGFFlwxx4vRuZdMfHQ2qrsHnXzygvyMOyVhCneVA9VMdNt3DKm7S//H/dsuSheERU9XVpL4XH6RKIikqlptcvi9ayYmMwzby8gBVVNX7lD+yGpYQ8OqRjh1gqllZV9wb9GEvAY8kEz4tjEuPyqM33tMoTM3OkRGPM0T
ZYlMpzPntzMWIHhj8jvUpNr49L3IrFbtDYo6jmzL8N8kqrDLrgkJdCKwWuKEDeH1rk1UHWfxiWH0vre2YhqjO3Y3O5O1tGX8pRdxpv4dxc+L0XeR07hihqGag7hsxL6r12ZwiaRMfxI+R4z/4bdF1iwRoCHqcB9Y5K4Jf4uhIqZ757bumrbX5ucNr99fHWWf8HijCbch2U8IkAAAAASUVORK5CYII=`

export const CHECK_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO5SURBVHgB3Zq/TxRBFMe/s5CIhZFKYmPWRioFYiyNZ2InJlhKAxTKSSOJfwDwH3CNAWm00FYT6NnCUiLEzsaVxliJoVASvfG9mTvu9m73dnf2N59k727v9vbme2/em5n3RiBNluQk/qIGCxMQmITEKL1r91zlto4DNOl5GA5eiH2khEBS6rJGjzPU+Dm62yjMcOlw6I9oJBVnLkgLWaGjhjQR2Kc/p4EN8QoGxBeUlZB+XLLYw7gWiy5oWY7ihIRILCNPJNZxHmtYF0dRLo8mqC5tetxFv4PnhUvHXeqGbtiFVtgFeCw5Wn1CcWKgfpvbwFE0hMGCnso5DNGNzKNXenAbmtQWbtPAy4Jgywwpy5QPC1NBwcJfEPuMLIll/JA4orZN+flUf5fjaMYBoKxiGN223VZbPfQLOlFjjI3yY+O3aqsHb5crs98Ew+HcaZ94LTSEd6geHit1BNXlPKrR1XqptaZjio4ggWeoLqdW0oKW1GwgdBTOkgsjwPR14OYVmHBqpWF12sx5wtnD9A3g+T0SdU6f7x0Ci28Qlxk6nHaXu4OCYDGr9ztiGLbSo1uIBy8wwV1OT/hsFEBbjB/jlxAPHmxJi6VyAAUwSAzz8RDxkahZZCMbORMm5ssPYOcz4iMxwT40gRyJImbxLcygSM1RzoYB7LhPbmtn5qj08gNw/Gfwd6KKCbtPIORHguK3REwuXwS2lxCrMZmLaRG+BPdh1iekXhsDNmf1ANlLXmIYI0FB+InKUwxjJGh7QATqFpW3GIZ96CsMAsMDmnetTAd//v2X9rUgshBDuBZJipTA64WttLYT/HkBYhgSJKkKYEiYKD8yFMMcWDTTTpTtjyMqYzHsQGQhrs8kJIqozMUwAo5OkizKn2mkrYICRS5iOP+9Ia7qsC3wGinQttTxSee9vW+5iGEcftAW0svXXaQER7jxMS1iz2QZYEIrPdzJy9UlC6qhikgKbJtiil92zxTWUFUElTBPX3ZTTSupYNA+6Z3LVc9KXIf1nHajc8QNVIe+bQD9s+0RrELXNMuO22qrhzNX8KpmSfIfidnyL0kGL/D0FxZQPhaCxDDh+xTYUlYJSpTczZpU3NoavLMkfAnON+D+WmygcFUbtsK3yUTLKbDzjShRRYT0hvrtCLtImPiblzi531SlSxvZ4oAH+q76aRSSbC+bV1W/9AtlDgyEtEm+AVCHdy6YcY3Jhgl6XOE12XtTIW2SC+qGxQm1RdOGLgLY8NuiyZkmnvI3KUEjySIRnD0q/wEKpErJm6pWigAAAABJRU5ErkJggg=='

export const UPLOAD_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKASURBVHgB7Zi9b9NAGMafu35IlKWChQUpRUJMiCCxMIBisSEkYGEuS5Sy0P4BqO0fgApTEAwxK1tVNoScUqlZkFq1C2QIHjIAEh8LFWqTHO/LJY2T2PFXXWfoT0p89l2c5+6599XdASfEQ/g1KH5Qs1LgLjWcxnEhYDcPsDxnCHuowFcbarEFLCEl6L8NT4FFS2XkOL4gRUhcWXpVyjFkkTIKyGmBj1QWBZVxVrb859xv7iFfkSASc2qF1GxR2cK8ChoI9mQDM/kbwmg1cJWEbiMhJI3jfLucwV/cC/QrhYWHhvg/chxpTYXnSIhxRKA/5cghNv/4Azx9B5yaBJ7cRmgkoiDwmKOciyWLpoXAilfTtztaZP0XlXcRmkgCKbqylIK2Xm4oa1+nooxbu0oN2Kx179d2tNDEBbaZ5jQAj2jnUWNB/ZQqCEUcgYfwqFRqvc861rq1DWN1pCBx0gmCvQP9uXVp0Np+3n8Crl8Azp72fX38EeyIY958pIRYd7fWyd4+YAa0OpbAtd1BG4vr7tb2U/1GI/nZv11kgdXvep7FwWueOokkkF9qbiI2QayOJDBIz4PiZ3VogfyyYREahWEdDi2w+hVHDltd/+leFzoPPrgGnD+DI2VqArh4zr0utEBOrncu49jwtHismdwiNAS2p8C8IbbbS/rUEArLvKLuLjZF78pkooH7VP8aCe87BlCwSctC/qYwBW2WLOhlE1PGC2FghJC0YVp13OdI8CJGCIkpmHS1Hc+WRkmkPlkoqBx42+mE56Yky1Xi82+dppXpVdk9+iioWfouIR0MEll2q+imGd2LGfTanTruh0fact7EX0HyClZRFM9wQkL8A4w20peisLWgAAAAAElFTkSuQmCC'

