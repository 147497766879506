<template>
  <div class="carousel">
    <button v-if="scrollable" @click="movePrev">
      <LeftArrow />
    </button>
    <div class="listings" ref="listings">
      <listing-card v-for="l in listings" :details="l" :key="l.id" :to="to" :target="target" @click="listingClicked" />
    </div>
    <button v-if="scrollable" @click="moveNext">
      <LeftArrow class="flipped" />
    </button>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import { parseUrlFilters, formatFilterForApi } from '../../logic.js'
import SeezSdk from '../../sdk.js'
import { analyticsMixin, analyticsEvents } from '../../analytics.js'
import Loader from '../Loader.ce.vue'
import ListingCard from '../ListingCard.ce.vue'
import LeftArrow from '../../assets/left-arrow.svg'

export default {
  name: 'SeezCarousel',
  components: { Loader, ListingCard, LeftArrow },
  mixins: [SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    filters: { type: String, default: null },
    to: { type: String, default: null },
    target: { type: String, default: null }
  },
  data() {
    return {
      listings: [],
      loading: false,
      scrollable: false,
      resizeObserver: new ResizeObserver(this.checkScrollbars)
    }
  },
  watch: {
    filters() {
      this.loadListings()
    }
  },
  mounted() {
    this.resizeObserver.observe(this.$refs.listings)
    this.loadListings()
  },
  beforeUnmount() {
    this.resizeObserver.disconnect()
  },
  methods: {
    async loadListings() {
      this.loading = true
      const query =
        'query listings($filter: ListingFiltersInput) {listings(filter: $filter){nodes{id,name,model{name},transmission{name},variant,brand{name},year,currency,priceRetail,priceMonthly,pictures,imageIds,kilometrage,dealership{name,logoUrl},fuelType{name},bodyType{name},registrationDate,color,reserved,reservationExpires},pageInfo{total,page,perPage,pages}}}'
      const internalFilter = parseUrlFilters(this.filters)
      const apiFilter = formatFilterForApi(internalFilter)
      const result = await this.queryApi(query, { filter: apiFilter })
      this.listings = result.listings.nodes
      this.$emit('loaded', result.listings.pageInfo)
      this.loading = false
    },
    checkScrollbars() {
      this.scrollable = this.$el.clientWidth > 440 && this.$refs.listings.clientWidth < this.$refs.listings.scrollWidth
    },
    moveNext() {
      this.track(analyticsEvents.CTA_CLICK, { iteraction: { carousel: 'next' } })
      const stepSize = this.$refs.listings.children?.[0].clientWidth ?? 0
      this.$refs.listings.scroll({ top: 0, left: this.$refs.listings.scrollLeft + stepSize, behavior: 'smooth' })
    },
    movePrev() {
      this.track(analyticsEvents.CTA_CLICK, { iteraction: { carousel: 'prev' } })
      const stepSize = this.$refs.listings.children?.[0].clientWidth ?? 0
      this.$refs.listings.scroll({ top: 0, left: this.$refs.listings.scrollLeft - stepSize, behavior: 'smooth' })
    },
    listingClicked(e, details) {
      const f = parseUrlFilters(this.filters)
      if (f.relatedTo || f.recommendedFor) this.trackEvent(analyticsEvents.CAROUSEL_CLICK_LISTING_DETAILS, e, details)
      else this.trackEvent(analyticsEvents.CAROUSEL_CAR_CLICK, e, details)
      this.$emit('select', e, details)
    },
    trackEvent(eventName, e, details) {
      return this.track(
        eventName,
        { car_id: e.id, listing_id: this.parentListingId, event_action: eventName, event_category: 'enhanced_ecommerce', event_label: { car_id: details.id }, event_name: eventName },
        { vehicle: details.item }
      )
    }
  }
}
</script>

<style lang="scss">
.carousel {
  background-color: white;
  position: relative;
  min-height: 10em;
  display: grid;
  grid-template-columns: auto 1fr auto;

  > button {
    border: none;
    background: none;
    font-size: 3em;
    font-weight: bold;
    cursor: pointer;

    > .flipped {
      transform: scaleX(-1);
    }
  }

  > .listings {
    grid-area: 1 / 2;
    display: flex;
    gap: 1em;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-inline: 0.5em;
    overflow: auto;
    padding: 1em;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      scroll-snap-align: start;
      min-width: 20em;
      position: relative;
    }
  }

  > .loader {
    position: absolute;
    inset: 0;
  }
}
</style>
