<template>
  <div id="review">
    <loader v-if="loading" />
    <div class="container" v-if="order.id">
      <OrderInfo :order-id="order.id" @cancelOrder="cancelOrder" />
      <OrderCard :order-id="order.id" :image-src="imageSrc" :full-name="fullName" :variant="order.listing?.variant" :year="order.listing?.year" :retail-price-string="retailPriceString" />
      <h2>{{ titleMessage }}</h2>
      <p>{{ t['carefullyReviewDetails'] }}</p>

      <div class="sectionTitle">
        <div class="titleBar">
          <img :src="doneCircle" />
          <h3 class="sectionTitle">{{ t['tradeIn'] }}</h3>
        </div>

        <a class="edit" v-if="!isOrderDone" @click="gotoEdit('tradeIn')"><img :src="editIcon" /></a>
      </div>
      <div class="values" v-if="order.tradeInState">
        <div>
          {{ t['make'] }}:
          <div class="value" data-test-id="review-values-make">{{ order.tradeInState?.make }}</div>
        </div>
        <div>
          {{ t['fuelType'] }}:
          <div class="value" data-test-id="review-values-fuel-type">{{ order.tradeInState?.fuelType }}</div>
        </div>
        <div>
          {{ t['model'] }}:
          <div class="value" data-test-id="review-values-model">{{ order.tradeInState?.model }}</div>
        </div>
        <div>
          {{ t['registrationDate'] }}:
          <div class="value" data-test-id="review-values-registration-date">{{ registrationDate }}</div>
        </div>
        <div>
          {{ t['variant'] }}:
          <div class="value" data-test-id="review-values-variant">{{ order.tradeInState?.variant }}</div>
        </div>
        <div>
          {{ t['currentCondition'] }}:
          <div class="value" data-test-id="review-values-car-condition">{{ order.tradeInState?.carCondition }}</div>
        </div>
        <div>
          {{ t['year'] }}:
          <div class="value" data-test-id="review-values-year">{{ order.tradeInState?.year }}</div>
        </div>
        <div>
          {{ t['outstandingFinancing'] }}:
          <div class="value" data-test-id="review-values-has-financing">{{ order.tradeInState?.hasFinancing }}</div>
        </div>
        <div>
          {{ t['mileage'] }}:
          <div class="value" data-test-id="review-values-kilometrage">{{ order.tradeInState?.kilometrage }}</div>
        </div>
      </div>

      <div class="sectionTitle">
        <div class="titleBar">
          <img :src="doneCircle" />
          <h3 class="sectionTitle">{{ t['personalInfo'] }}</h3>
        </div>

        <a class="edit" v-if="!isOrderDone" @click="gotoEdit('personalInformation')"><img :src="editIcon" /></a>
      </div>
      <div class="values">
        <div>
          {{ t['firstName'] }}:
          <div class="value" data-test-id="review-values-first-name">{{ order.personalInfoState?.firstName }}</div>
        </div>
        <div>
          {{ t['middleName'] }}:
          <div class="value" data-test-id="review-values-middle-name">{{ order.personalInfoState?.middleName }}</div>
        </div>
        <div>
          {{ t['lastName'] }}:
          <div class="value" data-test-id="review-values-last-name">{{ order.personalInfoState?.lastName }}</div>
        </div>
        <div>
          {{ t['emailAddress'] }}:
          <div class="value" data-test-id="review-values-email">{{ order.personalInfoState?.email }}</div>
        </div>
        <div>
          {{ t['phoneNumber'] }}:
          <div class="value" data-test-id="review-values-phone">{{ order.personalInfoState?.phone }}</div>
        </div>
        <div>
          {{ t['cprNumber'] }}:
          <div class="value" data-test-id="review-values-national-id">{{ order.personalInfoState?.nationalID }}</div>
        </div>
        <div>
          {{ t['insuranceCompany'] }}:
          <div class="value" data-test-id="review-values-insurance">{{ order.personalInfoState?.insurance }}</div>
        </div>
        <div>
          {{ t['city'] }}:
          <div class="value" data-test-id="review-values-city">{{ order.personalInfoState?.address?.city }}</div>
        </div>
        <div>
          {{ t['postalCode'] }}:
          <div class="value" data-test-id="review-values-postal-code">{{ order.personalInfoState?.address?.postalCode }}</div>
        </div>
        <div>
          {{ t['addressLine1'] }}:
          <div class="value" data-test-id="review-values-street">{{ order.personalInfoState?.address?.street }}</div>
        </div>
      </div>

      <div class="sectionTitle">
        <div class="titleBar">
          <img :src="doneCircle" />
          <h3 class="sectionTitle">{{ t['delivery'] }}</h3>
        </div>

        <a class="edit" v-if="!isOrderDone" @click="gotoEdit('carDelivery')"><img :src="editIcon" /></a>
      </div>
      <div class="values">
        <div>
          {{ t['date'] }}:
          <div class="value" data-test-id="review-values-date">{{ deliverDate?.date }}</div>
        </div>
        <div>
          {{ t['time'] }}:
          <div class="value" data-test-id="review-values-time">{{ deliverDate?.time }}</div>
        </div>
        <div>
          {{ t['city'] }}:
          <div class="value" data-test-id="review-values-deliver-city">{{ order.deliveryState?.address?.city }}</div>
        </div>
        <div>
          {{ t['addressLine1'] }}:
          <div class="value" data-test-id="review-values-delivery-street">{{ order.deliveryState?.address?.street }}</div>
        </div>
      </div>

      <div class="sectionTitle">
        <div class="titleBar">
          <img :src="doneCircle" />
          <h3 class="sectionTitle">{{ t['payment'] }}</h3>
        </div>

        <a class="edit" v-if="!isOrderDone" @click="gotoEdit('payment')"><img :src="editIcon" /></a>
      </div>
      <div class="values">
        <div>
          {{ t['carPrice'] }}:
          <div class="value" data-test-id="review-values-retail-price">{{ retailPriceString }}</div>
        </div>
        <div>
          {{ t['downPayment'] }}:
          <div class="value" data-test-id="review-values-down-payment">{{ formatCurrency('downPayment') }}</div>
        </div>
        <div>
          {{ t['loanAmount'] }}:
          <div class="value" data-test-id="review-values-loan-amount">{{ formatCurrency('loanAmount') }}</div>
        </div>
        <div>
          {{ t['monthlyPayment'] }}:
          <div class="value" data-test-id="review-values-monthly-payment">{{ formatCurrency('monthlyPayment') }}</div>
        </div>
        <div>
          {{ t['preferreedLoanDuration'] }}:
          <div class="value" data-test-id="review-values-financing">{{ order.paymentState?.financing?.paymentTerms }}</div>
        </div>
      </div>

      <div class="confirmBox" v-if="!isOrderDone">
        <p>{{ t['confirmationPageExplanation'] }}</p>
        <label><input type="checkbox" v-model="confirmCheckBox" data-test-id="review-confirm-order-agreement" /> {{ t['confirmAndProceedDisclaimer'] }}</label>
      </div>
      <div class="confirmButton">
        <SeezButton v-if="!isOrderDone" :disabled="!confirmCheckBox" @click="confirmOrder" data-test-id="review-confirm-order">{{ t['confirmOrderDetails'] }}</SeezButton>
        <SeezButton v-if="isOrderDone" @click="gotoWaiting" data-test-id="review-back">{{ t['back'] }}</SeezButton>
      </div>
    </div>
  </div>
</template>
<script>
import { lang } from '../lang'
import Loader from '../Loader.ce.vue'
import { doneCircle, editIcon } from '../../assets/images'
import SeezButton from './components/SeezButton.ce.vue'
import OrderInfo from './components/OrderInfo.ce.vue'
import OrderCard from './components/CheckoutOrderCard.ce.vue'

export default {
  name: 'CheckoutReview',
  components: { Loader, SeezButton, OrderInfo, OrderCard },
  mixins: [lang],
  props: {
    order: { type: Object, required: true },
    details: {
      type: Object,
      default: () => {
        return { currency: 'kr' }
      }
    }
  },
  data() {
    const urlParamsMap = Object.fromEntries(new URLSearchParams(this.orderIdParam))
    return {
      loading: false,
      completedOrder: false,
      orderId: urlParamsMap.order,
      confirmCheckBox: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    imageSrc() {
      return this.order.listing.pictures[0] || ''
    },
    fullName() {
      if (this.order?.name) return this.order.name

      const brand = this.order?.listing?.brand?.name
      const model = this.order?.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    registrationDate() {
      const date = new Date(this.order.tradeInState?.registrationDate)
      return date.toLocaleDateString()
    },
    retailPriceString() {
      if (this.order.listing.priceRetail == null) return null
      return this.order.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    titleMessage() {
      if (this.completedOrder) return this.t.orderSummary
      return this.t.confirmAndProceed
    },
    deliverDate() {
      let date, time
      if (this.order?.deliveryState?.preferredDate) {
        ;[date, time] = this.order.deliveryState.preferredDate.split('T')
        date = date?.replace('-', '/')
        time = time?.split('.')?.[0]
      }
      return { date, time }
    },
    isOrderDone() {
      return this.order.state === 'confirmed' || this.order.state === 'done'
    },
    doneCircle() {
      return doneCircle
    },
    editIcon() {
      return editIcon
    }
  },
  methods: {
    async confirmOrder() {
      this.$emit('confirmOrder')
    },
    gotoWaiting() {
      this.$emit('setSummary', false)
    },
    cancelOrder() {
      this.$emit('cancelOrder')
    },
    gotoEdit(name) {
      this.$emit('setEdit', name)
    },
    formatCurrency(name) {
      if (!this.order.paymentState?.financing?.[name]) return '-'
      return this.order.paymentState?.financing?.[name]?.toLocaleString(this.language, { maximumFractionDigits: 2, minimumFractionDigits: 0 }) + ' ' + this.currency
    }
  }
}
</script>
<style lang="scss" scoped>
#review {
  overflow: auto;
  height: 100%;
  > .loader {
    position: absolute;
    z-index: 100;
  }
  .container {
    max-width: 60rem;
    margin: auto;
    height: 92vh;
  }

  .sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.75rem 0;
    gap: 0.75rem;
    > .titleBar {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 100%;
      gap: 0.75rem;
      background: #eee;
      border-radius: 12px;
      padding: 0.75rem;
      > h3 {
        margin: 0;
      }
    }
    > .edit > img {
      cursor: pointer;
    }
  }
  .values {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
    > div {
      width: max-content;
      display: flex;
      gap: 0.5rem;
    }
    .value {
      font-weight: 700;
    }
  }
  .confirmBox {
    margin: 1.25rem;
    > label {
      cursor: pointer;
    }
  }
  .confirmButton {
    margin: 1rem 0;
    > button {
      width: 100%;
    }
  }
}
</style>
