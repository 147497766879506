<template>
  <div id="personalInformation" data-test-id="personal-section">
    <form ref="personalForm">
      <div>
        <h3>{{ t['personalInformation'] }}</h3>
        <p>{{ t['personalInfoIntro'] }}</p>
        <div class="personalInformation">
          <InputWError
            required
            class="textInput"
            :placeholder="t['firstName']"
            v-model="personalInformation.firstName"
            type="text"
            :error="inputErrors.customer_firstname"
            data-test-id="personal-form-first-name"
          />
          <InputWError class="textInput" :placeholder="t['middleName']" v-model="personalInformation.middleName" type="text" data-test-id="personal-form-middle-name" />
          <InputWError
            required
            class="textInput"
            :placeholder="t['lastName']"
            v-model="personalInformation.lastName"
            type="text"
            :error="inputErrors.customer_lastname"
            data-test-id="personal-form-last-name"
          />
          <InputWError
            required
            class="textInput"
            :placeholder="t['emailAddress']"
            v-model="personalInformation.email"
            type="email"
            :error="inputErrors.customer_email"
            data-test-id="personal-form-email"
          />
          <span class="phoneInput">
            <InputWError
              required
              class="textInput"
              :placeholder="t['phoneNumber']"
              v-model="personalInformation.phone"
              type="tel"
              :error="inputErrors.customer_phone"
              data-test-id="personal-form-phone"
              ><span class="prefix"><img :src="denmarkFlag" /> <span>+45</span></span></InputWError
            >
          </span>
          <InputWError
            required
            class="textInput"
            :placeholder="t['cprNumber']"
            pattern="(([0-2][0-9]|3[0-1])([0][0-9]|[1][0-2])([0-9][0-9]))([-]{0,1})[0-9]{4}"
            v-model="personalInformation.nationalID"
            type="text"
            :error="inputErrors.customer_nationalid"
            data-test-id="personal-form-cpr-number"
          />
          <MultiSelectWithError
            class="textInputSelect"
            :multi="false"
            :clear-placeholder="t['insuranceCompany']"
            :options="insuranceCompanies"
            v-model="personalInformation.insurance"
            :placeholder="t['insuranceCompany']"
            test-id="insurance_company"
            :error="inputErrors.customer_insurance"
            data-test-id="personal-form-insurance"
          />
        </div>
        <h5>{{ t['address'] }}</h5>
        <div class="addressInformation">
          <div class="detailRow">
            <InputWError
              required
              class="textInput"
              :placeholder="t['postalCode']"
              v-model="personalInformation.address.postalCode"
              type="text"
              :error="inputErrors.address_postal_code"
              data-test-id="personal-form-postal-code"
            />
            <InputWError
              required
              class="textInput"
              :placeholder="t['city']"
              v-model="personalInformation.address.city"
              type="text"
              :error="inputErrors.address_city"
              data-test-id="personal-form-city"
            />
            <InputWError required class="textInput" :placeholder="t['country']" v-model="personalInformation.address.country" disabled type="text" data-test-id="personal-form-country" />
          </div>
          <InputWError
            required
            class="textInput fullWidth"
            :placeholder="t['addressLine1']"
            v-model="personalInformation.address.street"
            type="text"
            :error="inputErrors.address_street"
            data-test-id="personal-form-street1"
          />
          <InputWError class="textInput fullWidth" :placeholder="t['addressLine2']" v-model="personalInformation.address.street2" type="text" data-test-id="personal-form-street2" />
        </div>
      </div>
    </form>
    <img class="scrollDown" :src="scrollDown" @click="gotoNextSection" />
  </div>
</template>
<script>
import { lang } from '../../lang'
import InputWError from '../components/InputWError.ce.vue'
import MultiSelectWithError from '../components/MultiSelectWError.ce.vue'
import { denmarkFlag, scrollDown } from '../../../assets/images'

// Sample CPR numbers: 031085-8909 0911758967, 041175-8934, 0911758023, 0511758967, 091085-8945, 091085-8989, 031085-8909, 091085-8312, 041085-8945, 041285-8967, 071095-7989

const inputFieldErrorsDefaultState = {
  customer_firstname: '',
  customer_lastname: '',
  customer_email: '',
  customer_phone: '',
  customer_nationalid: '',
  address_postal_code: '',
  address_city: '',
  address_street: ''
}

export default {
  name: 'PersonalSection',
  components: { InputWError, MultiSelectWithError },
  mixins: [lang],
  props: { currentStage: { type: String, default: '' }, state: { type: Object, required: true }, errors: { type: Array, default: () => [] } },
  data() {
    return {
      personalInformation: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        nationalID: '',
        insuranceCompany: null,
        address: {
          street: '',
          street2: '',
          postalCode: '',
          city: '',
          state: '',
          country: ''
        }
      },
      insuranceCompanies: [
        'Tryg',
        'If',
        'GF Forsikring',
        'Topdanmark Forsikring',
        'Alka',
        'Gjensidige',
        'Codan',
        'Alm Brand',
        'Vesthylland Forsikring',
        'Bauta Forsikring',
        'FDM Forsikring',
        'Købstædernes Forsikring',
        'Lokal Forsikring',
        'Lærerstandens Brandforsikring',
        'Nem Forsikring',
        'Nykredit Forsikring',
        'Thisted Forsikring',
        'other'
      ],
      inputErrors: { ...inputFieldErrorsDefaultState }
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    denmarkFlag() {
      return denmarkFlag
    },
    scrollDown() {
      return scrollDown
    }
  },
  watch: {
    state: {
      handler(value) {
        this.personalInformation = { ...value, address: { ...value.address } }
      }
    },
    currentStage: {
      handler(newValue, oldValue) {
        if (oldValue === 'customerInfo' && newValue !== 'customerInfo') {
          this.validateForm()
          this.$emit('update', 'customerInfo', this.personalInformation)
        }
      }
    },
    errors: {
      handler(errors) {
        this.inputErrors = { ...inputFieldErrorsDefaultState }
        errors.forEach(error => {
          const field = error.split(' ')[0].replace('.', '_')
          this.inputErrors[field] = error
        })
        this.validateForm()
      }
    }
  },
  mounted() {
    this.personalInformation = { ...this.state, address: { ...this.state.address } }
  },
  methods: {
    validateForm() {
      // this.$refs.personalForm?.reportValidity()
    },
    validateCPR(cprNumber) {
      const cprRegex = new RegExp('(([0-2][0-9]|3[0-1])([0][0-9]|[1][0-2])([0-9][0-9]))([-]{0,1})[0-9]{4}')
      return (cprRegex.hasMatch(cprNumber) && cprNumber.length == 10) || cprNumber.length == 11
    },
    gotoNextSection() {
      this.$emit('gotoComponent', 'personalInfo')
    }
  }
}
</script>
<style lang="scss" scoped>
#personalInformation {
  .phoneInput {
    .prefix {
      display: flex;
      gap: 0.375rem;
      padding: 0.5rem;
      > span {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }
  .textInput {
    @media screen and (max-width: 42rem) {
      grid-column-end: span 2;
    }
  }

  .personalInformation {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.875rem;
    width: 100%;
  }
  .addressInformation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.75rem;
    > input {
      width: auto;
    }
    .detailRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      gap: 12px;
    }
    .fullWidth {
      width: 100%;
    }
  }
  @media screen and (max-width: 56rem) {
    .personalInformation {
      display: flex;
      flex-direction: column;
    }
    .addressInformation > .detailRow {
      display: flex;
      flex-direction: column;
    }
  }
  .scrollDown {
    margin: 4rem auto;
    cursor: pointer;
  }
}
</style>
