<template>
  <div id="checkout-header">
    <div class="headerLeftSide">
      <img class="logo" :src="seezLogo" />
      <hr />
      <div class="orderId">{{ t['orderId'] }}:{{ orderId }}</div>
    </div>
    <hr />
    <div class="headerRightSide">
      <div class="contactUs"><img :src="whitePhoneIcon" />{{ t['contactUs'] }}</div>
      <hr />
      <a class="cancelOrder" @click="showConfirmationModal = true" data-test-id="cancel-order-button-show-confirm-modal">{{ t['cancelOrder'] }}</a>
    </div>
    <div class="mobileHeader">
      <img class="logo" :src="seezLogo" />
      <div class="orderSummary" @click="openSummaryModal()">{{ t['orderSummary'] }}</div>
    </div>
  </div>
</template>
<script>
import { seezLogo, whitePhoneIcon } from '../../assets/images'
import { lang } from '../lang'

export default {
  name: 'CheckoutHeader',
  mixins: [lang],
  props: { orderId: { type: String, default: '' } },
  computed: {
    seezLogo() {
      return seezLogo
    },
    whitePhoneIcon() {
      return whitePhoneIcon
    },
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    cancelOrder() {
      this.$emit('cancelOrder')
    },
    openSummaryModal() {
      this.$emit('setSummaryModal', true)
    }
  }
}
</script>
<style lang="scss">
#checkout-header {
  background: linear-gradient(98.72deg, #0068ff 40.14%, #9e80ff 107.39%);
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: space-between;
  padding: 1.5625rem 2rem;
  > .mobileHeader {
    display: none;
  }
  > hr {
    display: none;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 30px;
    font-family: 'Biennale';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    > hr {
      height: 1.75rem;
      margin: 0;
    }
  }
  > .headerRightSide {
    > .contactUs {
      display: flex;
      align-items: center;
      gap: 7px;
    }
    > .cancelOrder {
      border: 1px solid #ffffff;
      border-radius: 21px;
      padding: 0.4375rem 0.75rem;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
    }
  }
  @media screen and (max-width: 56rem) {
    padding: 2.5rem 1rem 0.3125rem 1rem;
    border-radius: 0;
    .headerLeftSide,
    .headerRightSide {
      display: none;
    }
    > .mobileHeader {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
