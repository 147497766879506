<template>
  <div>
    <label :for="'input' + label" v-if="label"> {{ label }}</label>
    <div :class="isClearInput ? 'clear seezInput' : 'seezInput'">
      <div class="prefixIcon" v-if="$slots.default"><slot /></div>
      <input :id="'input' + (label || '')" :value="modelValue" @input="changes" ref="input" v-bind="$attrs" :class="[errorMsg ? 'hasError' : '', $slots.default ? 'hasPrefix' : '']" />
      <div class="error" v-if="error">{{ errorMsg }}</div>
      <div class="postfixText" v-if="postfixText">{{ postfixText }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InputWError',
  props: {
    error: { type: String, default: '' },
    modelValue: { type: [String, Number], default: null },
    isClearInput: { type: Boolean, default: false },
    postfixText: { type: String, required: false, default: null },
    label: { type: String, default: null }
  },
  data() {
    return {
      errorMsg: ''
    }
  },
  watch: {
    error(val) {
      this.errorMsg = val
    },
    modelValue() {
      this.errorMsg = ''
    }
  },
  methods: {
    clearInvalid() {
      if (this.error) this.$refs.input.oninvalid = this.$refs.input.setCustomValidity('')
    },
    changes(event) {
      this.$emit('update:modelValue', event.target?.value)
    }
  }
}
</script>
<style lang="scss" scoped>
/* !important is used because parent's stylings has higher priority (ID is used) */
/* Hacks are tested and it would make them nasty. e.g. using ID will end in having multiple inputs with same ID */
/* TODO: clean up parent css with scoped instead of using ID then remove important */
div.seezInput {
  position: relative;
  input {
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    padding: 0.75em 1.75em;
    width: 100%;
    box-sizing: border-box;
    height: 3rem;
    &:focus-visible {
      outline: 1px solid #0068ff;
    }
    &:valid {
      border: 1px solid #ccc;
    }
    &.hasError {
      border: 1px solid rgb(248, 67, 67) !important;
    }
    &.hasError:focus-visible {
      outline: 1px solid rgb(211, 0, 0) !important;
    }
  }
  div.error {
    padding: 0.75em 0 0.75em 1.75em;
    color: rgb(248, 67, 67);
  }
  &.clear > input {
    padding: 0.5em 1em;
    border: none !important;
    flex-grow: 1;
    font-family: 'Biennale', sans-serif;
    color: #333333;
    outline: none;
    border-radius: 2em;
    height: 100%;
  }
  .postfixText {
    background: #eeeeee;
    border-radius: 0px 24px 24px 24px;
    position: absolute;
    right: 1px;
    top: 1px;
    height: 2.875rem;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.6875rem;
    line-height: 19px;
    color: #757575;
  }
  .prefixIcon {
    position: absolute;
    left: 1px;
    top: 1px;
    height: 93%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.6875rem;
    line-height: 19px;
    color: #757575;
    background: #f6f6f6;
    border: 1px solid #eeeeee;
    border-radius: 24px 0px 0px 24px;
  }
  input.hasPrefix {
    padding-left: 6rem;
  }
}
div {
  > label {
    font-style: normal;
    font-weight: 600;
    color: #003e99;
    padding: 0 1.75rem;
  }
}
</style>
