<template>
  <a @click="gotoComponent()" :class="'step ' + getClass()" :data-test-id="'goto-' + name">
    <div class="icon" v-if="loading"><SmallLoader fit-width /></div>
    <div class="icon" v-if="!loading"><img :src="getIcon" /></div>
    <div class="label" :data-test-id="'step-name-' + name">{{ label }}</div>
    <div class="status" :data-test-id="'step-status-' + name">{{ status }}</div>
  </a>
</template>

<script>
import { circleDot, warningCircle, doneCircle, currentCircle } from '../../../assets/images'
import SmallLoader from '../../Loader.small.ce.vue'

export default {
  name: 'CheckoutStep',
  components: { SmallLoader },
  props: {
    status: { type: String, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    currentStage: { type: String, required: true },
    loading: { type: Boolean, default: false }
  },
  computed: {
    isCurrentStage() {
      if (this.name === 'personalInformation') return this.currentStage === 'customerInfo'
      if (this.name === 'carDelivery') return this.currentStage === 'delivery'
      return this.currentStage === this.name
    },
    isPending() {
      return this.status === 'pending'
    },
    isIncomplete() {
      return this.status === 'incomplete'
    },
    isComplete() {
      return this.status === 'complete'
    },
    isCurrent() {
      return this.isCurrentStage
    },
    getIcon() {
      if (this.isCurrent && !this.isComplete) return currentCircle
      if (this.isPending) return circleDot
      if (this.isIncomplete) return warningCircle
      if (this.isComplete) return doneCircle
      return warningCircle // fallback icon
    }
  },
  methods: {
    gotoComponent() {
      this.$emit('gotoComponent', this.name)
    },
    getClass() {
      let classes = ''
      if (this.isCurrent) classes += 'current '
      if (this.isComplete) classes += 'complete'
      return classes
    }
  }
}
</script>
<style lang="scss" scoped>
.step {
  display: grid;
  grid-template-columns: 3.75rem 1fr;
  grid-template-areas:
    'icon label'
    'icon status';
  border-radius: 12px;
  margin: 0.625rem 0;
  padding: 0.625rem 0;
  cursor: pointer;
  .icon {
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center;
    > .loader {
      width: 16px;
      height: 16px;
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .label {
    grid-area: label;
    font-size: 16px;
    line-height: 22px;
  }
  .status {
    grid-area: status;
    font-style: normal;
    font-size: 12.8px;
    line-height: 18px;
    color: #afafaf;
  }
}
.step.current {
  background: #cce1ff4d;
}
.step.current.complete {
  background: #b3e1c280;
}
</style>
