<template>
 <Modal @close="close">
	 <div class="unavailableListingModal">
		<BanCircle />
		  <h1>{{ t['car_is_not_available'] }}</h1>
      <p v-if="!filters && !custom">{{ t['we_will_notify_you'] }}</p>
			<p v-if="custom" class="customText" />
			 <p v-if="filters">{{ t['we_got_similar_cars'] }}</p>
      <div v-if="filters" class="carouselWrapper">
        <Carousel :filters="filters" :to="to" :target="target"  />
      </div>
		<div class="buttonWrapper">
		<button aria-label="Search All" @click="searchAll">{{ `${t['search_all']}`}}</button>
		<button v-if="filters" aria-label="View Similar" @click="viewSimilar">{{ t['view_similar']}}</button>
		</div>
	 </div>
 </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import Carousel from '../Carousel/Carousel.ce.vue'
import BanCircle from '../../assets/ban-circle.svg'
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'

export default {
  name: 'UnavailableListingModal',
  components: { Modal,Carousel, BanCircle },
  mixins: [langMixin('UNAVAILABLE_LISTING_MODAL'), SeezSdk.vueQueryMixin],
  props: {
	  to: { type: String, default: null },
    target: { type: String, default: null },
    filters: { type: String, default: null },
    custom: { type: Boolean , default: false },

  },
  data() {
    return {
      loading: true,
			 hideText: true,
    }
  },
  mounted(){
    this.loadListings()
  },
	 methods: {
    close() {
      this.$emit('close')
    },
    searchAll() {
      this.$emit('search')
    },
    viewSimilar() {
      this.$emit('similar')
    },
	 }

}
</script>

<style lang="scss">
@import '../../base.scss';

.unavailableListingModal{
	@include normalize;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	> h1 {
	font-weight: 700;
  font-size: 20px;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	
	}

	> p {
		font-size: 1rem;
		font-weight: 400;
	}

	.buttonWrapper {
		display: flex;
		width: 100%;
		justify-content: center;
		gap: 0.5rem;
		margin-top: 2.625rem;

		> button {
			width: 100%;
			border: 1px solid #1C69D4;
			padding: 0.75rem 4.75rem;
			white-space: nowrap;
			flex: 1 1 0%;
			max-width: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}

		> button:first-of-type {
			background-color: #1C69D4;
			color: white;
			
		}

		:last-child {
			background-color: white ;
			color: #1C69D4;
		}


	}

	> .loader {
    position: absolute;
	}
}

</style>