<template>
  <Modal @close="close">
    <div class="cancelOrderDialog">
      <p class="title">{{ t['active_order_change'] }}</p>
      <p>{{ t['you_have_active_order'] }}</p>
      <p class="question">{{ t['are_you_sure_cancel'] }}</p>
      <div v-if="details" class="carDetails">
        <img :src="imageUrl" :alt="details.name" @error="imageError = true" />
        <span>{{ details.year }} &middot; {{ details.kilometrage.toLocaleString(language) }} Km</span>
        <b>{{ details.name }}</b>
        <span>{{ details.variant }}</span>
        <span>{{ t['price'] }}</span>
        <b>{{ details.price.toLocaleString(language) }} Kr.</b>
      </div>
      <Loader v-else />
      <div class="cta">
        <button @click="cancel">{{ t['cancel_start_new_order'] }}</button>
        <button @click="resume" class="preferred">{{ t['resume_order'] }}</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import Loader from '../Loader.ce.vue'
import { langMixin } from '../lang'
import SeezSdk from '../../sdk.js'
import { CAR_PLACEHOLDER } from '../../constants.js'

export default {
  name: 'SeezActiveOrderCancellation',
  components: { Modal, Loader },
  mixins: [langMixin('MODAL_ACTIVE_ORDER_COMPONENT'), SeezSdk.vueQueryMixin],
  inheritAttrs: false,
  props: {
    listing: { type: Number, required: true }
  },
  data() {
    return {
      details: null,
      imageError: false,
      loading: false
    }
  },
  computed: {
    imageUrl() {
      if (this.imageError) return CAR_PLACEHOLDER
      const d = this.details
      if (d.imageIds && d.imageIds.length > 0) return `${import.meta.env.VITE_IMAGES_URL}/image/0x120/listings/${d.id}/${d.imageIds[0]}.webp`
      if (d?.pictures?.[0] == null) return CAR_PLACEHOLDER
      return (import.meta.env.VITE_IMAGES_URL + d.pictures[0]).replace(/\/1600x0\//, '/0x120/')
    }
  },
  mounted() {
    this.loadListing()
  },
  methods: {
    async loadListing() {
      this.loading = true
      const result = await this.queryApi(`{listing(id:${this.listing}){id,name,variant,year,kilometrage,price,imageIds}}`)
      this.details = result.listing
      this.loading = false
    },
    close() {
      this.$emit('close', 'Nothing')
    },
    cancel() {
      this.$emit('close', 'CancelAndStartNew')
    },
    resume() {
      this.$emit('close', 'ResumeCurrent')
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.cancelOrderDialog {
  @include theme;

  text-align: center;

  > p {
    margin: 0.25em 0;

    &.title {
      font-size: 1.5em;
      font-weight: bold;
    }

    &.question {
      font-weight: bold;
    }
  }

  .loader {
    height: 140px;
    margin: 2em 0;
  }

  .carDetails {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: auto 1fr 1fr;
    justify-items: start;
    gap: 0.25em 1.5em;
    border: 1px solid #ccc;
    margin: 2em 0;
    border-radius: 0.5em;
    padding: 1em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    overflow: hidden;
    height: calc(120px - 2em);

    > img {
      grid-row: 1 / -1;
      margin: -1em 0 -1em -1em;
    }

    > span {
      color: #999;
      font-size: 0.8em;
    }
  }

  .cta {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
    justify-items: stretch;

    > button {
      border-radius: 4em;
      border: 2px solid var(--highlight);
      background-color: var(--background);
      color: var(--highlight);
      padding: 0.5em 2em;
      font-size: 1.1em;
      font-weight: bold;
      cursor: pointer;

      &.preferred {
        background-color: var(--highlight);
        color: var(--background);
      }
    }
  }
}
</style>
