<template>
  <span class="questionInfo">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 4C8.55219 4 9 4.44781 9 5C9 5.55219 8.55219 6 8 6C7.44781 6 7 5.55312 7 5C7 4.44688 7.44688 4 8 4ZM9.25 12H6.75C6.3375 12 6 11.6656 6 11.25C6 10.8344 6.33594 10.5 6.75 10.5H7.25V8.5H7C6.58594 8.5 6.25 8.16406 6.25 7.75C6.25 7.33594 6.5875 7 7 7H8C8.41406 7 8.75 7.33594 8.75 7.75V10.5H9.25C9.66406 10.5 10 10.8359 10 11.25C10 11.6641 9.66562 12 9.25 12Z"
        fill="#CCE1FF"
      />
      <path
        d="M8 6C8.55219 6 9 5.55219 9 5C9 4.44781 8.55219 4 8 4C7.44781 4 7 4.44687 7 5C7 5.55313 7.44687 6 8 6ZM9.25 10.5H8.75V7.75C8.75 7.3375 8.41563 7 8 7H7C6.5875 7 6.25 7.3375 6.25 7.75C6.25 8.1625 6.5875 8.5 7 8.5H7.25V10.5H6.75C6.3375 10.5 6 10.8375 6 11.25C6 11.6625 6.3375 12 6.75 12H9.25C9.66406 12 10 11.6641 10 11.25C10 10.8359 9.66563 10.5 9.25 10.5Z"
        fill="#0068FF"
      />
    </svg>
    <span>{{ text }}</span>
  </span>
</template>
<script>
export default {
  name: 'TooltipPopup',
  props: { text: { type: String, default: '' } }
}
</script>
<style lang="scss">
.questionInfo {
  position: relative;
  padding-right: 0.625rem;
  vertical-align: text-top;
}
.questionInfo > span {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  background-color: #cce1ff;
}
.questionInfo:hover > span {
  visibility: visible;
}
</style>
