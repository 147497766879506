export function parseUrlFilters(queryString) {
  if (queryString == null) return {}
  try {
    const sp = new URLSearchParams(queryString)
    const keys = [...new Set(sp.keys())]
    keys.sort()
    const result = {}
    const intFields = ['brands', 'models', 'families', 'year-min', 'year-max', 'kilometrage-min', 'kilometrage-max', 'price-min', 'price-max', 'body-types', 'transmissions', 'fuel-types', 'doors', 'page', 'related-to']
    const floatFields = ['engine-size-max']
    const arrayFields = ['models', 'families', 'body-types', 'colors', 'drive-types', 'fuel-types']
    for (const key of keys) {
      const values = sp
        .getAll(key)
        .flatMap(c => c.split(','))
        .map(c => {
          if (floatFields.includes(key)) return parseFloat(c)
          else if (intFields.includes(key)) return parseInt(c)
          else return c
        })
      result[key.replace(/-./g, x => x[1].toUpperCase())] = values?.length > 0 ? (arrayFields.includes(key) ? values : values[0]) : null
    }
    return result
  } catch (error) {
    console.warn(`error parsing ${queryString}`)
    return {}
  }
}

export function formatFilterForApi(filter) {
  const clean = {
    freeText: filter.freeText,
    yearMin: filter.yearMin,
    yearMax: filter.yearMax,
    kilometrageMin: filter.kilometrageMin,
    kilometrageMax: filter.kilometrageMax,
    priceMin: filter.priceMin,
    priceMax: filter.priceMax,
    priceType: filter.priceType,
    brands: filter.brands ? [filter.brands] : null,
    models: filter.models,
    families: filter.families,
    bodyTypes: filter.bodyTypes,
    fuelTypes: filter.fuelTypes,
    transmissions: filter.transmissions ? [filter.transmissions] : null,
    colors: filter.colors,
    sort: filter.sort ?? '-created_date',
    numberOfDoorsMax: filter.doors,
    numberOfDoorsMin: filter.doors,
    driveTypes: filter.driveTypes,
    engineSizeMax: filter.engineSizeMax ?? null,
    relatedTo: filter.relatedTo,
    targetSiteIds: filter.targetSiteIds,
    recommendedFor: filter.recommendedFor
  }
  const result = Object.fromEntries(Object.entries(JSON.parse(JSON.stringify(clean))).filter(e => e[1] != null))
  return Object.keys(result).length > 0 ? result : null
}

export function debounce(func, timeout = 300) {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

export function isValidEmail(email) {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/
  return email != null && typeof email === 'string' && email.length > 0 && emailRegex.test(email)
}


// // export function getDb(name, version) {
// //   let dbReq = indexedDB.open(name, version)

// //   return new Promise((resolve, reject) => {
// //     dbReq.onupgradeneeded = (event) => {
// //       let notes = event.target.result.createObjectStore('notes', { autoIncrement: true })
// //       console.log(notes)
// //       resolve(event.target.result)
// //     }
// //     dbReq.onsuccess = (event) => resolve(event.target.result)
// //     dbReq.onerror = reject
// //   })
// // }

// // export async function getFavorites() {
// //   const db = await getDb('seez', 1)
// //   let tx = db.transaction(['favorites'], 'readonly');
// //   let store = tx.objectStore('favorites');
// //   let req = store.get(1);
// // }
