<template>
  <div id="spaSign">
    <loader v-if="loading" />
    <div class="container" v-if="order.id">
      <div class="back"><img :src="chevronLeft" /> {{ t['backToOverview'] }}</div>
      <div class="titleBox">
        <span>{{ t['almostThere'] }}</span>
        <span>{{ t['justACoupleMore'] }}</span>
      </div>
      <a class="orderCard" :href="'/listing/' + order.listing.id"
        ><div class="carInformation">
          <loader v-if="!carPictureIsLoaded" />
          <img v-show="carPictureIsLoaded" class="carImage" :src="imageUrl" loading="lazy" @error="imageError = true" :alt="fullName" @load="carPictureIsLoaded = true" />
          <div class="details">
            <span class="name">{{ fullName }}</span>
            <span class="variant">{{ order.listing.variant }} {{ order.listing.year }}</span>
            <span class="price">{{ retailPriceString }}</span>
          </div>
        </div>
      </a>
      <div class="salesAgreement">
        <div class="centerAligned">
          <div>{{ t['spaTitle'] }}</div>
          <div>{{ t['spaExcited'] }}</div>
        </div>
        <div class="binding">{{ t['spaBinding'] }}</div>
        <div class="spaHtml" v-html="spaHtmlView" />
      </div>
      <div class="signButton">
        <SeezButton @click="signSpa">{{ t['spaSignNow'] }}</SeezButton>
      </div>
    </div>
  </div>
</template>
<script>
import { lang } from '../lang'
import Loader from '../Loader.ce.vue'
import SeezButton from './components/SeezButton.ce.vue'
import { chevronLeft } from '../../assets/images'

export default {
  name: 'SpaSign',
  components: { Loader, SeezButton },
  mixins: [lang],
  props: {
    order: { type: Object, required: true },
    details: { type: Object,       default: () => {
      return { currency: 'kr' }
    } }
  },
  data() {
    return {
      loading: false,
      carPictureIsLoaded: false,
      imageError: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    retailPriceString() {
      if (this.order.listing.priceRetail == null) return null
      return this.order.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    imageUrl() {
      if (this.imageError || this.order?.listing?.pictures?.[0] == null) return ''
      return import.meta.env.VITE_IMAGES_URL + this.order.listing.pictures[0]
    },
    fullName() {
      if (this.order?.name) return this.order.name

      const brand = this.order?.listing?.brand?.name
      const model = this.order?.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    spaHtmlView() {
      return this.order?.spaResponse?.previewHTML
    },
    chevronLeft() {
      return chevronLeft
    }
  },
  methods: {
    async signSpa() {
      this.$emit('signSpa')
    }
  }
}
</script>

<style lang="scss" scoped>
#spaSign {
  overflow: auto;
  height: 90vh;
  > .loader {
    position: absolute;
    z-index: 100;
  }
  .container {
    max-width: 60rem;
    margin: auto;
  }
  .back {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 0.75rem;
    color: #0068ff;
    font-weight: 700;
    font-size: 12px;
    > img {
      // https://codepen.io/sosuke/pen/Pjoqqp
      // convert black to #629cff
      filter: invert(36%) sepia(81%) saturate(6065%) hue-rotate(210deg) brightness(102%) contrast(107%);
      width: 1.25rem;
    }
  }
  .titleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    background: #cbe1ff;
    border-radius: 5px;
    padding: 1.5rem;
    margin: 0.75rem 0;
    > span {
      font-size: 0.8em;
    }
    > span:first-child {
      color: #0068ff;
      font-weight: 700;
      font-size: 1em;
    }
  }
  .orderCard {
    cursor: pointer;
    text-decoration: none;
    color: initial;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 0 10px hsla(0, 0%, 70%, 0.6);
    }

    .carInformation {
      display: flex;
      background: #ffffff;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
      border-radius: 0.75rem;

      .details {
        margin: 10px;
        display: flex;
        flex-direction: column;
        > .price {
          margin-top: auto;
        }
        > .name {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
        .price {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
      }
      > .carImage {
        max-width: 9rem;
        border-radius: 0.75rem 0 0 0.75rem;
      }
      > .loader {
        margin: auto;
      }
    }
  }
  .salesAgreement {
    .centerAligned {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
      margin: 0.75rem 0;

      > div:first-child {
        font-weight: 700;
        font-size: 1.2em;
      }
    }
    .binding {
      margin: 1rem 0;
      text-align: center;
      line-height: 22px;
    }
    .spaHtml {
      height: 50rem;
      @media (min-width: 55rem) {
        width: 80%;
      }
      overflow: auto;
      margin: auto;
    }
  }
  .signButton {
    margin: 0.75rem 0;
    > button {
      width: 100%;
    }
  }
}
</style>
