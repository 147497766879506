<template>
  <div class="emptySearch">
    <div>
      <svg v-if="!isButtonAction" width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M40.5391 36.6797L31.0098 27.0703C33.252 23.7604 34.1328 19.89 33.6523 15.459C33.0651 11.722 31.4635 8.57227 28.8477 6.00977C26.2318 3.39388 23.0553 1.81901 19.3184 1.28516C15.6348 0.858073 12.2982 1.44531 9.30859 3.04688C6.3724 4.70182 4.07682 6.9974 2.42188 9.93359C0.820312 12.9232 0.233073 16.2598 0.660156 19.9434C1.19401 23.6803 2.76888 26.8568 5.38477 29.4727C7.94727 32.1419 11.097 33.7435 14.834 34.2773C19.265 34.7578 23.1354 33.877 26.4453 31.6348L36.0547 41.1641C36.6953 41.8047 37.4427 42.125 38.2969 42.125C39.151 42.125 39.8984 41.8047 40.5391 41.1641C41.1797 40.5234 41.5 39.776 41.5 38.9219C41.5 38.0677 41.1797 37.3203 40.5391 36.6797ZM6.82617 17.7812C6.87956 14.8984 7.89388 12.4961 9.86914 10.5742C11.791 8.59896 14.1934 7.58464 17.0762 7.53125C19.959 7.58464 22.388 8.59896 24.3633 10.5742C26.2852 12.4961 27.2728 14.8984 27.3262 17.7812C27.2728 20.6641 26.2852 23.0664 24.3633 24.9883C22.388 26.9635 19.959 27.9779 17.0762 28.0312C14.1934 27.9779 11.791 26.9635 9.86914 24.9883C7.89388 23.0664 6.87956 20.6641 6.82617 17.7812Z"
          fill="#0068FF"
        />
      </svg>
      <svg v-if="isButtonAction" width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M37.5038 0H31.5038C30.6694 0 30.0038 0.668437 30.0038 1.5V6H39.0075L39.0038 1.5C39.0038 0.668437 38.3381 0 37.5038 0ZM21.0038 24H27.0038V9H21.0038V24ZM16.5038 0H10.5038C9.66938 0 9.00375 0.668437 9.00375 1.5L9 6H18.0038V1.5C18.0038 0.668437 17.3381 0 16.5038 0Z"
          fill="#99C3FF"
        />
        <path
          d="M5.98875 12.0094C5.75063 20.8031 0.328125 22.7156 0 34.875V39C0 40.65 1.35 42 3 42H15C16.65 42 18 40.65 18 39V24H21V9H8.98875C7.33687 9 6.03281 10.3594 5.98875 12.0094ZM42.0094 12.0094C41.9625 10.3594 40.6594 9 39.0094 9H27V24H30V39C30 40.65 31.35 42 33 42H45C46.65 42 48 40.65 48 39V34.875C47.6719 22.7156 42.2531 20.8031 42.0094 12.0094Z"
          fill="#0068FF"
        />
      </svg>

      <h1>{{ titleWithDefault }}</h1>
      <p v-if="description">{{ description }}</p>
      <a v-if="to" :class="isButtonAction && 'button'" :href="to">{{ actionLabelWithDefault }}</a>
    </div>
  </div>
</template>

<script>
import { lang } from './lang'

export default {
  name: 'SeezEmptySearch',
  mixins: [lang],
  props: {
    title: { type: String, default: null },
    actionLabel: { type: String, default: null },
    isButtonAction: { type: Boolean, default: false },
    description: { type: String, default: null },
    to: { type: String, default: null }
  },
  computed: {
    t() {
      return this.languageResources.EMPTY_SEARCH_COMPONENT_TRANSLATIONS
    },
    titleWithDefault() {
      return this.title || this.t.nothing_to_see
    },
    actionLabelWithDefault() {
      return this.actionLabel || this.t.search_now
    }
  }
}
</script>

<style lang="scss" scoped>
.emptySearch {
  margin: 3em auto;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > h1 {
      font-size: 1rem;
      font-weight: 700;
      color: #333333;
      margin-bottom: 0;
    }

    > p {
      font-weight: 400;
      color: #afafaf;
      margin-top: 0.5rem;
    }

    > a {
      color: #0068ff;
      font-weight: 700;
      cursor: pointer;
      text-decoration: none;
    }

    > .button {
      border-style: solid;
      border-color: #0068ff;
      border-width: 0.1rem;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
    }
  }
}
</style>
