<template>
  <a class="listingCard" @click="select" :data-test-id="'listing_card_' + index" :href="toUrl" :target="target">
    <img class="picture" :src="imageUrl" loading="lazy" @error="imageError = true" :alt="fullName" />
    <span class="name">{{ fullName }}</span>
    <span class="variant">{{ details.variant }}</span>
    <div class="details">
      <span>{{ details.year }}</span>
      <span v-if="details.kilometrage">{{ details.kilometrage.toLocaleString(language) }} km</span>
      <span>{{ details.fuelType ? details.fuelType.name : '' }}</span>
    </div>
    <button class="favorite" @click.stop.prevent="toggleFavorite" :data-test-id="'listing_card_' + index + '_favorite_button'" aria-label="Favorite">
      <svg width="24" height="21" viewBox="0 0 24 21" fill="#0068FF" xmlns="http://www.w3.org/2000/svg">
        <path
          v-if="!favorited"
          d="M20.8516 2.45703C19.6484 1.45443 18.2878 1.01042 16.7695 1.125C15.2799 1.23958 13.9766 1.86979 12.8594 3.01562L12 3.91797L11.1406 3.01562C10.224 2.04167 9.02083 1.42578 7.53125 1.16797C6.01302 0.910156 4.55208 1.33984 3.14844 2.45703C1.80208 3.66016 1.08594 5.12109 1 6.83984C0.942708 8.52995 1.51562 10.0482 2.71875 11.3945L11.0117 19.9453C11.2982 20.2318 11.6276 20.375 12 20.375C12.3724 20.375 12.6875 20.2318 12.9453 19.9453L21.2812 11.3516C22.4844 10.0339 23.0573 8.52995 23 6.83984C22.9141 5.12109 22.1979 3.66016 20.8516 2.45703ZM20.293 10.4062L12 19L3.70703 10.4062C2.84766 9.51823 2.40365 8.40104 2.375 7.05469C2.34635 5.73698 2.90495 4.54818 4.05078 3.48828C5.25391 2.57161 6.44271 2.27083 7.61719 2.58594C8.76302 2.90104 9.60807 3.3737 10.1523 4.00391L12 5.89453L13.8477 4.00391C14.3919 3.40234 15.237 2.92969 16.3828 2.58594C17.5573 2.27083 18.7604 2.57161 19.9922 3.48828C21.1094 4.54818 21.6536 5.73698 21.625 7.05469C21.5964 8.3724 21.1523 9.48958 20.293 10.4062Z"
          fill="#0068FF"
        />
        <path
          v-if="favorited"
          d="M20.8516 2.45703C19.6484 1.45443 18.2878 1.01042 16.7695 1.125C15.2799 1.23958 13.9766 1.86979 12.8594 3.01562L12 3.91797L11.1406 3.01562C10.224 2.04167 9.02083 1.42578 7.53125 1.16797C6.01302 0.910156 4.55208 1.33984 3.14844 2.45703C1.80208 3.66016 1.08594 5.12109 1 6.83984C0.942708 8.52995 1.51562 10.0482 2.71875 11.3945L11.0117 19.9453C11.2982 20.2318 11.6276 20.375 12 20.375C12.3724 20.375 12.6875 20.2318 12.9453 19.9453L21.2812 11.3516C22.4844 10.0339 23.0573 8.52995 23 6.83984C22.9141 5.12109 22.1979 3.66016 20.8516 2.45703"
        />
      </svg>
    </button>
    <img class="brand" v-if="brandUrl" :src="brandUrl" :alt="details.brand.name" :title="details.brand.name" loading="lazy" />
    <section class="price">
      <p :data-test-id="'listing_card_' + index + '_loan_price'" class="loan" v-if="loanPriceString">{{ loanPriceString }}</p>
      <p :data-test-id="'listing_card_' + index + '_retail_price'" class="retail">{{ retailPriceString }}</p>
    </section>
    <span :class="`corner ${tagStyle}`" v-if="tagText()">{{ tag }}</span>
  </a>
</template>

<script>
import { analyticsMixin, analyticsEvents } from '../analytics.js'
import { lang } from './lang'
import SeezSdk from '../sdk.js'
import { CAR_PLACEHOLDER } from '../constants.js'

export default {
  name: 'SeezListingCard',
  mixins: [lang, SeezSdk.vueFavoritesMixin, analyticsMixin],
  inheritAttrs: false,
  props: {
    details: { type: Object, required: true },
    to: { type: String, default: null },
    target: { type: String, default: null },
    index: { type: Number, default: 0 },
    isTrackingEnabled: { type: Boolean, default: true }
  },
  data() {
    return {
      imageError: false,
      tagState: '',
      tagStyle: ''
    }
  },
  computed: {
    t() {
      return this.languageResources.LISTING_CARD_COMPONENT_TRANSLATIONS
    },
    fullName() {
      if (this.details?.name) return this.details.name

      const brand = this.details && this.details.model && this.details.model.brand ? this.details.model.brand.name : null
      const model = this.details && this.details.model ? this.details.model.name : null
      return [brand, model].filter(n => n != null).join(' ')
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    retailPriceString() {
      if (this.details.priceRetail == null) return null
      return this.details.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    loanPriceString() {
      if (this.details.priceMonthly == null) return null
      return this.details.priceMonthly.toLocaleString(this.language) + ' ' + this.currency + `/ ${this.t.month}`
    },
    imageUrl() {
      if (this.imageError) return CAR_PLACEHOLDER
      const d = this.details
      if (d.imageIds && d.imageIds.length > 0) return `${import.meta.env.VITE_IMAGES_URL}/image/400x0/listings/${d.id}/${d.imageIds[0]}.webp`
      if (d?.pictures?.[0] == null) return CAR_PLACEHOLDER
      return (import.meta.env.VITE_IMAGES_URL + d.pictures[0]).replace(/\/1600x0\//, '/400x0/')
    },
    favorited() {
      return this.favorites.includes(parseInt(this.details.id))
    },
    brandUrl() {
      if (this.details.brand?.name == null) return null
      const name = this.details.brand.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/\s+/g, '-')
      return `${import.meta.env.VITE_BRANDS_URL}/${name}.webp`
    },
    toUrl() {
      if (this.to == null || this.to === '') return null
      return this.to.replace('{id}', this.details.id)
    }
  },
  methods: {
    select(e) {
      const action = typeof e === 'string' ? e : 'select'
      this.$emit('click', e, {
        id: this.details.id,
        item: this.details,
        action: action
      })
      if (this.isTrackingEnabled) {
        this.track(
          analyticsEvents.CAR_CLICK,
          {
            event_name: analyticsEvents.CAR_CLICK,
            event_category: 'enhanced_ecommerce',
            event_action: analyticsEvents.CAR_CLICK,
            listing_price: this.details.priceRetail,
            event_label: { car_id: this.details.id }
          },
          { vehicle: this.details }
        )
      }
    },
    tagText() {
      if (this.details.reserved) {
        this.tag = this.t.reserved
        this.tagStyle = 'reserved'
        return true
      }
      if (this.details.state === 'unavailable') {
        this.tag = this.t.sold
        this.tagStyle = 'sold'
        return true
      }
      return false
    },
    toggleFavorite() {
      this.setFavorite(this.details.id)
    }
  }
}
</script>

<style lang="scss">
.listingCard {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1em;
  box-shadow: 0 0 4px hsla(0, 0%, 70%, 0.5);
  height: 25em;
  color: #545454;
  background-color: white;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.15s ease-in-out 0s;
  text-decoration: none;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 10px hsla(0, 0%, 70%, 0.6);
  }

  @media screen and (max-width: 32rem) {
    height: 21em;
  }

  > img {
    width: auto;
    aspect-ratio: 3 / 2;
    display: block;
    object-fit: cover;
    height: 12.5em;

    @media screen and (max-width: 32rem) {
      width: auto;
      height: 9em;
    }
  }

  > .name {
    display: block;
    margin: 1rem 1rem 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.25em;
    font-weight: bold;
    color: black;
    padding-bottom: 0.2em;
    text-align: start;

    @media screen and (max-width: 32rem) {
      font-size: 1em;
    }
  }

  > .variant {
    display: block;
    margin: 0 1rem;
    color: #757575;
    font-size: 0.75em;
    width: 240px;
    height: 20px;
    text-align: start;
  }

  .details {
    margin: 2em 1em;
    display: flex;
    color: #707070;
    gap: 0.5em;

    > span {
      background-color: #f6f6f6;
      display: inline-block;
      padding: 0.25em 0.5em;
      border-radius: 0.25em;
      font-size: 0.75em;
      font-weight: 500;
    }
  }

  .favorite {
    background: none;
    background-color: white;
    border: none;
    height: 3em;
    width: 3em;
    padding: 0;
    border-radius: 2em;
    position: absolute;
    right: 1em;
    top: 52%;
    cursor: pointer;

    @media screen and (max-width: 32rem) {
      top: 45%;
    }

    > img {
      height: 1.5em;
      width: 1.5em;
      display: inline-block;
      vertical-align: middle;
    }
  }

  > .brand {
    position: absolute;
    bottom: 0.5em;
    right: 0.75em;
    height: 3em;
    width: 3em;
    display: block;
  }

  > .price {
    position: absolute;
    bottom: 0.5em;
    left: 1em;
    overflow: hidden;
    text-align: left;
    color: #333333;

    > * {
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
    }

    > p.retail {
      font-size: 1.2em;
      font-weight: 800;
      color: black;
    }

    > p.loan {
      font-size: 1em;
      font-weight: 400;
      color: #757575;
      padding-bottom: 0.2em;

      > * {
        vertical-align: middle;
      }
    }
  }

  > .corner {
    font-size: 0.75em;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5em 1em;
    border-radius: 1.5em;
    color: white;
    font-weight: 900;
    margin: 0.5em;
    min-width: 4.25em;
    text-align: center;
  }

  > .reserved {
    background-color: #ffa200;
  }

  > .sold {
    background-color: #ff0009;
  }

  @media screen and (max-width: 24rem) {
    font-size: 4vw;
  }
}
</style>
