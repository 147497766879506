<template>
  <div class="tradeInDrawerContainer" data-test-id="trade-in-container">
    <div class="thankYou" v-if="submitted">
      <img :src="check" />
      <h2>{{ t['thanks_you_for_submitting'] }}</h2>
      <p>{{ t['we_ll_contact_you_via_via_email'] }}</p>
      <button class="doneThankYou" data-test-id="thank_you_in_button" @click="closeDrawer">
        {{ t['done'] }}
      </button>
    </div>
    <div class="relativePositioned" v-if="!submitted">
      <loader :class="'tradeInDrawerLoader' + (showFullForm ? ' tradeInDrawerLoaderFullForm' : '')" v-if="loading" />
      <div class="closeIcon" @click="closeDrawer">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.0312 12.9688C15.6406 13.5312 15.6406 14.5156 15.0312 15.0781C14.75 15.3594 14.375 15.5 14 15.5C13.5781 15.5 13.2031 15.3594 12.9219 15.0781L8 10.1562L3.03125 15.0781C2.75 15.3594 2.375 15.5 2 15.5C1.57812 15.5 1.20312 15.3594 0.921875 15.0781C0.3125 14.5156 0.3125 13.5312 0.921875 12.9688L5.84375 8L0.921875 3.07812C0.3125 2.51562 0.3125 1.53125 0.921875 0.96875C1.48438 0.359375 2.46875 0.359375 3.03125 0.96875L8 5.89062L12.9219 0.96875C13.4844 0.359375 14.4688 0.359375 15.0312 0.96875C15.6406 1.53125 15.6406 2.51562 15.0312 3.07812L10.1094 8.04688L15.0312 12.9688Z"
            fill="#545454"
          />
        </svg>
      </div>
      <div class="tradeInTitle">
        <h2 class="">{{ t['trade_in_your_car'] }}</h2>
        <p class="tradeInSubTitle">{{ t['to_get_your_trade_In_price'] }}</p>
      </div>
      <form @submit.prevent="createTradeIn" ref="vehicleForm">
        <label class="licensePlate">
          <span>
            <span class="prefix"><img :src="euFlag" width="21" height="21" /> <span>DK</span></span>
            <input required v-model="tradeInModel.licensePlate" type="text" ref="licensePlateInput" @keydown="vehicleLookup" minlength="5" maxlength="10" />
            <button type="button" class="search" @click="vehicleLookup()">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13">
                <g stroke-width="2" stroke="#ffffff" fill="none">
                  <path d="M11.29 11.71l-4-4" />
                  <circle cx="5" cy="5" r="4" />
                </g>
              </svg>
            </button>
          </span>
        </label>
        <div class="thingsToNote" v-if="!showFullForm">
          <h3>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 4C8.55219 4 9 4.44781 9 5C9 5.55219 8.55219 6 8 6C7.44781 6 7 5.55313 7 5C7 4.44687 7.44687 4 8 4ZM9.25 12H6.75C6.3375 12 6 11.6656 6 11.25C6 10.8344 6.33594 10.5 6.75 10.5H7.25V8.5H7C6.58594 8.5 6.25 8.16406 6.25 7.75C6.25 7.33594 6.5875 7 7 7H8C8.41406 7 8.75 7.33594 8.75 7.75V10.5H9.25C9.66406 10.5 10 10.8359 10 11.25C10 11.6641 9.66563 12 9.25 12Z"
                fill="#CCE1FF"
              />
              <path
                d="M8 6C8.55219 6 9 5.55219 9 5C9 4.44781 8.55219 4 8 4C7.44781 4 7 4.44687 7 5C7 5.55313 7.44687 6 8 6ZM9.25 10.5H8.75V7.75C8.75 7.3375 8.41563 7 8 7H7C6.5875 7 6.25 7.3375 6.25 7.75C6.25 8.1625 6.5875 8.5 7 8.5H7.25V10.5H6.75C6.3375 10.5 6 10.8375 6 11.25C6 11.6625 6.3375 12 6.75 12H9.25C9.66406 12 10 11.6641 10 11.25C10 10.8359 9.66563 10.5 9.25 10.5Z"
                fill="#0068FF"
              />
            </svg>
            &nbsp;{{ t['free_tradein_notes'] }}
          </h3>
          <div v-html="t['free_tradein_explanation']" />
        </div>
        <template v-if="showFullForm">
          <div class="carDetailsTitle">
            <h2>{{ t['car_details_trade_in'] }}</h2>
            <p>{{ t['fill_in_your_cars_information'] }}</p>
          </div>

          <div class="carDetails">
            <input required class="radiusInput" :placeholder="t['make']" v-model="tradeInModel.make" type="text" />
            <input required class="radiusInput" :placeholder="t['model']" v-model="tradeInModel.model" type="text" />
            <input required class="radiusInput" :placeholder="t['variant']" v-model="tradeInModel.variant" type="text" />
            <input required class="radiusInput" :placeholder="t['modelYear']" v-model.number="tradeInModel.year" type="number" ref="modelYearField" min="1900" max="2100" />
            <!-- <input required class="radiusInput calendarIcon" :placeholder="t['registrationDate']" v-model="tradeInModel.registrationDate" type="date" :max="new Date().toISOString().split('T')[0]" /> -->
            <input
              required
              class="radiusInput calendarIcon"
              type="text"
              :placeholder="t['registrationDate']"
              v-model="tradeInModel.registrationDate"
              :max="new Date().toISOString().split('T')[0]"
              @focus="dateInputFocus"
              @blur="dateInputBlur"
              
            />
            <multi-select
              class="radiusInputSelect"
              :multi="false"
              :clear-placeholder="t['registrationType']"
              :options="registrationTypes"
              v-model.number="tradeInModel.registrationType"
              :placeholder="t['registrationType']"
              test-id="registration_type"
            />
            <input required class="radiusInput" :placeholder="t['kilometrage']" v-model="tradeInModel.kilometrage" type="number" min="0" max="1000000" />
          </div>

          <div class="moreDetailsTitle">
            <h2>{{ t['more_details'] }}</h2>
            <p>{{ t['has_your_car_been_in'] }}</p>
          </div>
          <div class="radio-btn-group">
            <div class="radio">
              <input required="required" type="radio" name="car-accident" :value="true" v-model="tradeInModel.accident" id="accident-yes" /><label for="accident-yes">{{ t['yes'] }}</label>
            </div>
            <div class="radio">
              <input type="radio" name="car-accident" :value="false" v-model="tradeInModel.accident" id="accident-no" /><label for="accident-no">{{ t['no'] }}</label>
            </div>
          </div>

          <p class="carConditionTitle">
            {{ t['what_your_car_current_condition'] }}
          </p>
          <multi-select class="selectCondition" :multi="false" :options="conditions" v-model.number="tradeInModel.carCondition" :placeholder="t['car_condition_select']" test-id="car_condition" />

          <div class="conditionDetails" v-if="tradeInModel.carCondition">
            <div v-if="tradeInModel.carCondition === 'good'" class="goodCarText">
              {{ t['carConditionGoodDef'] }}
            </div>
            <div v-if="tradeInModel.carCondition === 'poor'" class="poorCarText">
              {{ t['carConditionPoorDef'] }}
            </div>
            <div v-if="tradeInModel.carCondition === 'average'" class="averageCarText">
              {{ t['carConditionAverageDef'] }}
            </div>
            <div v-if="tradeInModel.carCondition === 'great'" class="greatCarText">
              {{ t['carConditionGreatDef'] }}
            </div>
          </div>

          <p class="carConditionTitle">{{ t['how_many_keys'] }}</p>
          <div class="carDetails">
            <input required class="radiusInput doubleColumn" :placeholder="t['number_of_keys']" v-model="tradeInModel.numberOfKeys" type="number" min="1" max="20" />
          </div>

          <div class="imageUpload">
            <h2>{{ t['vehicle_images'] }}</h2>
            <p>
              {{ t['upload'] }}<b>{{ t['front_back_interior'] }}</b
              >{{ t['and'] }}<b>{{ t['damage'] }}</b
              >{{ t['pictures_of_your_car'] }}
            </p>
            <div :class="'uploadSection' + (isPicturesFieldIsInvalid ? ' invalidImages' : '')" @drop.prevent="dropFiles" @dragover.prevent @click="$refs.selectFiles.click">
              <img :src="uploadPlaceholder" :alt="t['upload'] + t['front_back_interior'] + t['and'] + t['damage'] + t['pictures_of_your_car']" width="40" />
              <p>
                {{ t['drag_your_file'] }}<label class="tradeInImagesLabel" :for="isMobile ? '' : 'tradeInImages'">{{ t['browse_file'] }}</label>
                <input type="file" id="tradeInImages" multiple @change="selectFile" ref="selectFiles" accept=".pdf,.jpg,.jpeg,.png" />
              </p>
            </div>
            <div class="imagesPreview">
              <div v-for="(img, key) in tradeInModel.pictures" :key="img.url">
                <div class="thumbnail"><img :src="baseUrl + img.url" /></div>
                <div class="fileName">{{ img.name }}</div>
                <div class="remove" @click="removeImage(key)">
                  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z"
                      fill="#FF0009"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="emailAddressTitle">
            <h2>{{ t['what_is_your_email_address'] }}</h2>
            <p>{{ t['we_ll_send_an_email_you'] }}</p>
          </div>
          <div class="carDetails">
            <input required class="radiusInput doubleColumn" :placeholder="t['please_enter_your_name']" v-model="tradeInModel.fullName" type="text" />
            <input required class="radiusInput" :placeholder="t['please_enter_phone']" v-model="tradeInModel.phone" type="phone" />
            <input required class="radiusInput" :placeholder="t['please_enter_email']" v-model="tradeInModel.email" type="text" />
          </div>

          <div class="privacyBox">
            <input required type="checkbox" class="privacyCheckBox" id="privacy" v-model="acceptPrivacy" />
            <label for="privacy">{{ t['i_accept_privacy_policy'] }}</label>
          </div>

          <button class="getTradeInValue" type="submit" data-test-id="get_details_trade_in_button" :disabled="!acceptPrivacy">
            {{ t['get_my_trade_in_value'] }}
          </button>
        </template>
      </form>
    </div>
  </div>
</template>
<script>
import { analyticsMixin, analyticsEvents } from '../../analytics.js'
import SeezSdk from '../../sdk.js'
import { lang } from '../lang'
import Loader from '../Loader.ce.vue'
import { CHECK_ICON, EU_FLAG, UPLOAD_ICON } from '../../constants'
import MultiSelect from '../MultiSelect.ce.vue'

export default {
  name: 'TradeInDrawer',
  components: { Loader, MultiSelect },
  mixins: [lang, SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    listing: { type: String, default: null },
    searchVehicle: { type: String, default: '' }
  },
  data() {
    return {
      loading: false,
      vehicleLookupResponse: {},
      condition: null,
      carHasAccident: null,
      hasFinancing: null,
      intersectionObserver: new IntersectionObserver(x => x.forEach(this.intersectionTrigger)),
      instantTradeInFormProcessInterval: null,
      isMobile: window.innerWidth < 768,
      tradeInModel: {
        licensePlate: '',
        make: '',
        model: '',
        variant: '',
        year: '',
        registrationDate: '',
        registrationType: '',
        kilometrage: '',
        accident: '',
        numberOfKeys: '',
        carCondition: '',
        fullName: '',
        phone: '',
        email: '',
        pictures: []
      },
      acceptPrivacy: false,
      submitted: false,
      conditions: [],
      registrationTypes: [],
      showFullForm: false,
      isPicturesFieldIsInvalid: false
    }
  },
  computed: {
    t() {
      return this.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    },
    euFlag() {
      return EU_FLAG
    },
    check() {
      return CHECK_ICON
    },
    uploadPlaceholder() {
      return UPLOAD_ICON
    },
    baseUrl() {
      return import.meta.env.VITE_IMAGES_URL + '/'
    }
  },
  watch: {
    searchVehicle() {
      this.loading = true
      this.tradeInModel.licensePlate = this.searchVehicle
      this.vehicleLookup()
    }
  },
  mounted() {
    this.intersectionObserver.observe(this.$refs.vehicleForm)
    this.loadUserDetails()
    this.conditions = [
      { id: 'poor', name: this.t.poor },
      { id: 'good', name: this.t.good },
      { id: 'average', name: this.t.average },
      { id: 'great', name: this.t.great }
    ]
    this.registrationTypes = [
      { id: this.t.regTypeVanWithoutVat, name: this.t.regTypeVanWithoutVat },
      { id: this.t.regTypeVanWithVat, name: this.t.regTypeVanWithVat },
      { id: this.t.regTypePersonal, name: this.t.regTypePersonal },
      { id: this.t.regTypeLeasing, name: this.t.regTypeLeasing }
    ]
  },
  methods: {
    dateInputFocus(event) {
      event.target.type = 'date'
    },
    dateInputBlur(event) {
      if (event.target.value == '') {
        event.target.type = 'text'
      }
    },
    async loadUserDetails() {
      const user = await window.seezSdk.getCurrentUser()
      if (user && user.email) {
        this.tradeInModel.email = user.email
        this.tradeInModel.fullName = user.name

        const { me } = await this.queryApi('{me{phone}}')
        this.tradeInModel.phone = me.phone
      }
    },
    intersectionTrigger() {},
    trackIndependentTradeIn(eventName, properties) {
      if (eventName === analyticsEvents.TRADE_IN_REG_INPUT) {
        this.track(eventName, properties)
      }
      //debouncer function which will trigger an event in 2 seconds.
      if (eventName === analyticsEvents.TRADE_IN_FORM_PROCESS) {
        this.track(eventName, properties.tradeInModel)
      }
      if (eventName === analyticsEvents.TRADE_IN_REQUEST_SUBMITTED) {
        this.track(eventName, properties)
      }
    },
    focusOnInput() {
      this.$nextTick(() => this.$refs.licensePlateInput.focus())
    },
    async vehicleLookup(e) {
      this.$refs.licensePlateInput.setCustomValidity('')
      this.$refs.vehicleForm.reportValidity()
      if (e != null && e.key !== 'Enter') return
      this.loading = true

      try {
        const response = await this.queryApi(
          `query vehicleLookup {vehicleLookup(licensePlate: "${this.tradeInModel.licensePlate}") {brand,model,variant,vin,enginePower,registrationDate,fuelEfficiency,fuelType,inspectionDate,licensePlate,weightTotal}}`
        )
        this.tradeInModel.model = response.vehicleLookup.model
        this.tradeInModel.variant = response.vehicleLookup.variant
        this.tradeInModel.make = response.vehicleLookup.brand
        this.tradeInModel.vin = response.vehicleLookup.vin
        this.tradeInModel.fuelType = response.vehicleLookup.fuelType
        this.loading = false
        this.trackIndependentTradeIn(analyticsEvents.TRADE_IN_REG_INPUT, { licensePlate: this.tradeInModel.licensePlate })

        this.showFullForm = true
        this.$nextTick(function () {
          this.$refs.modelYearField.focus()
        })
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          this.loading = false
        }
        this.$refs.licensePlateInput.setCustomValidity('License plate is not found')
        this.$refs.vehicleForm.reportValidity()
      }
      return false
    },
    async createTradeIn() {
      if (!this.showFullForm) return
      if (this.tradeInModel.pictures.length === 0) {
        this.$refs.selectFiles.setCustomValidity(this.t.you_should_upload_images)
        this.isPicturesFieldIsInvalid = true
        this.$refs.vehicleForm.reportValidity()
        return
      }
      const query = `mutation createTradeIn($payload: TradeInInput) {
          createTradeIn(input: $payload) {
            id
            vin
            licensePlate
            make
            model
            year
            variant
            registrationDate
            kilometrage
            fuelType
            carCondition
            registrationType
            color
            accident
            listingId
            numberOfKeys
            pictures {
              type
              url
            }
          }
}`

      this.loading = true
      try {
        const variables = {
          payload: {
            vin: this.tradeInModel.vin,
            licensePlate: this.tradeInModel.licensePlate,
            make: this.tradeInModel.make,
            model: this.tradeInModel.model,
            year: this.tradeInModel.year,
            variant: this.tradeInModel.variant,
            kilometrage: +this.tradeInModel.kilometrage,
            fuelType: this.tradeInModel.fuelType,
            registrationDate: this.tradeInModel.registrationDate,
            carCondition: this.tradeInModel.carCondition,
            registrationType: this.tradeInModel.registrationType,
            // color: this.tradeInModel, //TODO: it's not coming in backend response and it's not mentioned in Ui design
            accident: this.tradeInModel.accident,
            numberOfKeys: +this.tradeInModel.numberOfKeys,
            listingId: +this.listing,
            name: this.tradeInModel.fullName,
            phone: this.tradeInModel.phone,
            email: this.tradeInModel.email,
            pictures: this.tradeInModel.pictures.map(({ url, type }) => {
              return { type, url }
            })
          }
        }
        await this.queryApi(query, variables)

        this.trackIndependentTradeIn(analyticsEvents.TRADE_IN_REQUEST_SUBMITTED, { payload: this.tradeInModel })
        this.loading = false
        this.submitted = true
        this.showFullForm = false
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          this.loading = false
        }
      }
    },
    closeDrawer() {
      // this.loading = false
      // this.vehicleLookupResponse = {}
      // this.condition = null
      // this.carHasAccident = null
      // this.hasFinancing = null
      // this.tradeInModel = { email: '' }
      // this.acceptPrivacy = false
      this.submitted = false
      this.$emit('closeDrawer')
      if (this.$refs.vehicleForm) this.intersectionObserver.unobserve(this.$refs.vehicleForm)
    },
    async selectFile() {
      this.loading = true
      const files = [...this.$refs.selectFiles.files]
      if (!files || files.length === 0) return
      let uploadPromises = files.map(file => {
        return this.imageUpload(file)
      })
      await Promise.all(uploadPromises)
      this.loading = false
    },
    async dropFiles(event) {
      this.loading = true
      const files = [...event.dataTransfer.files]
      const uploadPromises = files?.map(file => {
        return this.imageUpload(file)
      })
      await Promise.all(uploadPromises)
      this.loading = false
    },
    async imageUpload(file) {
      this.$refs.selectFiles.setCustomValidity('')
      this.isPicturesFieldIsInvalid = false
      const result = await this.uploadFile('trade-in/' + this.tradeInModel.licensePlate, file, true)
      const uploadedFile = {
        type: 'other',
        name: file.name,
        url: result
      }
      this.tradeInModel.pictures.push(uploadedFile)
    },
    removeImage(index) {
      this.tradeInModel.pictures.splice(index, 1)
    }
  }
}
</script>
<style lang="scss">
.tradeInDrawerContainer {
  padding: 1.4375rem 2.5rem;
  @media screen and (max-width: 40rem) {
    padding: 1.4375rem 1.25rem;
  }

  input {
    font-size: 16px;
    font-family: 'Biennale', sans-serif;
    font-weight: 400;

    input::placeholder {
      color: #757575;
    }
  }

  .doubleColumn {
    grid-column-end: span 2;
  }

  .closeIcon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .licensePlate > span {
    display: flex;
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    margin: 0 0 30px 0;

    > input {
      padding: 0.5em 1em;
      border: none;
      flex-grow: 1;

      color: #333333;
      outline: none;
      border-radius: 2em;
    }

    > span.prefix {
      padding: 0.5em 1em;
      position: relative;
      background: #003e99;
      border-radius: 2em 0 0 2em;
      display: flex;
      flex-direction: column;
      margin: -0.4px -1px;
      > span {
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 11px;
        text-align: center;
        padding-top: 4px;
        color: white;
      }
    }

    &.error {
      > input {
        border-left-color: red;
        border-top-color: red;
        border-bottom-color: red;
      }

      > span {
        border-color: red;

        &::before {
          content: '!';
          color: red;
          position: absolute;
          top: 0.5em;
          left: -4em;
          border: 1px solid red;
          border-radius: 2em;
          height: 1em;
          width: 1em;
          padding: 0.125em;
          text-align: center;
          line-height: 1em;
          font-weight: bold;
        }
      }
    }

    > button.search {
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      overflow: hidden;
      outline: none;
      display: flex;
      justify-self: center;
      align-items: center;
      color: white;
      text-decoration: none;
      cursor: pointer;
      width: 45px;
      height: 45px;
      padding: 16px;
      background: #0068ff;
      border-radius: 24px;
      margin: 3px;
    }
  }

  .thingsToNote {
    padding: 1em;

    > h3 {
      display: flex;
      align-items: center;
    }
  }

  .carDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;

    @media screen and (max-width: 40rem) {
      grid-template-columns: 1fr;

      img {
        height: 1em;
        padding-left: 0.5em;
        margin: auto;
      }
    }

    > p {
      color: black;
    }
  }

  .radio-btn-group {
    display: flex;
    gap: 14px;
    .radio {
      flex-grow: 1;
      label {
        background: #fff;
        padding: 0.75rem 1.25rem;
        border: 1px solid #cbcbcb;
        border-radius: 2em;
        cursor: pointer;
        color: #444;
        transition: box-shadow 400ms ease;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;

        &:hover {
          background: rgba(0, 83, 204, 0.04);
          border: 1px solid #0053cc;
          border-radius: 2em;
          color: #0053cc;
        }
      }

      input[type='radio'] {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      input[type='radio']:checked + label {
        background: #0068ff;
        color: #fff;
        border-color: #0068ff;
      }
    }
  }

  .radiusInput {
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    padding: 0.75em 1.75em;
    width: 100%;
    box-sizing: border-box;

    &:focus-visible {
      outline: 1px solid #0068ff;
    }

    @media screen and (max-width: 42rem) {
      grid-column-end: span 2;
    }
  }

  .radiusInputSelect {
    width: 100%;
    & span {
      line-height: 29px;
      font-size: 14px;
    }
    & button svg {
      top: 46%;
    }

    @media screen and (max-width: 42rem) {
      grid-column-end: span 2;
    }
  }

  .radiusInputFullWidth {
    @extend .radiusInput;
    min-width: 20em;
  }

  .carCondition {
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    height: 48px;
    padding: 15px 24px;
    width: 95%;
  }

  .conditionDetails {
    background: #f6f6f6;
    border-radius: 2em;
    padding: 8px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12.8px;
    line-height: 18px;
    margin: 26px 0 0 0;
  }

  .goodCarText {
    color: #0068ff;
  }

  .poorCarText {
    color: #ff0009;
  }

  .averageCarText {
    color: #ffa200;
  }

  .greatCarText {
    color: #009a33;
  }

  .privacyBox {
    margin: 20px 0;
    font-size: 14px;
  }

  .getTradeInValue {
    cursor: pointer;
    background-color: var(--highlight);
    border: none;
    border-radius: 2em;
    margin: 1em 0 0;
    padding: 0.75em 1.5em;
    font-size: 1em;
    font-weight: 500;
    color: white;
    width: 100%;
    height: 48px;

    &:hover {
      background-color: #0053cc;
    }

    &:disabled {
      background-color: #0053cc33;
      cursor: not-allowed;
    }
  }

  .thankYou {
    padding-top: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
      margin: 34px 0 0 0;
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #000000;
      margin: 8px 0 0 0;
    }

    > .doneThankYou {
      width: 252px;
      height: 48px;
      border: 1px solid #0068ff;
      border-radius: 24px;
      color: #0068ff;
      background: #ffffff;
      height: 40px;
      cursor: pointer;
      margin: 48px 0 0 0;
    }
  }

  .tradeInDrawerLoader {
    position: fixed;
    z-index: 100;
    height: 100vh;
    top: 0;
    &.tradeInDrawerLoaderFullForm {
      position: sticky;
      margin-top: -100vh;
    }
  }

  .tradeInTitle {
    > h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
      color: #000000;
      margin: 2px 0 8px 0;
    }
    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin: 0 0 30px 0;
    }
  }

  .carDetailsTitle {
    > h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin: 0 0 8px 0;
    }
    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 12.8px;
      line-height: 18px;
      color: #757575;
      margin: 0 0 25px 0;
    }
  }

  .moreDetailsTitle {
    > h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin: 37px 0 23px 0;
    }
    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: black;
      margin: 0 0 0.5em 0;
    }
  }

  .outstandingFinancingTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: black;
    margin: 2em 0 0.5em 0;
  }

  .carConditionTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: black;
    margin: 2em 0 0.5em 0;
  }

  .emailAddressTitle {
    > h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin: 39px 0 8px 0;
    }
    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 12.8px;
      line-height: 18px;
      color: #757575;
      margin: 0 0 22px 0;
    }
  }
  .imageUpload {
    > h2 {
      font-size: 1rem;
      line-height: 1.375rem;
      color: #000000;
      margin: 2.375rem 0 0 0;
    }
    > p {
      line-height: 1.375rem;
      color: #000000;
      margin-top: 0.625rem;
    }
    > .uploadSection {
      transition: all 0.1s ease;
      &:hover {
        border: 1px dashed #1500ff;
        background: rgba(204, 225, 255, 0.8);
      }
      &.invalidImages {
        background: #ffb3b580;
        border: 1px dashed #ff0009;
      }
      &.invalidImages:hover {
        background: #ffb3b599;
        border: 1px dashed #ff0009;
      }
      background: rgba(204, 225, 255, 0.5);
      border: 1px dashed #0068ff;
      border-radius: 0.625rem;
      padding: 2.9375rem 6.5rem 2.8125rem 6.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > p {
        text-align: center;
      }
      input[type='file'] {
        opacity: 0;
        width: 1px;
        height: 1px;
      }
    }
    @media screen and (max-width: 40rem) {
      > .uploadSection {
        padding: 1.25rem 0.625rem;
      }
    }
    label.tradeInImagesLabel {
      text-decoration: underline;
      color: blue;
      cursor: pointer;
    }
    .imagesPreview {
      margin-top: 0.8125rem;
      > div {
        display: flex;
        margin: 0.375rem 0;
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        align-items: center;
        gap: 0.75rem;
        > .fileName {
          flex-grow: 1;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.1875rem;
          display: flex;
          align-items: center;
          color: #000000;
        }
        > .remove {
          margin: 1.75rem 1.375rem;
          cursor: pointer;
        }
      }
      .thumbnail > img {
        height: 4.125rem;
        padding-left: 0.3125rem;
      }
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  input.calendarIcon {
    background-position-x: 93%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='15' viewBox='0 0 13 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 2.5H9.40625V1.40625C9.40625 1.05078 9.10547 0.75 8.75 0.75C8.42188 0.75 8.09375 1.05078 8.09375 1.40625V2.5H4.15625V1.40625C4.15625 1.05078 3.85547 0.75 3.5 0.75C3.11719 0.75 2.84375 1.05078 2.84375 1.40625V2.5H1.75C0.765625 2.5 0 3.29297 0 4.25V13C0 13.9844 0.765625 14.75 1.75 14.75H10.5C11.457 14.75 12.25 13.9844 12.25 13V4.25C12.25 3.29297 11.457 2.5 10.5 2.5ZM10.9375 6V8.84375H6.78125V6H10.9375ZM5.46875 6V8.84375H1.3125V6H5.46875ZM1.3125 13V10.1562H5.46875V13.4375H1.75C1.50391 13.4375 1.3125 13.2461 1.3125 13ZM10.5 13.4375H6.78125V10.1562H10.9375V13C10.9375 13.2461 10.7188 13.4375 10.5 13.4375Z' fill='%23757575'/%3E%3C/svg%3E%0A");
    background-size: 13px;
    padding: 0.55em 1em 0.75em 1.75em;
  }

  .selectCondition {
    & span {
      line-height: 29px;
      font-size: 14px;
    }
    & button svg {
      top: 46%;
    }
  }

  .relativePositioned {
    position: relative;
  }
  button {
    font-family: 'Biennale', sans-serif;
  }
}
</style>
