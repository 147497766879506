<template>
  <Modal @close="close">
    <div class="loginModal">
      <div v-if="seezMarketplaceBanner" :class="brandBanner">
        <BlobIcon />
      </div>
      <div v-else :class="brandBanner">
        <div v-if="brandBanner !== 'whiteLabel'" class="powerBySeez">
          Powered by
          <span class="headerLogo">
            <LogoIcon />
          </span>
        </div>
      </div>
      <div class="whiteLabelLogo" />
      <div class="login">
        <template v-if="!remaining">
          <h1>{{ t['welcome'] }}</h1>
          <p v-if="hasCustomtext" />
          <p v-else>{{ t['please_login'] }}</p>
          <input ref="email" type="email" v-model="email" @keyup.enter="requestCode" name="email" autocomplete="email" data-test-id="login_email_field" :placeholder="[[t['email_address']]]" />
        </template>
        <template v-if="remaining">
          <div class="otp">
            <a v-if="remaining" @click="endTimer"><ChevronLeftIcon /> {{ t['go_back'] }}</a>
            <h1>{{ t['please_check_email'] }}</h1>
            <p>{{ t['we_sent_to'] }} {{ email }}</p>
            <div class="inputOtpWrapper">
              <InputOTP :length="6" :error="errorMessage" @value="handleOtpValue" />
            </div>
            <time>{{ remaining }}</time>
            <p class="resend">
              {{ t['did_not_get_code'] }}<a @click="requestCode">{{ t['click_to_resend'] }}</a>
            </p>
          </div>
        </template>
        <button v-if="!remaining" @click="requestCode" :class="{ requested: remaining != null }" data-test-id="login_request_code_button">
          {{ t['get_otp'] }}
        </button>
        <button @click="authenticate" v-if="remaining" :disabled="!validCode" data-test-id="login_authenticate_button">{{ t['confirm'] }}</button>
        <template v-if="!remaining">
          <div class="socialWrapper">
            <h5>{{ t['sign_in_using'] }}</h5>
            <div>
              <button v-if="onGoogleLogin" @click="onGoogleLogin" class="social" data-test-id="login_google">
                <GoogleIcon />
                {{ t['google'] }}
              </button>
              <!-- <button v-if="onFacebookLogin" @click="onFacebookLogin" class="social" data-test-id="login_facebook">
                <FacebookIcon />
                {{ t['facebook'] }}
              </button> -->
            </div>
          </div>
          <div class="checkboxWrapper">
            <label>
              <input :class="{ selectionRequired }" data-test-id="terms_of_use" type="checkbox" v-model="acceptedConditions" />
              <span v-if="hasCustomtext" class="customTermsText" />
              <span v-else>
                {{ t['i_agree'] }} <a href="/terms" target="_blank" rel="noreferrer"> {{ t['terms_conditions'] }}</a> & <a href="/privacy" target="_blank" rel="noreferrer">{{ t['privacy'] }}</a>
              </span>
            </label>
            <label>
              <input data-test-id="marketing_terms" type="checkbox" v-model="acceptedMarketingTerms" />
              <span v-if="hasCustomtext" class="customMarketingText" />
              <span v-else>
                {{ t['marketing_terms'] }}
              </span>
            </label>
          </div>
        </template>
        <p class="error" v-if="errorMessage && !hasCustomtext" data-test-id="login_error_message">
          {{ errorMessage }}
        </p>
        <p class="error" v-if="errorMessage && hasCustomtext"/>
      </div>
      <Loader v-show="loading" />
    </div>
  </Modal>
</template>

<script>
import Loader from '../Loader.ce.vue'
import Modal from '../Modal.ce.vue'
import InputOTP from './InputCode.ce.vue'
import { isValidEmail } from '../../logic'
import { analyticsMixin, analyticsEvents } from '../../analytics.js'
import LogoIcon from '../../assets/seez-logo.svg'
// import { syncFavorites, syncSavedSearches } from '../favorites.js'
import BlobIcon from '../../assets/blob.svg'
import GoogleIcon from '../../assets/google.svg'
// import FacebookIcon from '../../assets/facebook.svg'
import ChevronLeftIcon from '../../assets/chevron-left.svg'
import { lang } from '../lang'

const customizationOptions = {
  marketplace: 'marketplaceBanner',
  empty: 'empty',
  custom: 'custom',
  whiteLabel: 'whiteLabel'
}

export default {
  name: 'SeezLoginModal',
  components: { Modal, InputOTP, LogoIcon, BlobIcon, Loader, ChevronLeftIcon, GoogleIcon },
  mixins: [lang, analyticsMixin],
  props: {
    brandBanner: { type: String, default: customizationOptions.empty }
  },
  data() {
    return {
      expiration: null,
      remaining: null,
      interval: null,
      email: '',
      code: '',
      errorMessage: null,
      loading: false,
      acceptedConditions: false,
      acceptedMarketingTerms: false,
      selectionRequired: false
    }
  },
  computed: {
    t() {
      return this.languageResources.LOGIN_COMPONENT_TRANSLATIONS
    },
    hasCustomtext() {
      return [customizationOptions.custom, customizationOptions.whiteLabel].includes(this.brandBanner)
    },
    seezMarketplaceBanner() {
      if (this.brandBanner === customizationOptions.marketplace) return true
      return false
    },
    eula() {
      return this.t.accept_eula
        .replace('{tu}', `<a href="/terms" target="_blank" rel="noreferrer">${this.t.terms_use}</a>`)
        .replace('{pp}', `<a href="/privacy" target="_blank" rel="noreferrer">${this.t.privacy_policy}</a>`)
    },
    validEmail() {
      return isValidEmail(this.email)
    },
    validCode() {
      return this.code != null && this.code.length === 6
    }
  },
  watch: {
    email() {
      this.errorMessage = null
      this.selectionRequired = false
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.email.focus())
    this.startTracking()
  },
  beforeDestroy() {
    this.endTimer()
  },
  methods: {
    startTracking() {
      this.loadSegmentTracking(import.meta.env.VITE_SEGMENT_ID)
    },
    onGoogleLogin() {
      if (import.meta.env.VITE_GOOGLE_CLIENT_ID == null) return

      const isTermsSelected = this.checkAcceptedConditions()
      if (!isTermsSelected) return

      this.loading = true

      const params = {
        response_type: 'code',
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        redirect_uri: import.meta.env.VITE_AUTH_URL + '/google',
        state: btoa(JSON.stringify({ url: window.location.toString(), marketing: this.acceptedMarketingTerms })),
        // login_hint: "jsmith@example.com"
        scope: ['email', 'profile', 'openid']
      }

      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${this.objectToQueryString(params)}`
    },
    onFacebookLogin() {
      if (import.meta.env.VITE_FACEBOOK_CLIENT_ID == null) return

      const isTermsSelected = this.checkAcceptedConditions()
      if (!isTermsSelected) return

      this.loading = true

      const params = {
        client_id: import.meta.env.VITE_FACEBOOK_CLIENT_ID,
        redirect_uri: import.meta.env.VITE_AUTH_URL + '/facebook',
        state: btoa(JSON.stringify({ url: window.location.toString(), marketing: this.acceptedMarketingTerms })),
        scope: ['email']
      }
      window.location.href = `https://www.facebook.com/v13.0/dialog/oauth?${this.objectToQueryString(params)}`
    },
    objectToQueryString(obj) {
      const encoded_params = Object.keys(obj).map(k => `${k}=${Array.isArray(obj[k]) ? obj[k].join(' ') : encodeURIComponent(obj[k])}`)
      return encoded_params.join('&')
    },
    checkAcceptedConditions() {
      if (!this.acceptedConditions) {
        this.selectionRequired = true
        this.errorMessage = 'Du skal acceptere Seez’s vilkår for brug og privatlivspolitik for at fortsætte'
        return false
      }
      return true
    },
    async requestCode() {
      if (this.loading) return

      if (!this.validEmail) {
        this.errorMessage = 'Ugyldig Email'
        return
      }

      const isTermsSelected = this.checkAcceptedConditions()

      if (!isTermsSelected) return

      this.errorMessage = null
      this.loading = true
      try {
        const { expiration } = await window.seezSdk.requestCode(this.email)
        this.endTimer()
        this.expiration = new Date(expiration)
        this.recalcRemaining()
        this.interval = setInterval(this.recalcRemaining, 1000)
      } catch (error) {
        this.errorMessage = 'Error requesting code'
      }
      this.track(analyticsEvents.OTP_OPEN, {
        event_name: analyticsEvents.OTP_OPEN,
        event_category: 'signup_flow',
        event_action: analyticsEvents.OTP_OPEN,
        label: '/'
      })
      this.loading = false
    },
    async authenticate() {
      if (this.loading) return
      if (!this.validEmail) {
        this.errorMessage = 'Invalid Email'
        return
      }
      if (!this.validCode) {
        this.trackCTA(analyticsEvents.OTP_ERROR, { category: 'signup_flow', name: 'otp_error' })
        this.errorMessage = 'Invalid Code'
        return
      }

      this.errorMessage = null
      this.loading = true
      try {
        await window.seezSdk.loginWithOTP(this.email, this.code, this.acceptedMarketingTerms)
        this.endTimer()
        this.trackLoginSuccess()
        this.close()
        this.trackIdentify()
      } catch (error) {
        console.error(error)
        this.errorMessage = 'Invalid username or code'
      }
      this.loading = false
    },
    recalcRemaining() {
      if (!this.expiration == null) {
        this.remaining = null
      } else {
        const remaining = Math.floor((this.expiration - new Date()) / 1000)
        if (remaining > 0) {
          const sec = remaining % 60
          const min = ((remaining - sec) / 60) % 60
          this.remaining = `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`
        } else {
          this.endTimer()
        }
      }
    },
    endTimer() {
      if (this.interval) clearInterval(this.interval)
      this.interval = null
      this.remaining = null
      this.expiration = null
    },
    close() {
      this.$emit('close')
    },
    handleOtpValue(value) {
      this.code = value
      this.authenticate()
      if (this.errorMessage) this.errorMessage = !this.errorMessage
    },
    trackLoginSuccess() {
      this.track(analyticsEvents.LOGIN_SUCCESS, {
        event_name: analyticsEvents.LOGIN_SUCCESS,
        event_category: 'signup_flow',
        event_action: analyticsEvents.LOGIN_SUCCESS,
        label: '/'
      })
    },
    genUserData({
      id,
      name,
      email,
      loginStatus,

    }){
      return {
        userId: id,
        name,
        email,
        loginStatus
      }
    },
    trackIdentify() {
      this.identify(this.genUserData({ email: this.email, loginStatus: true }))
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.loginModal {
  @include theme;

  display: flex;
  min-height: 34.375rem;

  .empty {
    display: none;
    background-color: red;
  }

  > .marketplaceBanner {
    display: none;
    background: linear-gradient(170.4deg, #0068ff -3.83%, #9e80ff 111.41%);
    border-radius: 0.5em 0 0 0.5em;
    width: 18.125rem;
    margin: -2em 2em -2em -2em;

    > svg {
      position: absolute;
      bottom: -0.01rem;
    }

    @media (min-width: 768px) {
      display: block;
    }
  }

  .custom,
  .whiteLabel {
    background-color: #eeeeee;
    margin: -2em 2em -2em -2em;
    width: 18.125rem;
    position: relative;
    color: white;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    > .powerBySeez,
    .whiteLabelLogo {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-55%);
      white-space: nowrap;
      display: flex;
      justify-content: center;
      gap: 1.25rem;
      padding-top: 1rem;

      > .headerLogo {
        > svg {
          width: 80px;
          transform: translate(-0.5em, 0.1em);

          .face,
          .eye,
          .letter,
          .mouth {
            fill: white;
          }

          .mouth {
            fill: #99c3ff;
          }

          .left.eye {
            transform-origin: 7% 30%;
          }

          .right.eye {
            transform-origin: 29% 30%;
          }

          .mouth {
            transform: translateY(1em);
          }
        }
      }
    }
  }

  > .login {
    position: relative;
    z-index: 2;
    @media (min-width: 768px) {
      padding: 2rem 3rem;
      width: 24rem;
    }

    > input {
      width: calc(100% - 1rem);
      background-color: #f6f6f6;
      border-radius: 24px;
      padding: 1.025rem 0;
      border: none;
      padding-inline-start: 1rem;
      font-size: 1rem;
      font-family: 'Biennale', sans-serif;

      @media (min-width: 768px) {
        font-size: 0.8rem;
      }
    }

    > button {
      width: 100%;
      background-color: var(--highlight);
      border-radius: 24px;
      padding: 0.875em 0;
      color: white;
      font-size: 1rem;
      border: none;
      margin-top: 1rem;
      cursor: pointer;
      font-family: 'Biennale', sans-serif;
    }

    > .socialWrapper {
      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 0.5rem;

        .social {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #e2e2e2;
          text-decoration: none;
          color: black;
          font-size: 0.8rem;
          font-weight: 700;
          border-radius: 24px;
          padding: 0.5rem 1rem;
          flex: 1 1 0%;
          background-color: white;
          cursor: pointer;
          font-family: 'Biennale', sans-serif;

          > svg {
            padding-inline-end: 0.4rem;
          }
        }
      }
    }

    > .checkboxWrapper {
      margin-top: 2rem;

      label {
        display: block;
        color: #757575;
        font-size: 0.8rem;
        margin: 0.5rem 0;

        input {
          margin-inline-end: 0.5rem;
          cursor: pointer;
        }

        input.selectionRequired {
          outline: 1px solid red;
          border: none;
        }

        span {
          > a {
            color: black;
            font-weight: 700;
          }
        }
      }
    }

    > .otp {
      margin: 2em 0;

      @media (min-width: 768px) {
        margin: 0 0;
      }

      > a {
        background-color: white;
        border: none;
        font-weight: 700;
        font-size: 0.8rem;
        cursor: pointer;
        > svg {
          padding-inline-end: 0.5rem;
        }
      }

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 31.25px;
        white-space: nowrap;
        margin-inline-start: 1rem;
        margin: 1rem 0 0.75rem 0rem;
        padding: 0;
        @media (max-width: 395px) {
          font-size: 1.778rem;
        }
      }

      p {
        font-weight: 400;
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
      }

      .inputOtpWrapper {
        margin: 2rem 0;
      }

      time {
        color: #1a2634;
        font-weight: bold;
        display: block;
        margin: 0rem 1.3rem;
        text-align: center;
      }

      .resend {
        margin: 1rem 0 2rem 0;
        text-align: center;

        > a {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    > .error {
      color: red;
      text-align: center;
      font-size: 12px;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
