<template>
  <div id="overview">
    <div class="container">
      <div class="thankYouTitle">
        <h1>{{ t['thankYouForSubmittingYourApplication'] }}</h1>
        <a @click="gotoSummary" data-test-id="overview-goto-summary">{{ t['orderSummary'] }}</a>
      </div>
      <OrderCard :order-id="order.id" :image-src="imageUrl" :full-name="fullName" :variant="order.listing?.variant" :year="order.listing?.year" :retail-price-string="retailPriceString" />
      <div class="stepsContainer">
        <h3>{{ t['statusScreenNextSteps'] }}</h3>
        <StepCard v-bind="tradeInStep" v-if="order.tradeInState && order.tradeInState.willTradeIn" title="tradeIn" :icon="tradeInArrows">
          <SeezButton
            v-if="tradeInStep.status !== 'complete' && tradeInStep.status !== 'rejected'"
            @click="setViewOffer"
            :disabled="tradeInStep.disabledAction"
            data-test-id="overview-tradein-action"
            >{{ t[tradeInStep.buttonText] }}</SeezButton
          >
          <div v-if="tradeInStep.status === 'complete'">
            <div class="row price" data-test-id="overview-tradein-price"><img :src="completeCheckIcon" />{{ retailPriceString }}</div>
            <div class="row fullName">{{ tradeInInfo }}</div>
          </div>
          <div v-if="tradeInStep.status === 'rejected'">
            <div class="row rejectedMsg"><img :src="rejectedIcon" data-test-id="overview-tradein-rejected" /> {{ t['rejected'] }}</div>
          </div>
        </StepCard>

        <StepCard v-if="financingStep" v-bind="financingStep" title="financing" :icon="financingIcon">
          <div class="financingAction">
            <SeezButton v-if="financingStep.status === 'action'" @click="gotoApplication" :disabled="financingStep.disabledAction" data-test-id="overview-financing-application">{{
              t[financingStep.buttonText]
            }}</SeezButton>
            <div class="returnToApplication">
              <img :src="arrowUp" /><a v-if="financingStep.status === 'complete'" @click="gotoApplication">{{ t[financingStep.buttonText] }}</a>
            </div>
            <span>{{ t['financingProvidedBy'] }} <img :src="santander" alt="santander" /></span>
          </div>
        </StepCard>

        <StepCard v-if="order.listing.licensePlate" v-bind="insuranceStep" :title="insuranceStep.title" :icon="trygIcon" :icon-bg="insuranceStep.iconBg">
          <SeezButton v-if="insuranceStep.status === 'action'" @click="gotoApplication" :disabled="insuranceStep.disabledAction" data-test-id="overview-insurance-application">{{
            t[insuranceStep.buttonText]
          }}</SeezButton>
        </StepCard>

        <hr />
        <h3>{{ t['statusScreenTakeCarHome'] }}</h3>
        <StepCard v-bind="spaStep" :title="spaStep.title" :icon="spaIcon" :icon-bg="spaStep.iconBg">
          <SeezButton @click="setSpaSign" :disabled="spaStep.disabledAction" data-test-id="overview-spa-sign-action">{{ t[spaStep.buttonText] }}</SeezButton>
        </StepCard>

        <div class="supportBox">
          <p>{{ t['financingYouCanReachOut'] }}</p>
          <div class="support">
            <img :src="supportSmallImage" alt="Seez support" />
            <a class="phone" :href="'tel:' + t['supportNumber']"><img :src="phoneIcon" />{{ t['supportNumber'] }}</a>
            <a class="email" :href="'mailto:' + t['supportEmail']"><img :src="emailIcon" />{{ t['supportEmail'] }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lang } from '../../lang'
import StepCard from './StepCard.ce.vue'
import { StepsStatus } from './Step.status'
import SeezButton from '../components/SeezButton.ce.vue'
import { tradeInArrows, financingIcon, completeCheckIcon, santander, arrowUp, trygIcon, spaIcon, supportSmallImage, phoneIcon, emailIcon, rejectedIcon } from '../../../assets/images'
import OrderCard from '../components/CheckoutOrderCard.ce.vue'

export default {
  name: 'OverviewPage',
  components: { StepCard, SeezButton, OrderCard },
  mixins: [lang],
  props: {
    order: { type: Object, required: true }
  },
  data() {
    return {
      imageError: false,
      carPictureIsLoaded: false,
      tradeInStep: null,
      financingStep: null,
      insuranceStep: null,
      spaStep: null,
      interval: null
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    imageUrl() {
      return this.order?.listing?.pictures[0]
    },
    tradeInInfo() {
      const make = this.order?.tradeInState?.make
      const model = this.order?.tradeInState?.model
      return [make, model].filter(n => n != null).join(' ')
    },
    fullName() {
      if (this.order?.name) return this.order.name

      const brand = this.order?.listing?.brand?.name
      const model = this.order?.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    retailPriceString() {
      if (this.order.listing.priceRetail == null) return null
      return this.order.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      // TODO: get currency
      return 'kr.'
    },
    completeCheckIcon() {
      return completeCheckIcon
    },
    tradeInArrows() {
      return tradeInArrows
    },
    financingIcon() {
      return financingIcon
    },
    santander() {
      return santander
    },
    arrowUp() {
      return arrowUp
    },
    trygIcon() {
      return trygIcon
    },
    spaIcon() {
      return spaIcon
    },
    supportSmallImage() {
      return supportSmallImage
    },
    phoneIcon() {
      return phoneIcon
    },
    emailIcon() {
      return emailIcon
    },
    rejectedIcon() {
      return rejectedIcon
    }
  },
  beforeMount() {
    this.tradeInStep = this.buildTradeInStep()
    this.financingStep = this.buildFinancingStep()
    this.insuranceStep = this.buildInsuranceStep()
    this.spaStep = this.buildSpaStep()
  },
  mounted() {
    this.interval = setInterval(() => {
      this.$emit('getOrder', true)
    }, 3000)
  },
  beforeUnMount() {
    clearInterval(this.interval)
  },
  methods: {
    gotoSummary() {
      this.$emit('setSummary', true)
    },
    action(step) {
      this[step]?.action()
    },
    setViewOffer() {
      this.$emit('setViewOffer', true)
    },
    setSpaSign() {
      this.$emit('setSpaSign', true)
    },
    gotoApplication() {
      window.open(this.order.paymentState?.financing?.redirectUrl)
    },
    buildTradeInStep() {
      if (this.order.tradeInState?.state === 'pending') return StepsStatus.tradeIn.pending
      if (this.order.tradeInState?.state === 'offerSent') return StepsStatus.tradeIn.action
      if (this.order.tradeInState?.state === 'accepted') return StepsStatus.tradeIn.complete
      if (this.order.tradeInState?.state?.toLowerCase()?.includes('rejected')) return StepsStatus.tradeIn.rejected
      //TODO: state approved is missing in flutter app
    },
    buildFinancingStep() {
      if (this.order.paymentState.type === 'financing' && this.order.paymentState?.financing?.state === 'submitted') return StepsStatus.financing.complete
      if (this.order.paymentState.type === 'financing' && this.order.paymentState?.state === 'draft') return StepsStatus.financing.pending
      if (this.order.paymentState.type === 'financing' && this.order.paymentState?.state === 'pending') return StepsStatus.financing.pending
    },
    buildInsuranceStep() {
      if (this.order.personalInfoState.insurance === 'Tryg') {
        StepsStatus.insurance.action.statusText = 'insuranceTrygIfYouWish'
        StepsStatus.insurance.action.status = 'complete'
      } else StepsStatus.insurance.action.statusText = 'insuranceTrygYouCanStill'
      return StepsStatus.insurance.action
    },
    buildSpaStep() {
      if (this.order.spaResponse) return StepsStatus.spa.action
      return StepsStatus.spa.pending
    }
  }
}
</script>

<style lang="scss">
#overview {
  height: 100%;
  overflow: auto;
  .container {
    max-width: 50rem;
    margin: auto;
    height: 90vh;
    .stepsContainer {
      margin: 2.875rem auto 1.375rem auto;
      button {
        width: 100%;
      }
      .row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.6875rem;
        margin-bottom: 0.25rem;
      }
      .price {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 28px;
      }
      .rejectedMsg {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 28px;
        > img {
          width: 1.5em;
        }
      }
      .fullName {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .financingAction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
        > span {
          font-weight: 700;
          font-size: 10.24px;
          line-height: 14px;
          text-align: center;
          > img {
            width: 6.5625rem;
          }
        }
        a {
          font-weight: 700;
          font-size: 12px;
          line-height: 24px;
          color: #0068ff;
          text-decoration: underline;
          margin-left: 8px;
        }
        .returnToApplication {
          cursor: pointer;
        }
      }
      > h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        margin: 0.25rem 0;
      }
      > hr {
        border-top: 1px solid #eeeeee;
        margin: 2.125rem 0;
      }
      > .supportBox {
        > p {
          text-align: center;
        }
        > .support {
          position: relative;
          > a {
            text-decoration: none;
            color: black;
            position: absolute;
            left: 0;
            background: white;
            padding: 1.25rem;
            width: 10rem;
          }
          display: flex;
          justify-content: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          > .phone {
            bottom: 2.1875rem;
            left: 2.1875rem;
            box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
            border-radius: 8px;
            padding: 0.5625rem 0.75rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;
          }
          > .email {
            bottom: -1rem;
            left: 65px;
            box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
            border-radius: 8px;
            padding: 0.5625rem 0.75rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;
          }
        }
      }
    }
  }

  .thankYouTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 27px 0;
    > h1 {
      font-size: 20px;
      line-height: 28px;
      margin: 0;
    }
    > a {
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #000000;
      cursor: pointer;
    }
  }
  .orderCard {
    cursor: pointer;
    text-decoration: none;
    color: initial;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 0 10px hsla(0, 0%, 70%, 0.6);
    }

    .carInformation {
      display: flex;
      background: #f6f6f6;
      border-radius: 1rem;
      max-height: 11.25rem;
      gap: 1.1875rem;
      > div {
        flex: 1;
      }

      .details {
        margin: 1.125rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.5625rem;
        > .price {
          flex: 1;
          display: flex;
          align-items: center;
        }
        > .name {
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
        }
        .price {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
      }
      > .carImage {
        position: relative;
        border-radius: 0.75rem 0 0 0.75rem;
        > .loader {
          position: absolute;
        }
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem 0rem 0rem 1rem;
        }
      }
    }
  }
}
</style>
