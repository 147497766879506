<template>
  <div class="stepsOverviewWrapper" :class="showStepsOverviewModal ? 'open' : ''">
    <div class="closeIcon" @click="setStepsOverviewModal(false)"><img :src="closeIcon" /></div>
    <div class="stepsOverview" @click.stop>
      <ImageSlider :images="order.listing?.pictures" />
      <div class="details">
        <span class="name" data-test-id="card-name">{{ fullName }}</span>
        <div class="row">
          <span class="variant" data-test-id="card-variant-year">{{ order.listing.variant }} {{ order.listing.year }}</span>
          <span class="price" data-test-id="car-price">{{ retailPriceString }}</span>
        </div>
      </div>
      <div class="journeySteps">
        <div class="journeyStatus">
          <div>{{ t['purchaseJourney'] }}</div>
          <div :class="'status ' + journeyStatus.class">{{ journeyStatus.text }}</div>
        </div>
        <div class="steps">
          <FormTab
            :label="t['tradeIn']"
            name="tradeIn"
            :status="order.appState.tradeIn.state"
            :current-stage="currentStage"
            @gotoComponent="gotoComponent"
            :loading="order.appState.tradeIn.loading"
            @setStepsOverviewModal="setStepsOverviewModal"
          />
          <FormTab
            :label="t['personalInformation']"
            name="personalInformation"
            :status="order.appState.customerInfo.state"
            :current-stage="currentStage"
            @gotoComponent="gotoComponent"
            :loading="order.appState.customerInfo.loading"
            @setStepsOverviewModal="setStepsOverviewModal"
          />
          <FormTab
            :label="t['carDelivery']"
            name="carDelivery"
            :status="order.appState.delivery.state"
            :current-stage="currentStage"
            @gotoComponent="gotoComponent"
            :loading="order.appState.delivery.loading"
            @setStepsOverviewModal="setStepsOverviewModal"
          />
          <FormTab
            :label="t['payment']"
            name="payment"
            :status="order.appState.payment.state"
            :current-stage="currentStage"
            @gotoComponent="gotoComponent"
            :loading="order.appState.payment.loading"
            @setStepsOverviewModal="setStepsOverviewModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageSlider from '../form/Image.Slider.ce.vue'
import FormTab from './Form.Tab.ce.vue'
import { lang } from '../../lang'
import SeezSdk from '../../../sdk.js'
import { closeIcon } from '../../../assets/images'

export default {
  name: 'StepsOverviewModal',
  components: { ImageSlider, FormTab },
  mixins: [lang, SeezSdk.vueQueryMixin],
  props: {
    showStepsOverviewModal: { type: Boolean, default: false },
    order: { type: Object, required: true },
    currentStage: { type: String, required: true }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    fullName() {
      if (this.order.name) return this.order.name
      const brand = this.order.listing?.brand?.name
      const model = this.order.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    retailPriceString() {
      if (this.order.listing.priceRetail == null) return null
      return this.order.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    journeyStatus() {
      if (this.order.allStepsComplete(this.order.currentStage)) return { text: this.t.completed, class: 'completed' }
      return { text: this.t.pending, class: 'pending' }
    },
    closeIcon() {
      return closeIcon
    }
  },
  watch: {
    showStepsOverviewModal(val) {
      console.log(val)
    }
  },
  methods: {
    gotoComponent(name) {
      this.$emit('gotoComponent', name)
    },
    setStepsOverviewModal(status) {
      this.$emit('setStepsOverviewModal', status)
    }
  }
}
</script>
<style lang="scss">
.stepsOverviewWrapper {
  display: none;
  @media screen and (max-width: 56rem) {
    height: 100vh;
    top: -200vh;
    left: 0;
    right: 0;
    background: #ffffff;
    min-height: 100vh;
    position: fixed;
    z-index: 100;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    > .closeIcon {
      display: none;
    }
    .stepsOverview-leave-active {
      transition: all 0.3s;
    }

    .stepsOverview-enter-from,
    .stepsOverview-leave-to {
      transform: all 0.3s ease-out;
      opacity: 0;
    }
    .drawer-leave-active,
    .drawer-enter-active {
      transition: all 0.8s ease;
    }
    .drawer-enter,
    .drawer-leave-to {
      transform: rotate(180deg);
      opacity: 0;
    }
    .stepsOverview {
      padding: 2em 3em;
      background: white;
      overflow: auto;
      height: 100%;
      > .details {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        > .name {
          font-weight: 700;
          font-size: 25px;
          line-height: 35px;
        }
        > .row {
          display: flex;
          justify-content: space-between;
          .price {
            font-weight: 700;
            font-size: 1rem;
            line-height: 22px;
            margin-top: auto;
            margin-left: auto;
          }
          .variant {
            width: 13rem;
            font-weight: 400;
            font-size: 12.8px;
            line-height: 18px;
          }
        }
      }
    }
    .journeySteps {
      display: block;
      > .journeyStatus {
        display: flex;
        justify-content: space-between;
        > div {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.1875rem;
        }
        > div.status {
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.1875rem;
          color: #ffa200;
        }
        > div.status.completed {
          color: #009a33;
        }
      }
      > .steps {
        margin-top: 2rem;
      }
    }
    .open {
      top: 0;
      .stepsOverview {
        padding: 1rem;
      }
      > .closeIcon {
        display: block;
        position: absolute;
        top: 6.4px;
        left: 0.3125rem;
        z-index: 100;
      }
      left: 0;
      .overviewButton > img {
        rotate: 180deg;
        transition: rotate 0.5s;
      }
    }
    .stepsOverviewWrapper {
      .overviewButton {
        display: none;
      }
    }
  }
}
</style>
