/* eslint-disable quote-props */
export const CALCULATOR_COMPONENT_TRANSLATIONS = {
  loan_estimator: 'Låneberegner',
  get_a_clearer_image: 'Få et mere klart billede af, hvad du har råd til. Indtast din udbetaling og vælg løbetid for at få et overslag på din månedlige ydelse.',
  car_estimated_price: 'Bilens forventede pris',
  preferred_down_payment: 'Udbetaling',
  kr: 'Kr.',
  downpayment_is_required: '* Skal være minimum 20% af bilens pris',
  loan_is_below_santander: '* Mindstebeløb for lån hos Santander er 50.000 kr.',
  preferred_loan_duration_months: 'Ønsket løbetid for lånet',
  months: 'Måneder',
  loan_amount: 'Lånebeløb',
  monthly_payment: 'Månedlig ydelse',
  kr_month: 'kr. / md.',
  hide_loan: 'Gem låne detaljer',
  show_loan: 'Vis låne detaljer',
  bank_loan: 'Blankolån',
  financed_by: 'Finansiering ydet af',
  aop_before_tax: 'AOP før skat',
  down_payment_pct: 'Udbetaling',
  financed_amount: 'Finansieret beløb',
  nominal_interest: 'Nominel rente',
  total_repay: 'Samlet tilbagebetaling',
  base_amount: 'Lånebeløb',
  total_loan_costs: 'Samlede låneomkostninger',
  interest_type: 'Rente type',
  interest_rate: 'Rente',
  variable: 'Variabel',
  financing_details: 'Finansierings detaljer',
  purchase_contract: 'Købekontrakt med ejendomsforbehold',
  fixed: 'Fast'
}

export const DRIVE_TYPE_FILTER_TRANSLATIONS = {
  drive_type_F: 'Forhjulstræk',
  drive_type_B: 'Baghjulstræk',
  drive_type_4: '4-hjulstræk'
}

export const FILTER_PANEL_COMPONENT_TRANSLATIONS = {
  advanced_filters: 'Filtre',
  clear_all: 'Fjern filtre',
  brand: 'Mærke',
  model: 'Model',
  select_make: 'Vælg Mærke',
  select_model: 'Vælg Model',
  year: 'Årgang',
  from: 'Fra',
  Monthly: 'Pr. måned',
  Full: 'Kontant',
  to: 'Til',
  max_kilometrage: 'Maks km',
  max_budget: 'Maks Budget',
  kilometrage: 'Kilometertal',
  price: 'Pris',
  body_type: 'Karosseritype',
  transmission: 'Gear',
  any: 'Alle',
  fuel_type: 'Brændstof',
  color: 'Farve',
  all_makes: 'Alle mærker',
  save_search: 'Gem søgning',
  save_filter_find_later: 'Gem dine søgefiltre og find dem nemt på din profil senere',
  save_this_search: 'Gem denne søgning',
  my_saved_searches: 'Mine gemte søgninger',
  grey: 'Grå',
  silver: 'Sølv',
  white: 'Hvid',
  black: 'Sort',
  blue: 'Blå',
  red: 'Rød',
  purple: 'Lilla',
  orange: 'Orange',
  brown: 'Brun',
  green: 'Grøn',
  yellow: 'Gul',
  Convertible: 'Cabriolet',
  Coupe: 'Coupé',
  Hatchback: 'Hatchback',
  MPV: 'MPV',
  Sedan: 'Sedan',
  SUV: 'SUV',
  Truck: 'Lastbil',
  Van: 'Varebil',
  Wagon: 'Stationcar',
  Cabriolet: 'Cabriolet',
  Stationcar: 'Stationcar',
  number_of_doors: 'Antal døre',
  drive_type: 'Drivlinje',
  engine_size: 'Motor',
  search_vehicles: 'Søg køretøjer',
  ...DRIVE_TYPE_FILTER_TRANSLATIONS,
}

export const EMPTY_SEARCH_COMPONENT_TRANSLATIONS = {
  nothing_to_see: 'Her er ikke noget!',
  you_have_not_saved_search: 'Du har ikke gemt nogen søgninger endnu',
  you_have_not_favorites: 'Du har ikke angivet nogen fortegnelse som favorit endnu',
  search_now: 'Søg nu'
}

export const LISTING_CARD_COMPONENT_TRANSLATIONS = {
  reserved: 'Reserveret',
  month: 'måned',
  sold: 'Solgt'
}

export const LOGIN_COMPONENT_TRANSLATIONS = {
  welcome: 'Velkommen!',
  please_login: 'Upload din e-mail for at gemme dine favorit søgninger og for at få adgang til dine konto detaljer',
  email_address: 'Email adresse',
  please_check_email: 'Tjek din e-mail',
  we_sent_to: 'Vi har sendt en kode til dig ',
  did_not_get_code: 'Ikke modtaget en kode? ',
  click_to_resend: 'Klik og send igen',
  sign_in_using: 'Underskriv ved hjælp af',
  google: 'Google',
  facebook: 'Facebook',
  i_agree: 'Jeg er enig med Seez’s',
  terms_conditions: 'Vilkår',
  privacy: 'privatlivspolitik',
  please_verify_code: 'Indtast koden, der er sendt til din email',
  resend_otp: 'Send koden',
  get_otp: 'Få tilsendt adgangskode',
  confirm: 'Bekræft OTP',
  go_back: 'Tilbage',
  marketing_terms: 'Jeg acceptere at modtage information, omkring marketing, her under information om præsentationer og produkter til e-mail, telefon og sms'
}

export const LISTING_DETAILS_COMPONENT_TRANSLATIONS = {
  get_valuation: 'Få din indbyttevurdering',
  download_brochure: 'Download Brochure',
  brochure: 'Brochure',
  save: 'Spare',
  share: 'Dele',
  vat_include: 'Varebil inkl moms',
  vat_plus: 'Varebil plus moms',
  van: 'Varebil',
  cash: 'Kontant',
  financing_from: 'Finansering fra',
  financed_by: 'Finansiering ydet af',
  month: 'Måned',
  monthly_payment_info:
    'Månedlig ydelse 2.721 kr. i 84 mdr. Variabel lånerente 1,26%. Årlige omk. 4,56%. Samlede kreditomkostninger: 32.565 kr. Udbetaling: 48.980 kr. Samlet beløb der skal betales: 228.485 kr.',
  get_started: 'Kom igang',
  login_to_buy: 'Log ind for at købe',
  resume_checkout: 'Genoptag køb',
  car_reserved: 'Bil reserveret',
  reserved: 'Reserveret',
  sold: 'Solgt',
  coming_soon: 'Kommer snart',
  color: 'Farve',
  kilometrage: 'Kilometertal',
  horsepower: 'HK',
  km: 'Kilometertal',
  transmission: 'Gearkasse',
  fuel: 'Brændstof',
  registration: 'Registrering',
  car_financing: 'Bil finansiering',
  finance_your_car: 'Total online-finansiering',
  quick_specs: 'Specifikationer',
  completely_online: 'af din bil',
  we_made_get_loan_easy_msg: ' Vi har gjort det så nemt og effektivt som muligt at få et billån. Med Seez kan du nu ansøge online og få den mest favorable rente inden for 24 timer.',
  enter_your_down_payment: 'Indtast din udbetaling og lånets løbetid for at få et estimat af din månedlige ydelse',
  seez_benefits_you_get: 'Seez fordele du opnår med denne bil',
  quality_assurance: 'Kvalitetssikring',
  we_believe_value_almost_new: 'Vi tror på værdien i "næsten ny"',
  fourteen_days_return: '14 dages fuld returret',
  we_believe_full_transparency: 'Vi tror på fuld gennemsigtighed',
  one_year_warranty: '1 års garanti',
  we_believe_full_peace_mind: 'Vi tror på fuldstændig "ro i sindet"',
  free_delivery: 'Fri levering',
  we_believe_full_convenience: 'Vi tror på total bekvemmelighed',
  general_information: 'Generel information',
  year: 'Årgang',
  range: 'Range',
  fuel_consumption: 'Km/l',
  l_km: 'L/100 km',
  km_l: 'km/L',
  green_tax: 'Grøn ejerafgift',
  fuel_type: 'Brændstof',
  reference_number: 'Referencenummer',
  technical_specs: 'Tekniske specifikationer',
  hk: 'HK',
  acceleration: 'Acceleration (0-100 km/t)',
  interior_material: 'Interior material',
  top_speed: 'Tophastighed',
  airbags: 'Airbags',
  number_of_gears: 'Antal gear',
  abs: 'ABS',
  available: 'Available',
  undisclosed: 'Undisclosed',
  cylinders: 'Antal cylindre',
  dimension_capacity: 'Dimensioner/kapacitet',
  weight: 'Totalvægt',
  max_attachment: 'Max anhænger',
  load_capacity: 'Læssekapacitet',
  number_of_doors: 'Antal døre',
  comfort_technology: 'Komfort og teknologi',
  design: 'Design',
  safety_environment: 'Sikkerhed og miljø',
  equipment_details: 'Udstyrsliste',
  you_might_like: 'Du vil måske være interesseret i',
  equipment: 'Udstyr',
  price: 'Pris',
  description: 'Beskrivelse',
  read_more: 'Læs Mere',
  read_less: 'Skjul',
  free_tradein_notes: 'Ting at notere',
  free_tradein_explanation:
    '<p>Få en hurtig vurdering af din gamle bil så du kan få en ide om hvor meget den bidrager til din nye bil.</p><p>Byttebilsværdien bliver givet af vurderingseksperter fra vores betroede forhandlere.</p><p>Vi giver en bindende byttepris på din byttebil, når vi har alle nødvendige informationer på den.</p>',
  Mercedes: {
    id: 23,
    name: 'Mercedes-Benz',
    main: `Hos Seez ved vi, at Mercedes er indbegrebet af luksus, stilrent design og køreegenskaber af øverste skuffe. Mercedes fåes både som MPV, stationcar, SUV, coupé, hatchback, sedan og cabriolet, og vi forhandler de mest populære modeller, som blandt andet trækker A-klassen, C-klassen og CLK-klassen. Alle modeller, som også findes i en AMG-udgave, hvilken tilbyder en endnu højere ydeevne. 
Mercedes er et bilmærke, som arbejder under et motto om at ”The Best or Nothing”, og det er i høj grad noget, de lever op til. Med en god brugt Mercedes er du med andre ord garanteret en bil i et stilrent design, der oser af karakter og samtidig har en høj ydeevne, som sikrer dig en god køreoplevelse – hver gang.`
  },
  Ford: {
    id: 17,
    name: 'Ford',
    main: `Vi har et bredt udvalg af nyere brugte Ford biler til salg, hvilket betyder, at du har mulighed for at finde netop den næsten nye bil, du er på udkig efter. 
Ford er et bilmærke med en lang historie og dermed også en masse erfaring, når det kommer til at producere biler. Det betyder, at Ford har et stærkt og attraktivt udvalg af biler, som blandt andet rummer Ford Ka, Fiesta, Focus, Kuga samt C-Max og S-Max modellerne. Det har gjort, at Ford er at finde på listen over de mest populære bilmærker i Danmark og er stærkt repræsenteret i alle kategorier – lige fra den mindre bybil til den store SUV.`
  },
  Renault: {
    id: 13,
    name: 'Renault',
    main: `Renault er et fransk bilmærke, som blev etableret i 1898 af Louis Renault, og som efter en stor succes i motorsport slog sig op som storleverandør af især personbiler. 
Renault er fransk ingeniørkunst, når det er allerbedst. I dag står bilmærket bag en lang række livsstilsbiler og varevogne, som har oplevet stor efterspørgsel fra både ind- og udland. Hos Seez forstår vi denne efterspørgsel og har derfor samlet et bredt udvalg af brugte Renault biler af øverste skuffe, som er nøje udvalgt af vores medarbejdere. Det betyder, at du har mulighed for at finde netop den bil, du er på udkig efter.`
  },
  Dacia: {
    id: 30,
    name: 'Dacia',
    main: `Dacia forhandler kvalitetsbiler, som er fyldt med innovative teknologier og praktiske løsninger – alt sammen til skarpe priser. Deres sortiment strækker sig fra den kompakte MPV til den smarte familiebil, som alle er bygget efter et koncept om bundsolid offroad kapacitet. Det betyder dog ikke, at man behøver at gå på kompromis med designet. Dacia er nemlig fyldt med smukke linjer og luksus, som gør køreoplevelsen til en leg. 
Dacia biler er kendt for at være rummelige, så du altid har plads til oppakningen. Eksempelvis får du op til 475 liters bagagerum i den ikoniske Dacia Duster, hvilket betyder, at du er sikret en bil, som dækker de fleste af dagligdagens udfordringer.`
  },
  we_make_your_trade_in_easy: 'Vi gør det let at bytte din gamle bil',
  fill_out_your_details_and_quick: 'Udfyld oplysningerne og vi giver dig en hurtig vurdering.',
  start_valuation: 'Få en pris på din bil',
  learn_more_about_trade_in: 'Lær om byttebil',
  trade_in_your_car: 'Byt din bil nemt med Seez',
  to_get_your_trade_In_price: 'For at få din byttepris, lad os starte din bils nummerplade.',
  car_details_trade_in: 'Bil detaljer',
  fill_in_your_cars_information: 'Udfyld din bils oplysninger så nøjagtigt som muligt.',
  make: 'Mærke',
  model: 'Model',
  variant: 'Variant',
  modelYear: 'Årgang',
  registrationDate: 'Registreringsdato',
  registrationType: 'Registreringstypen',
  more_details: 'Flere detaljer',
  has_your_car_been_in: 'Har din bil været involveret i en ulykke?',
  no: 'Nej',
  yes: 'Ja',
  does_your_car_have_outstanding_financing: 'Er der restgæld i din bil?',
  what_your_car_current_condition: 'Hvad er din bils nuværende stand?',
  poor: 'Dårlig',
  good: 'God',
  average: 'Gennemsnitlig',
  great: 'Næsten perfekt',
  carConditionPoorDef:
    'Dårlig betyder at bilens tilstand er rigtig sløj. Der er væsentlig beskadigelse af både in- og eksteriør. Den trænger til en gennemgribende istandsættelse - måske bør den skrottes.',
  carConditionAverageDef:
    'Gennemsnitlig betyder, at bilens bedste år ligger langt bag den. Den kan køre, men eksteriøret er grimt og slidt og interiøret gammelt og sjusket. Mekanisk er der måske også et par problemer!',
  carConditionGoodDef: 'God betyder, at bilen kører ganske udmærket, men den er ikke helt på toppen længere. Eksteriøret har måske små ridser samt stenslag og interiøret har nogen slitage.',
  carConditionGreatDef:
    'Næsten perfekt betyder, at bilens stand er over gennemsnittet. Eksteriøret er perfekt eller har få mindre ridser og interiøret er rent, uden pletter og uden lugt. Mekanisk kører den uden problemer af nogen art.',
  i_accept_privacy_policy: 'Jeg accepterer Seez Persondata- og cookie-politik',
  get_my_trade_in_value: 'send forespørgsel',
  how_many_keys: 'Hvor mange nøgler har du?',
  number_of_keys: 'Antallet af nøgler',
  what_is_your_email_address: 'Hvad er din e-mail-adresse?',
  we_ll_send_an_email_you: 'Vi sender en e-mail med dit tilbud til dig',
  please_enter_email: 'Indtast venligst din e-mailadresse',
  please_enter_outstanding_amount: 'indtast venligst restgæld',
  thanks_you_for_submitting: 'Tak, fordi du har indsendt din indbytning',
  we_ll_contact_you_via_via_email: 'Vi kontakter dig via e-mail, når vi har et tilbud på din bil',
  done: 'Afslut',
  condition: 'Bilens stand',
  regTypeVanWithoutVat: 'Varebil på gule plader uden moms',
  regTypeVanWithVat: 'Varebil på gule plader med moms',
  regTypePersonal: 'Personbil',
  regTypeLeasing: 'Leasing',
  please_enter_phone: 'Telefonnummer',
  please_enter_your_name: 'Fulde navn',
  vehicle_images: 'Billeder af bilen',
  upload: 'Upload',
  front_back_interior: ' front, bagside, interiør',
  and: ', og ',
  damage: 'Skade ',
  pictures_of_your_car: 'billeder af din bil, så vi kan give dig det bedst mulige tilbud.',
  drag_your_file: 'Træk dine byttebils billeder for at uploade dem eller ',
  browse_file: 'Gennemse filer',
  you_should_upload_images: 'Du bedes uploade din bils billeder'
}

export const RESULT_PANEL_COMPONENT_TRANSLATIONS = {
  filter: 'Filtre',
  sort_by: 'Sorter efter',
  most_attractive: 'Mest Attraktive',
  recently_added: 'Senest tilføjede',
  price_ascending: 'Pris: Fra lavest til højest',
  price_descending: 'Pris: Fra højest til lavest',
  kilometrage_ascending: 'Antal km: Fra lavest til højest',
  kilometrage_descending: 'Antal km: Fra højest til lavest',
  age_descending: 'Alder: Nyeste til ældste',
  age_ascending: 'Alder: Ældste til nyeste',
  newest: 'Newest',
  lowest_kilometrage: 'Lowest Kilometrage',
  lowest_price: 'Lowest Price',
  there_are_no_vehicles_msg: 'There are no vehicles matching your criteria',
  all_cars: 'Alle biler',
  search_results: 'Søgeresultater',
  no_results_found: 'Beklager! Ingen resultater fundet',
  please_try_different_search: 'Prøv at ændre din søgning',
  get_inspired: 'Bliv inspireret',
  error_listing: 'Der opstod en fejl under søgningen efter fortegnelser',
  budget: 'Økonomisk',
  family_life: 'Familievenlig',
  outdoor_adventure: 'Friluftsoplevelser',
  single: 'Luksusliv',
  environmentally_cars: 'Miljøvenlig',
  economical_cars: 'Økonomibiler',
  family_suv: 'Sikker for familien SUVs',
  ...DRIVE_TYPE_FILTER_TRANSLATIONS
}

export const MODAL_ACTIVE_ORDER_COMPONENT = {
  active_order_change: 'Ændringer til aktiv ordre',
  you_have_active_order: 'Du har allerede en aktiv ordre i gang',
  are_you_sure_cancel: 'Er du sikker på, at du vil annullere din nuværende ordre, og starte på en ny?',
  are_you_sure_cancel_active_order: 'Er du sikker på, at du vil annullere din nuværende ordre, og starte på en ny?',
  price: 'Pris',
  cancel_start_new_order: 'Annuller og start ny ordre',
  resume_order: 'Genoptag ordre'
}

export const UNAVAILABLE_LISTING_MODAL = {
  car_is_not_available: 'Denne bil er ikke længere tilgængelig',
  we_got_similar_cars: 'Vi har lignende biler, du kan vælge imellem.',
  back_home: 'Gå til forsiden',
  view_similar: 'Se lignende biler',
  we_will_notify_you: 'Vi giver dig besked, når vi har en ny tilsvarende bil, eller hvis denne bil blive tilgængelig igen.',
  browse_all: 'Browse all',
  search_all: 'Søg alle',
}

export const SEO_DATA = {
  default: { title: 'Brugt {brand}' },
  bmw: {
    title: 'Brugt BMW',
    description:
      'Hos Seez finder du et udvalg af næsten nye BMW biler. BMW er for mange bilelskere den ultimative kombination af kvalitet, luksus, sportslige køreegenskaber og design. Når du køber en brugt BMW hos Seez, er du garanteret førsteklasses kvalitet. Alle vores biler befinder sig i kategorien ’næsten nye’, og er certificerede og gennemtestede.'
  },
  dacia: {
    title: 'Brugt Dacia',
    description:
      'Dacia forhandler kvalitetsbiler, som er fyldt med innovative teknologier og praktiske løsninger – alt sammen til skarpe priser. Deres sortiment strækker sig fra den kompakte MPV til den smarte familiebil, som alle er bygget efter et koncept om bundsolid offroad kapacitet. Det betyder dog ikke, at man behøver at gå på kompromis med designet. Dacia er nemlig fyldt med smukke linjer og luksus, som gør køreoplevelsen til en leg. Dacia biler er kendt for at være rummelige, så du altid har plads til oppakningen. Eksempelvis får du op til 475 liters bagagerum i den ikoniske Dacia Duster, hvilket betyder, at du er sikret en bil, som dækker de fleste af dagligdagens udfordringer.'
  },
  ford: {
    title: 'Brugt Ford',
    description:
      'Hos Seez forhandler vi brugte Ford biler af øverste skuffe, og vi går aldrig på kompromis med hverken kvalitet eller service. Vi hjælper gerne med finansiering samt levering af din nye bil, og vi er altid tilgængelige, hvis du har spørgsmål.'
  },
  mercedes: {
    title: 'Brugt Mercedes-Benz',
    description:
      'Seez forhandler brugte Mercedes af højeste kvalitet, og vi hjælper gerne med finansiering samt levering af din nye bil. Vi har et bredt udvalg af nyere brugte Mercedes Benz biler til salg, hvilket betyder, at du har mulighed for at finde netop den bil, du er på udkig efter.'
  },
  peugeot: {
    title: 'Brugt Peugeot',
    description:
      'Peugeot er et af de mest populære bilmærker i Danmark og med god grund. Den franske bilproducent er kendt for at forene høj kvalitet og et moderne design med overkommelige priser. Hos Seez har vi samlet et udvalg af de bedste brugte Peugeot-modeller på markedet. Hos Seez finder du udelukkende næsten nye biler, så du er sikret en bil i topklasse.'
  },
  renault: {
    title: 'Brugt Renault',
    description: 'Seez forhandler brugte Renault af højeste kvalitet, og vi står gerne for finansiering samt levering af din nye, brugte bil. Med en brugt Renault får du en bil i særklasse.'
  },
  skoda: {
    title: 'Brugt Skoda',
    description:
      'Hos Seez forhandler vi næsten nye Skoda biler fra certificerede forhandlere i hele landet. Vores bilunivers består af flere velkendte modeller, så som minibilen Skoda Citigo, de større Fabia og Octavia eller den velkendte Superb, som for mange rimer på rummelighed og komfort. Det betyder, at du har mulighed for at finde den perfekte Skoda til dine behov.'
  },
  toyota: {
    title: 'Brugt Toyota',
    description:
      'Er du på jagt efter den næste bil, som kan tage dig ud på nye eventyr? Seez forhandler næsten nye Toyota biler fra certificerede forhandlere i hele landet. Vi har et bredt udvalg af populære Toyota-modeller til salg, hvilket betyder, at du har mulighed for at finde netop den bil, som passer til dig og dine behov.'
  },
  vw: {
    title: 'Brugt VW',
    description:
      'Hos Seez forhandler vi udelukkende næsten nye Volkswagen biler. Vi udvælger alle brugte VW med største omhu og stiller høje krav til deres stand. Det betyder, at du har mulighed for at finde en brugt bil, der næsten kan betragtes som en ny. Med en brugt Volkswagen er du sikret en kvalitetsbil, der forener effektive hestekræfter med innovativ teknologi og et moderne design fra bilens indre til ydre.'
  }
}

export const CHECKOUT_COMPONENT_TRANSLATIONS = {
  orderId: 'Ordre ID',
  reservationEnded: 'Reservation udløbet',
  reservationYouMayInitiate: 'Du kan starte dit køb igen, hvis bilen stadig er til salg',
  reservationOkay: 'Okay',
  reservation45Minutes: 'Din bil er reserveret i 45 minutter, mens du gennemfører købsprocessen.',
  reservation24Hours: 'Din bil er reserveret i 24 timer, mens du gennemfører købsprocessen.',
  carReservedForYou: 'Bilen er nu reserveret til dig.',
  cancelOrder: 'Annullér forespørgsel',
  personalInformation: 'Personlig information',
  about3Minutes: 'Ca. 3 minutter',
  tradeIn: 'Byttebil',
  about5Minutes: 'Ca. 5 minutter',
  financing: 'Finansiering',
  welcomeBack: 'Velkommen tilbage',
  about1Minute: 'Ca. 1 minut',
  addOns: 'Tilkøb',
  delivery: 'Levering',
  about2Minutes: 'Ca. 2 minutter',
  viewOrderSummary: 'Se samlet forespørgsel',
  confirmOrderDetails: 'Gennemgå forespørgsel',
  sendOrderDetails: 'Send forespørgsel',
  notice:
    'Bemærk: Det vil tage ca. 10-15 minutter at udfylde ordre-formularen. Du kan scrolle op og ned og udfylde punkterne i den rækkefølge, du ønsker. Når du er færdig og klar til at fortsætte, lyser knappen "Bekræft". Når du klikker på den, har du afsluttet din ordre.',
  backToIntro: 'Tilbage til start',
  personalInfoIntro: 'For at kunne betjene dig bedst muligt, har vi brug for nogle personlige detaljer.',
  personalInfoNameHint: 'Vi kan kun levere bilen til dig, hvis navnet på din ordre er det samme navn som på dit kørekort.',
  firstName: 'Fornavn',
  middleName: 'Mellemnavn',
  lastName: 'Efternavn',
  asPerDriverLicense: 'Kørekort nr.',
  emailAddress: 'Email',
  phoneNumber: 'Telefon nr.',
  emailAddressHint: 'Til at sende en ordrebekræftelse',
  cprNumber: 'CPR-nummer',
  cprNumberHint: 'Til færdiggørelse af din registrering',
  cprNumberInvalid: 'Ugyldig CPR nummer',
  insuranceCompany: 'Forsikringsselskab',
  insuranceCompanyOther: 'Forsikringsselskab (andet)',
  insuranceCompanyHint: 'Kan du ikke finde det, eller har du ikke et endnu, \n\nså vælg "andet"',
  address: 'Adresse',
  country: 'Land',
  denmark: 'Danmark',
  city: 'By',
  state: '!!!"States" does not exist in DK',
  postalCode: 'Postnummer',
  addressStreet: 'Gade/vej',
  addressNumberFloor: 'Nummer/etage',
  addAddressLine: 'Tilføje linje for adresse',
  scrollToContinue: 'rul ned for at fortsætte',
  required: 'Påkrævet',
  emailInvalid: 'Ugyldig email-adresse',
  error: 'Fejl',
  tradeInSubtitle: 'Ud med det gamle, ind med det nye.\nDrop turen til forhandleren og byt din bil 100% online.',
  tradeInWouldYouLikeToAdd: 'Ønsker du at tilføje en bil, vi skal tage i bytte?',
  yes: 'Ja',
  no: 'Nej',
  tradeInInfoTitle: 'Byt din bil',
  tradeInQuickAndEasy: 'At bytte din bil er nemt og hurtigt med Seez',
  tradeInInfoStep1: '1. Besvar nogle få spørgsmål om din byttebil',
  tradeInInfoStep1Description: 'Uploade nogle billeder af din bil, så vi kan give dig det mest præcise tilbud.',
  tradeInInfoStep2: '2. Bekræft din forespørgsel',
  tradeInInfoStep2Description: 'Det er ikke bindende, og du kan altid annullere din forespørgsel.',
  tradeInInfoStep3: '3. Modtag et tilbud på din byttebil inden for én time (normal arbejdstid)',
  tradeInInfoStep3Description: 'Den officielle forhandler sender dig et tilbud, som du kan vælge at afvise eller acceptere.',
  tradeInInfoStep4: '4. Slap af derhjemme',
  tradeInInfoStep4Description: 'Vores eksperter vil gennemgå din byttebil på din hjemmeadresse, når vi leverer din nye bil.',
  howItWorks: 'Sådan gør du',
  tradeInFewQuestions: 'Svar på nogle få spørgsmål om din byttebil og modtag et tilbud fra os inden for en time (i åbningstiden)',
  tradeInOutstandingFinancingInfo: 'Det tilbud du modtager fra os, minus eventuel restgæld, trækkes fra i udbetalingen på din nye bil.',
  tradeInOfferExceeds: 'Hvis bytteprisen på din gamle bil, overstiger udbetalingen på den nye, overfører vi det overskydende beløb til dig.',
  tradeInDeliveryAndInspection: 'Når vi leverer din nye bil, giver vi din byttebil en hurtig gennemgang og tager den med os med det samme.',
  tradeInWhatWeAsk: 'Spørgsmål du kan forvente',
  tradeInRequiredInformation: 'Vi spørger ind til hvor langt din bil har kørt, tilstand, servicehistorie og om oplysninger vedrørende eventuel restgæld.',
  tradeInRequiredPictures: 'Vi vil også bede dig om at uploade et par billeder af din bil, så vi kan give dig vores mest præcise vurdering.',
  addANewTradeIn: 'Tilføj byttebil',
  tradeInAddPlateNumber: 'Tilføj bilens nummerplade eller udfyld de obligatoriske felter manuelt.',
  enterPlateNumber: 'Tilføj nummerplade',
  plateNumberInvalid: 'Åh nej! Vi kunne ikke finde en bil med det registreringsnummer.',
  plateNumberEnterDifferentOne: 'Prøv igen eller udfyld felterne nedenfor.',
  carDetails: 'Specifikationer på din bil',
  carDetailsFillInfo: 'Svar så præcist som muligt',
  clearAll: 'Slet alt',
  make: 'Mærke',
  model: 'Model',
  variant: 'Variant',
  year: 'Årgang',
  mileage: 'Km',
  color: 'Farve',
  fuelType: 'Brændstof',
  registrationDate: 'Registreringsdato',
  moreDetails: 'Flere detaljer',
  carPhotos: 'Billeder af bilen',
  carPhotosEnsureOnePhoto:
    'Byttetilbuddet vil blive baseret på disse billeder. For at give dig den mest nøjagtige vurdering skal du sørge for, at der er ét tydeligt foto af hvert af de krævede områder, og at du fremhæver eventuelle skader.',
  carPhotosBestOfferPossible: 'Upload nogle billeder af din bil, så vi kan give dig det bedst mulige tilbud.',
  carPhotosUploading: 'Uploader...',
  addFrontPhoto: 'Front',
  addBackPhoto: 'Bagende',
  addInteriorPhoto: 'Interiør',
  addExtraPhoto: 'Ekstra',
  addDamagesPhoto: 'Damages',
  addScratchesPhoto: 'Scratches',
  addDentsPhoto: 'Dents',
  damagePhotos: 'Billeder af skader (ridser, buler, stenslag osv.)',
  addPhoto: 'Tilføj billede',
  addMorePhotos: 'Tilføj flere billeder',
  carPhotosMissing: 'Mangler din byttebil ansøgning bilbilleder, kan det endelige tilbud blive justeret på baggrund af slutsyn.',
  carQuirks: 'Lidt om din bil',
  carQuirksHint:
    'Vi vil gerne byde den bedste pris for din bil, men det skal også være en pris der passer til virkeligheden. Derfor skal du herunder beskrive dens gode sider og dens skavanker. Har den stenslag eller flænger i forruden? Har den buler eller ridser - hvor mange? Har der været dyr i bilen? Har der været røget i bilen?',
  otherDetails: 'Andre detaljer',
  accidentQuestion: 'Har din bil været involveret i en ulykke?',
  accidentMajorAccidents: 'Biler der har været ude for større ulykker, bliver muligvis ikke accepteret som indbytning. Forhandlerens vilkår og betingelser gælder.',
  serviceHistory: 'Servicehistorik',
  serviceHistoryQuestion: 'Hvordan er din bils servicehistorik?',
  serviceHistoryComplete: 'En komplet servicehistorie inkluderer servicebog, hvor al service er overholdt dokumenteret med stempler, forhandler/værksted, datoer og km-stand.',
  serviceHistoryFull: 'Fuldstændig',
  serviceHistoryPartial: 'Delvis',
  serviceHistoryNone: 'Intet',
  numberOfKeys: 'Antallet af nøgler',
  howManyKeys: 'Hvor mange nøgler har du?',
  currentCondition: 'Nuværende stand',
  carConditionQuestion: 'Hvad er din bils nuværende stand?',
  carConditionSelect: 'Vælg tilstand',
  carConditionPoor: 'Dårlig',
  carConditionGood: 'God',
  carConditionAverage: 'Gennemsnitlig',
  carConditionGreat: 'Næsten perfekt',
  carConditionPoorDef:
    'Dårlig betyder at bilens tilstand er rigtig sløj. Der er væsentlig beskadigelse af både in- og eksteriør. Den trænger til en gennemgribende istandsættelse - måske bør den skrottes.',
  carConditionAverageDef:
    'Gennemsnitlig betyder, at bilens bedste år ligger langt bag den. Den kan køre, men eksteriøret er grimt og slidt og interiøret gammelt og sjusket. Mekanisk er der måske også et par problemer!',
  carConditionGoodDef: 'God betyder, at bilen kører ganske udmærket, men den er ikke helt på toppen længere. Eksteriøret har måske små ridser samt stenslag og interiøret har nogen slitage.',
  carConditionGreatDef:
    'Næsten perfekt betyder, at bilens stand er over gennemsnittet. Eksteriøret er perfekt eller har få mindre ridser og interiøret er rent, uden pletter og uden lugt. Mekanisk kører den uden problemer af nogen art.',
  registrationType: 'Registreringstypen',
  regTypeQuestion: 'Hvordan er din bil registreret',
  regTypeVanWithoutVat: 'Varebil på gule plader uden moms',
  regTypeVanWithVat: 'Varebil på gule plader med moms',
  regTypePersonal: 'Personbil',
  regTypeLeasing: 'Leasing',
  oustandingFinancingQuestion: 'Er der restgæld i din bil?',
  outstandingFinancing: 'Restgæld',
  outstandingAmount: 'Udestående gæld',
  outstandingFinancingInfo: 'Hvis du er i tvivl om restgæld bedes du kontakte din bank, finansieringsselskab eller tjek din netbank.',
  oustandingFinancingDisclaimer: 'Undgå besværet med tinglyst bank restgæld, ved at vælge ja giv du os accept til at indfri bank gælden.',
  oustandingFinancingDisclaimer1: 'Sørg for, at restgælden er så nøjagtigt som muligt - du vil være ansvarlig for at dække forskellen.',
  tradeInDiscrepanciesDisclaimer: 'Uoverensstemmelser kan resultere i ændringer af tilbuddet eller fuldstændig annullering.',
  tradeInMakeMeAnOfferDisclaimer:
    'Klikker du på "Giv mig et tilbud", bekræfter du, at du er den primære ejer af bilen og at alle oplysninger, du har givet er korrekte. Uoverensstemmelser kan ændre vores tilbud eller fuldstændig annullere det.',
  makeMeAnOffer: 'Giv mig et tilbud',
  tradeInSubmitted: 'Du har anmodet om et tilbud på din byttebil',
  tradeInTeamOfExperts: 'Vores eksperter vil kigge på din bil og vende tilbage til dig med et tilbud indenfor en time (indenfor åbningstiden).',
  continueToFinancing: 'Fortsæt til finansiering',
  tradeInOffer: 'Byttebil tilbud',
  continueWithoutTradeIn: 'Fortsæt uden byt af bil',
  tradeInOfferIsHere:
    'Tilbuddet på din byttebil er nu klar. Er din byttebil præcis som du har beskrevet den, vil du modtage det beløb, som vi har tilbudt, uden fratræk eller gebyrer. Se detaljerne nedenfor.',
  photos: 'Fotos',
  tradeInTotalValue: 'Total værdi af byttebilen',
  oustandingFinancing: 'Restgæld',
  receivableTradeInAmount: 'Tilgodehavende byttebeløb',
  acceptOffer: 'Accepter tilbud',
  acceptOfferDisclaimer: 'Klikker du på "Accepter tilbud" erklærer du dig enig i Seez indbytnings-',
  paymentMethods: 'Betalingsmetoder',
  financingQuestion: 'Hvordan vil du betale din bil?',
  bankLoan: 'Finansiering',
  bankTransfer: 'Bankoverførsel',
  bankTransferTo: 'Til',
  bankTransferRegistrationNumber: 'Reg nr.',
  bankTransferAccountNumber: 'Kontonummer',
  bankTransferReference: 'Reference',
  bankTransferBankName: 'Bank-navn',
  bankTransferTotalAmount: 'Totalbeløb',
  financingInfoTitle: 'Betaling for din bil',
  financingOneOfTwoWays: 'Du kan betale din bil på to måder.',
  financingBankLoanWay:
    'Vælg dine foretrukne finansierings vilkår med Santander, som er vores finansieringspartner.\nNår du har gennemført din forespørgsel vil du modtage en e-mail for at ansøge om finansiering.\nEfter godkendelse og underskrevet slutseddel, skal du overføre din udbetaling.',
  financingBankTransferWay: 'Betal for hele bilen via bankoverførsel, efter du har underskrevet din slutseddel.',
  financingCanYouReally: 'Kan man virkelig ansøge om et Finansiering online?',
  financingEasierLife: 'For at gøre det nemmere for dig, har vi gjort det muligt at gennemføre fuld finansiering online.',
  financingFillApplicationForm: 'Udfyld ansøgningen fra din lænestol og få svar inden for 24 timer.',
  financingThingsToKeepInMind: 'Ting du skal huske',
  financingIfTradeInAdded:
    'Har du tilføjet en byttebil, kan du ikke overføre betalingen, før du har modtaget et tilbud på din byttebil. Det tilbudte beløb for din byttebil vil blive modregnet i udbetalingen på din nye bil.',
  financingQuestion1: 'Hvordan vil du betale din bil?',
  financingGoToApplication: 'Ved at klikke på "Gå til ansøgning" videresendes du til Santanders hjemmeside, så du kan udfylde ansøgningen.',
  financingDetails: 'Finansieringsoplysninger',
  showFinancingDetails: 'Vis detaljer',
  hideFinancingDetails: 'Skjul detaljer',
  loanCalculator: 'Låneberegner',
  loanCalculatorTitle: 'Brug vores låneberegner for at få et estimat på dine månedlige ydelser.',
  loanCalculatorRedirectedToPortal: 'Når du er klar, bliver du viderestillet til ansøgningen på Santanders portal.',
  preferredDownPayment: 'Ønsket udbetaling',
  downPaymentInvalid: 'Minimum 20% af bilens kontantpris',
  preferreedLoanDuration: 'Ønsket løbetid (i mdr.)',
  preferredInterestType: 'Ønsket rente type',
  loanSummary: 'Låneoversigt',
  loanAmount: 'Lånebeløb',
  monthlyPayment: 'Månedlig ydelse',
  monthlyPaymentDisclaimer:
    '**Månedlig ydelse 8.000 kr. i 72 mdr. Variabel lånerente 1,26%. Årlige omk. 3,82%. Samlede kreditomkostninger: 59.591 kr. Udbetaling: 142.000 kr. Samlet beløb der skal betales: 568.000 kr.',
  goToApplication: 'Gå til ansøgning',
  financingProvidedBy: 'Finansiering ydet af',
  aprDisclaimer: 'Årlige omkostninger og månedlig ydelse besluttes endeligt af Santander og kan variere afhængig af din kreditværdighed, den valgte bil og lånets løbetid.',
  redirectedToSantander: 'Du er nu omdirigeret til Santanders hjemmeside.',
  applyToFinancing: 'Ansøg om finansiering',
  confirmApplicationInformation: 'Hvis du har indsendt din finansieringsansøgning bedes du bekræfte nedenfor.',
  returnToApplicationInformation: 'Hvis du ikke har indsendt finansieringsansøgningen, kan du bruge nedenstående link til at vende tilbage til din ansøgning.',
  onceFinancingReadyConfirm: 'Once the financing application is finished, please confirm below.',
  letUsKnowToProceed: 'Når du har udfyldt og indsendt finansierings-ansøgningen, så giv os besked, så vi kan fortsætte.',
  submittedMyApplication: 'Jeg har indsendt min ansøgning',
  returnToFinancingApplication: 'Tilbage til ansøgningen',
  loanApplicationSubmitted: 'Finansieringsansøgning indsendt.',
  supportNumber: '+45 80 82 08 01',
  supportEmail: 'support@seez.dk',
  loanApplicationOnceCompleted: 'Når du har gennemført dit checkout, kontakter vi finansudbyderen og fortsætter med din ansøgning.',
  loanApplicationSupport: 'Hvis du har spørgsmål, bedes du kontakte os på live support  (alle dage 10:00 - 18:00) eller Tlf.:',
  notifiedOnceDecisionMade: 'Du får besked så snart, der er truffet en beslutning.',
  carDelivery: 'Levering af bilen',
  insurance: 'Insurance',
  chooseInsurance: 'Choose your Insurance',
  trygInsurance: 'Tryg Insurance',
  trygInsuranceInfo: 'Reservations are made for the insurance company\'s app errors.  are made f Reservations are made for the insurance compa',
  trygInsuranceApply: 'Apply',
  otherInsurance: 'Other Insurance',
  otherInsuranceInfo: 'Please select your insurance company from below. If your company is not listed, please select “other” and type the company name.',
  otherInsuranceSelectorPlaceholder: 'Insurance company',
  getYourCarDelivered: 'Få din bil leveret, hvor som helst du ønsker det - uden beregning.',
  deliveryWhen: 'Hvornår vil du gerne have din bil leveret?',
  date: 'Dato',
  time: 'Tid',
  deliveryWhere: 'Hvor vil du gerne have den leveret?',
  deliveryAnywhere: 'Få din bil leveret derhjemme, på din arbejdsplads eller et helt tredje sted. Vi leverer overalt i Danmark undtagen til ikke brofaste øer.',
  town: 'By',
  addressLine1: 'Adresse',
  confirm: 'Bekræft',
  deliveryTandC: 'Leveringsbetingelser og vilkårLeveringsbetingelser og vilkår',
  carReserved: 'Bil reserveret',
  carefullyReviewDetails: 'Gå omhyggeligt alle detaljer igennem og bekræft, at informationerne er fuldstændig korrekte.',
  personalInfo: 'Personlig info',
  confirmAndProceed: 'Bekræft og fortsæt',
  loanApplicationInfoButton: 'Når du er klar, bliver du videresendt til Santanders finansieringansøgning på deres hjemmeside. Herefter skal du fortsætte din anmodning på Seez.dk.',
  confirmationPageExplanation:
    'Ved at bekræfte informationerne, kan vi gå i gang med at behandle din forespørgsel og indsende den til forhandleren. Dette er ikke bindende, og du kan til enhver tid annullere med et enkelt klik.',
  confirmAndProceedDisclaimer: 'Ved at klikke på "Bekræft & Fortsæt", bekræfter jeg, at jeg har læst og accepteret ',
  termsAndConditions: 'Vilkår og Betingelser',
  errorsResponsibilityDisclaimer: 'Fejl der opdages senere, vil være dit ansvar.',
  thankYouForConfirming: 'Tak fordi du bekræftede din ordre',
  meanwhileFillLoanApplication: 'Du kan i mellemtiden gennemføre og indsende din finansierings ansøgning til Santander',
  carIsNowReservedFor24: 'Din nye bil er nu reserveret i',
  carIsNowReservedFor241: ' 24 timer.',
  onceYouReceivedTradeInOffer: 'Så snart du modtager et tilbud på din byttebil, kan du underskrive kontrakten og betale for din nye bil.',
  backToHome: 'Hjem',
  orderSummary: 'Oversigt',
  tradeInAccepted: 'Byttetilbud godkendt',
  thankYouForAcceptingOffer: 'Tak for din godkendelse af vores byttetilbud! Så mangler der blot nogle få trin, så er din næsten nye bil din.',
  tradeInRejecteed: 'Byttetilbud afvist',
  youRejectedOffer: 'Du har afslået vores byttetilbud. Men du kan stadig fortsætte her og købe din næsten nye bil.',
  almostThere: 'Så er alt næsten klaret!',
  justACoupleMore: 'Der er lige et par ting mere, så er bilen officielt din',
  spaWaitingText: 'Når din Slutseddel er klar, får du besked på mail, så du kan underskrive den.',
  spaTitle: 'Slutseddel',
  spaExcited: 'Vi håber, du glæder dig lige så meget, som vi gør! Gennemgå slutseddelen.',
  spaBinding:
    'Denne slutseddel vil kun være bindende såfremt din låneansøgning bliver godkendt. Hvis ikke du bliver godkendt til et lån eller kan betale på andre måder vil denne slutseddel ikke være gyldig.',
  spaReadyRedirectToDocusign: 'Så snart du er klar, vil du blive omdirigeret til DocuSign, så du kan underskrive.',
  spaSignNow: 'Underskriv Nu',
  payment: 'Betaling',
  transferRequiredAmount:
    'Overfør det aftalte beløb til nedenstående konto. \nTak fordi du handlede bil online hos os. Men selvfølgelig er det her ikke enden på det hele. Vi bliver her og er klar med support og svar på ethvert spørgsmål, så længe du har brug for det.',
  downPayment: 'Udbetaling',
  aopBeforeTax: 'AOP før skat',
  downPaymentPct: 'Udbetaling',
  financedAmount: 'Finansieret beløb',
  nominalInterest: 'Nominel rente',
  totalRepay: 'Samlet tilbagebetaling',
  baseAmount: 'Lånebeløb',
  totalLoanCosts: 'Samlede låneomkostninger',
  interestType: 'Rente type',
  interestRate: 'Rente',
  interestTypeVariable: 'Variabel',
  interestTypeFixed: 'Fast',
  carPrice: 'Bilens pris',
  tradeInValue: 'Byttebils værdi',
  tradeInAmount: 'Indbyttebeløb',
  amountToBePaid: 'Beløb der skal betalesg',
  transferDetails: 'Overførsels-detaljer',
  saveDetails: 'Gem detaljer',
  transferComplete: 'Overførsel afsluttet',
  bankTransferNotice1: 'Bemærk: ',
  bankTransferNotice2: 'Vi har ikke adgang til dine betalingsoplysninger.',
  decideToReturn: 'Hvis du returnerer bilen inden for den 14 dages returret, vil hele din udbetaling blive refunderet.',
  congratulations: 'TILLYKKE',
  youAreNowOwner: 'Du er nu den stolte ejer af en næsten ny bil!',
  ourTeamWillPrepareCar: 'Nu får din bil den helt store klargøring, så den funkler, skinner og dufter, når vi leverer den.',
  warning: 'Advarsel',
  cancelWarning: 'Annullering af denne ordre vil slette alle ikke gemte oplysninger.',
  cancelAreYouSure: 'Er du sikker på, at du vil fortsætte?',
  cancelYesImSure: 'Ja, jeg vil annullere forespørgsel',
  returnToOrder: 'Tilbage til forespørgsel',
  activeOrderChange: 'Ændringer til aktiv ordre',
  orderAlreadyInProgress: 'Du har allerede en aktiv ordre i gang',
  cancelAreYouSureToStart: 'Er du sikker på, at du vil annullere din nuværende ordre, og starte på en ny?',
  cancelAndStartNew: 'Annuller og start ny ordre',
  resumeOrder: 'Genoptag ordre',
  agreementSigned: 'Aftale underskrevet',
  carIsOfficiallyYours: 'Bilen er nu officielt din.',
  thanksForSigning: 'Tak fordi du underskrev salgsaftalen. Du kan nu fortsætte til betaling.',
  proceedToPayment: 'Gå til betaling',
  carIsNowReserved: 'Bilen er nu reserveret til dig.',
  weWillContactDealer: 'Vi vil nu tage kontakt til forhandleren for at reservere bilen for dig.',
  onceFinancingIsApproved: 'Når din finansierings-ansøgning er godkendt, vil du få besked via e-mail, så du kan gå videre til betaling.',
  completed: 'Færdig',
  inProgress: 'Igangværende',
  pending: 'Afventer',
  processing: 'Processere',
  incomplete: 'Ikke færdig',
  noTradeInSelected: 'Ingen bytteværdi valgt',
  noAdditionalComments: 'Ingen yderligere kommentarer',
  noPhotosAdded: 'Ingen tilføjede billeder',
  months: 'mdr.',
  month: 'md.',
  overview: 'Oversigt',
  overviewTitle: 'Du vil blive bedt om at overføre nedenstående beløb via bankoverførsel efter du har underskrevet din slutseddel.',
  tradeInDescription:
    'Vores evaluerings eksperter gennemgår din byttebil og vender\ntilbage til dig inden for en hverdag med et tilbud. Hvis du acceptere, \nvil tilbuddet blive trukket fra din fulde udbetaling.',
  noTradeinAdded: 'Ingen',
  totalToBePaid: 'Købesum',
  congratulationsPath: 'assets/journey_end/congratulations_da.png',
  tradeInPicturesErrorMessage: '*Billeder er påkrævet for at kunne få et tilbud',
  downPaymentPctBelow20Text: 'Blankolån',
  downPaymentPctAbove20Text: 'Købekontrakt med ejendomsforbehold',
  term: 'Periode',
  myAddress: 'Min Adresse',
  addNewAddress: 'Tilføj ny adresse',
  newAddress: 'Ny adresse',
  notYetFilled: 'Ikke fyldt'
}

export const ORDER_CARD_COMPONENT_TRANSLATIONS = {
  km: 'km',
  price: 'Pris',
  kr: 'kr.',
  order_summary: 'Ordreoversigt',
  restart_order: 'Genstart ordre',
  resume_purchase: 'Genoptag køb',
  cancelled: 'annulleret',
  view_order: 'Se ordre'
}

export const ORDERS_COMPONENT_TRANSLATIONS = {
  my_orders: 'Ordrer',
  cancel_order: 'Annullere ordren',
  current_order: 'Nuværende ordre',
  expires_in: 'udløber om',
  previous_orders: 'Tidligere bestillinger',
  cancel_order_title: 'Annulér ordren',
  cancel_order_description: 'Annullering af denne ordre vil fjerne reservationen og slette alle informationer, der ikke er gemt.<br /><b>Er du sikker på, at du vil fortsætte?</b>',
  cancel_order_confirm: 'Ja, jeg vil annullere ordren',
  cancel_order_abort: 'Tilbage til ordren'
}

export const PROFILE_COMPONENT_TRANSLATIONS = {
  account_settings: 'Konto indstillinger',
  personal_information: 'Personlige oplysninger',
  email: 'Email',
  full_name: 'Full Name',
  first_name: 'Fornavn',
  middle_name: 'Mellemnavn',
  last_name: 'Efternavn',
  phone: 'Phone',
  you_have_to_login: 'You have to login before seeing your profile',
  save: 'Gem',
  marketing_consent: 'Markedsføringssamtykke'
}