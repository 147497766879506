<template>
  <div id="sideNav">
    <div class="sideNavBox">
      <ImageSlider :images="listing?.pictures" />
      <div class="details">
        <span class="name" data-test-id="card-name">{{ fullName }}</span>
        <span class="variant" data-test-id="card-variant-year">{{ listing.variant }} {{ listing.year }}</span>
        <span class="price" data-test-id="car-price">{{ retailPriceString }}</span>
      </div>
      <div class="components">
        <FormTab :label="t['tradeIn']" name="tradeIn" :status="appState.tradeIn.state" :current-stage="currentStage" @gotoComponent="gotoComponent" :loading="appState.tradeIn.loading" />
        <FormTab
          :label="t['personalInformation']"
          name="personalInformation"
          :status="appState.customerInfo.state"
          :current-stage="currentStage"
          @gotoComponent="gotoComponent"
          :loading="appState.customerInfo.loading"
        />
        <FormTab :label="t['carDelivery']" name="carDelivery" :status="appState.delivery.state" :current-stage="currentStage" @gotoComponent="gotoComponent" :loading="appState.delivery.loading" />
        <FormTab :label="t['payment']" name="payment" :status="appState.payment.state" :current-stage="currentStage" @gotoComponent="gotoComponent" :loading="appState.payment.loading" />
      </div>
    </div>
    <div class="sideNavBoxMobile">
      <div class="tabStatus">
        <div @click="gotoComponent('tradeIn')" :class="appState.tradeIn.state + ' tradeIn ' + (currentStage === 'tradeIn' ? 'current' : '')" />
        <div @click="gotoComponent('personalInformation')" :class="appState.customerInfo.state + ' personalInfo ' + (currentStage === 'customerInfo' ? 'current' : '')" />
        <div @click="gotoComponent('carDelivery')" :class="appState.delivery.state + ' delivery ' + (currentStage === 'delivery' ? 'current' : '')" />
        <div @click="gotoComponent('payment')" :class="appState.payment.state + ' payment ' + (currentStage === 'payment' ? 'current' : '')" />
      </div>
      <div class="currentStep">
        <div :class="'wrapper ' + currentStage">
          <div class="tradeInText">
            <span>{{ t['tradeIn'] }}</span>
            <div @click="openStepsModal"><img class="chevDown" :src="chevDown" v-if="currentStage === 'tradeIn'" /></div>
          </div>
          <div class="customerInfoText">
            <span>{{ t['personalInformation'] }}</span>
            <div @click="openStepsModal"><img class="chevDown" :src="chevDown" v-if="currentStage === 'customerInfo'" /></div>
          </div>
          <div class="deliveryText">
            <span>{{ t['carDelivery'] }}</span>
            <div @click="openStepsModal"><img class="chevDown" :src="chevDown" v-if="currentStage === 'delivery'" /></div>
          </div>
          <div class="paymentText">
            <span>{{ t['payment'] }}</span>
            <div @click="openStepsModal"><img class="chevDown" :src="chevDown" v-if="currentStage === 'payment'" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lang } from '../../lang'
import FormTab from '../components/Form.Tab.ce.vue'
import ImageSlider from './Image.Slider.ce.vue'
import { chevDown } from '../../../assets/images'

export default {
  name: 'SeezSideNav',
  components: { FormTab, ImageSlider },
  mixins: [lang],
  props: {
    orderId: { type: String, default: '' },
    name: { type: String, default: null },
    listing: {
      type: Object,
      default: () => {
        return {}
      }
    },
    appState: {
      type: Object,
      default: () => {
        return {
          customerInfo: {
            state: null
          },
          tradeIn: {
            state: null
          },
          payment: {
            state: null
          },
          delivery: {
            state: null
          }
        }
      }
    },
    details: {
      type: Object,
      default: () => {
        return { currency: 'kr' }
      }
    },
    currentStage: { type: String, required: true },
    editSection: { type: String, default: '' }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    fullName() {
      if (this?.name) return this.name
      const brand = this.listing?.brand?.name
      const model = this.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    retailPriceString() {
      if (this.listing.priceRetail == null) return null
      return this.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    imageSrc() {
      return this.listing?.pictures?.[0] || ''
    },
    initialOffsetTop() {
      const el = this.$parent.$refs.tradeIn.$el
      return el.offsetTop
    },
    chevDown() {
      return chevDown
    }
  },
  mounted() {
    if (this.editSection)
      this.$nextTick(() => {
        this.gotoComponent(this.editSection)
      })
  },
  methods: {
    gotoComponent(ref) {
      this.$emit('gotoComponent', ref)
    },
    cancelOrder() {
      this.$emit('cancelOrder')
    },
    openStepsModal() {
      this.$emit('setStepsOverviewModal', true)
    }
  }
}
</script>
<style lang="scss" scoped>
#sideNav {
  .sideNavBoxMobile {
    display: none;
  }
  @media screen and (max-width: 56rem) {
    height: 4.5rem;
    background: #f6f6f6;
    border: 1px solid #eeeeee;
    border-radius: 0 0 16px 16px;
    /* position: fixed;
    top: 6.875rem; */
    position: sticky;
    top: 0;
    /* width: 100vw; */
    .sideNavBoxMobile {
      display: block;
    }
    .sideNavBox {
      display: none;
    }
    .tabStatus {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      gap: 0.25rem;
      cursor: pointer;
      > div {
        display: block;
        width: 20px;
        height: 4px;
        border-radius: 16px;
        background: #afafaf;
      }
      > div.current {
        background: #0068ff;
        width: 2.125rem;
      }
      > div.complete {
        background: #009a33;
      }
    }
    .currentStep {
      width: 100%;
      overflow: hidden;
      > div.wrapper {
        display: flex;
        position: relative;
        top: 0;
        right: 0;
        transition: all 1s ease;
        > div {
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.75rem;
          width: 50%;
          flex-shrink: 0;
          box-sizing: border-box;
          padding: 10px;
          text-align: center;
          color: #cbcbcb;
        }
        &.tradeIn {
          right: -20%;
          > .tradeInText {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: #000000;
          }
        }
        &.customerInfo {
          right: 25%;
          > .customerInfoText {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: #000000;
          }
        }
        &.delivery {
          right: 75%;
          > .deliveryText {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: #000000;
          }
        }
        &.payment {
          right: 130%;
          > .paymentText {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: #000000;
          }
        }
        .chevDown {
          width: 0.75rem;
        }
      }
    }
  }
  padding: 1.0625rem 0.9375rem 1.0625rem 1.3125rem;
  height: 46rem;
  .sideNavBox {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
  }
  .components {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .details {
    margin: 1.25rem 1.75rem;
    display: flex;
    flex-direction: column;
    > .name {
      font-weight: 700;
      font-size: 1rem;
      line-height: 22px;
    }
    .price {
      font-weight: 700;
      font-size: 1rem;
      line-height: 22px;
      margin-top: auto;
      margin-left: auto;
    }
    .variant {
      width: 13rem;
    }
  }
}
</style>
