<template>
  <div id="tradeInOffer" data-test-id="trade-in-offer">
    <loader v-if="loading" />
    <div class="container">
      <div class="titleBox">
        <h2>{{ t['tradeInOffer'] }}</h2>
        <p>{{ t['tradeInOfferIsHere'] }}</p>
        <a class="withoutTradeIn" @click="rejectOffer">{{ t['continueWithoutTradeIn'] }}</a>
      </div>
      <div class="carDetails">
        <h3>{{ t['carDetails'] }}</h3>
        <div class="values">
          <div>
            {{ t['make'] }}:
            <div class="value">{{ order.tradeInState.make }}</div>
          </div>
          <div>
            {{ t['mileage'] }}:
            <div class="value">{{ order.tradeInState.kilometrage }}</div>
          </div>
          <div>
            {{ t['model'] }}:
            <div class="value">{{ order.tradeInState.model }}</div>
          </div>
          <div>
            {{ t['fuelType'] }}:
            <div class="value">{{ order.tradeInState.fuelType }}</div>
          </div>
          <div>
            {{ t['variant'] }}:
            <div class="value">{{ order.tradeInState.variant }}</div>
          </div>
          <div>
            {{ t['registrationDate'] }}:
            <div class="value">{{ order.tradeInState.registrationDate }}</div>
          </div>
          <div>
            {{ t['year'] }}:
            <div class="value">{{ order.tradeInState.year }}</div>
          </div>
          <div>
            {{ t['outstandingFinancing'] }}:
            <div class="value">{{ order.tradeInState.hasFinancing }}</div>
          </div>
          <div>
            {{ t['currentCondition'] }}:
            <div class="value">{{ order.tradeInState.carCondition }}</div>
          </div>
          <div>
            {{ t['registrationType'] }}:
            <div class="value">{{ order.tradeInState.registrationType }}</div>
          </div>
        </div>
        <h3>{{ t['carQuirks'] }}</h3>
        <p>{{ order.tradeInState.customerComment }}</p>
        <h2>{{ t['photos'] }}</h2>
        <div class="photos">
          <div class="img" v-for="image of order.tradeInState.pictures" :key="image.url"><img :src="baseUrl + image.url" /></div>
        </div>
      </div>
      <div class="offerPriceDetails">
        <h3>{{ t['tradeInOffer'] }}</h3>
        <div class="valueRow">
          <span>{{ t['tradeInTotalValue'] }}</span>
          <span class="bold">{{ formattedOfferAmount }}</span>
        </div>
        <div class="valueRow">
          <span>{{ t['outstandingAmount'] }}</span>
          <span class="bold">{{ formattedOutstandingFinanceAmount }}</span>
        </div>
        <div class="valueRow totalValue">
          <span>{{ t['receivableTradeInAmount'] }}</span>
          <span class="bold">{{ formattedTotalAmount }}</span>
        </div>
      </div>
      <div class="acceptAndConfirmBox">
        <div class="acceptBox">
          <label
            ><input type="checkbox" v-model="acceptCheckBox" />{{ t['acceptOfferDisclaimer'] }}
            <div class="terms">{{ t['termsAndConditions'] }}</div></label
          >
        </div>
        <SeezButton :disabled="!acceptCheckBox" @click="acceptOffer">{{ t['acceptOffer'] }}</SeezButton>
      </div>
    </div>
  </div>
</template>
<script>
import { lang } from '../lang'
import Loader from '../Loader.ce.vue'
import SeezButton from './components/SeezButton.ce.vue'

export default {
  name: 'TradeInOffer',
  components: { Loader, SeezButton },
  mixins: [lang],
  props: {
    order: { type: Object, required: true },
    details: {
      type: Object,
      default: () => {
        return { currency: 'kr' }
      }
    }
  },
  data() {
    return {
      loading: false,
      acceptCheckBox: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    formattedOfferAmount() {
      return this.order?.tradeInState?.offerAmount?.toLocaleString(this.language) + ' ' + this.currency
    },
    formattedOutstandingFinanceAmount() {
      return this.order?.tradeInState?.outstandingFinanceAmount?.toLocaleString(this.language) + ' ' + this.currency
    },
    formattedTotalAmount() {
      return this.order?.tradeInState?.totalAmount?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    baseUrl() {
      return import.meta.env.VITE_IMAGES_URL + '/'
    }
  },
  methods: {
    async rejectOffer() {
      this.$emit('rejectOffer')
    },
    async acceptOffer() {
      this.$emit('acceptOffer')
    }
  }
}
</script>
<style lang="scss" scoped>
#tradeInOffer {
  overflow: auto;
  height: 90vh;
  padding: 2rem 0;
  .container {
    max-width: 60rem;
    margin: auto;
  }
  > .loader {
    position: absolute;
    z-index: 100;
  }
  .titleBox {
    position: relative;
    text-align: center;
    > a.withoutTradeIn {
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .carDetails {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.625rem;
    font-size: 0.8rem;
    margin: 0.75rem 0;
    .values {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;
      > div {
        width: max-content;
        display: flex;
        flex-direction: row;
        > .value {
          font-weight: 700;
        }
      }
    }
    .valueRow {
      display: flex;
      justify-content: space-between;
    }
    .photos {
      display: flex;
      flex-direction: row;
      gap: 0.75rem;
      flex-wrap: wrap;
      > .img > img {
        width: 12rem;
      }
    }
  }
  .offerPriceDetails {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
    font-size: 0.8rem;
    margin: 0.75rem 0;
    .valueRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px;
    }
    .totalValue {
      background: #cce1ff;
      border-radius: 5px;
      font-weight: 700;
    }
  }
  .acceptAndConfirmBox {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    .acceptBox {
      width: 70%;
      @media screen and (max-width: 24rem) {
        width: 100%;
      }
      margin: auto;
      .terms {
        display: inline-block;
        color: #0268ff;
      }
    }
    > button {
      width: 100%;
    }
  }
  .bold {
    font-weight: bold;
  }
}
</style>
