<template>
  <div class="favorites">
    <div class="list" v-if="!loading && !error && listings.length > 0">
      <ListingCard v-for="(l, i) in listings" :key="l.id" :ref="'vehicle' + i" :index="i" :details="l" :to="to" @click="$emit('select', parseInt(l.id))" />
    </div>
    <EmptySearch v-if="!loading && listings.length === 0" :to="url" :description="t['you_have_not_favorites']" :lg="lg" />
    <p v-if="!loading && error" class="error">{{ error }}</p>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import ListingCard from '../ListingCard.ce.vue'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import EmptySearch from '../EmptySearch.ce.vue'
import { lang } from '../lang'

export default {
  name: 'SeezFavorites',
  components: { ListingCard, Loader, EmptySearch },
  mixins: [lang, SeezSdk.vueQueryMixin, SeezSdk.vueFavoritesMixin],
  props: { to: { type: String, default: null } },
  data() {
    return {
      loadingDetails: false,
      listings: [],
      error: null,
      url: '/search'
    }
  },
  computed: {
    t() {
      return this.languageResources.EMPTY_SEARCH_COMPONENT_TRANSLATIONS
    },
    loading() {
      return this.loadingDetails
    }
  },
  watch: {
    favorites() {
      this.loadListings()
    }
  },
  mounted() {
    this.loadListings()
  },
  methods: {
    async loadListings() {
      this.error = null
      if (this.favorites.length > 0) {
        this.loadingDetails = true
        try {
          const query =
            'query favorites($ids:[ID]){listingsByIds(ids:$ids){id,name,variant,brand{name},year,currency,priceRetail,priceMonthly,pictures,kilometrage,state,dealership{name,logoUrl},fuelType{name},reserved,reservationExpires}}'
          const result = await this.queryApi(query, { ids: this.favorites.map(i => i.toString()) })
          this.listings = result.listingsByIds
        } catch (error) {
          console.error(error)
          this.error = 'Error getting favorites'
        }
        this.loadingDetails = false
      } else {
        this.listings = []
      }
    }
  }
}
</script>

<style lang="scss">
.favorites {
  > .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 17em);
    justify-content: start;
    gap: 1em;
    padding: 1em 0 0 0.5em;

    @media screen and (max-width: 36rem) {
      grid-template-columns: repeat(auto-fill, 100%);
      padding: 1em 1em 0 1em;
    }
  }

  > .error {
    color: red;
    text-align: center;
    margin: 0;
    font-size: 2em;
  }
}
</style>
