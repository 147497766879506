<template>
  <div id="checkOut">
    <loader v-if="loading" />
    <div class="currentPage" v-if="order.id || notFound">
      <CheckoutHeader :order-id="order?.id || orderId" @setSummaryModal="setSummaryModal" />
      <CheckoutForm
        v-if="page === 'form'"
        :order="order"
        :edit-section="editSection"
        @updateOrderState="updateOrderState"
        @updateLocalState="updateLocalState"
        @confirmOrder="confirmToReviewOrder"
        @cancelOrder="cancelOrder"
        data-test-id="checkout-form"
        :target-site="targetSite"
        :scroll-to-step="scrollToStep"
        @setStepsOverviewModal="setStepsOverviewModal"
      />
      <CheckoutOverview
        v-if="page === 'overview'"
        :order="order"
        @setSummary="setSummary"
        @setViewOffer="setViewOffer"
        @setSpaSign="setSpaSign"
        data-test-id="checkout-overview"
        @getOrder="getOrder"
      />
      <CheckoutReview v-if="page === 'review'" :order="order" @confirmOrder="confirmOrder" @cancelOrder="cancelOrder" @setEdit="setEdit" @setSummary="setSummary" data-test-id="checkout-review" />
      <Congratulations v-if="page === 'congratulations'" :order="order" @setSummary="setSummary" data-test-id="checkout-congratulations" />
      <BankTransfer v-if="page === 'bankTransfer'" :order="order" @transferComplete="transferComplete" data-test-id="checkout-bank-transfer" />
      <SpaSign v-if="page === 'spaSign'" :order="order" @signSpa="signSpa" data-test-id="checkout-spa-sign" />
      <TradeInOffer v-if="page === 'tradeInOffer'" :order="order" @rejectOffer="rejectOffer" @acceptOffer="acceptOffer" data-test-id="checkout-trade-in-offer" />
      <div class="canceledOrder" v-if="page === 'cancelled' || page === 'notfound'">
        <div v-if="page === 'cancelled'">{{ t['thisOrder'] }} {{ order.id }} {{ t['hasBeenCancelled'] }}</div>
        <div v-if="page === 'notfound'">{{ t['thisOrder'] }} {{ orderId }} {{ t['notFound'] }}</div>
        <a href="/" data-test-id="cancel-order">{{ t['backToHome'] }}</a>
      </div>
    </div>
    <StepsOverviewModal
      :show-steps-overview-modal="showStepsOverviewModal"
      @setStepsOverviewModal="setStepsOverviewModal"
      :order="order"
      :current-stage="order.currentStage"
      @gotoComponent="gotoComponent"
    />
    <SummaryModal :show-summary-modal="showSummaryModal" @setSummaryModal="setSummaryModal" :order="order" :current-stage="order.currentStage" @gotoComponent="gotoComponent" />
  </div>
</template>
<script>
import SeezSdk from '../../sdk.js'
import { OrderState } from './components/orderState'
import CheckoutReview from './Review.ce.vue'
import TradeInOffer from './TradeInOffer.ce.vue'
import SpaSign from './SpaSign.ce.vue'
import BankTransfer from './BankTransfer.ce.vue'
import Congratulations from './Congratulations.ce.vue'
import CheckoutForm from './form/CheckoutForm.ce.vue'
import CheckoutOverview from './Overview/Overview.ce.vue'
import Loader from '../Loader.ce.vue'
import { listingQuery, tradeInQuery, spaQuery, orderStateQuery } from './components/queries'
import { lang } from '../lang'
import { parseUrlFilters } from '../../logic.js'
import CheckoutHeader from './Checkout.Header.ce.vue'
import { drawerIcon, openDrawer } from '../../assets/images'
import StepsOverviewModal from './components/StepsOverview.ce.vue'
import SummaryModal from './components/Summary.ce.vue'

const mapIdToStageName = {
  tradeIn: 'tradeIn',
  personalInformation: 'customerInfo',
  carDelivery: 'delivery',
  payment: 'payment'
}

export default {
  name: 'CheckOut',
  components: { Loader, CheckoutReview, TradeInOffer, SpaSign, BankTransfer, Congratulations, CheckoutForm, CheckoutOverview, CheckoutHeader, StepsOverviewModal, SummaryModal },
  mixins: [lang, SeezSdk.vueQueryMixin],
  props: {
    params: { type: String, default: '' },
    targetSite: { type: String, default: 'seez' }
  },
  data() {
    const urlParamsMap = parseUrlFilters(this.params)
    let urlQueries = new URLSearchParams(window.location.search)
    return {
      orderId: urlParamsMap.order,
      summary: urlQueries.has('summary') || false,
      event: urlParamsMap.event || false,
      order: new OrderState(),
      loading: false,
      forceRefreshPageState: false,
      editSection: '',
      viewOffer: urlParamsMap.viewoffer || false,
      spaSign: urlQueries.has('spasign') || false,
      urlQueries: urlQueries,
      notFound: false,
      showStepsOverviewModal: false,
      showSummaryModal: false,
      scrollToStep: null
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    page() {
      this.forceRefreshPageState
      // possible values: confirmed, review, congratulations, bankTransfer, spaSign, tradeInOffer, form, notFound
      if (this.notFound) return 'notfound'
      if (!this.order.id) return ''
      if (this.order.state === 'cancelled') return 'cancelled'
      if (this.editSection) {
        return 'form'
      }
      if (this.summary && this.order.state === 'confirmed') {
        return 'review'
      }
      if (this.spaSign) {
        if (this.order.paymentResponse?.bankTransfer?.state === 'initiated' || this.order.paymentResponse?.bankTransfer?.state === 'received') return 'congratulations'
        if (this.isSpaSigned) {
          this.setSpaSign(false)
          return 'bankTransfer'
        }
        return 'spaSign'
      }
      if ((this.order.spaState && this.order.paymentResponse?.bankTransfer?.state == 'initiated') || this.order.paymentResponse?.bankTransfer?.state == 'received') {
        return 'congratulations'
      }
      if (this.isSpaSigned) {
        return 'bankTransfer'
      }
      if (this.order.state === 'confirmed' && this.viewOffer) {
        return 'tradeInOffer'
      }
      if (this.order.state === 'confirmed') {
        return 'overview'
      }

      //// Rest will be legacy soon

      if (this.summary && this.order.state === 'confirmed') {
        return 'review'
      }
      if (this.order.spaState) {
        if (this.order.paymentResponse?.bankTransfer?.state === 'initiated' || this.order.paymentResponse?.bankTransfer?.state === 'received') return 'congratulations'
        if (
          this.order.spaState?.state === 'customerSigned' ||
          this.order.spaState?.state === 'completed' ||
          this.order.spaState?.state === 'signed' ||
          this.event === 'viewing_complete' ||
          this.event === 'signing_complete'
        )
          return 'bankTransfer'
        return 'spaSign'
      }
      if (this.order.appState?.current === 'summary') {
        return 'review'
      }
      return 'form'
    },
    isSpaSigned() {
      const spaState = this.order.spaState?.state
      return (
        (spaState === 'customerSigned' && spaState == 'customerSigned') ||
        spaState == 'completed' ||
        spaState == 'signed' ||
        spaState == 'customerSigned' ||
        this.urlQueries.get('event') == 'viewing_complete' ||
        this.urlQueries.get('event') == 'signing_complete'
      )
    },
    overviewStyles() {
      return {
        background: `url(${drawerIcon})`
      }
    },
    openDrawer() {
      return openDrawer
    },
    carImage() {
      return this.order.listing?.pictures?.[0] || ''
    }
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.forceRefreshPageState = !this.forceRefreshPageState
      }
    }
  },
  mounted() {
    this.getOrder()
  },
  methods: {
    async getOrder(quite) {
      try {
        if (!quite) this.loading = true
        const query = `
          {
            order(id: ${this.orderId}) {
              ${orderStateQuery}
            }
          }
        `
        const { order } = await this.queryApi(query)
        const newOrder = new OrderState()
        newOrder.setOrder(order)
        this.order = newOrder
        // this.order.setOrder(order)
      } catch (err) {
        console.error(err)
        this.notFound = true
      } finally {
        this.loading = false
      }
    },
    async updateOrder(summary) {
      if (!this.order.checkIfUpdateIsRequired() && !summary) {
        this.order.appState[this.order.previousStage].errors = [...this.order.appState[this.order.previousStage].errors]
        return
      }
      const updateOrder = this.order.prepareUpdateMutation(summary)
      const { updateOrder: newOrder } = await this.queryApi(updateOrder)
      this.order.setOrder(newOrder)
    },
    updateLocalState(stateName, stateValue, forcePreviousStage) {
      if (forcePreviousStage) this.order.previousStage = forcePreviousStage
      switch (stateName) {
        case 'tradeIn':
          this.order.updateTradeInState(stateValue)
          break
        case 'customerInfo':
          this.order.updatePersonalState(stateValue)
          break
        case 'delivery':
          this.order.updateCarDeliveryState(stateValue)
          break
        case 'payment':
          this.order.updatePaymentState(stateValue)
      }
      this.updateOrder()
    },
    updateOrderState(entries) {
      if (this.order.currentStage === mapIdToStageName[entries[0].target.id]) return
      this.order.previousStage = this.order.currentStage
      this.order.currentStage = mapIdToStageName[entries[0].target.id]
    },
    async confirmToReviewOrder() {
      this.loading = true
      this.order.previousStage = this.order.currentStage
      await this.updateOrder('summary')
      this.loading = false
      this.editSection = ''
    },
    async confirmOrder() {
      try {
        this.loading = true
        const id = this.order.id
        const confirmOrder = `
      mutation {
        confirmOrder(orderId: ${id}) {
          id
          state
          listing {
             ${listingQuery}
          }
          tradeIn {
            ${tradeInQuery}
          }
          spa {
            ${spaQuery}
          }
        }
      }
    `
        await this.queryApi(confirmOrder)
        await this.submitPayment()
        await this.getOrder()
      } catch (err) {
        //TODO: use toast
        alert('CONFIRM ORDER FAILED')
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async cancelOrder() {
      try {
        this.loading = true
        const mutation = this.order.prepareCancelOrderMutation(this.order.id)
        await this.queryApi(mutation)
        await this.getOrder()
        window.location.href = '/'
      } catch (err) {
        console.error(err)
        alert('ERROR canceling order')
      } finally {
        this.loading = false
      }
    },
    async submitPayment() {
      if (this.order.paymentState?.financing?.paymentTerms && this.order.paymentState?.financing?.downPayment) {
        this.loading = true
        const mutation = this.order.prepareSubmitPaymentToQuery(this.order.id)
        await this.queryApi(mutation)
        this.loading = false
      }
      this.order.paymentState.financingState = 'submitted'
      const mutation = this.order.prepareSetPaymentSubmittedToQuery(this.order.id)
      await this.queryApi(mutation)
      await this.updateOrder()
    },
    async transferComplete() {
      try {
        this.loading = true
        const mutation = this.order.prepareCompleteTransferMutation(this.order.id)
        await this.queryApi(mutation)
        await this.getOrder()
      } catch (err) {
        console.error(err)
        alert('Error Completing transfer')
      } finally {
        this.loading = false
      }
    },
    async signSpa() {
      try {
        this.loading = true
        const returnUrl = window.location.origin + '/checkout/?order=' + this.order.id
        const mutation = this.order.prepareSignSpaMutation(this.order.id, returnUrl)
        const { createSigningView } = await this.queryApi(mutation)
        if (createSigningView.redirectUrl) window.open(createSigningView.redirectUrl, '_blank')
        await this.getOrder()
      } catch (err) {
        console.error(err)
        alert('Error siging SPA')
      } finally {
        this.loading = false
      }
    },
    async rejectOffer() {
      try {
        this.loading = true
        const mutation = this.order.prepareRejectTradeInOfferMutation(this.orderId)
        await this.queryApi(mutation)
        await this.getOrder()
      } catch (err) {
        // TODO: use toast
        alert('Reject trade in failed')
      } finally {
        this.loading = false
        this.setViewOffer(false)
      }
    },
    async acceptOffer() {
      try {
        this.loading = true
        const mutation = this.order.prepareAcceptTradeInOfferMutation(this.orderId)
        await this.queryApi(mutation)
        await this.getOrder()
      } catch (err) {
        // TODO: use toast
        alert('Accept trade in Failed')
      } finally {
        this.loading = false
        this.setViewOffer(false)
      }
    },
    setEdit(name) {
      this.editSection = name
    },
    setSummary(value) {
      if (value) {
        const params = new URLSearchParams(location.search)
        params.set('summary', true)
        window.history.replaceState({}, '', `${location.pathname}?${params}`)
      } else {
        const params = new URLSearchParams(location.search)
        params.delete('summary')
        window.history.replaceState({}, '', `${location.pathname}?${params}`)
      }
      this.summary = value
    },
    setSpaSign(value) {
      if (value) {
        const params = new URLSearchParams(location.search)
        params.set('spasign', true)
        window.history.replaceState({}, '', `${location.pathname}?${params}`)
      } else {
        const params = new URLSearchParams(location.search)
        params.delete('spasign')
        window.history.replaceState({}, '', `${location.pathname}?${params}`)
      }
      this.spaSign = value
    },
    setViewOffer(value) {
      this.viewOffer = value
    },
    setSummaryModal(status) {
      this.showSummaryModal = status
    },
    setStepsOverviewModal(status) {
      this.showStepsOverviewModal = status
    },
    gotoComponent(name) {
      this.scrollToStep = name
    }
  }
}
</script>
<style lang="scss" scoped>
#checkOut {
  overflow: hidden;
  height: 100% !important;
  background: white;
  > .loader {
    position: absolute;
    z-index: 200;
  }
  .canceledOrder {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    > a {
      text-decoration: none;
    }
  }
  .overviewButton {
    position: absolute;
    right: 30px;
    top: 50%;
    height: 95px;
    width: 45px;
    > img {
      position: absolute;
      right: 16px;
      top: 50%;
    }
  }
  /* .drawer-leave-active,
  .drawer-enter-active {
    transition: all 0.8s ease;
  }
  .drawer-enter,
  .drawer-leave-to {
    transform: rotate(180deg);
    opacity: 0;
  } */
  .summary-enter-active {
    transition: all 0.3s ease-out;
  }
  @media screen and (max-width: 56rem) {
    .overviewButton {
      display: none;
    }
  }
}
</style>
