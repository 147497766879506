<template>
  <div class="orderInformation">
    <div class="confirmCancel" v-if="showConfirmationModal">
      <div class="modal">
        <h2>{{ t['cancelOrder'] }}</h2>
        <div>{{ t['cancelWarning'] }}</div>
        <div class="warning">{{ t['cancelAreYouSure'] }}</div>
        <SeezButton @click="cancelOrder" data-test-id="cancel-order-button-confirm">{{ t['cancelYesImSure'] }}</SeezButton>
        <div class="return" @click="showConfirmationModal = false" data-test-id="cancel-order-button-return">{{ t['returnToOrder'] }}</div>
      </div>
    </div>
    <div>
      {{ t['orderId'] }}: <b>{{ orderId }}</b>
    </div>
    <a class="cancelOrder" @click="showConfirmationModal = true" data-test-id="cancel-order-button-show-confirm-modal">{{ t['cancelOrder'] }}</a>
  </div>
</template>

<script>
import { lang } from '../../lang'
import SeezButton from './SeezButton.ce.vue'

export default {
  name: 'OrderInfo',
  components: { SeezButton },
  mixins: [lang],
  props: { orderId: { type: String, default: null } },
  data() {
    return {
      showConfirmationModal: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    cancelOrder() {
      this.$emit('cancelOrder')
    }
  }
}
</script>
<style lang="scss">
.orderInformation {
  .confirmCancel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: brightness(60%);
    z-index: 300;
    .modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      border-radius: 1rem;
      padding: 1rem;
      gap: 1rem;
      .warning {
        font-size: 1.2rem;
        font-weight: 700;
      }
      .return {
        color: #999;
        font-size: 0.8rem;
        cursor: pointer;
      }
    }
  }
  position: relative;
  background: #f6f6f6;
  border-radius: 12px;
  padding: 0.75rem 0.75rem 0.4375rem 0.75rem;
  margin-bottom: 1.0625rem;
  > .cancelOrder {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
  }
}
</style>
