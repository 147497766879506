<template>
  <a @click="gotoComponent()" :class="'tab ' + getClass()" :data-test-id="'goto-' + name">
    <div class="icon" v-if="loading"><SmallLoader fit-width /></div>
    <div class="icon" v-if="!loading"><img :src="getIcon" /></div>
    <div class="label" :data-test-id="'tab-name-' + name">{{ label }}</div>
    <div :class="'status ' + status" :data-test-id="'tab-status-' + name">{{ status }}</div>
    <div class="estimate" :data-test-id="'tab-estimate-' + name">{{ t['about3Minutes'] }}</div>
    <div class="chevronRight"><img :src="chevronRight" /></div>
  </a>
</template>

<script>
import { circleDot, warningCircle, doneCircle, currentCircle, chevronRight } from '../../../assets/images'
import SmallLoader from '../../Loader.small.ce.vue'
import { lang } from '../../lang'

export default {
  name: 'FormTab',
  components: { SmallLoader },
  mixins: [lang],
  props: {
    status: { type: String, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    currentStage: { type: String, required: true },
    loading: { type: Boolean, default: false }
  },
  computed: {
    isCurrentStage() {
      if (this.name === 'personalInformation') return this.currentStage === 'customerInfo'
      if (this.name === 'carDelivery') return this.currentStage === 'delivery'
      return this.currentStage === this.name
    },
    isPending() {
      return this.status === 'pending'
    },
    isIncomplete() {
      return this.status === 'incomplete'
    },
    isComplete() {
      return this.status === 'complete'
    },
    isCurrent() {
      return this.isCurrentStage
    },
    getIcon() {
      if (this.isCurrent && !this.isComplete) return currentCircle
      if (this.isPending) return circleDot
      if (this.isIncomplete) return warningCircle
      if (this.isComplete) return doneCircle
      return warningCircle // fallback icon
    },
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    chevronRight() {
      return chevronRight
    }
  },
  methods: {
    gotoComponent() {
      this.$emit('gotoComponent', this.name)
      this.$emit('setSummaryModal', false)
    },
    getClass() {
      let classes = ''
      if (this.isCurrent) classes += 'current '
      if (this.isComplete) classes += 'complete'
      return classes
    }
  }
}
</script>
<style lang="scss" scoped>
.tab {
  display: grid;
  grid-template-columns: 3.75rem 1fr;
  grid-template-rows: 1.5rem 1.2rem 1rem;
  grid-template-areas:
    'icon label'
    'icon status'
    'icon estimate';
  cursor: pointer;
  height: 3.5625rem;
  border-radius: 0px 16px 0px 0px;
  background-color: white;
  border: 1px solid #eeeeee;
  border-bottom: none;
  margin-top: -1rem;
  padding: 1rem 1.375rem;
  &:last-child {
    border-radius: 16px;
  }
  .icon {
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: start;
    > .loader {
      width: 16px;
      height: 16px;
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .label {
    grid-area: label;
    font-size: 16px;
    line-height: 22px;
  }
  .status {
    grid-area: status;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #afafaf;
  }
  .estimate {
    grid-area: estimate;
    display: none;
    font-size: 12.8px;
    line-height: 18px;
    color: #afafaf;
  }
  .chevronRight {
    display: none;
    grid-area: chevron;
  }
  &.current {
    transition: height 0.5s;
    background: #cce1ff4d;
    height: 5rem;
    grid-template-rows: 2rem 1.2rem 1rem;
    padding: 1.875rem 1.375rem;
    .estimate {
      display: block;
    }

    .status {
      color: #0068ff;
    }
    .label {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }
  &.current.complete {
    background: #b3e1c280;
    .status {
      color: #009a33;
    }
  }
  @media screen and (max-width: 56rem) {
    background: #ffffff;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #e2e2e2;
    border-radius: 0;
    padding: 24px 0;
    grid-template-columns: 1.65rem 1fr;
    grid-template-areas:
      'icon label chevron'
      'icon status chevron'
      'icon estimate chevron';
    > .icon {
      padding-top: 1rem;
      justify-content: start;
    }
    > .chevronRight {
      display: block;
      padding-top: 1rem;
    }
    .status.complete {
      color: #009a33;
    }
    .status.incomplete {
      color: #ffa200;
    }
  }
}
</style>
