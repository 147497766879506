<template>
  <div id="payment" class="fixedHeight" ref="payment">
    <form ref="paymentForm" data-test-id="payment-form">
      <h2>{{ t['paymentMethods'] }}</h2>
      <p>{{ t['financingOneOfTwoWays'] }}</p>
      <div class="paymentInformation">
        <tooltip />
        <div class="paymentInformationBody">
          <h5>{{ t['bankLoan'] }}</h5>
          <p>{{ t['financingBankLoanWay'] }}</p>
          <h5>{{ t['bankTransfer'] }}</h5>
          <p>{{ t['financingBankTransferWay'] }}</p>
          <h5>{{ t['financingThingsToKeepInMind'] }}</h5>
          <p>{{ t['financingIfTradeInAdded'] }}</p>
        </div>
      </div>
      <h4>{{ t['financingQuestion1'] }}</h4>
      <div class="paymentMethodOptions">
        <Radio
          :options="[
            { placeholder: 'bankLoan', value: PaymentMethods.bankLoan },
            { placeholder: 'bankTransfer', value: PaymentMethods.bankTransfer }
          ]"
          name="payment-method"
          v-model="paymentModel.type"
          :disabled="financingSubmitted"
          @change="formChanges"
          data-test-id="payment-form-method"
        />
      </div>
      <div v-if="paymentModel.type === PaymentMethods.bankLoan && !financingSubmitted">
        <h3>{{ t['loanCalculator'] }}</h3>
        <p>{{ t['loanCalculatorTitle'] }}</p>
        <div class="loanCalculatorContainer">
          <div class="loanCalculator">
            <div>{{ t['preferredDownPayment'] }}</div>
            <InputWError
              class="radiusInput"
              :placeholder="t['preferredDownPayment']"
              v-model="paymentModel.financing.downPayment"
              type="number"
              @change="formChanges"
              data-test-id="payment-form-down-payment"
            />
            <span>{{ t['downPaymentInvalid'] }}</span>
            <div class="loadDurations">
              <div
                v-for="duration in durations"
                :key="duration"
                @click="setDuration(duration)"
                :class="paymentModel.financing.paymentTerms === duration ? 'selected' : ''"
                :data-test-id="'payment-form-loan-duration-' + duration"
              >
                {{ duration }}
              </div>
            </div>
            <h4>{{ t['preferredInterestType'] }}</h4>
            <Radio
              :options="[
                { placeholder: 'interestTypeFixed', value: 'fixed' },
                { placeholder: 'interestTypeVariable', value: 'variable' }
              ]"
              name="interest-type"
              v-model="paymentModel.financing.interestType"
              @change="formChanges"
              data-test-id="payment-form-interest-type"
            />
          </div>
          <div class="loanSummary">
            <h5 v-if="!loanCalculatorLoading">{{ t['monthlyPayment'] }}</h5>
            <p>{{ t['downPaymentPctAbove20Text'] }}</p>
            <loader v-if="loanCalculatorLoading" />
            <span class="loanAmount" data-test-id="payment-form-monthly-payment">{{ monthlyPayment }}</span>
            <div class="loanTable">
              <div>{{ t['loanAmount'] }}</div>
              <div>{{ t['term'] }}</div>
              <div>APR</div>
            </div>
            <div class="loanTable bolded">
              <div data-test-id="payment-form-loan-amount">{{ formatCurrency('loanAmount') }}</div>
              <div data-test-id="payment-form-payment-term">{{ paymentTerms }}</div>
              <div data-test-id="payment-form-apr">{{ formatWithPercent('apr') }}</div>
            </div>
            <div class="disclaimer" v-html="disclaimer" data-test-id="payment-form-disclaimer" />
            <span>{{ t['financingProvidedBy'] }} <img :src="santander" alt="santander" /></span>
          </div>
        </div>
        <div class="financingDetails">
          <div class="titleRow">
            <h4>{{ t['financingDetails'] }}</h4>
            <div @click="showDetails()" data-test-id="payment-form-financial-details">{{ isFinancingDetailsShown ? t['hideFinancingDetails'] : t['showFinancingDetails'] }}</div>
          </div>
          <div class="details" v-if="isFinancingDetailsShown">
            <div>
              <div>{{ t['aopBeforeTax'] }}</div>
              <div class="bolded" data-test-id="payment-form-aop-before-tax">{{ calcAndFormatPercent('aopBeforeTax') }}</div>
            </div>

            <div>
              <div>{{ t['downPaymentPct'] }}</div>
              <div class="bolded" data-test-id="payment-form-down-payment-detail">{{ calcAndFormatPercent('downPaymentPct') }}</div>
            </div>

            <div>
              <div>{{ t['financedAmount'] }}</div>
              <div class="bolded" data-test-id="payment-form-down-payment-pct">{{ calcAndFormatPercent('downPaymentPct') }}</div>
            </div>

            <div>
              <div>{{ t['nominalInterest'] }}</div>
              <div class="bolded" data-test-id="payment-form-nominal-inerest-rate">{{ calcAndFormatPercent('nominalInterestRate') }}</div>
            </div>

            <div>
              <div>{{ t['totalRepay'] }}</div>
              <div class="bolded" data-test-id="payment-form-down-payment">{{ formatCurrency('downPayment') }}</div>
            </div>

            <div>
              <div>{{ t['baseAmount'] }}</div>
              <div class="bolded" data-test-id="payment-form-loan-amount-detail">{{ formatCurrency('loanAmount') }}</div>
            </div>

            <div>
              <div>{{ t['totalLoanCosts'] }}</div>
              <div class="bolded" data-test-id="payment-form-total-loan-cost">{{ formatCurrency('totalLoanCost') }}</div>
            </div>

            <div>
              <div>{{ t['interestType'] }}</div>
              <div class="bolded" data-test-id="payment-form-interest-type">{{ paymentModel.financing.interestType }}</div>
            </div>

            <div>
              <div>{{ t['interestRate'] }}</div>
              <div class="bolded" data-test-id="payment-form-interest-rate">{{ calcAndFormatPercent('interestRate') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="paymentModel.type === PaymentMethods.bankTransfer">
        <div class="bankTransferTitle">
          <h3>{{ t['overview'] }}</h3>
          <p>{{ t['overviewTitle'] }}</p>
        </div>
        <div class="bankTransferOverview">
          <div class="bankTransferRow">
            <span data-test-id="payment-form-brand-model">{{ brand }} {{ model }}</span>
            <span data-test-id="payment-form-retail-price">{{ retailPriceString }}</span>
          </div>

          <div class="bankTransferRow">
            <span>
              {{ t['tradeInValue'] }}
            </span>
            <span>{{ willTradeIn ? 'TBD' : t['noTradeinAdded'] }}</span>
          </div>

          <hr />
          <div class="bankTransferRow total">
            <span>{{ t['totalToBePaid'] }}</span>
            <span data-test-id="payment-form-total-to-be-paid">{{ totalToBePaid }}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { lang } from '../../lang'
import Loader from '../../Loader.ce.vue'
import InputWError from '../components/InputWError.ce.vue'
import Radio from '../components/Radio.ce.vue'
import Tooltip from '../components/Tooltip.ce.vue'
import { santander } from '../../../assets/images'
const PaymentMethods = Object.freeze({ bankLoan: 'financing', bankTransfer: 'bankTransfer' })

export default {
  name: 'PaymentSection',
  components: { Loader, InputWError, Radio, Tooltip },
  mixins: [lang],
  props: {
    listing: {
      type: Object,
      required: true
    },
    currentStage: { type: String, default: '' },
    state: {
      type: Object,
      default: () => {
        return {}
      }
    },
    willTradeIn: { type: Boolean, default: false },
    details: {
      type: Object,
      default: () => {
        return { currency: 'kr' }
      }
    }
  },
  data() {
    return {
      paymentModel: {
        financing: {
          downPayment: null,
          paymentTerms: null,
          returnUrl: null,
          state: null,
          interestType: null
        },
        downPayment: null,
        paymentTerms: null
      },
      PaymentMethods,
      loanCalculatorLoading: false,
      isFinancingDetailsShown: false,
      elementHeight: null
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    brand() {
      return this.listing?.brand?.name || '-'
    },
    model() {
      return this.listing?.model?.name || '-'
    },
    retailPriceString() {
      if (this.listing.priceRetail == null) return null
      return this.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    totalToBePaid() {
      if (this.willTradeIn) return 'TBD'
      return this.retailPriceString
    },
    monthlyPayment() {
      if (!this.paymentModel?.financing?.monthlyPayment) return '-'
      return this.paymentModel?.financing?.monthlyPayment?.toLocaleString(this.language) + ' ' + this.currency + ' / ' + this.t.month
    },
    loanAmount() {
      if (!this.paymentModel?.financing?.loanAmount) return '-'
      return this.paymentModel?.financing?.loanAmount?.toLocaleString(this.language) + ' ' + this.currency
    },
    paymentTerms() {
      if (!this.paymentModel?.financing?.paymentTerms) return '-'
      return this.paymentModel?.financing?.paymentTerms + this.t.months
    },
    disclaimer() {
      return this.paymentModel?.financing?.disclaimer || ''
    },
    financingSubmitted() {
      return this.paymentModel.financing?.state === 'submitted'
    },
    durations() {
      return [12, 24, 36, 48, 60, 72, 84, 96]
    },
    santander() {
      return santander
    }
  },
  watch: {
    state: {
      handler() {
        this.setDefaultState()
      },
      deep: true
    },
    currentStage: {
      handler(newValue, oldValue) {
        if (oldValue === 'payment' && newValue !== 'payment') {
          this.validateForm()
          this.$emit('update', 'payment', this.paymentModel)
        }
      }
    },
    'paymentModel.type'() {
      const height = this.$refs.payment.clientHeight
      this.elementHeight = height * 1.1 + 'px'
    }
  },
  mounted() {
    this.setDefaultState()
  },
  methods: {
    validateForm() {
      // this.$refs.paymentForm?.reportValidity()
    },
    formChanges() {
      this.loanCalculatorLoading = true
      this.validateForm()
      this.$emit('update', 'payment', this.paymentModel, 'payment')
    },
    setDuration(value) {
      this.paymentModel.financing.paymentTerms = value
      this.formChanges()
    },
    setDefaultState() {
      this.paymentModel = { ...this.state, financing: { ...this.state.financing } }
      if (!this.paymentModel.financing) {
        this.paymentModel.financing = {
          downPayment: null,
          paymentTerms: null,
          returnUrl: null
        }
      }
      this.loanCalculatorLoading = false
    },
    showDetails() {
      this.isFinancingDetailsShown = !this.isFinancingDetailsShown
    },
    calcAndFormatPercent(name) {
      if (!this.paymentModel?.financing?.[name]) return '-'
      return (this.paymentModel?.financing?.[name] / 100).toFixed(2) + '%'
    },
    formatWithPercent(name) {
      if (!this.paymentModel?.financing?.[name]) return '-'
      return this.paymentModel?.financing?.[name]?.toFixed(2) + '%'
    },
    formatCurrency(name) {
      if (!this.paymentModel?.financing?.[name]) return '-'
      return this.paymentModel?.financing?.[name]?.toLocaleString(this.language, { maximumFractionDigits: 2, minimumFractionDigits: 0 }) + ' ' + this.currency
    }
  }
}
</script>
<style lang="scss" scoped>
#payment {
  justify-content: start !important;
  &.fixedHeight {
    height: v-bind(elementHeight);
  }
  .paymentInformation {
    background: #f6f6f6;
    border-radius: 12px;
    display: flex;
    padding: 1rem;
    .paymentInformationBody {
      > h5 {
        margin: 0;
      }
    }
  }

  .paymentMethodOptions {
    display: flex;
    gap: 14px;
    justify-content: center;
    .radio {
      width: 10rem;
      flex-grow: 0;
      label {
        background: #fff;
        padding: 0.75rem 1.25rem;
        border-radius: 2em;
        cursor: pointer;
        color: #444;
        transition: box-shadow 400ms ease;
        font-size: 14px;
        border: 1px solid #1a2634;
        &:hover {
          opacity: 0.8;
        }
      }

      input[type='radio'] {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      input[type='radio']:checked + label {
        background: #000000;
        border: 1px solid #1a2634;
        border-radius: 2em;
      }
    }
  }
  .loanCalculatorContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
  }
  .loanCalculator {
    display: flex;
    flex-direction: column;
    > span > img {
      width: 10rem;
    }
  }
  .financingDetails {
    > .titleRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px;
      > h4 {
        margin: 0;
      }
      > div {
        color: blue;
        border-radius: 0.3125rem;
        padding: 0.3125rem;
        cursor: pointer;
        &:hover {
          background: rgb(219, 237, 255);
        }
      }
    }
    > .details {
      border: 1px rgb(223, 223, 223) solid;
      padding: 1.25rem;
      border-radius: 0.3125rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
      grid-gap: 0.875rem;
      width: 100%;
      > div {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px #d9d9d9 solid;
        padding: 12px 0;
      }
    }
  }
  .bankTransferTitle {
    border-top: 2px solid #e4e4e4;
    margin-top: 2rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > h3 {
      font-weight: 700;
      font-size: 1.5em;
      line-height: 22px;
      width: fit-content;
      margin: 1rem;
    }
    > p {
      font-size: 0.8em;
      line-height: 22px;
      width: fit-content;
    }
  }
  .bankTransferOverview {
    border-radius: 2em;
    border-radius: 2em;
    border: 1px solid #e4e4e4;
    padding: 1.2rem;
    border-radius: 2em;
    border: 1px solid #e4e4e4;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 12px;
    > div.bankTransferRow {
      display: flex;
      justify-content: space-between;
    }
    > div.total {
      font-weight: 700;
    }
    > hr {
      border: 1px solid #e4e4e4;
      width: 100%;
    }
  }
  .questionInfo {
    position: relative;
  }
  .questionInfo > span {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    background-color: #cce1ff;
  }
  .questionInfo:hover > span {
    visibility: visible;
  }
  .loadDurations {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
    > div {
      background: #eee;
      padding: 20px;
      border-radius: 6px;
      width: 2rem;
      display: flex;
      height: 2rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: #ddd;
      }
    }
    > div.selected {
      background: #0068ff;
      color: white;
      &:hover {
        background: #0051c7;
      }
    }
  }
  .loanSummary {
    background: #e8f2ff;
    border-radius: 10px;
    padding: 0.75rem;
    > h5 {
      margin: 0;
    }
    > p {
      margin: 0;
    }
    .loanAmount {
      font-family: 'Biennale';
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 5.3125rem;
      display: flex;
      align-items: center;
      background: linear-gradient(147.27deg, #0068ff 23.48%, #9e80ff 92.92%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .loanTable {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .disclaimer {
      margin: 1.25rem 0;
    }
    img {
      width: 8.75rem;
    }
  }
  .bolded {
    font-weight: 700;
  }
  @media screen and (max-width: 56rem) {
    .loanCalculatorContainer {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
