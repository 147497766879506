<template>
  <button :class="outline ? 'outline button' : 'button'" v-bind="$attrs"><slot/></button>
</template>
<script>
export default {
  name: 'SeezButton',
  props: {
    outline: { type: Boolean, default: false }
  }
}
</script>
<style lang="scss" scoped>
.button {
  background: #0068ff;
  border-radius: 68px;
  padding: 0.75rem 1.25rem;
  border-radius: 2em;
  cursor: pointer;
  transition: box-shadow 400ms ease;
  font-size: 14px;
  border: none;
  display: flex;
  justify-content: center;
  color: white;
  &:disabled {
    opacity: 0.3;
    background: #ccc;
    color: #444;
  }
  &:hover {
    opacity: 0.8;
  }
  &.outline {
    background: white;
    color: #0068ff;
    border: 1px solid #0068ff;
  }
}
</style>
