<template>
  <div id="carDelivery">
    <form ref="deliveryForm" data-test-id="delivery-form">
      <h3>{{ t['carDelivery'] }}</h3>
      <p>{{ t['getYourCarDelivered'] }}</p>
      <h5>{{ t['deliveryWhen'] }}</h5>
      <div class="deliveryDate">
        <InputWError
          :placeholder="t['date']"
          v-model="deliveryModel.date"
          type="date"
          :min="new Date().toISOString().split('T')[0]"
          :error="inputErrors.delivery_date"
          data-test-id="delivery-form-date"
        />
        <InputWError
          :placeholder="t['time']"
          v-model="deliveryModel.time"
          type="time"
          :min="new Date().toISOString().split('T')[0]"
          :error="inputErrors.delivery_date"
          data-test-id="delivery-form-time"
        />
      </div>
      <h5>{{ t['deliveryWhere'] }} <tooltip :text="t['accidentMajorAccidents']" /></h5>
      <div class="chooseAddress">
        <div v-if="personalInformation?.address?.street" @click="selectAddress(true)" :class="isMyAddress ? 'selected' : ''">
          <img :src="homeIcon" />
          <div class="option">
            <div>{{ t['myAddress'] }}</div>
            <div>
              {{ personalInformation?.address?.street + ' ,' + personalInformation?.address?.postalCode + ' ,' + personalInformation?.address?.city + ' ,' + personalInformation?.address?.country }}
            </div>
          </div>
          <img :src="isMyAddress ? selectedCircle : unselectedCircle" />
        </div>

        <div @click="selectAddress(false)" :class="isMyAddress ? '' : 'selected'">
          <img :src="newHomeIcon" />
          <div class="option">{{ t['addNewAddress'] }}</div>
          <img :src="isMyAddress ? unselectedCircle : selectedCircle" />
        </div>
      </div>
      <div class="deliveryAddress" v-if="!isMyAddress">
        <InputWError
          class="textInput"
          :placeholder="t['postalCode']"
          v-model="deliveryModel.address.postalCode"
          type="text"
          :error="inputErrors.address_postalCode"
          data-test-id="delivery-form-postal-code"
        />
        <InputWError class="textInput" :placeholder="t['city']" v-model="deliveryModel.address.city" type="text" :error="inputErrors.address_city" data-test-id="delivery-form-city" />
        <InputWError
          class="textInput"
          :placeholder="t['country']"
          v-model="deliveryModel.address.country"
          disabled
          type="text"
          :error="inputErrors.address_country"
          data-test-id="delivery-form-country"
        />
        <InputWError
          class="textInput fullWidth"
          :placeholder="t['addressLine2']"
          v-model="deliveryModel.address.street2"
          type="text"
          :error="inputErrors.address_street2"
          data-test-id="delivery-form-street2"
        />
        <InputWError
          class="textInput fullWidth"
          :placeholder="t['addressLine1']"
          v-model="deliveryModel.address.street"
          type="text"
          :error="inputErrors.address_street"
          data-test-id="delivery-form-street1"
        />
      </div>
    </form>
    <img class="scrollDown" :src="scrollDown" @click="gotoNextSection" />
  </div>
</template>
<script>
import { lang } from '../../lang'
import Tooltip from '../components/Tooltip.ce.vue'
import InputWError from '../components/InputWError.ce.vue'
import { homeIcon, newHomeIcon, selectedCircle, unselectedCircle, scrollDown } from '../../../assets/images'

const inputFieldErrorsDefaultState = {
  delivery_date: '',
  address_postal_code: '',
  address_city: '',
  address_street: ''
}

export default {
  name: 'CarDelivery',
  components: { Tooltip, InputWError },
  mixins: [lang],
  props: { currentStage: { type: String, default: '' }, state: { type: Object, required: true }, personalInformation: { type: Object, default: null }, errors: { type: Array, default: () => [] } },
  data() {
    return {
      deliveryModel: {
        date: null,
        time: null,
        address: {
          postalCode: '',
          city: '',
          country: '',
          street2: '',
          street: ''
        }
      },
      inputErrors: { ...inputFieldErrorsDefaultState },
      isMyAddress: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    newHomeIcon() {
      return newHomeIcon
    },
    homeIcon() {
      return homeIcon
    },
    selectedCircle() {
      return selectedCircle
    },
    unselectedCircle() {
      return unselectedCircle
    },
    scrollDown() {
      return scrollDown
    }
  },
  watch: {
    state: {
      handler() {
        this.setDefaultState()
      },
      deep: true
    },
    currentStage: {
      handler(newValue, oldValue) {
        if (oldValue === 'delivery' && newValue !== 'delivery') {
          this.validateForm()
          this.$emit('update', 'delivery', this.deliveryModel)
        }
      }
    },
    errors: {
      handler(errors) {
        this.inputErrors = { ...inputFieldErrorsDefaultState }
        errors.forEach(error => {
          const field = error.split('not')[0].replace('.', '_').replace(' ', '_')?.toLowerCase()?.trim()
          this.inputErrors[field] = error
        })
        this.validateForm()
      }
    },
    personalInformation: {
      handler(info) {
        if (info.address?.postalCode) {
          this.isMyAddress = true
        }
      }
    }
  },
  mounted() {
    this.setDefaultState()
  },
  methods: {
    setDefaultState() {
      let time = null
      let date = null
      if (this.state.preferredDate) {
        ;[date, time] = this.state.preferredDate.split('T')
        time = time.split('.')[0]
      }
      this.deliveryModel = { ...this.state, date, time, address: { ...this.state.address } }
      if (this.personalInformation?.address?.postalCode) this.isMyAddress = true
      if (!this.deliveryModel.address) {
        this.deliveryModel.address = {
          postalCode: '',
          city: '',
          country: '',
          street2: '',
          street: ''
        }
      }
    },
    selectAddress(isMyAddress) {
      this.isMyAddress = isMyAddress
      if (isMyAddress) {
        if (this.personalInformation) {
          if (!this.deliveryModel.address.postalCode) {
            this.deliveryModel.address.postalCode = this.personalInformation.address.postalCode
          }
          if (!this.deliveryModel.address.city) {
            this.deliveryModel.address.city = this.personalInformation.address.city
          }
          if (!this.deliveryModel.address.country) {
            this.deliveryModel.address.country = this.personalInformation.address.country
          }
          if (!this.deliveryModel.address.street2) {
            this.deliveryModel.address.street2 = this.personalInformation.address.street2
          }
          if (!this.deliveryModel.address.street) {
            this.deliveryModel.address.street = this.personalInformation.address.street
          }
        }
        this.isMyAddress = isMyAddress
      }
    },
    gotoNextSection() {
      this.$emit('gotoComponent', 'personalInfo')
    }
  }
}
</script>
<style lang="scss" scoped>
#carDelivery {
  > form{
    width: 100%;
  }
  .deliveryAddress {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.75rem;
    > input {
      width: auto;
    }
    .fullWidth {
      width: 100%;
    }
  }
  .deliveryDate {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 0.75rem;
  }
  .chooseAddress {
    display: flex;
    gap: 1rem;
    > div {
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      padding: 0.75rem 25px;
      display: flex;
      gap: 1rem;
      margin: 0.75rem 0;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      flex: 1;
      &.selected {
        border: 2px solid #0068ff;
      }
      .option {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: #000000;
        flex: 1;
      }
      img {
        height: 100%;
      }
    }
  }
  .scrollDown {
    margin: 4rem auto;
    cursor: pointer;
  }
  @media screen and (max-width: 56rem) {
    .deliveryAddress > .textInput {
      width: 100%;
    }
  }
}
</style>
