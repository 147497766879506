<template>
  <div class="filtersPanel">
    <h2>
      <span>{{ `${t['advanced_filters']} ${selectedFilterCount}` }}</span>
      <span v-if="isFilterSelected" class="clearFilters" @click="clearFilters">
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.75 11.375C0.75 12.0078 1.24219 12.5 1.875 12.5H8.625C9.23438 12.5 9.75 12.0078 9.75 11.375V3.5H0.75V11.375ZM7.125 5.375C7.125 5.1875 7.28906 5 7.5 5C7.6875 5 7.875 5.1875 7.875 5.375V10.625C7.875 10.8359 7.6875 11 7.5 11C7.28906 11 7.125 10.8359 7.125 10.625V5.375ZM4.875 5.375C4.875 5.1875 5.03906 5 5.25 5C5.4375 5 5.625 5.1875 5.625 5.375V10.625C5.625 10.8359 5.4375 11 5.25 11C5.03906 11 4.875 10.8359 4.875 10.625V5.375ZM2.625 5.375C2.625 5.1875 2.78906 5 3 5C3.1875 5 3.375 5.1875 3.375 5.375V10.625C3.375 10.8359 3.1875 11 3 11C2.78906 11 2.625 10.8359 2.625 10.625V5.375ZM10.125 1.25H7.3125L7.07812 0.828125C6.98438 0.640625 6.79688 0.5 6.58594 0.5H3.89062C3.67969 0.5 3.49219 0.640625 3.39844 0.828125L3.1875 1.25H0.375C0.164062 1.25 0 1.4375 0 1.625V2.375C0 2.58594 0.164062 2.75 0.375 2.75H10.125C10.3125 2.75 10.5 2.58594 10.5 2.375V1.625C10.5 1.4375 10.3125 1.25 10.125 1.25Z"
            fill="#333333"
          />
        </svg>
        {{ t['clear_all'] }}
      </span>

      <button @click="$emit('close')">{{ `${t['search_vehicles']} ${totalResult}` }}</button>
    </h2>
    <div class="tags" v-if="options">
      <span v-for="tag in tags.filter(t => t.label)" :key="tag.id + '_' + tag.value">
        {{ tag.label }}
        <i>&times;</i>
        <span class="closeBtn" @click="removeFilter(tag)" />
      </span>
    </div>
    <input type="search" v-model.trim="freeTextFilter" @search="searchText" :placeholder="t['search_vehicles']" data-test-id="filter_freetext_field" />
    <h3>{{ t['brand'] }} &amp; {{ t['model'] }}</h3>
    <multi-select :multi="false" :clear-placeholder="t['all_makes']" :options="options.brands" v-model.number="selectedFilters.brands" :placeholder="t['select_make']" test-id="car_make" />
    <multi-select :multi="true" :options="modelsOptions" v-model="selectedFilters.models" v-if="selectedFilters.brands" :placeholder="t['select_model']" test-id="car_model" />
    <h3>{{ t['year'] }}</h3>
    <multi-select class="half" :multi="false" :options="yearsFrom" v-model.number="selectedFilters.yearMin" :placeholder="t['from']" test-id="year_from" />
    <multi-select class="half" :multi="false" :options="yearsTo" v-model.number="selectedFilters.yearMax" :placeholder="t['to']" test-id="year_to" />
    <h3>{{ t['kilometrage'] }}</h3>
    <input type="text" v-model="kmMaxString" :placeholder="t['max_kilometrage']" data-test-id="milage" />
    <h3>{{ t['price'] }}</h3>
    <multi-select
      :multi="false"
      :is-default-selected="true"
      :options="localizedPriceTypes"
      v-model="selectedFilters.priceType"
      :required="true"
      class="half"
      id="priceTypeSelector"
      test-id="payment_type"
    />
    <input type="text" v-model="priceMaxString" :placeholder="t['max_budget']" class="half" id="priceSelector" test-id="max_budget" />
    <h3>{{ t['engine_size'] }}</h3>
    <multi-select :multi="false" :options="options.engines" v-model="selectedFilters.engineSizeMax" :placeholder="t['any']" test-id="engine_size" />
    <h3>{{ t['body_type'] }}</h3>
    <div class="bodies">
      <button v-for="(c, i) in options.bodyTypes" :key="c.id" @click="toggleBody(c.id)" :class="{ selected: selectedFilters.bodyTypes.includes(c.id) }" :data-test-id="'filter_body_option_' + (i + 1)">
        <img :src="getBodyTypeAsset(c.name)" :alt="c.name" />
        <span>{{ t[`${c.name}`] }}</span>
      </button>
    </div>
    <h3>{{ t['transmission'] }}</h3>
    <multi-select :multi="false" :options="options.transmissions" v-model.number="selectedFilters.transmissions" :placeholder="t['any']" test-id="transmission" />
    <h3>{{ t['fuel_type'] }}</h3>
    <multi-select :multi="true" :options="options.fuelTypes" v-model="selectedFilters.fuelTypes" :placeholder="t['any']" test-id="fuel_type" />
    <h3>{{ t['drive_type'] }}</h3>
    <multi-select :multi="true" :options="localizedDrives" v-model="selectedFilters.driveTypes" :placeholder="t['any']" test-id="drive_type" />
    <h3>{{ t['color'] }}</h3>
    <div class="colors">
      <label v-for="(c, i) in options.colors" :key="c.id">
        <button
          :style="colorBackground(c.color)"
          @click="toggleColor(t[c.id])"
          :class="{ selected: selectedFilters.colors.includes(t[c.id]) }"
          :data-test-id="'filter_color_option_' + (i + 1)"
          :aria-label="c.name"
        >
          &nbsp;
        </button>
        {{ t[c.id] }}
      </label>
    </div>
    <h3>{{ t['number_of_doors'] }}</h3>
    <multi-select :multi="false" :options="options.doors" v-model.number="selectedFilters.doors" :placeholder="t['any']" test-id="number_of_doors" />
    <button @click="saveSearch" class="saveSearch" :disabled="Object.keys(modelValue).length === 0" test-id="save_search">
      <svg width="24" height="14" viewBox="4 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.75 14.7188L11.5625 10.5312C12.5 9.38542 12.9792 8.04167 13 6.5C12.9583 4.66667 12.3229 3.13542 11.0938 1.90625C9.86458 0.677083 8.33333 0.0416667 6.5 0C4.66667 0.0416667 3.13542 0.677083 1.90625 1.90625C0.677083 3.13542 0.0416667 4.66667 0 6.5C0.0416667 8.33333 0.677083 9.86458 1.90625 11.0938C3.13542 12.3229 4.66667 12.9583 6.5 13C8.0625 12.9792 9.40625 12.5104 10.5312 11.5938L14.7188 15.7812C14.8646 15.9271 15.0417 16 15.25 16C15.4583 16 15.6354 15.9271 15.7812 15.7812C16.0729 15.4271 16.0625 15.0729 15.75 14.7188ZM6.5 11.5C5.08333 11.4583 3.90625 10.9688 2.96875 10.0312C2.03125 9.09375 1.54167 7.91667 1.5 6.5C1.54167 5.08333 2.03125 3.90625 2.96875 2.96875C3.90625 2.03125 5.08333 1.54167 6.5 1.5C7.91667 1.54167 9.09375 2.03125 10.0312 2.96875C10.9688 3.90625 11.4583 5.08333 11.5 6.5C11.4583 7.91667 10.9688 9.09375 10.0312 10.0312C9.09375 10.9688 7.91667 11.4583 6.5 11.5ZM9 5.75H7.21875V4C7.17708 3.54167 6.9375 3.29167 6.5 3.25C6.29167 3.25 6.11458 3.32292 5.96875 3.46875C5.82292 3.61458 5.75 3.79167 5.75 4V5.75H3.96875C3.53125 5.79167 3.29167 6.04167 3.25 6.5C3.29167 6.95833 3.53125 7.20833 3.96875 7.25H5.75V9C5.79167 9.45833 6.03125 9.70833 6.46875 9.75C6.92708 9.70833 7.16667 9.45833 7.1875 9V7.25H8.9375C9.4375 7.20833 9.70833 6.95833 9.75 6.5C9.70833 6.04167 9.45833 5.79167 9 5.75Z"
        />
      </svg>
      {{ t['save_this_search'] }}
    </button>
    <div class="modalSearchName" v-if="showSaveSearchModal">
      <dialog open>
        <i @click="cancelSaveSearch" test-id="cancel_save">&times;</i>
        <h1>{{ t['save_search'] }}</h1>
        <p>{{ t['save_filter_find_later'] }}</p>
        <input type="text" maxlength="100" v-model="searchName" ref="searchNameInput" name="name" placeholder="Search name" test-id="save_name" />
        <button @click="confirmSaveSearch" test-id="save_this_search" :disabled="searchName === ''">{{ t['save_this_search'] }}</button>
        <Loader v-if="saving" />
      </dialog>
    </div>
  </div>
</template>

<script>
import { debounce } from '../../logic'
import Loader from '../Loader.ce.vue'
import { formatFilterForApi } from '../../logic.js'
import SeezSdk from '../../sdk.js'
import MultiSelect from '../MultiSelect.ce.vue'
import { lang } from '../lang'

export default {
  name: 'SeezFilterPanel',
  components: { Loader, MultiSelect },
  mixins: [lang, SeezSdk.vueSavedSearchesMixin],
  props: {
    modelValue: { type: Object, required: true },
    options: { type: Object, required: true },
    tags: { type: Array, required: true },
    preload: { type: Number, default: null }, // Saved search id
    totalResult: { type: String, default: '' }
  },
  data() {
    return {
      freeTextFilter: this.modelValue.freeText ?? '',
      selectedFilters: {
        freeText: this.modelValue.freeText ?? '',
        brands: this.modelValue.brands ?? null,
        models: [...(this.modelValue.models ?? []).map(m => `M${m}`), ...(this.modelValue.families ?? []).map(m => `F${m}`)],
        kilometrageMax: this.modelValue.kilometrageMax ?? null,
        priceMax: this.modelValue.priceMax ?? null,
        priceType: 'retail',
        yearMin: this.modelValue.yearMin ?? null,
        yearMax: this.modelValue.yearMax ?? null,
        bodyTypes: this.modelValue.bodyTypes ?? [],
        fuelTypes: this.modelValue.fuelTypes ?? [],
        transmissions: this.modelValue.transmissions ?? null,
        colors: [],
        doors: this.modelValue.doors ?? null,
        driveTypes: this.modelValue.driveTypes ?? [],
        engineSizeMax: this.modelValue.engineSizeMax ?? null
      },
      kmMaxString: this.modelValue?.kilometrageMax?.toString() ?? '',
      priceMaxString: this.modelValue?.priceMax?.toString() ?? '',
      searchName: '',
      showSaveSearchModal: false,
      saving: false,
      selectedSavedSearch: null
    }
  },
  computed: {
    t() {
      return this.languageResources.FILTER_PANEL_COMPONENT_TRANSLATIONS
    },
    localizedPriceTypes() {
      return this.options.priceTypes.map(pt => ({ id: pt.id, name: this.t[pt.name] }))
    },
    modelsOptions() {
      const families = this.options.families.filter(x => x.brandid === this.selectedFilters.brands)
      return families.map(f => ({ id: `F${f.id}`, name: f.name, count: f.count, children: f.models.map(m => ({ id: `M${m.id}`, name: m.name, count: m.count })) }))
    },
    yearsFrom() {
      if (this.selectedFilters.yearMax) return this.options.years.filter(y => y.id <= this.selectedFilters.yearMax)
      else return this.options.years
    },
    yearsTo() {
      if (this.selectedFilters.yearMin) return this.options.years.filter(y => y.id >= this.selectedFilters.yearMin)
      else return this.options.years
    },
    selectedFilterCount() {
      return this.tags.length > 0 ? `(${this.tags.length})` : ''
    },
    isFilterSelected() {
      return this.tags.length > 0
    },
    localizedDrives() {
      if (this.options == null || this.options.drives == null) return []
      return this.options.drives.map(d => ({ id: d.id, name: this.t[`drive_type_${d.id}`], count: d.count }))
    }
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler(newValue) {
        const entries = Object.entries(newValue).filter(e => e[1] != null && e[1] !== '' && (!Array.isArray(e[1]) || e[1].length > 0))
        entries.sort((a, b) => (a[0] > b[0] ? 1 : -1))
        for (const pair of entries) {
          if (Array.isArray(pair[1])) {
            pair[1] = [...pair[1]]
            pair[1].sort()
          }
        }
        const cleanFilters = Object.fromEntries(entries)
        if (cleanFilters.freeText === '') delete cleanFilters.freeText
        if (cleanFilters.priceMin == null && cleanFilters.priceMax == null) delete cleanFilters.priceType
        if (newValue.models && newValue.models.length > 0) {
          cleanFilters.families = newValue.models.filter(m => m.startsWith('F')).map(f => parseInt(f.substring(1)))
          if (cleanFilters.families.length === 0) delete cleanFilters.families
          cleanFilters.models = newValue.models.filter(m => m.startsWith('M')).map(f => parseInt(f.substring(1)))
          if (cleanFilters.models.length === 0) delete cleanFilters.models
        }
        if (JSON.stringify(this.modelValue) !== JSON.stringify(cleanFilters)) {
          delete cleanFilters.page
          this.$emit('update:modelValue', cleanFilters)
        }
      }
    },
    'selectedFilters.priceMax'(v) {
      this.priceMaxString = v != null && v > 0 ? v.toString() : ''
    },
    'selectedFilters.kilometrageMax'(v) {
      this.kmMaxString = v != null && v > 0 ? v.toString() : ''
    },
    'selectedFilters.freeText'(v) {
      const cleanValue = v?.trim().replace(/\s{2,}/, ' ') ?? ''
      const oldValue = this.freeTextFilter?.trim().replace(/\s{2,}/, ' ') ?? ''
      if (cleanValue !== oldValue) this.freeTextFilter = cleanValue
    },
    'selectedFilters.brands'(v, o) {
      if (v !== o) {
        this.selectedFilters.models = []
      }
    },
    modelValue: {
      deep: true,
      handler(newValue) {
        this.compileFilters(newValue)
      }
    },
    selectedSavedSearch(v) {
      if (v) {
        this.applySearch(parseInt(v))
        this.$nextTick(() => (this.selectedSavedSearch = null))
      }
    },
    kmMaxString: debounce(function (v) {
      try {
        let newValue = v == null || v === '' ? null : parseInt(v)
        if (newValue === 0) newValue = null
        if (this.selectedFilters.kilometrageMax != newValue) this.selectedFilters.kilometrageMax = newValue
      } catch (error) {
        //ignore
      }
    }, 750),
    priceMaxString: debounce(function (v) {
      try {
        let newValue = v == null || v === '' ? null : parseInt(v)
        if (newValue === 0) newValue = null
        if (this.selectedFilters.priceMax != newValue) this.selectedFilters.priceMax = newValue
      } catch (error) {
        //ignore
      }
    }, 750),
    freeTextFilter: debounce(function (v) {
      this.selectedFilters.freeText = v
    }, 750)
  },
  mounted() {
    this.compileFilters(this.modelValue)

    if (this.preload) this.applySearch(this.preload)
  },
  methods: {
    async searchText() {
      this.selectedFilters.freeText = this.freeTextFilter
    },
    clearFilters() {
      this.selectedFilters.freeText = ''
      this.selectedFilters.brands = []
      this.selectedFilters.models = []
      this.selectedFilters.yearMin = null
      this.selectedFilters.yearMax = null
      this.selectedFilters.priceMin = null
      this.selectedFilters.priceMax = null
      this.selectedFilters.priceType = 'retail'
      this.selectedFilters.kilometrageMin = null
      this.selectedFilters.kilometrageMax = null
      this.selectedFilters.bodyTypes = []
      this.selectedFilters.fuelTypes = []
      this.selectedFilters.transmissions = null
      this.selectedFilters.colors = []
      this.selectedFilters.driveTypes = []
      this.selectedFilters.doors = null
      this.selectedFilters.engineSizeMax = null
    },
    toggleColor(color) {
      if (this.selectedFilters.colors.includes(color)) this.selectedFilters.colors = this.selectedFilters.colors.filter(c => c != color)
      else {
        const newList = [...this.selectedFilters.colors, color]
        newList.sort()
        this.selectedFilters.colors = newList
      }
    },
    colorBackground(color) {
      return {
        //background: `radial-gradient(circle at 25% 25%, white 8%, ${color} 58%, black 100%)` // old 3d style :D
        background: color
      }
    },
    toggleBody(body) {
      if (this.selectedFilters.bodyTypes.includes(body)) this.selectedFilters.bodyTypes = this.selectedFilters.bodyTypes.filter(c => c != body)
      else {
        const newList = [...this.selectedFilters.bodyTypes, body]
        newList.sort()
        this.selectedFilters.bodyTypes = newList
      }
    },
    compileFilters(v) {
      const defaults = {
        freeText: '',
        brands: null,
        models: null,
        kilometrageMin: null,
        kilometrageMax: null,
        priceMin: null,
        priceMax: null,
        priceType: this.selectedFilters?.priceType ?? 'retail',
        yearMin: null,
        yearMax: null,
        bodyTypes: [],
        transmissions: null,
        fuelTypes: [],
        colors: [],
        doors: null,
        driveTypes: [],
        engineSizeMax: null
      }
      const compiled = Object.assign({}, defaults, v, { models: [...(v.models ?? []).map(m => `M${m}`), ...(v.families ?? []).map(m => `F${m}`)] })
      delete compiled.families
      for (const key in compiled) {
        if (this.selectedFilters[key] != compiled[key] && JSON.stringify(this.selectedFilters[key]) != JSON.stringify(compiled[key])) {
          this.selectedFilters[key] = compiled[key]
        }
      }
    },
    getBodyTypeAsset(name) {
      try {
        const cleanName = name
          .trim()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .replace(/\s+/g, '')
        return `${import.meta.env.VITE_IMAGES_URL}/body-types/${cleanName}.webp`
      } catch (error) {
        return null
      }
    },
    saveSearch() {
      this.showSaveSearchModal = true
      this.searchName = ''
      this.$nextTick(() => this.$refs.searchNameInput.focus())
    },
    async confirmSaveSearch() {
      this.saving = true
      this.addSearch(this.searchName, formatFilterForApi(this.modelValue))
      this.saving = false
      this.showSaveSearchModal = false
    },
    cancelSaveSearch() {
      this.showSaveSearchModal = false
    },
    removeFilter(tag) {
      return this.$emit('remove', { id: tag.id, keys: tag.keys, label: tag.label, value: tag?.value })
    },
    applySearch(id) {
      const search = this.savedSearches.find(s => parseInt(s.id) === id)
      if (search == null) return
      const f = search.filterParameters
      this.selectedFilters.brands = f.brands?.length > 0 ? f.brands[0] : null
      this.selectedFilters.models = f.models ?? []
      this.selectedFilters.yearMin = f.yearMin
      this.selectedFilters.yearMax = f.yearMax
      this.selectedFilters.priceMin = f.priceMin
      this.selectedFilters.priceMax = f.priceMax
      this.selectedFilters.priceType = f.priceType
      this.selectedFilters.kilometrageMin = f.kilometrageMin
      this.selectedFilters.kilometrageMax = f.kilometrageMax
      this.selectedFilters.bodyTypes = f.bodyTypes ?? []
      this.selectedFilters.fuelTypes = f.fuelTypes ?? []
      this.selectedFilters.transmissions = f.transmissions?.length > 0 ? f.transmissions[0] : null
      this.selectedFilters.colors = f.colors ?? []
      this.selectedFilters.driveTypes = f.driveTypes ?? []
      this.selectedFilters.doors = f.numberOfDoorsMin
      this.selectedFilters.engineSizeMax = f.engineSizeMax
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.filtersPanel {
  @include theme;
  @include scrollBars;

  color: #545454;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  align-self: stretch;
  overflow: hidden auto;
  padding: 0 1.5em 0 1.5em;
  padding-bottom: 3rem;
  background-color: var(--background);
  z-index: 1;

  @media (min-width: 48rem) {
    padding-bottom: 0;
  }

  > h2 {
    font-size: 1.563rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    margin: 0 -1em;
    padding: 0.5rem 1em 0.8rem 1em;

    > div {
      display: flex;
      gap: 1rem;
    }

    @media screen and (max-width: 42rem) {
      padding: 0.5rem 1em;
    }

    > button {
      font-size: 0.75em;
      display: none;
      border: none;
      background: var(--highlight);
      color: white;
      border-radius: 2em;
      padding: 0.5em 1.5em;
      margin: 0;
      cursor: pointer;
      font-family: 'Biennale', sans-serif;
      position: fixed;
      bottom: 1.5em;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      @media screen and (max-width: 42rem) {
        display: block;
      }
    }
  }

  > h3 {
    font-size: 0.8em;
    margin: 1.5em 0 0.5em 0;
    color: black;
  }

  > .tags {
    display: none;
    flex-wrap: wrap;
    padding: 0.5rem 0 0.5rem 0;
    margin: 0;
    overflow: hidden;
    gap: 1em;

    @media screen and (max-width: 42rem) {
      overflow-x: auto;
      flex-wrap: nowrap;
      display: flex;
    }

    > span {
      border: 1px solid var(--highlight);
      border-radius: 1em;
      padding: 0.25em 0.75em;
      color: var(--highlight);
      height: fit-content;
      font-size: 0.9em;
      white-space: nowrap;
      position: relative;

      > i {
        display: inline-block;
        margin-left: 0.5em;
        font-style: normal;
      }

      > .closeBtn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 1em;
        cursor: pointer;
      }
    }
  }

  input,
  select {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75em 0.75em 0.75em 1.75em;
    margin: 0.5em 0;
    background: white;
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    font-family: 'Biennale', sans-serif;
    color: #757575;
    font-size: 0.75em;

    @media screen and (max-width: 42rem) {
      font-size: 1em;
    }

    &:focus {
      outline: 1px solid var(--highlight);
    }

    &[value=''] {
      color: var(--highlight);
    }
  }

  .multiselect {
    margin: 0.5em 0;
  }

  .asymmetric {
    display: flex;
    gap: 0.5em;
    align-items: stretch;

    > input {
      flex: 1 1;
      margin: 0;
    }

    > button {
      background-color: var(--highlight);
      border: 1px solid var(--highlight);
      border-radius: 0.25em;
      font-size: 1em;
      padding: 0.25em 0.4em 0.125em;

      > img {
        display: block;
        height: 1em;
      }
    }
  }

  > .half {
    width: calc(50% - 0.5em);
    display: inline-block;
  }

  > .half + .half {
    margin-left: 1em;
  }

  > .half.left + .half.right {
    margin-left: 0;
  }

  > #priceTypeSelector {
    width: 45%;

    > button {
      border-radius: 2em 0 0 2em;
      color: white;
      background-color: #1a2634;

      > svg > path {
        fill: white !important;
      }
    }
  }

  > #priceSelector {
    width: 55%;
    margin-left: 0;
    border-radius: 0 2em 2em 0;
    padding-left: 1em;
    outline-offset: -1px;
  }

  > .bodies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.25em;
    margin: 0.5em 0;

    > button {
      font-size: x-small;
      border: 1px solid #cbcbcb;
      background-color: white;
      border-radius: 2em;
      padding: 1em 0 1em 0;
      cursor: pointer;
      font-family: 'Biennale', sans-serif;
      line-height: 2em;
      display: grid;
      grid-template-rows: 1fr auto;
      place-items: end center;
      height: 7em;
      color: black;

      > img {
        display: block;
        width: 4rem;
        height: auto;
        margin: 0 auto 0.3em;

        path {
          fill: red;
        }
      }

      > span {
        white-space: nowrap;
        text-decoration: none;

        > html {
          * {
            color: #000000;
          }
        }

        > i {
          line-height: 0.2;
          display: block;
        }

        .inline-block {
          display: inline-block;
        }
      }

      &.selected {
        border: 1px solid var(--highlight);
        background-color: #cce1ff80;
      }

      // &:hover {
      //   border: 1px solid #0068ff;
      //   background-color: #cce1ff80;
      //   color: black;

      //   > img {
      //     filter: none;
      //   }

      //   @media screen and (max-width: 42rem) {
      //     color: black;
      //     > img {
      //       filter: none;
      //     }
      //   }
      // }
      @media screen and (min-width: 42rem) {
        &:hover {
          border: 1px solid #0068ff;
        }
      }
    }
  }

  > .colors {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(2.5em, 1fr));
    justify-items: center;

    // display: flex;
    // flex-wrap: wrap;
    gap: 0.75em;
    margin: 0.5em 0;
    // justify-content: space-between;

    > label {
      font-size: 0.7em;
      text-align: center;
      white-space: nowrap;
      width: 3em;
      cursor: pointer;

      > button {
        border: 1px solid #cbcbcb;
        border-radius: 2em;
        width: 2.5em;
        height: 2.5em;
        margin: auto auto 0.4em auto;
        display: block;

        &.selected {
          outline: 3px solid var(--highlight);
          outline-offset: 2px;
          font-weight: bold;
        }
      }
    }
  }

  > .saveSearch {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75em;
    margin: 0.5em 0;
    background: white;
    border: 1px solid var(--highlight);
    color: var(--highlight);
    border-radius: 2em;
    font-family: 'Biennale', sans-serif;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      border-color: #cbcbcb;
      color: #cbcbcb;
      cursor: not-allowed;
      > svg {
        filter: saturate(0);
      }
    }

    > svg {
      vertical-align: middle;

      > path {
        fill: var(--highlight);
      }
    }
  }

  > .modalSearchName {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px) brightness(60%);
    z-index: 2;

    > dialog {
      text-align: center;
      position: relative;
      padding: 2em 3em;
      border: none;
      border-radius: 0.25em;
      background-color: white;

      > i {
        cursor: pointer;
        font-size: 2em;
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        line-height: 0.5em;
        font-style: normal;
      }

      > h1 {
        margin: 0;
      }

      > p {
        margin: 0.5em auto;
        width: 30ch;
      }

      > input {
        border: none;
        outline: none;
        background-color: #f6f6f6;
        border-radius: 2em;
      }

      > button {
        width: 100%;
        outline: none;
        box-sizing: border-box;
        padding: 0.75em;
        margin: 1em 0 0;
        background: white;
        border: 1px solid var(--highlight);
        color: var(--highlight);
        border-radius: 2em;
        font-family: 'Biennale', sans-serif;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      > .loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .clearFilters {
    font-size: 0.8rem;
    font-weight: 700;
    cursor: pointer;
    color: #1f1f1f;
    font-style: normal;
    background-color: #eeeeee;
    border-radius: 0.875rem;
    padding: 0.4rem 0.9rem;
    display: flex;
    align-items: center;
    margin-inline-end: 0;

    > svg {
      padding-right: 0.5rem;
    }
  }

  > .multiselect.mySearches {
    > button {
      border: none;
      color: var(--highlight);
      max-width: 100%;
      box-sizing: border-box;
      background: transparent;
      outline: none;
      padding: 0;
      margin-bottom: 1em;

      > svg {
        position: initial;
        font-size: 2em;
        margin-left: 0.25em;
        > path {
          fill: var(--highlight);
        }
      }

      // &::after {
      //   content: '  ˅';
      //   font-weight: 900;
      // }
    }
  }
}
</style>
