<template>
<div class="inputCode">
	  <input :disabled="disabled" :class="{errorFlag, disabled}" v-for="(item, index) in items" :key="index" type="text" maxlength="1" />
</div>
	
</template>

<script>
export default {
  name: 'InputCode',
  props: {
    length: { type: Number, required: true, default: 6 },
    error: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      items: [1,2,3,4,5,6],
      errorFlag: false,
    }
  },
  watch: {
    error(value){
      if(value) this.errorFlag = true
    }
  },
  mounted() {
	  const inputLength = Array.from(Array(this.length).keys())
    this.items = inputLength

    const inputs =  this.$el.querySelectorAll('input')
 
    inputs.forEach((inputs, i)=> {
      
      inputs.dataset.index = i
      inputs.addEventListener('paste', this.handleOnPasteCode)
      inputs.addEventListener('keyup', this.handleCodeInput)

    })
  },
	  methods: {
    handleOnPasteCode(e){
      const data = e.clipboardData.getData('text')
      const inputs = this.$el.querySelectorAll('input')
      const value = data.split('')

      if(value.length === inputs.length) {
        inputs.forEach((input, index)=> input.value = value[index])
        this.saveCode()
      }

    },
    saveCode(){
      const inputs =  this.$el.querySelectorAll('input')
      let code = ''
      inputs.forEach(input=> code += input.value )
      this.$emit('value', code)    
      
    },
    handleCodeInput(e){
      const inputs =  this.$el.querySelectorAll('input')
      const input = e.target
    
      let value = input.value
      input.value = ''
      input.value = value ? value[0] : ''

      const fieldIndex = input.dataset.index

      if(this.error) {
        inputs.forEach(input => input.value = '')
        
      }

      if(value.length > 0 && fieldIndex < inputs.length - 1 ) {
        input.nextElementSibling.focus()
      }

      if(e.key === 'Backspace' && fieldIndex > 0){
        input.previousElementSibling.focus()

        if(this.error) { 
          this.errorFlag = !this.errorFlag
          inputs[0].focus()
        }
      }

      if(fieldIndex == inputs.length - 1){
        this.saveCode()
      }
    },
  },

}
</script>

<style lang="scss">
.inputCode {
  width: 100%;
  display: flex;
  margin: auto;
  gap: 0.2rem;
  justify-content: space-around;
  

  @media (min-width: 768px) {
   gap: 0.6rem;
       }


   > input {
 width: 25px;
 height: 33px;
 border-radius: 8px;
 border: 1px solid #E2E2E2;
 font-size: 1.5rem;
 text-align: center;
 font-family: "Biennale", sans-serif;
 font-weight: 700;

   @media (min-width: 768px) {
  width: 46px;
  height: 46px;
  font-size: 2.1rem;

       }
}

> input:focus {
 outline: none;
 border: 1px solid #0068FF;
 box-shadow: 0px 0px 2px #0068FF;

}

> input.errorFlag{
  border: 1px solid red;
  box-shadow: 0px 0px 2px red;
  
}

> input.disabled {
  opacity: 0.5;
  background-color: lightgray;
  cursor: not-allowed;
}
 

}
</style>