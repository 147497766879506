<template>
  <div class="userProfile">
    <h2>{{ t['account_settings'] }}</h2>
    <form @submit.prevent="submitProfile">
      <fieldset>
        <legend>{{ t['personal_information'] }}</legend>
        <label>
          <span>{{ t['first_name'] }}</span>
          <input type="text" name="firstName" autocomplete="given-name" :value="user?.firstName" />
        </label>
        <label>
          <span>{{ t['middle_name'] }}</span>
          <input type="text" name="middleName" :value="user?.middleName" />
        </label>
        <label>
          <span>{{ t['last_name'] }}</span>
          <input type="text" name="lastName" autocomplete="family-name" :value="user?.lastName" />
        </label>
        <label>
          <span>{{ t['email'] }}</span>
          <input type="email" name="email" autocomplete="email" :value="user?.email" disabled />
        </label>
        <label>
          <span>{{ t['phone'] }}</span>
          <input type="tel" name="phone" autocomplete="tel" :value="user?.phone" />
        </label>
      </fieldset>
      <input type="submit" :value="t['save']" />
    </form>
    <section class="marketing">
      <label>
        <span>{{ t['marketing_consent'] }}</span>
        <Toggle :on="user?.acceptedMarketingTerms != null" @change="toggleMarketing" />
      </label>
      <p>
        <slot>
          {{ l.marketing_terms }}
        </slot>
      </p>
    </section>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import { lang } from '../lang.js'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import Toggle from '../Toggle.ce.vue'

export default {
  name: 'UserProfile',
  components: { Loader, Toggle },
  mixins: [lang, SeezSdk.vueQueryMixin],
  inheritAttrs: false,
  props: {},
  data() {
    return {
      loading: false,
      user: null,
      error: null
    }
  },
  computed: {
    l() {
      return this.languageResources.LOGIN_COMPONENT_TRANSLATIONS
    },
    t() {
      return this.languageResources.PROFILE_COMPONENT_TRANSLATIONS
    }
  },
  beforeCreate() {
    console.log(this)
  },
  mounted() {
    this.loadProfile()
  },
  methods: {
    async loadProfile() {
      this.loading = true
      try {
        const { me } = await this.queryApi('{me{id,firstName,middleName,lastName,email,phone,acceptedMarketingTerms}}')
        this.user = me
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    async submitProfile(e) {
      this.error = null
      const formData = Object.fromEntries(new FormData(e.target))
      for (const key in formData) formData[key] = formData[key]?.trim()
      this.loading = true
      try {
        const query = 'mutation updateme($details:UserInputType) {updateMe(details: $details) {id,firstName,middleName,lastName,email,phone, acceptedMarketingTerms}}'
        const payload = { details: formData }
        const { updateMe } = await this.queryApi(query, payload)
        this.user = updateMe
        await window.seezSdk.getAccessToken(true)
      } catch (error) {
        console.error(error)
        this.error = 'Error saving profile'
      }
      this.loading = false
    },
    async toggleMarketing(e) {
      this.loading = true
      try {
        this.user.acceptedMarketingTerms = await this.queryApi(`mutation {changeMarketingPreferences(accepted:${e})}`)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.userProfile {
  @include theme;

  position: relative;
  background-color: var(--background);
  padding: 1em;

  > form {
    input {
      border-radius: 3em;
    }

    > fieldset {
      border: none;
      display: flex;
      gap: 1em;
      flex-wrap: wrap;
      margin: 0 0 1em 0;
      padding: 0;

      > legend {
        padding: 0;
        margin-block-end: 1em;
      }

      > label {
        flex-grow: 1;
        min-width: 30%;
        > * {
          display: block;
          width: 100%;
          box-sizing: border-box;
        }

        > span {
          color: #999;
          font-size: 0.75em;
          margin: 0 0 0.25em 2em;
        }

        > input {
          padding: 1em 1.5em;
          border: 1px solid #ccc;
        }
      }
    }

    > [type='submit'] {
      background: var(--highlight);
      color: var(--background);
      border: none;
      font-size: 1em;
      padding: 1em 3em;
      margin-inline-start: auto;
      display: block;
      min-width: 30%;
    }
  }

  > section.marketing {
    margin-block-start: 2em;

    > label {
      display: flex;

      > span {
        flex-grow: 1;
      }
    }

    > p {
      font-size: 0.8em;
      max-height: 16em;
      overflow: auto;
    }
  }

  > .loader {
    position: absolute;
    inset: 0;
  }
}
</style>
