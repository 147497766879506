<template>
  <div id="sideNav">
    <OrderInfo :order-id="orderId" @cancelOrder="cancelOrder" />
    <OrderCard :order-id="listing.id" :image-src="imageSrc" :full-name="fullName" :variant="listing.variant" :year="listing.year" :retail-price-string="retailPriceString" />
    <div class="components">
      <Step :label="t['tradeIn']" name="tradeIn" :status="appState.tradeIn.state" :current-stage="currentStage" @gotoComponent="gotoComponent" :loading="appState.tradeIn.loading" />
      <Step
        :label="t['personalInformation']"
        name="personalInformation"
        :status="appState.customerInfo.state"
        :current-stage="currentStage"
        @gotoComponent="gotoComponent"
        :loading="appState.customerInfo.loading"
      />
      <Step :label="t['carDelivery']" name="carDelivery" :status="appState.delivery.state" :current-stage="currentStage" @gotoComponent="gotoComponent" :loading="appState.delivery.loading" />
      <Step :label="t['payment']" name="payment" :status="appState.payment.state" :current-stage="currentStage" @gotoComponent="gotoComponent" :loading="appState.payment.loading" />
    </div>
  </div>
</template>

<script>
import { lang } from '../../lang'
import Step from '../components/Step.ce.vue'
import OrderInfo from '../components/OrderInfo.ce.vue'
import OrderCard from '../components/CheckoutOrderCard.ce.vue'

export default {
  name: 'CheckOutSideNav',
  components: { Step, OrderInfo, OrderCard },
  mixins: [lang],
  props: {
    orderId: { type: String, default: '' },
    name: { type: String, default: null },
    listing: {
      type: Object,
      default: () => {
        return {}
      }
    },
    appState: {
      type: Object,
      default: () => {
        return {
          customerInfo: {
            state: null
          },
          tradeIn: {
            state: null
          },
          payment: {
            state: null
          },
          delivery: {
            state: null
          }
        }
      }
    },
    details: {
      type: Object,
      default: () => {
        return { currency: 'kr' }
      }
    },
    currentStage: { type: String, required: true },
    editSection: { type: String, default: '' }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    fullName() {
      if (this?.name) return this.name
      const brand = this.listing?.brand?.name
      const model = this.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    retailPriceString() {
      if (this.listing.priceRetail == null) return null
      return this.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    imageSrc() {
      return this.listing?.pictures?.[0] || ''
    }
  },
  mounted() {
    if (this.editSection)
      this.$nextTick(() => {
        this.gotoComponent(this.editSection)
      })
  },
  methods: {
    gotoComponent(ref) {
      const el = this.$parent.$refs[ref].$el
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
    cancelOrder() {
      this.$emit('cancelOrder')
    }
  }
}
</script>
<style lang="scss" scoped>
#sideNav {
  border-right: 1px solid #eeeeee;
  padding: 1.0625rem 0.9375rem 1.0625rem 1.3125rem;
  .components {
    display: flex;
    flex-direction: column;
  }
}
</style>
