<template>
  <div class="myOrders">
    <h1>{{ t['my_orders'] }}</h1>
    <Loader v-if="loading" />
    <div v-else>
      <div v-if="active != null">
        <h2>
          <span>{{ t['current_order'] }}</span>
          <span v-if="activeExpiration">
            {{ t['expires_in'] }}:
            <time>{{ activeExpiration }}</time>
          </span>
          <span v-else />
          <span />
          <button @click="askConfirmation = true">
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.7 11.2H7.3C7.45 11.2 7.6 11.075 7.6 10.9V5.5C7.6 5.35 7.45 5.2 7.3 5.2H6.7C6.525 5.2 6.4 5.35 6.4 5.5V10.9C6.4 11.075 6.525 11.2 6.7 11.2ZM10.8 2.8H8.725L7.875 1.4C7.675 1.075 7.225 0.8 6.85 0.8H4.325C3.95 0.8 3.5 1.075 3.3 1.4L2.45 2.8H0.4C0.175 2.8 0 3 0 3.2V3.6C0 3.825 0.175 4 0.4 4H0.8V12.4C0.8 13.075 1.325 13.6 2 13.6H9.2C9.85 13.6 10.4 13.075 10.4 12.4V4H10.8C11 4 11.2 3.825 11.2 3.6V3.2C11.2 3 11 2.8 10.8 2.8ZM4.275 2.075C4.3 2.05 4.375 2 4.4 2H4.425H6.775C6.8 2 6.875 2.05 6.9 2.075L7.325 2.8H3.85L4.275 2.075ZM9.2 12.4H2V4H9.2V12.4ZM3.9 11.2H4.5C4.65 11.2 4.8 11.075 4.8 10.9V5.5C4.8 5.35 4.65 5.2 4.5 5.2H3.9C3.725 5.2 3.6 5.35 3.6 5.5V10.9C3.6 11.075 3.725 11.2 3.9 11.2Z"
                fill="#AFAFAF"
              />
            </svg>
            {{ t['cancel_order'] }}
          </button>
        </h2>
        <order-card :order="active" :to="to" :lg="lg" />
      </div>
      <div>
        <h2>{{ t['previous_orders'] }}</h2>
        <order-card v-for="order in orders" :key="order.id" :order="order" :to="to" :lg="lg" />
      </div>
    </div>
    <div class="cancelConfirmation" v-if="askConfirmation">
      <dialog open>
        <h1>{{ t['cancel_order_title'] }}</h1>
        <p v-html="t['cancel_order_description']" />
        <button @click="confirmCancelation" class="red">{{ t['cancel_order_confirm'] }}</button>
        <button @click="askConfirmation = false">{{ t['cancel_order_abort'] }}</button>
      </dialog>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import OrderCard from './OrderCard.ce.vue'
import Loader from '../Loader.ce.vue'
import { lang } from '../lang'

export default {
  name: 'SeezOrders',
  components: { OrderCard, Loader },
  mixins: [lang, SeezSdk.vueQueryMixin],
  props: {
    to: { type: String, default: '/checkout?order={id}' }
  },
  data: () => ({
    orders: [],
    active: null,
    activeExpiration: null,
    loading: false,
    interval: null,
    askConfirmation: false
  }),
  computed: {
    t() {
      return this.languageResources.ORDERS_COMPONENT_TRANSLATIONS
    }
  },
  mounted() {
    this.loadOrders()
    // this.interval = setInterval(this.recalcExpiration, 1000)
  },
  destroyed() {
    // if (this.interval) clearInterval(this.interval)
  },
  methods: {
    async loadOrders() {
      this.loading = true
      try {
        const query =
          'fragment orderFields on Order {id,state,createdOn,listing {id,name,year,kilometrage,priceRetail,pictures,imageIds,variant,reserved,reservationExpires}},{user {activeOrder {...orderFields},orders {...orderFields}}}'
        const { user } = await this.queryApi(query)
        this.active = user.activeOrder
        const orders = user.orders.filter(o => o.state !== 'ongoing')
        orders.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
        this.orders = orders
        this.recalcExpiration()
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    recalcExpiration() {
      if (!this.active?.listing?.reserved || this.active?.listing?.reservationExpires == null) {
        this.activeExpiration = null
      } else {
        const remaining = Math.floor((new Date(this.active.listing.reservationExpires) - new Date()) / 1000)
        if (remaining > 0) {
          const sec = remaining % 60
          const min = ((remaining - sec) / 60) % 60
          const hours = Math.floor((remaining - min * 60 - sec) / 3600)
          this.activeExpiration = `${hours.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`
        } else {
          this.activeExpiration = null
        }
      }
    },
    confirmCancelation() {
      this.askConfirmation = false
      this.cancelActiveOrder()
    },
    async cancelActiveOrder() {
      this.loading = true
      await this.queryApi(`mutation {cancelOrder(orderId: ${this.active.id}) {id} }`)
      await this.loadOrders()
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.myOrders {
  place-self: start stretch;

  h1 {
    text-align: left;
    font-size: 1.25em;
    color: #333333;
  }

  > div > div > h2 {
    display: flex;
    gap: 1em;
    align-items: center;
    color: #757575;
    margin: 1.5em 0 0.5em 0.5em;
    font-size: 1em;

    > span {
      flex: auto;
    }

    > :nth-child(3) {
      flex-grow: 1;
    }
    time {
      color: #1a2634;
      font-family: 'sysrem-ui', monospace, 'Courier New', Courier;
      font-weight: bold;
    }
    > button {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      color: #757575;
      font-family: 'Biennale', sans-serif;

      @media screen and (max-width: 28rem) {
        width: 40%;
      }
    }
  }

  > .cancelConfirmation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(2px) brightness(60%);
    display: grid;
    place-items: center;

    > dialog {
      font-size: 1.25em;
      background: white;
      border-radius: 2em;
      display: grid;
      gap: 0.75em;
      border: none;
      padding: 2em 4em;
      min-width: 10rem;

      > * {
        margin: 0 auto;
      }

      > p {
        max-width: 35ch;
        text-align: center;
      }

      > button {
        border: none;
        background: none;
        padding: 0.5em;
        width: 100%;
        box-sizing: border-box;
        border-radius: 2em;
        cursor: pointer;
        font-size: 1em;
        font-family: 'Biennale', sans-serif;

        &:hover {
          background-color: #f0f0f0;
        }

        &.red {
          border: 1px solid red;
          color: red;
        }
      }
    }
  }
}
</style>
