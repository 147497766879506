<template>
  <div>
    <label :for="'select' + label" v-if="label"> {{ label }}</label>
    <multi-select :id="'select' + label" :model-value="modelValue" @input="changes" ref="multiSelect" v-bind="$attrs" :options="options" :class="errorMsg ? 'hasError' : ''" />
    <div class="error" v-if="errorMsg">{{ errorMsg }}</div>
  </div>
</template>
<script>
import MultiSelect from '../../MultiSelect.ce.vue'

export default {
  name: 'MultiSelectError',
  components: { MultiSelect },
  props: {
    error: { type: String, default: '' },
    modelValue: { type: [String, Number], default: null },
    options: { type: Object, required: true },
    label: { type: String, default: null }
  },
  data() {
    return {
      errorMsg: ''
    }
  },
  watch: {
    error(val) {
      this.errorMsg = val
    },
    modelValue() {
      this.errorMsg = ''
    }
  },
  methods: {
    clearInvalid() {
      if (this.error) this.$refs.multiSelect.oninvalid = this.$refs.multiSelect.setCustomValidity('')
    },
    changes(event) {
      this.$emit('update:modelValue', event.target?.value)
    }
  }
}
</script>
<style lang="scss">
/* !important is used because parent's stylings has higher priority (ID is used) */
/* Hacks are tested and it would make them nasty. e.g. using ID will end in having multiple inputs with same ID */
/* TODO: clean up parent css with scoped instead of using ID then remove important */
div {
  div.multiselect > button {
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    padding: 0.75em 1.75em;
    width: 100%;
    box-sizing: border-box;
    height: 3rem;

    &:focus-visible {
      outline: 1px solid #0068ff;
    }
    &:valid {
      border: 1px solid #ccc !important;
    }
    svg {
      top: 1.75em;
    }
  }
  div.multiselect.hasError > button {
    border: 1px solid rgb(248, 67, 67) !important;
  }
  div.hasError:focus-visible > button {
    outline: 1px solid rgb(211, 0, 0) !important;
  }
  div.error {
    padding: 0.75em 0 0.75em 1.75em;
    color: rgb(248, 67, 67);
  }
  > label {
    font-style: normal;
    font-weight: 600;
    color: #003e99;
    padding: 0 1.75rem;
  }
}
</style>
