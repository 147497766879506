<template>
  <div class="summaryWrapper" :class="showSummaryModal ? 'open' : ''">
    <div class="closeIcon" @click="setSummaryModal(false)"><img :src="closeIcon" /></div>
    <Transition name="summary" mode="out-in">
      <div class="summaryBackdrop" @click="setSummaryModal(false)" v-if="showSummaryModal" />
    </Transition>
    <div class="summary" @click.stop>
      <ImageSlider :images="order.listing?.pictures" />
      <div class="details">
        <span class="name" data-test-id="card-name">{{ fullName }}</span>
        <div class="row">
          <span class="variant" data-test-id="card-variant-year">{{ order.listing.variant }} {{ order.listing.year }}</span>
          <span class="price" data-test-id="car-price">{{ retailPriceString }}</span>
        </div>
      </div>
      <hr />
      <div class="addons">
        <!-- TODO: add addons -->
      </div>
      <hr />
      <div class="tradeInSection">
        <h6>{{ t['tradeIn'] }}</h6>
      </div>
    </div>
    <Transition name="drawer">
      <div :style="overviewStyles" class="summaryButton" @click="setSummaryModal(!showSummaryModal)"><img :src="openDrawer" /></div>
    </Transition>
  </div>
</template>
<script>
import ImageSlider from '../form/Image.Slider.ce.vue'
import { lang } from '../../lang'
import SeezSdk from '../../../sdk.js'
import { drawerIcon, openDrawer, closeIcon } from '../../../assets/images'

export default {
  name: 'SummaryModal',
  components: { ImageSlider },
  mixins: [lang, SeezSdk.vueQueryMixin],
  props: {
    showSummaryModal: { type: Boolean, default: false },
    order: { type: Object, required: true },
    currentStage: { type: String, required: true }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    fullName() {
      if (this.order.name) return this.order.name
      const brand = this.order.listing?.brand?.name
      const model = this.order.listing?.model?.name
      return [brand, model].filter(n => n != null).join(' ')
    },
    retailPriceString() {
      if (this.order.listing.priceRetail == null) return null
      return this.order.listing.priceRetail?.toLocaleString(this.language) + ' ' + this.currency
    },
    currency() {
      return this.details?.currency ?? 'kr.'
    },
    journeyStatus() {
      if (this.order.allStepsComplete(this.order.currentStage)) return { text: this.t.completed, class: 'completed' }
      return { text: this.t.pending, class: 'pending' }
    },
    openDrawer() {
      return openDrawer
    },
    closeIcon() {
      return closeIcon
    },
    overviewStyles() {
      return {
        background: `url(${drawerIcon})`,
        backgroundRepeat: 'no-repeat',
        width: '3rem',
        height: '6.625rem',
        backgroundPosition: '0% 0%',
        backgroundSize: '100% 100%'
      }
    }
  },
  watch: {
    showSummaryModal(val) {
      console.log(val)
    }
  },
  methods: {
    gotoComponent(name) {
      this.$emit('gotoComponent', name)
    },
    setSummaryModal(status) {
      this.$emit('setSummaryModal', status)
    }
  }
}
</script>
<style lang="scss">
.summaryWrapper {
  background: #ffffff;
  height: auto;
  min-height: 100vh;
  max-width: 29rem;
  top: 0;
  left: 100%;
  right: 0;
  padding: 0;
  position: fixed;
  z-index: 100;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  > .closeIcon {
    display: none;
  }
  .summary-leave-active {
    transition: all 0.3s;
  }

  .summary-enter-from,
  .summary-leave-to {
    transform: all 0.3s ease-out;
    opacity: 0;
  }
  .drawer-leave-active,
  .drawer-enter-active {
    transition: all 0.8s ease;
  }
  .drawer-enter,
  .drawer-leave-to {
    transform: rotate(180deg);
    opacity: 0;
  }
  .summaryBackdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(13px);
    overflow: auto;
    z-index: -1;
  }
  .summary {
    padding: 2em 3em;
    background: white;
    min-height: 100vh;
    > .details {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      > .name {
        font-weight: 700;
        font-size: 25px;
        line-height: 35px;
      }
      > .row {
        display: flex;
        justify-content: space-between;
        .price {
          font-weight: 700;
          font-size: 1rem;
          line-height: 22px;
          margin-top: auto;
          margin-left: auto;
        }
        .variant {
          width: 13rem;
          font-weight: 400;
          font-size: 12.8px;
          line-height: 18px;
        }
      }
    }
    > hr {
      border-top: 1px solid #e2e2e2;
      margin: 1rem 0;
    }
    > .tradeInSection {
      > h6 {
        font-weight: 700;
        font-size: 12.8px;
        line-height: 18px;
        color: #000000;
      }
    }
  }
  .journeySteps {
    display: block;
    > .journeyStatus {
      display: flex;
      justify-content: space-between;
      > div {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.1875rem;
      }
      > div.status {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        color: #ffa200;
      }
      > div.status.completed {
        color: #009a33;
      }
    }
    > .steps {
      margin-top: 2rem;
    }
  }
  .summaryButton {
    position: absolute;
    left: -3rem;
    width: 100%;
    top: 40%;
    right: 0;
    height: 100%;
    cursor: pointer;
    > img {
      position: absolute;
      right: 1.125rem;
      top: 44%;
      width: 0.875rem;
      height: 0.875rem;
      rotate: 0deg;
      transition: rotate 0.5s;
    }
  }

  &.open {
    left: 60%;
    .summaryButton > img {
      rotate: 180deg;
      transition: rotate 0.5s;
    }
  }
  .journeySteps {
    display: none;
  }
  @media screen and (max-width: 56rem) {
    &.open {
      left: 0;
    }
    .summary {
      padding: 1rem;
    }
    .closeIcon {
      display: block;
    }
    .summaryButton {
      display: none;
    }
  }
}
</style>
