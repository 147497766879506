<template>
  <div class="loader">
    <svg class="spinner" width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
    </svg>
  </div>
</template>

<script>
// TODO: change behavior and appearance based on UI design
export default {
  name: 'SmallLoader'
}
</script>

<style lang="scss">
$offset: 187;
$duration: 1.4s;

.loader {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  backdrop-filter: blur(2px);
}

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #cce1ff;
  }
  25% {
    stroke: #99c3ff;
  }
  50% {
    stroke: #66a4ff;
  }
  75% {
    stroke: #3386ff;
  }
  100% {
    stroke: #0068ff;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
</style>
