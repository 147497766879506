<template>
  <div id="search">
    <FilterPanel
      v-model="currentFilters"
      :options="options"
      :tags="tags"
      @remove="removeFilter"
      :preload="preload"
      :class="{ showFilter: showFilter }"
      @close="showFilter = false"
      :lg="lg"
      :total-result="total"
    />
    <div :class="{ resultsSection: true, showFilter: showFilter }">
      <div class="SEOText">
        <h1 v-if="brandData">{{ brandData.title }}{{ total }}</h1>
        <h1 v-else>{{ brandData === undefined ? 'Brugt ' : '' }}{{ title }}{{ total }}</h1>
      </div>
      <div class="tags" v-if="options">
        <span v-for="tag in tags.filter(t => t.label)" :key="tag.id + '_' + tag.value">
          {{ tag.label }}
          <i @click="removeFilter(tag)">&times;</i>
          <span class="closeBtn" @click="removeFilter(tag)" />
        </span>
      </div>
      <div class="sorting">
        <button @click="showFilter = true">
          <svg width="16" height="16" viewBox="0 1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.6955 0.75H2.34786C1.17208 0.75 0.570519 2.17188 1.41818 2.99219L5.84786 7.42188V12.125C5.84786 12.5625 6.03927 12.9453 6.36739 13.2188L8.11739 14.5312C8.96505 15.1055 10.2229 14.5586 10.2229 13.4648V7.42188L14.6252 2.99219C15.4729 2.17188 14.8713 0.75 13.6955 0.75ZM8.91036 6.875V13.4375L7.16036 12.125V6.875L2.34786 2.0625H13.7229L8.91036 6.875Z"
              fill="#000000"
            />
          </svg>
          {{ `${t['filter']} ${selectedFilterCount}` }}
        </button>
        <multi-select :multi="false" v-model="currentFilters.sort" :options="sortingOrders" :required="true" :placeholder="t['sort_by']" />
      </div>
      <ResultsPanel v-model="currentFilters" :options="options" @click="clicked" :lg="lg" :to="to" :target="target" @results="updateTotal" />
    </div>
  </div>
</template>

<script>
import { analyticsMixin, analyticsEvents } from '../../analytics.js'
import FilterPanel from './FilterPanel.ce.vue'
import ResultsPanel from './ResultsPanel.ce.vue'
import MultiSelect from '../MultiSelect.ce.vue'
import { parseUrlFilters, formatFilterForApi } from '../../logic.js'
import SeezSdk from '../../sdk.js'
import { lang } from '../lang.js'
import { prismicCms } from '../cms'

const filtersMap =  {
  '?body-types=1,5,9': 'family_life',
  '?price-max=250000&price-type=retail': 'budget',
  '?body-types=8,9': 'outdoor_adventure',
  '?body-types=7,11': 'single',
  '?body-types=1,8,9': 'family_suv',
  '?price-max=200000&price-type=retail&fuel-types=3': 'economical_cars',
  '?fuel-types=3': 'environmentally_cars'
}

export default {
  name: 'SeezSearch',
  components: { FilterPanel, ResultsPanel, MultiSelect },
  mixins: [lang, SeezSdk.vueQueryMixin, prismicCms, analyticsMixin],
  inheritAttrs: false,
  props: {
    filters: { type: String, default: null }, // URL querystring format
    to: { type: String, default: null },
    target: { type: String, default: null }
  },
  data() {
    const parsedFilters = parseUrlFilters(this.filters)
    return {
      currentFilters: parsedFilters,
      oldFilterString: JSON.stringify(parsedFilters),
      options: {
        brands: [],
        families: [],
        fuelTypes: [],
        bodyTypes: [],
        transmissions: [],
        years: [],
        doors: [],
        drives: [],
        engines: [],
        colors: [
          { id: 'grey', color: '#757575' },
          { id: 'silver', color: '#E2E2E2' },
          { id: 'white', color: '#FFFFFF' },
          { id: 'black', color: '#000000' },
          { id: 'blue', color: '#0068FF' },
          { id: 'red', color: '#FF0009' },
          { id: 'orange', color: '#FFA500' },
          { id: 'brown', color: '#664E00' },
          { id: 'green', color: '#009A33' },
          { id: 'yellow', color: '#FFD500' }
        ],
        priceTypes: [
          { id: 'retail', name: 'Full' },
          { id: 'emi', name: 'Monthly' }
        ]
      },
      showFilter: false,
      page: 1,
      title: '',
      totalResults: null
    }
  },
  computed: {
    t() {
      return this.languageResources.RESULT_PANEL_COMPONENT_TRANSLATIONS
    },
    brandData() {
      if (this.currentFilters.brands == null) return null
      const brandOption = this.options.brands.find(o => o.id === this.currentFilters.brands)
      if (brandOption == null) return null
      const brandSeo = this.languageResources.SEO_DATA[this.$root.slugify(brandOption.name)] ?? { title: this.languageResources.SEO_DATA.default.title.replace('{brand}', brandOption.name) }
      return brandSeo
    },
    sortingOrders() {
      if (this.t == null) return []
      return [
        { id: '-attractiveness', name: this.t.most_attractive },
        { id: '-created_date', name: this.t.recently_added },
        { id: 'price', name: this.t.price_ascending },
        { id: '-price', name: this.t.price_descending },
        { id: 'mileage', name: this.t.kilometrage_ascending },
        { id: '-mileage', name: this.t.kilometrage_descending },
        { id: '-year', name: this.t.age_descending },
        { id: 'year', name: this.t.age_ascending }
      ]
    },
    tags() {
      const result = []

      if (this.currentFilters.brands) result.push({ id: 'brands', keys: ['brands'], label: this.options?.brands?.find(b => b.id === this.currentFilters.brands)?.name })
      if (this.currentFilters.families && this.currentFilters.families.length > 0) {
        if (Array.isArray(this.currentFilters.families))
          result.push(...this.currentFilters.families.map(f => ({ id: `families${f}`, keys: ['families'], value: f, label: this.options?.families?.find(of => of.id === f)?.name })))
        else
          result.push({
            id: `families${this.currentFilters.families}`,
            keys: ['families'],
            value: this.currentFilters.families,
            label: this.options?.families?.find(of => of.id === this.currentFilters.families)?.name
          })
      }
      if (this.currentFilters.models && this.currentFilters.models.length > 0) {
        if (Array.isArray(this.currentFilters.models))
          result.push(...this.currentFilters.models.map(m => ({ id: `models${m}`, keys: ['models'], value: m, label: this.options?.families?.flatMap(f => f.models)?.find(om => om.id === m)?.name })))
        else
          result.push({
            id: `models${this.currentFilters.models}`,
            keys: ['models'],
            value: this.currentFilters.models,
            label: this.options?.models?.find(b => b.id === this.currentFilters.models)?.name
          })
      }

      if (this.currentFilters.yearMin && this.currentFilters.yearMax)
        result.push({ id: 'year', keys: ['yearMin', 'yearMax'], label: `between ${this.currentFilters.yearMin} and ${this.currentFilters.yearMax}` })
      else if (this.currentFilters.yearMin) result.push({ id: 'yearMin', keys: ['yearMin'], label: `${this.currentFilters.yearMin} or newer` })
      else if (this.currentFilters.yearMax) result.push({ id: 'yearMax', keys: ['yearMax'], label: `${this.currentFilters.yearMax} or older` })

      if (this.currentFilters.kilometrageMin && this.currentFilters.kilometrageMax)
        result.push({
          id: 'kilometrage',
          keys: ['kilometrageMin', 'kilometrageMax'],
          label: `between ${this.currentFilters.kilometrageMin.toLocaleString(this.language)} km and ${this.currentFilters.kilometrageMax.toLocaleString(this.language)} km`
        })
      else if (this.currentFilters.kilometrageMin)
        result.push({ id: 'kilometrageMin', keys: ['kilometrageMin'], label: `${this.currentFilters.kilometrageMin.toLocaleString(this.language)} km or more` })
      else if (this.currentFilters.kilometrageMax)
        result.push({ id: 'kilometrageMax', keys: ['kilometrageMax'], label: `${this.currentFilters.kilometrageMax.toLocaleString(this.language)} km or less` })

      if (this.currentFilters.priceMin && this.currentFilters.priceMax)
        result.push({
          id: 'price',
          keys: ['priceMin', 'priceMax'],
          label: `between ${this.currentFilters.priceMin.toLocaleString(this.language)} kr. and ${this.currentFilters.priceMax.toLocaleString(this.language)} kr.`
        })
      else if (this.currentFilters.priceMin) result.push({ id: 'priceMin', keys: ['priceMin'], label: `${this.currentFilters.priceMin.toLocaleString(this.language)} kr. or more` })
      else if (this.currentFilters.priceMax) result.push({ id: 'priceMax', keys: ['priceMax'], label: `${this.currentFilters.priceMax.toLocaleString(this.language)} k. or less` })

      if (this.currentFilters.bodyTypes && this.currentFilters.bodyTypes.length > 0)
        result.push(...this.currentFilters.bodyTypes.map(bt => ({ id: `bodyType${bt}`, keys: ['bodyTypes'], value: bt, label: this.options?.bodyTypes?.find(b => b.id === bt)?.name })))

      if (this.currentFilters.transmissions)
        result.push({ id: 'transmissions', keys: ['transmissions'], label: this.options?.transmissions?.find(b => b.id === this.currentFilters.transmissions)?.name })
      if (this.currentFilters.fuelTypes && this.currentFilters.fuelTypes.length > 0) {
        result.push(...this.currentFilters.fuelTypes.map(f => ({ id: `fuelTypes${f}`, keys: ['fuelTypes'], value: f, label: this.options?.fuelTypes?.find(ft => ft.id === f)?.name })))
      }

      if (this.currentFilters.colors && this.currentFilters.colors.length > 0) result.push(...this.currentFilters.colors.map(c => ({ id: `color${c}`, keys: ['colors'], value: c, label: c })))

      if (this.currentFilters.engineSizeMax) result.push({ id: 'engineSizeMax', keys: ['engineSizeMax'], label: this.options?.engines?.find(b => b.id === this.currentFilters.engineSizeMax)?.name })

      if (this.currentFilters.driveTypes && this.currentFilters.driveTypes.length > 0) {
        result.push(...this.currentFilters.driveTypes.map(d => ({ id: `driveTypes${d}`, keys: ['driveTypes'], value: d, label: this.t[`drive_type_${d}`] })))
      }

      if (this.currentFilters.doors) result.push({ id: 'doors', keys: ['doors'], label: `${this.currentFilters.doors} doors` })

      if (this.currentFilters.freeText && this.currentFilters.freeText.length > 0) {
        result.push(...this.currentFilters.freeText.split(' ').map(w => ({ id: `freeText${w}`, keys: ['freeText'], value: w, label: w })))
      }
      return result
    },
    preload() {
      return this.currentFilters?.ss ? parseInt(this.currentFilters?.ss) : null
    },
    selectedFilterCount() {
      return this.tags.length > 0 ? `(${this.tags.length})` : ''
    },
    total() {
      return this.totalResults > 0 ? ` (${this.totalResults})` : ''
    }
  },
  watch: {
    filters(v) {
      const parsedFilters = parseUrlFilters(v)
      if (JSON.stringify(parsedFilters) !== this.oldFilterString) this.currentFilters = parseUrlFilters(v)
    },
    currentFilters: {
      deep: true,
      handler(v) {
        if (JSON.stringify(v) !== this.oldFilterString) {
          this.$emit('filtersUpdated', v)
          this.track(analyticsEvents.SEARCH_RESULT, { category: 'user_engagement', action: 'search_result', label: 'Filters', iteraction: this.genFilterData(v) })
        }
        this.oldFilterString = JSON.stringify(v)
        this.title = this.handleTitleByFilters()
        this.loadAvailableOptions()
        this.totalResults = null
      }
    }
  },
  mounted() {
    this.title = this.handleTitleByCategory()
    this.loadAvailableOptions()
  },
  methods: {
    async loadAvailableOptions() {
      const { filterOptionsWithCounters: filterOptions } = await this.queryApi(
        'query filterOptionsWithCounters($filter: ListingFiltersInput){filterOptionsWithCounters(filter:$filter){brands{id,name,count},families{id,name,brandid,count},models{id,name,familyid,count},fuels{id,name,count},bodies{id,name,count},transmissions{id,name,count},years{year,count},numbersOfDoors{number_of_doors,count},driveTypes{drive_type,count},engineSizes{engine_size,count}}}',
        { filter: formatFilterForApi(this.currentFilters) }
      )
      const newFilters = {
        brands: filterOptions.brands.map(b => ({ id: parseInt(b.id), name: b.name, count: b.count })),
        families: filterOptions.families.map(f => ({
          id: parseInt(f.id),
          name: f.name,
          models: filterOptions.models.filter(m => m.familyid === f.id).map(m => ({ id: parseInt(m.id), name: m.name, count: m.count })),
          brandid: parseInt(f.brandid),
          count: f.count
        })),
        bodyTypes: filterOptions.bodies.filter(b => b.name !== 'Unknown').map(b => ({ id: parseInt(b.id), name: b.name, count: b.count })),
        transmissions: filterOptions.transmissions.map(b => ({ id: parseInt(b.id), name: b.name, count: b.count })),
        fuelTypes: filterOptions.fuels.map(b => ({ id: parseInt(b.id), name: b.name, count: b.count })),
        years: filterOptions.years.map(x => ({ id: x.year, name: x.year.toString(), count: x.count })),
        doors: filterOptions.numbersOfDoors.map(x => ({ id: x.number_of_doors, name: x.number_of_doors.toString(), count: x.count })),
        drives: filterOptions.driveTypes.map(d => ({ id: d.drive_type, count: d.count })),
        engines: filterOptions.engineSizes.map(x => ({
          id: parseFloat(x.engine_size),
          name: `${x.engine_size.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} L`,
          count: x.count
        }))
      }
      Object.assign(this.options, newFilters)
      this.removeUnavailableOptions()
    },
    removeUnavailableOptions() {
      if (this.currentFilters.brands && !this.options.brands.some(b => b.id === this.currentFilters.brands)) this.currentFilters.brands = null
      if (this.currentFilters.families) {
        const unavailableFamilies = this.currentFilters.families.filter(b => !this.options.families.map(x => x.id).includes(b))
        if (unavailableFamilies.length > 0) this.currentFilters.families = this.currentFilters.families.filter(b => !unavailableFamilies.includes(b))
      }
      if (this.currentFilters.models) {
        const unavailableModels = this.currentFilters.models.filter(
          b =>
            !this.options.families
              .flatMap(x => x.models)
              .map(x => x.id)
              .includes(b)
        )
        if (unavailableModels.length > 0) this.currentFilters.models = this.currentFilters.models.filter(b => !unavailableModels.includes(b))
      }
      if (this.currentFilters.bodyTypes) {
        const unavailableBodyTypes = this.currentFilters.bodyTypes.filter(b => !this.options.bodyTypes.map(x => x.id).includes(b))
        if (unavailableBodyTypes.length > 0) this.currentFilters.bodyTypes = this.currentFilters.bodyTypes.filter(b => !unavailableBodyTypes.includes(b))
      }
      if (this.currentFilters.transmissions && !this.options.transmissions.some(b => b.id === this.currentFilters.transmissions)) this.currentFilters.transmissions = null
      if (this.currentFilters.fuelTypes) {
        const unavailableFuelTypes = this.currentFilters.fuelTypes.filter(b => !this.options.fuelTypes.map(x => x.id).includes(b))
        if (unavailableFuelTypes.length > 0) this.currentFilters.fuelTypes = this.currentFilters.fuelTypes.filter(b => !unavailableFuelTypes.includes(b))
      }
      if (this.currentFilters.driveTypes) {
        const unavailableDriveTypes = this.currentFilters.driveTypes.filter(b => !this.options.drives.map(x => x.id).includes(b))
        if (unavailableDriveTypes.length > 0) this.currentFilters.driveTypes = this.currentFilters.driveTypes.filter(b => !unavailableDriveTypes.includes(b))
      }
      if (this.currentFilters.doors && !this.options.doors.some(b => b.id === this.currentFilters.doors)) this.currentFilters.doors = null
    },
    removeFilter(e) {
      const newFilter = JSON.parse(JSON.stringify(this.currentFilters))
      for (const k of e.keys) {
        if (k === 'freeText') {
          newFilter[k] = newFilter[k]
            .replace(e.value, '')
            .trim()
            .replace(/\s{2,}/, ' ')
        } else if (e.value) {
          newFilter[k] = newFilter[k].filter(v => v != e.value)
          if (newFilter[k].length === 0) delete newFilter[k]
        } else {
          delete newFilter[k]
        }
      }
      this.currentFilters = newFilter
    },
    handleTitleByCategory() {
      return this.t[filtersMap[this.filters] || 'search_results']
    },
    handleTitleByFilters() {
      if (Object.keys(this.currentFilters).filter(f => f !== 'sort').length === 0) return this.t.all_cars
      if (this.currentFilters.brands && this.options?.brands) return this.options?.brands?.find(b => b.id === this.currentFilters.brands)?.name
      return this.t.search_results
    },
    clicked(e, details) {
      this.$emit('select', e, details)
    },
    updateTotal(r) {
      this.totalResults = r.pageInfo.total
    },
    genFilterData({
      bodytype,
      brand,
      colors,
      fueltype,
      kilometrageMax,
      kilometrageMin,
      model,
      priceMax,
      priceMin,
      priceType,
      transmission,
      yearMax,
      yearMin,
      doors,
      seats,
    }){
      return {
        search: {
          filter: {
            make: brand,
            model,
            year: yearMin || yearMax && { yearMin, yearMax },
            mileage: kilometrageMin || kilometrageMax && { kilometrageMin, kilometrageMax },
            price: priceMin || priceMax && { priceMin, priceMax },
            priceType,
            bodytype,
            transmission,
            fueltype,
            colors,
            doors,
            seats,
          },
        },
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

#search {
  @include theme;

  font-family: 'Biennale', sans-serif;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 28ch 5fr;

  .SEOText {
    grid-area: title;
    h1 {
      margin: 1rem 0 0 1rem;
      padding: 0;
      color: black;

      @media screen and (max-width: 42rem) {
        font-size: 1.3rem;
        margin: 0.4rem 0rem 0.4rem 1rem;
      }
    }
  }

  .resultsSection {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-areas: 'title sorting' 'tags tags' 'result result';
    overflow: hidden;
    padding: 0.5em;

    > *:not(:last-child) {
      margin-bottom: 0.5em;
    }

    > h1:not(:last-child) {
      grid-area: title;
      margin: 0 0 1rem 1rem;
      color: black;
      font-size: 1.5em;
    }

    > .tags {
      grid-area: tags;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      gap: 1em;
      position: relative;
      margin-left: 1em;
      padding: 0.5rem 0 0.5rem 0;
      @media screen and (max-width: 50rem) {
        overflow-x: auto;
        flex-wrap: nowrap;
      }

      > span {
        border: 1px solid var(--highlight);
        border-radius: 1em;
        padding: 0.25em 0.75em;
        color: var(--highlight);
        height: fit-content;
        font-size: 0.9em;
        white-space: nowrap;
        position: relative;

        > i {
          display: inline-block;
          margin-left: 0.5em;
          cursor: pointer;
          font-style: normal;
        }

        > .closeBtn {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 1em;
          cursor: pointer;
        }
      }
    }

    > .sorting {
      grid-area: sorting;
      padding-top: 1rem;

      @media screen and (max-width) {
        padding-top: 0rem;
      }

      > button {
        border: none;
        outline: none;
        padding: 0.5em;
        background-color: transparent;
        display: none;
        white-space: nowrap;

        > * {
          vertical-align: text-bottom;
        }
      }

      > .multiselect {
        padding-right: 1em;

        > button {
          border: none;
          outline: none;
          font-size: 0.9em;
        }

        > ul {
          left: auto;
          right: 0;
        }
      }

      @media screen and (max-width: 42rem) {
        display: flex;
        margin: 0 1em 1em 1em;
        height: 3em;
        gap: 1em;
        padding-top: 0rem;

        > button,
        > .multiselect {
          flex-grow: 1;
          border: 1px solid black;
          padding: 0.25em 0.75em;
          width: 100%;
          color: black;

          &:first-child {
            border-radius: 2em;
            border: 1px solid black;
            font-family: 'Biennale', sans-serif;
            font-size: 1em;
            color: black;
          }

          &:last-child {
            border-radius: 2em;
            font-family: 'Biennale', sans-serif;
            font-size: 1em;
            text-align: center;
            padding: 0.25em 0;
            margin-left: -1px;
            color: black;
          }
        }
        > button {
          display: inline-block;

          > svg {
            display: inline-block;
            vertical-align: text-bottom;
          }
        }
      }
    }

    .resultsList {
      grid-area: result;
    }
  }

  @media screen and (max-width: 50rem) {
    .resultsSection {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: repeat(3, auto) 1fr;
      grid-template-areas: 'title sorting' 'tags tags' 'result result';
      overflow: hidden;
      padding: 0.3em 0;
    }
  }

  @media screen and (max-width: 42rem) {
    grid-template-columns: auto;

    .filtersPanel:not(.showFilter) {
      display: none;
    }

    .resultsSection {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto) 1fr;
      grid-template-areas: 'title' 'sorting' 'tags' 'result';

      &.showFilter {
        display: none;
      }
    }
  }
}
</style>
