

export const analyticsMixin = {
  methods: {
    track(eventKey, properties, vData) {
      return window.seezSdk.track(eventKey, properties, vData)
    },
    identify({ userId, name, email, loginStatus }) {
      return window.seezSdk.identify({ userId, name, email, loginStatus })
    },
    trackCTA(eventName, properties) {
      return window.seezSdk.track(eventName, {
        event_category: properties.category,
        event_label: properties.name,
        target_site_id: properties.target_site_id,
        event_action: { iteraction: { navigation: { name: properties.name, type: properties.type } } },
        payload: properties.payload
      }, { vehicle: properties.listing })
    },
  }
}

export const analyticsEvents = {
  CTA_CLICK: 'cta_click',
  CAROUSEL_CLICK_LISTING_DETAILS: 'carousel_click_listing_details',
  CAROUSEL_CAR_CLICK: 'carousel_car_click',
  TRADE_IN_LICENSE_PLATE_SEARCH: 'instant_trade_in_license_plate_entry',
  TRADE_IN_FORM_PROCESS: 'instant_trade_in_form_process',
  TRADE_IN_REQUEST_SUBMITTED: 'trade_in_request_submitted',
  TRADE_IN_REG_INPUT: 'trade_in_reg_input ',
  SEARCH_RESULT: 'search_result',
  LOGIN_SUCCESS: 'login_success',
  OTP_OPEN: 'otp_open',
  OTP_ERROR: 'otp_error',
}
