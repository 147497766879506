<template>
  <div class="calculatorWrapper">
    <div v-if="loading" class="loader">
      <loader />
    </div>
    <div class="calculator">
      <div class="leftContent">
        <slot />
        <template v-if="listing == null">
          <label for="carPrice">{{ t['car_estimated_price'] }}</label>
          <span class="input">
            <input id="carPrice" :placeholder="t['loan_is_below_santander']" v-model.number="carPrice" type="number" />
            <span>{{ t['kr'] }}</span>
          </span>
          <sub v-if="error && carPrice" :class="{ error: loanBelowMin }">{{ t['loan_is_below_santander'] }}</sub>
        </template>
        <div class="percentage">
          <label for="downpayment">{{ t['preferred_down_payment'] }}</label>
          <!-- <span>{{ `${downPaymentPct} %` }}</span> -->
        </div>
        <span class="input" :class="{ error: downPaymentBelowMin }">
          <input id="downpayment" v-model.number="downPayment" placeholder="Downpayment" />
          <span>{{ t['kr'] }}</span>
        </span>
        <sub v-if="downPaymentBelowMin" :class="{ error: downPaymentBelowMin }">{{ t['downpayment_is_required'] }}</sub>

        <label for="loanDurationMenu">{{ t['preferred_loan_duration_months'] }} {{ `(${t['months']})` }}</label>
        <div id="loanDurationMenu" class="loanDurationOptions">
          <button v-for="o in paymentTermsOptions" :key="o" @click="setPaymentTermsOption(o)" :class="{ active: o === paymentTerms }">{{ o }}</button>
        </div>

        <label class="checkboxLabel" for="checkboxMenu">{{ t['interest_type'] }}</label>
        <div id="checkboxMenu" class="checkboxMenu">
          <label for="fixedInput" :class="{ active: value === interestTypes.FIXED }">
            <input id="fixedInput" class="checkbox" v-model="value" :value="interestTypes.FIXED" type="radio" />
            <span>{{ t['fixed'] }}</span>
          </label>
          <label :class="{ active: value === interestTypes.VARIABLE }">
            <input id="variableInput" type="radio" v-model="value" :value="interestTypes.VARIABLE" />
            <span for="variableInput">{{ t['variable'] }}</span>
          </label>
        </div>
      </div>
      <div class="rightContent">
        <h1>{{ t['monthly_payment'] }}</h1>
        <h5>{{ purchaseType }}</h5>
        <p class="blue-text">
          {{ monthlyPayment ? `${monthlyPayment.toLocaleString(language)} ` : '-' }} <span>{{ `${t['kr_month']}` }}</span>
        </p>
        <table>
          <tr>
            <th>{{ t['loan_amount'] }}</th>
            <th>Term</th>
            <th>APR</th>
          </tr>
          <tr>
            <td>{{ loanValue }}</td>
            <td>{{ termMonths }}</td>
            <td>{{ aprValue }}</td>
          </tr>
        </table>
        <div class="paymentTerms">
          <sub v-html="disclaimer" />
        </div>
        <div class="financingBy">
          <p>{{ t['financed_by'] }}</p>
          <div class="imgContainer">
            <img :src="santanderImage" alt="santander" />
          </div>
        </div>
      </div>
    </div>
    <template v-if="loanDetails">
      <div class="loanDetailsHeader">
        <h2>{{ t['financing_details'] }}</h2>
        <button class="showLoanDetailsButton" @click="showLoanDetails = !showLoanDetails">{{ showLoanDetailsLabel }}</button>
      </div>

      <transition>
        <div v-if="showLoanDetails" class="loanDetails">
          <dl class="loanTable">
            <div v-for="loanDetail in loanDetails" :key="loanDetail.itemLabel">
              <dt>{{ t[`${loanDetail.itemLabel}`] }}</dt>
              <dd>
                <b>{{ loanDetail.value }}</b>
              </dd>
            </div>
          </dl>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { debounce } from '../../logic'
import SeezSdk from '../../sdk.js'
import Loader from '../Loader.ce.vue'
import { lang } from '../lang'
import { santander } from '../../assets/images.js'

const InterestTypes = {
  FIXED: 'fixed',
  VARIABLE: 'variable'
}
Object.freeze(InterestTypes)

export default {
  name: 'SeezCalculator',
  components: { Loader },
  mixins: [lang, SeezSdk.vueQueryMixin],
  props: { listing: { type: String, default: null }, vertical: { type: Boolean, default: false } },
  data() {
    return {
      aopBeforeTax: null,
      paymentTermsOptions: [12, 24, 36, 48, 60, 72, 84, 96],
      carPrice: null,
      apr: null,
      downPayment: null,
      minDownPayment: null,
      paymentTerms: 96,
      loanAmount: null,
      monthlyPayment: null,
      disclaimer: null,
      loading: false,
      downPaymentBelowMin: false,
      loanBelowMin: false,
      value: InterestTypes.VARIABLE,
      downPaymentPct: null,
      showLoanDetails: true,
      data: null,
      interestTypes: InterestTypes,
      loanDetails: null
    }
  },
  computed: {
    t() {
      return this.languageResources.CALCULATOR_COMPONENT_TRANSLATIONS
    },
    loanValue() {
      return this.loanAmount ? `${this.loanAmount.toLocaleString(this.language)} kr.` : '-'
    },
    aprValue() {
      return this.apr ? `${this.apr} %` : '-'
    },
    termMonths() {
      return !this.loading ? `${this.paymentTerms} ${this.t.months}` : '-'
    },
    showLoanDetailsLabel() {
      return this.showLoanDetails ? this.t.hide_loan : this.t.show_loan
    },
    purchaseType() {
      return this.downPaymentPct < 20 ? this.t.bank_loan : this.t.purchase_contract
    },
    santanderImage() {
      return santander
    }
  },
  watch: {
    carPrice() {
      this.calculate()
    },
    paymentTerms() {
      this.calculate()
    },
    value() {
      this.calculate()
    },
    downPayment(v, o) {
      if ((o == null || o < this.minDownPayment) && v === this.minDownPayment) return
      this.percentage = this.percentageCalculation(this.downPayment, this.carPrice)
      this.calculate()
    }
  },
  mounted() {
    if (this.listing) this.requestCalculation()
  },
  methods: {
    calculate: debounce(async function () {
      this.requestCalculation()
    }, 800),
    async requestCalculation() {
      let downPayment = null
      try {
        downPayment = parseFloat(this.downPayment)
      } catch (error) {
        //send null
      }
      if ((downPayment == null && this.listing == null) || !this.paymentTermsOptions.includes(this.paymentTerms)) return
      if (this.listing == null && (this.carPrice == null || this.carPrice <= 0)) return
      this.loanBelowMin = this.carPrice < 50000
      if (this.carPrice < 50000 && this.listing == null) return
      this.abortController?.abort()
      this.abortController = new AbortController()

      const query = `query loanCalculation($listingId: ID $downPayment: Float $paymentTerms: Int $amount: Float $interestType: InterestType) {
          loanCalculation(listingId: $listingId downPayment: $downPayment paymentTerms: $paymentTerms, amount: $amount interestType: $interestType) {
            aopBeforeTax
            financedAmount
            loanAmount
            downPayment
            downPaymentTotal
            downPaymentTradeIn
            downPaymentPct
            totalPayable
            totalLoanCost
            paymentTerms
            monthlyPayment
            nominalInterestRate
            interestType
            interestRate
            apr
            disclaimer
            downPaymentBelowMin
            nominalInterestRate

          }
        }`

      const payload = { downPayment: downPayment, paymentTerms: this.paymentTerms, interestType: this.value }
      if (this.listing) payload.listingId = this.listing
      else payload.amount = this.carPrice

      this.loading = true
      try {
        const { loanCalculation: data } = await this.queryApi(query, payload)
        this.loanAmount = data.loanAmount
        if (this.downPayment == null) this.minDownPayment = data.downPayment
        this.data = data
        this.aopBeforeTax = data.aopBeforeTax
        this.downPayment = data.downPayment
        this.monthlyPayment = data.monthlyPayment
        this.disclaimer = data.disclaimer
        this.downPaymentPct = data.downPaymentPct?.toFixed(0)
        this.apr = data.apr
        this.downPaymentBelowMin = data.downPaymentBelowMin && payload.downPayment != null
        this.loadLoanDetails(data)
        this.loading = false
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          this.loading = false
        }
      }
    },
    loadLoanDetails(data) {
      const loanDetailsArray = [
        { itemLabel: 'aop_before_tax', value: data.aopBeforeTax?.toFixed(2) + ' %' },
        { itemLabel: 'base_amount', value: `${data.loanAmount.toLocaleString(this.language)} kr.` },
        { itemLabel: 'down_payment_pct', value: data.downPaymentPct?.toFixed(2) + ' %' },
        { itemLabel: 'total_loan_costs', value: `${data.totalLoanCost.toLocaleString(this.language)} kr.` },
        { itemLabel: 'financed_amount', value: `${data.financedAmount.toLocaleString(this.language)} kr.` },
        { itemLabel: 'interest_type', value: this.t[`${data.interestType}`] },
        { itemLabel: 'nominal_interest', value: data.nominalInterestRate?.toFixed(2) + ' %' },
        { itemLabel: 'interest_rate', value: data.nominalInterestRate?.toFixed(2) + ' %' },
        { itemLabel: 'total_repay', value: data.totalPayable?.toLocaleString(this.language) + ' kr.' }
      ]

      this.loanDetails = loanDetailsArray
    },
    setPaymentTermsOption(value) {
      this.paymentTerms = value
    },
    handleRadioValue() {
      this.fixed = !this.fixed
      this.floating = !this.floating
    },
    percentageCalculation(givenValue, totalValue) {
      return (100 * givenValue) / totalValue
    }
  }
}
</script>

<style lang="scss">
.calculatorWrapper {
  position: relative;

  * {
    font-family: 'Biennale';
  }

  .loader {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .calculator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: black;
    gap: 2rem;
    padding: 1rem 0rem;

    @media (min-width: 870px) {
      flex-direction: row;
    }

    > .leftContent {
      > h1 {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
        padding: 0 0 2rem 0;
      }

      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.16px;
      }

      label {
        font-size: 1rem;
        font-weight: 700;
      }

      > .percentage {
        display: flex;
        justify-content: space-between;

        > span {
          font-size: 12.8px;
          font-weight: 700;
        }
      }

      > .input {
        margin: 1rem 0;
        display: flex;
        position: relative;

        > input {
          font-size: 1rem;
          padding: 0.9rem 1rem;
          border-radius: 24px;
          width: 100%;
          border: 1px solid #e2e2e2;
          margin-bottom: 1rem;
        }

        > span {
          background-color: #0068ff;
          border-radius: 0px 24px 24px 24px;
          color: white;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: absolute;
          right: 0;
          top: 0.12rem;
          padding: 1.05rem 1.5rem;

          @media (min-width: 768px) {
            top: 0;
            padding: 1.02rem 1.5rem;
          }
        }
      }

      .error {
        > input {
          border-left-color: red;
          border-top-color: red;
          border-bottom-color: red;
        }

        > span {
          border-color: red;

          &::before {
            content: '!';
            color: red;
            position: absolute;
            top: 1em;
            left: -4em;
            border: 1px solid red;
            border-radius: 2em;
            height: 1em;
            width: 1em;
            padding: 0.125em;
            text-align: center;
            line-height: 1em;
            font-weight: bold;
          }
        }
      }

      @media (min-width: 870px) {
        width: 55%;
      }

      > .loanDurationOptions {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @media (min-width: 1012px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        gap: 9px;
        margin: 1rem 0 2rem 0;

        > button {
          background-color: white;
          border: none;
          padding: 14px 17px;
          cursor: pointer;
          border-radius: 6px;
          font-size: 14px;
          white-space: nowrap;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          max-height: 2.8rem;
        }

        > button:hover,
        .active {
          font-weight: 600;
          color: #0068ff;
          max-height: 2.8rem;
        }

        > .active {
          border: 2px solid #0068ff;
          font-weight: 700;
          max-height: 2.8rem;
        }
      }

      > .checkboxMenu {
        display: flex;
        gap: 1rem;
        padding-top: 1rem;

        > label {
          background-color: white;
          border-radius: 8px;
          padding: 0.6rem 0.6rem;
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          display: flex;

          > span {
            padding-inline-start: 0.5rem;
            font-weight: 400;
          }

          > input[type='radio' i] {
            margin: 3px 3px 3px 5px;
          }
        }

        .active {
          border: 2px solid #0068ff;

          > span {
            font-weight: 600;
          }
        }
      }
    }

    > .rightContent {
      background-color: white;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      position: relative;
      font-weight: 700;
      padding: 1em;
      width: 90%;
      
      margin: auto;

      @media (min-width: 415px) {
        max-width: none;
        margin: 0;
      }

      @media (min-width: 870px) {
        width: 30%;
        margin-inline-start: 0.5em;
        padding: 2em;
      }

      > h1 {
        font-size: 1rem;
        font-weight: 600;
        padding: 0;
        margin: 0;
        padding-bottom: 0.5rem;
      }

      > h5 {
        padding: 0;
        margin: 0;
        font-weight: 400;
      }

      .blue-text {
        color: #0068ff;
      }

      > p {
        font-size: 2.8rem;

        @media (min-width: 768px) {
          font-size: 3.3rem;
        }

        line-height: 34px;
        margin: 0;
        padding: 2rem 0 2rem 0;

        > span {
          font-size: 1.2rem;

          @media (min-width: 768px) {
            font-size: 1.5rem;
          }

          padding-inline-start: 1rem;
          padding-top: 1rem;
        }
      }

      > table {
        width: 100%;
        font-size: 1rem;

        > tr {
          > th {
            text-align: start;
            color: #545454;
            font-weight: 400;

            @media (min-width: 870px) {
              width: 33%;
              padding-right: 6px;
            }
          }

          > td {
            text-align: start;
            font-weight: 700;
            padding: 0.5em 0;
          }
        }
      }

      > .paymentTerms {
        font-size: 0.9rem;
        color: #757575;
        padding-top: 0.5em;
        font-weight: 400;

        > sub {
          line-height: 0.9rem;
        }

        > sub {
          margin-top: 2em;
        }
      }

      > .financingBy {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 2.5em;
        background-color: reds;

        @media (min-width: 768px) {
          justify-content: flex-start;
          flex-direction: row;
        }

        > p {
          font-size: 0.9rem;
          font-weight: 600;
          color: #545454;
          padding: 0;
          margin: 0;
          white-space: nowrap;
        }

        > .imgContainer {
          width: 10.75em;
          padding-top: 0.3rem;

          @media (min-width: 768px) {
            padding-top: 0;
            padding-inline-start: 0.8em;
          }

          > img {
            width: 100%;
            height: auto;
          }
        }
      }

      > button {
        position: absolute;
        left: 2.5em;
        bottom: -1.5em;
        border-radius: 24px;
        background: #0068ff;
        border: none;
        color: white;
        padding: 0.75em 1em;
        gap: 1rem;
        width: 12.938em;
        height: 3em;
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;

        &:hover {
          background-color: #0053cc;
        }
      }
    }

    sub {
      display: block;
      font-size: 0.75em;
      line-height: 1.2em;
      color: #afafaf;

      &.error {
        color: red;
      }
    }
  }

  .loanDetailsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-size: 1em;
      font-weight: 700;
      color: black;
    }

    > .showLoanDetailsButton {
      border: none;
      color: #0068ff;
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
    }
  }

  .loanDetails {
    background-color: #ffffff;
    border-radius: 10px;
    color: black;

    > .loanTable {
      display: grid;
      grid-template-columns: 1fr;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      padding: 1rem 0;

      > div {
        display: flex;
        justify-content: space-between;
        padding: 0.8rem 0;
        font-size: 12.8px;
        margin-inline-start: 1rem;
        margin-inline-end: 1rem;
      }

      > div:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
}
</style>
