<template>
  <div id="checkoutForm">
    <loader v-if="loading" />
    <CheckOutSideNav
      v-if="targetSite !== 'seez'"
      :order-id="order.id"
      :name="order.name"
      :listing="order.listing"
      :app-state="order.appState"
      @cancelOrder="cancelOrder"
      :current-stage="order.currentStage"
      :edit-section="editSection"
      data-test-id="checkout-side-nav"
      :target-site="targetSite"
    />
    <SeezSideNav
      v-if="targetSite === 'seez'"
      :order-id="order.id"
      :name="order.name"
      :listing="order.listing"
      :app-state="order.appState"
      @cancelOrder="cancelOrder"
      :current-stage="order.currentStage"
      :edit-section="editSection"
      data-test-id="checkout-side-nav"
      :target-site="targetSite"
      @gotoComponent="gotoComponent"
      @setStepsOverviewModal="setStepsOverviewModal"
    />
    <div class="checkoutContainer" ref="checkoutContainer" id="checkoutContainer">
      <CheckoutTradeIn
        ref="tradeIn"
        :order-id="order.id"
        @update="updateLocalState"
        :current-stage="order.currentStage"
        :state="order.tradeInResponse"
        :errors="order.appState.tradeIn.errors"
        @gotoComponent="gotoComponent"
      />
      <Personal
        ref="personalInformation"
        @update="updateLocalState"
        :current-stage="order.currentStage"
        :state="order.personalInfoResponse"
        :errors="order.appState.customerInfo.errors"
        @gotoComponent="gotoComponent"
      />
      <CarDelivery
        ref="carDelivery"
        @update="updateLocalState"
        :current-stage="order.currentStage"
        :state="order.deliveryResponse"
        :personal-information="order.personalInfoResponse"
        :errors="order.appState.delivery.errors"
        @gotoComponent="gotoComponent"
      />
      <Payment
        ref="payment"
        @update="updateLocalState"
        :current-stage="order.currentStage"
        :listing="order.listing"
        :state="order.paymentResponse"
        :will-trade-in="order.tradeInState.willTradeIn"
        :errors="order.appState.customerInfo.errors"
        @gotoComponent="gotoComponent"
      />
    </div>
    <div class="termsAndConditionPanel">
      <span class="terms">{{ t['termsAndConditions'] }}</span>
      <span class="orderSummary" @click="openSummaryModal()" data-test-id="checkout-open-summary">{{ t['viewOrderSummary'] }}</span>
    </div>
    <div class="confirmPanel">
      <SeezButton v-if="order.currentStage === 'tradeIn'" type="button" @click="gotoComponent('personalInformation')" data-test-id="checkout-next-step-personal-info">{{
        t['next'] + ' ' + t['personalInfo'] + ' >'
      }}</SeezButton>

      <SeezButton v-if="order.currentStage === 'customerInfo'" type="button" @click="gotoComponent('carDelivery')" data-test-id="checkout-next-step-personal-info">{{
        t['next'] + ' ' + t['delivery']
      }}</SeezButton>

      <SeezButton v-if="order.currentStage === 'delivery'" type="button" @click="gotoComponent('payment')" data-test-id="checkout-next-step-personal-info">{{
        t['next'] + ' ' + t['payment']
      }}</SeezButton>

      <SeezButton v-if="order.currentStage === 'payment'" type="button" @click="confirmOrder" :disabled="disabledConfirmButton()" data-test-id="checkout-confirm-order">{{
        t['confirmOrderDetails']
      }}</SeezButton>
    </div>
  </div>
</template>

<script>
import { lang } from '../../lang'
import CheckOutSideNav from './SideNav.ce.vue'
import SeezSideNav from './SeezSideNav.ce.vue'
import CheckoutTradeIn from './1-TradeIn.ce.vue'
import Payment from './4-Payment.ce.vue'
import Personal from './2-Personal.ce.vue'
import CarDelivery from './3-CarDelivery.ce.vue'
import Loader from '../../Loader.ce.vue'
import SeezButton from '../components/SeezButton.ce.vue'
import { closeIcon } from '../../../assets/images'

export default {
  name: 'CheckoutForm',
  components: { CheckOutSideNav, CheckoutTradeIn, Payment, Personal, CarDelivery, Loader, SeezButton, SeezSideNav },
  mixins: [lang],
  props: {
    order: { type: Object, required: true },
    editSection: { type: String, default: '' },
    targetSite: { type: String, default: 'seez' },
    scrollToStep: { type: String, default: null }
  },
  data() {
    return {
      intersectionObserver: new IntersectionObserver(this.updateOrderState, { threshold: 0.1, rootMargin: '0px', root: document.querySelector('#checkoutContainer') }),
      loading: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    closeIcon() {
      return closeIcon
    },
    initialOffsetTop() {
      const el = this.$refs.tradeIn.$el
      return el.offsetTop
    }
  },
  watch: {
    scrollToStep(name) {
      this.gotoComponent(name)
    }
  },
  mounted() {
    this.intersectionObserver.observe(this.$refs.tradeIn.$el)
    this.intersectionObserver.observe(this.$refs.personalInformation.$el)
    this.intersectionObserver.observe(this.$refs.carDelivery.$el)
    this.intersectionObserver.observe(this.$refs.payment.$el)
  },
  unmount() {
    this.intersectionObserver.unobserve(this.$refs.tradeIn.$el)
    this.intersectionObserver.unobserve(this.$refs.personalInformation.$el)
    this.intersectionObserver.unobserve(this.$refs.carDelivery.$el)
    this.intersectionObserver.unobserve(this.$refs.payment.$el)
  },
  methods: {
    disabledConfirmButton() {
      if (!this.order) return true
      return !this.order.allStepsComplete(this.order.currentStage)
    },
    updateLocalState(stateName, stateValue, forcePreviousStage) {
      this.$emit('updateLocalState', stateName, stateValue, forcePreviousStage)
    },
    updateOrderState(entries) {
      // entries = entries.filter(ent => ent.intersectionRatio > 0.15)
      entries = entries.filter(ent => ent.isIntersecting)
      if (entries.length > 0) this.$emit('updateOrderState', entries)
    },
    confirmOrder() {
      this.$emit('confirmOrder')
    },
    cancelOrder() {
      this.$emit('cancelOrder')
    },
    openSummaryModal() {
      this.$emit('openSummaryModal')
    },
    gotoComponent(ref) {
      const el = this.$refs[ref].$el
      this.$refs.checkoutContainer.scrollTo({ top: el.offsetTop - this.initialOffsetTop, behavior: 'smooth' })
    },
    setStepsOverviewModal(status) {
      this.$emit('setStepsOverviewModal', status)
    }
  }
}
</script>
<style lang="scss" scoped>
#checkoutForm {
  @media screen and (max-width: 56rem) {
    display: block;
    .checkoutContainer {
      height: 100%;
    }
  }
  display: grid;
  grid-template-columns: 354px 1fr;
  grid-template-rows: 1fr 70px;
  height: 75vh;
  font-family: 'Biennale';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 18px;
  color: #000000;
  .summaryModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    backdrop-filter: brightness(60%);
    > .summaryModal {
      background: white;
      padding: 1rem;
      border-radius: 12px;
      border: 1px solid #ccc;
      padding: 1rem 0 1rem 1rem;
      > .closeIcon {
        cursor: pointer;
        display: flex;
        justify-content: end;
        margin: 0.4rem;
      }
      > .reviewWrapper {
        max-height: 95%;
        overflow: hidden;
      }
    }
  }

  > .loader {
    position: absolute;
    z-index: 100;
  }
  .checkoutContainer {
    overflow: auto;
    scroll-snap-type: y mandatory;
    > div {
      min-height: 100vh;
      scroll-snap-align: start;
      scroll-snap-stop: always;
      position: relative;
      width: 90%;
      padding: 1.4375rem 2.5rem 40rem 2.5rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      max-width: 52rem;
      @media screen and (max-width: 56rem) {
        min-height: 85rem;
        padding: 1rem;
      }
    }
  }
  .termsAndConditionPanel,
  .confirmPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
  }
  .confirmPanel {
    border-top: 1px solid #eeeeee;
  }
  .termsAndConditionPanel {
    border-right: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    font-size: 1.1em;
    .terms {
      font-family: 'Red Hat Display';
      line-height: 14px;
      color: #545454;
    }
    .orderSummary {
      font-style: normal;
      font-weight: 700;
      color: #0068ff;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 56rem) {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.4375rem;
    color: #545454;
    .termsAndConditionPanel {
      display: none;
    }
    .confirmPanel {
      position: sticky;
      bottom: 0;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
      border-radius: 16px 16px 0px 0px;
      padding: 1.625rem;
      > .button {
        font-family: 'Biennale';
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
        font-style: normal;
        font-weight: 700;
        color: #ffffff;
      }
    }
  }
}
</style>
