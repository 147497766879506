<template>
  <div class="imageUpload">
    <loader v-if="loading" />
    <p>
      <!-- Icon -->
      {{ t[title] }}
    </p>
    <div :class="'uploadSection' + (isPicturesFieldIsInvalid ? ' invalidImages' : '')" @drop.prevent="dropFiles" @dragover.prevent @click="$refs.selectFiles.click">
      <img :src="baseUrl + currentImage" :alt="t['upload']" v-if="currentImage" />
      <div class="uploadMessage">
        <img :src="placeholder" :alt="t['upload']" width="40" v-if="!currentImage" />
        <div class="body">
          <label class="tradeInImagesLabel" v-if="!isMobile">
            <input type="file" @change="selectFile" ref="selectFiles" accept=".pdf,.jpg,.jpeg,.png" />
            {{ t['addPhoto'] }}
          </label>
          <div v-if="isMobile">{{ t['addPhoto'] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { lang } from '../../lang'
import SeezSdk from '../../../sdk.js'
import { uploadPlaceHolder } from '../../../assets/images'
import Loader from '../../Loader.ce.vue'

export default {
  name: 'UploadImage',
  components: { Loader },
  mixins: [lang, SeezSdk.vueQueryMixin],
  props: {
    title: { type: String, required: true },
    type: { type: String, default: 'other' },
    currentImage: { type: String, default: '' }
  },
  data() {
    return {
      isPicturesFieldIsInvalid: false,
      loading: false
    }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    isMobile() {
      return window.innerWidth < 768
    },
    placeholder() {
      return uploadPlaceHolder
    },
    baseUrl() {
      return import.meta.env.VITE_IMAGES_URL + '/'
    }
  },
  watch: {
    currentImage() {
      this.loading = false
    }
  },
  methods: {
    async selectFile() {
      const file = this.$refs.selectFiles.files[0]
      if (!file) return
      this.loading = true
      this.$emit('uploadImage', file, this.type)
    },
    async dropFiles(event) {
      const file = event.dataTransfer.files[0]
      this.loading = true
      this.$emit('uploadImage', file, this.type)
    }
  }
}
</script>
<style lang="scss" scoped>
.imageUpload {
  position: relative;
  margin: 0 auto;
  > .loader {
    position: absolute;
    z-index: 100;
  }
  > p {
    line-height: 1.375rem;
    color: #000000;
    margin-top: 0.625rem;
  }
  > .uploadSection {
    width: 11rem;
    height: 6.75rem;
    position: relative;
    transition: all 0.1s ease;
    &:hover {
      border: 1px dashed #1500ff;
      background: rgba(204, 225, 255, 0.8);
    }
    &.invalidImages {
      background: #ffb3b580;
      border: 1px dashed #ff0009;
    }
    &.invalidImages:hover {
      background: #ffb3b599;
      border: 1px dashed #ff0009;
    }
    background: rgba(204, 225, 255, 0.5);
    border: 1px dashed #0068ff;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .body {
      text-align: center;
    }
    input[type='file'] {
      opacity: 0;
      width: 1px;
      height: 1px;
    }
    img {
      width: 100%;
    }
    .uploadMessage {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        max-width: 1.75rem;
      }
      > .body {
        font-style: normal;
        font-weight: 700;
        font-size: 0.8rem;
        line-height: 1.125rem;
        color: #0068ff;
      }
    }
  }
  @media screen and (max-width: 56rem) {
    > .uploadSection {
      padding: 1.25rem 0.625rem;
    }
    .uploadMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        max-width: 1.75rem;
      }
    }
  }
  label.tradeInImagesLabel {
    cursor: pointer;
    padding: 0;
  }
}
</style>
