export const tradeInPictureQuery = `
    url
    type
`

export const tradeInQuery = `
    id
    vin
    licensePlate
    make
    model
    year
    variant
    registrationDate
    kilometrage
    hasFinancing
    outstandingFinanceAmount
    customerComment
    totalAmount
    offerAmount
    state
    fuelType
    carCondition
    registrationType
    color
    numberOfKeys
    accident
    serviceType
    pictures {
        ${tradeInPictureQuery}
    }
`

export const spaQuery = `
id
state
previewHTML
documentId
`

export const appStateQuery = `
        current
        customerInfo {
            sequence
        state
        errors
        }
        tradeIn {
        sequence
        state
        errors
    }
        payment {
        sequence
        state
        errors
    }
        customization {
        sequence
        state
        errors
    }
        delivery {
        sequence
        state
        errors
    }
`

export const deliveryQuery = `
    type
    preferredDate
    deliveryDate {
        startTime
      length
    }
    address {
    street
    street2
    postalCode
    city
    state
    country
    }
`

export const pricingBankTransferQuery = `
    vehiclePrice
    addonsPrice
    valueAddedServicesPrice
    deliveryPrice
    licensePlatePrice
    tradeInAmount
    tradeInOffer
    tradeInFinancing
    subtotal
    total
    final
    downPayment
`

export const paymentQuery = `
  type
  state
  financing {
    returnUrl
    state
    redirectUrl
    loanAmount
    downPayment
    downPaymentPct
    aopBeforeTax
    financedAmount
    totalPayable
    paymentTerms
    nominalInterestRate
    interestRate
    interestType
    totalPayable
    totalLoanCost
    monthlyPayment
    disclaimer
    downPaymentBelowMin
    apr
    }
  bankTransfer {
    state
    pricing {
      ${pricingBankTransferQuery}
    }
}
`

export const customerInfoQuery = `
    firstName
    middleName
    lastName
    email
    phone
    insurance
    nationalID
    name
    address {
        street
        street2
        postalCode
        city
        state
        country
    }
`

export const listingAddOnQuery = `
    id
    name
    description
    currency
    price
`

export const listingQuery = `
    id
        brand { name }
        model { name }
    variant
    priceRetail
    primaryImageUrl
    year
    reservationExpires
    referenceNumber
        showroom {
        registry
        bankaccount
        bankRegistration
    }
        dealership {
        name
        bankName
    }
    pictures
        addons {
        ${listingAddOnQuery}
    }
    vehicleId
    kilometrage
    color
    registrationDate
        family { name }
        fuelType { name }
        transmission { name }
        bodyType { name }
`

export const orderStateQuery = `
    id
    state
    customerId
        listing {
        ${listingQuery}
    }
        customerInfo {
        ${customerInfoQuery}
    }
        payment {
        ${paymentQuery}
    }
        customization {
        addons {
            ${listingAddOnQuery}
        }
    }
        delivery {
        ${deliveryQuery}
    }
        appState {
        ${appStateQuery}
    }
        spa {
        ${spaQuery}
    }
        tradeIn {
        ${tradeInQuery}
    }
`

export function dQuoteWrapper(value) {
  if (value) return `"${value}"`
  return null
}
