<template>
  <div :class="['orderCard', order.state]">
    <img :src="imageUrl" @error="imageError = true" />
    <div>
      <span>{{ order.listing.year }} &middot; {{ order.listing.kilometrage.toLocaleString($root.language) }} {{ t['km'] }}</span>
      <h3>{{ order.listing.name }}</h3>
      <span>{{ order.listing.variant }}</span>
    </div>
    <div>
      <span>{{ t['price'] }}</span>
      <br />
      <span class="big" v-if="order.listing.priceRetail">{{ order.listing.priceRetail.toLocaleString($root.language) }} {{ t['kr'] }}</span>
    </div>
    <span v-if="order.state !== 'ongoing'" :class="['status', order.state]">{{ t[`${order.state}`] || order.state }}</span>
    <!-- <a :href="'/checkout?order=' + order.id + '#summary'" class="summary" v-if="['completed', 'ongoing'].includes(order.state)">{{ t['order_summary'] }}</a> -->
    <button v-if="order.state === 'expired'">
      <img
        src="data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1 5.16C10.1 7.64 8.08 9.64 5.62 9.64C4.64 9.64 3.7 9.34 2.92 8.76C2.64 8.54 2.58 8.14 2.8 7.86C3.02 7.58 3.42 7.52 3.7 7.72C4.26 8.14 4.92 8.36 5.62 8.36C7.38 8.36 8.82 6.94 8.82 5.16C8.82 3.4 7.38 1.98 5.62 1.98C4.86 1.98 4.16 2.24 3.58 2.7L4.5 3.6C4.84 3.94 4.58 4.54 4.1 4.54H1.2C0.98 4.54 0.82 4.36 0.82 4.14V1.24C0.82 0.74 1.4 0.5 1.74 0.84L2.68 1.8C3.48 1.1 4.52 0.7 5.62 0.7C8.08 0.7 10.1 2.7 10.1 5.16Z' fill='%230068FF'/%3E%3C/svg%3E%0A"
      />
      {{ t['restart_order'] }}
    </button>
    <div class="actionButtons">
      <a @click="track" v-if="order.state === 'ongoing'" :href="checkoutUrl" class="button">{{ t['resume_purchase'] }}</a>
      <button @click="checkout" class="button" v-if="['confirmed', 'done'].includes(order.state)">{{ t['view_order'] }}</button>
    </div>
  </div>
</template>

<script>
import { analyticsMixin, analyticsEvents } from '../../analytics.js'
import { lang } from '../lang'
import { CAR_PLACEHOLDER } from '../../constants.js'

export default {
  name: 'SeezOrderCard',
  mixins: [lang, analyticsMixin],
  props: {
    order: { type: Object, required: true },
    to: { type: String, required: true }
  },
  data() {
    return {
      events: analyticsEvents,
      imageError: false
    }
  },
  computed: {
    t() {
      return this.languageResources.ORDER_CARD_COMPONENT_TRANSLATIONS
    },
    checkoutUrl() {
      return this.to.replace('{id}', this.order.id)
    },
    summaryAvailable() {
      const states = ['done', 'ongoing', 'confirmed']
      return states.includes(this.order?.state)
    },
    imageUrl() {
      if (this.imageError) return CAR_PLACEHOLDER
      // this.order.listing.pictures[0]
      const d = this.order.listing
      if (d.imageIds && d.imageIds.length > 0) return `${import.meta.env.VITE_IMAGES_URL}/image/400x0/listings/${d.id}/${d.imageIds[0]}.webp`
      if (d?.pictures?.[0] == null) return CAR_PLACEHOLDER
      return (import.meta.env.VITE_IMAGES_URL + d.pictures[0]).replace(/\/1600x0\//, '/400x0/')
    }
  },
  methods: {
    savePageReferrer(){
      localStorage.setItem('page_referrer', window.location.href)
    },
    track() {
      this.savePageReferrer()
      this.trackCTA(analyticsEvents.START_PJ, {
        category: 'enhanced_ecommerce',
        type: 'start_pj',
        name: this.$route.params.id,
        listing: this.order
      })
    },
    checkout() {
      this.savePageReferrer()
      window.location = this.checkoutUrl
    }
  }
}
</script>

<style lang="scss">
.orderCard {
  --cardHue: 235;

  display: grid;
  grid-template-columns: auto 3fr 2fr auto;
  grid-template-rows: 1fr auto;
  gap: 1em;
  padding: 1em;
  margin: 1em 0;
  border-radius: 0 2em 2em 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-left: 0.5ch solid hsl(var(--cardHue), 100%, 50%);
  text-align: left;

  &.cancelled,
  &.expired {
    --cardHue: 0;
  }

  &.completed {
    --cardHue: 180;
  }

  &.onhold {
    --cardHue: 45;
  }

  > img {
    width: 12em;
    margin: -1em 0 -1em -1em;
    grid-row-end: span 2;
  }

  h3 {
    color: #1a2634;
    font-size: 1.25em;
    margin: 0;
    white-space: nowrap;
  }

  span {
    color: #afafaf;

    &.big {
      color: #1a2634;
      font-size: 1.25em;
      font-weight: bold;
    }

    &.status {
      border-radius: 2em;
      padding: 0.25em 1em;
      align-self: start;
      color: hsl(var(--cardHue), 100%, 50%);
      background-color: hsl(var(--cardHue), 100%, 90%);

      &.ongoing {
        display: none;
      }
    }
  }

  > .summary {
    color: var(--highlight);
    cursor: pointer;
    grid-area: 2 / 2;
    align-self: center;
  }

  > .actionButtons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    grid-area: 2 / 2 / 3 / 5;

    @media screen and (max-width: 50rem) {
      grid-area: auto;
    }

    > a.button {
      grid-area: 2 / 2 / 3 / 5;
      justify-self: end;
      padding: 0.5em 1.5em;
      border: none;
      border-radius: 2em;
      background-color: var(--highlight);
      color: white;
      cursor: pointer;
    }

    .button {
      @media screen and (max-width: 50rem) {
        margin-inline-end: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  button {
    grid-area: 2 / 3 / 2 / span 2;
    justify-self: end;
    padding: 0.5em 1.5em;
    border: 1px solid var(--highlight);
    border-radius: 2em;
    background-color: white;
    color: var(--highlight);
    cursor: pointer;
  }

  &.ongoing {
    > img {
      width: 20em;
    }

    > div:nth-child(3) {
      justify-self: end;
    }
  }

  @media screen and (max-width: 65rem) {
    &.ongoing {
      > img {
        height: 14em;
      }
    }
  }

  @media screen and (max-width: 50rem) {
    grid-template-columns: auto;
    padding: 0;

    > img {
      width: 100%;
      margin: 0;
      border-radius: 0 2em 0 0;
    }

    span {
      margin-left: 1em;

      &.status {
        width: fit-content;
        justify-self: end;
        margin: 1em;
      }

      &.big {
        margin-left: 0.6em;
      }
    }

    h3 {
      margin-left: 0.6em;
      margin-block-start: 0;
    }

    &.ongoing {
      grid-template-columns: none;
      > img {
        width: 100%;
        height: auto;
      }

      h3 {
        grid-area: 3/1;
      }

      span {
        grid-area: 3/1;
      }

      > a.button {
        grid-area: 5/1;
        justify-self: end;
        padding: 0.5em 1.5em;
        border: none;
        border-radius: 2em;
        background-color: var(--highlight);
        color: white;
        cursor: pointer;
        margin: 0 1em 1em 0;
      }

      > div:nth-child(3) {
        justify-self: start;
        grid-area: 4/1;
      }

      .summary {
        grid-area: 5/1;
        margin-left: 1em;
      }
    }
  }
}
</style>
