export default class SeezSdk {
  #handoutToken = null
  #accessToken = null
  #refreshPromise = null
  #alreadyChecked = false
  #baseHeaders = { 'Accept': '*/*', 'Content-Type': 'application/json' }
  #favorites = JSON.parse(localStorage.getItem('favorites')) ?? []
  #savedSearches = JSON.parse(localStorage.getItem('searches')) ?? []

  constructor() {
    this.clientId = document?.querySelector('[data-seez-client-id]')?.getAttribute('data-seez-client-id')
    if (this.clientId) this.#baseHeaders['Client-Id'] = this.clientId
    this.#parseHandoutFromUrl()
  }

  customizeStyles(tag) {
    const name = tag.tagName.toLowerCase()
    const styles = []
    for (const ss of document.styleSheets) {
      for (const rule of ss.cssRules) {
        if (rule.cssText.startsWith(name)) styles.push(rule.cssText.replace(name, ''))
      }
    }
    if (styles.length > 0) {
      const styleSheet = document.createElement('style')
      styleSheet.innerHTML = styles.join(' ')
      tag.shadowRoot.appendChild(styleSheet)
    }
  }

  //#region modals
  showMessage(content) {
    let promiseResolve = null

    function closed() {
      document.body.querySelectorAll('seez-sdk-modal').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve()
    }

    const modalComponent = document.createElement('seez-sdk-modal')
    modalComponent.innerHTML = content
    document.body.appendChild(modalComponent)
    modalComponent.addEventListener('close', closed)

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }
  //#endregion

  //#region Buy Button
  async getLinksForListings(externalIds) {
    const uniqueExternalIds = [...new Set(externalIds)]
    let seezLinks = []
    try {
      const response = await this.queryApi(
        'mutation l($ids: [String]){generateLinksFromExternalIds(externalId: $ids)}',
        { ids: uniqueExternalIds }
      )
      seezLinks = response.generateLinksFromExternalIds
    } catch (error) {
      console.error(error)
    }
    return externalIds.reduce((t, c, i) => { if (seezLinks[i]) t[c] = seezLinks[i]; return t }, {})
  }

  async injectSeezOnlineBuying(forceRefresh = false, callback) {
    const selector = '[data-listing-id]:not(.statusReady):not(.statusError)'
    const tags = [...document.querySelectorAll(forceRefresh ? '[data-listing-id]' : selector)]
    if (tags.length > 0) {
      tags.forEach(t => t.classList.remove('statusReady', 'statusError'))
      tags.filter(t => !t.classList.contains('customStyles')).forEach(t => t.style.visibility = 'hidden')

      const links = await this.getLinksForListings(tags.map(t => t.getAttribute('data-listing-id')))
      for (const tag of tags) {
        const externalId = tag.getAttribute('data-listing-id')
        tag.classList.add(externalId in links ? 'statusReady' : 'statusError')
        if (externalId in links) {
          if (tag.tagName === 'A') {
            tag.href = links[externalId]
          } else {
            tag.onclick = () => window.location = links[externalId]
          }
          if (!tag.classList.contains('customStyles')) tag.style.visibility = null
        }
      }
    }
    if (callback) callback(tags)
  }
  //#endregion

  //#region Session
  async requestCode(email) {
    const url = `${import.meta.env.VITE_AUTH_URL}/otp?email=${encodeURIComponent(email)}`
    const requestOTPResponse = await fetch(url)
    return requestOTPResponse.json()
  }

  #navigateToPost(url, payload) {
    var form = document.createElement('form')
    form.style.visibility = 'hidden'
    form.method = 'POST'
    form.action = url
    for (const key in payload) {
      var input = document.createElement('input')
      input.name = key
      input.value = payload[key]
      form.appendChild(input)
    }
    document.body.appendChild(form)
    form.submit()
  }

  async loginWithOTP(email, otp, acceptsMarketing) {
    const payload = {
      method: 'POST',
      headers: { 'Accept': '*/*', 'Content-Type': 'application/json', },
      body: JSON.stringify({ email: email, otp: otp })
    }
    const response = await fetch(import.meta.env.VITE_AUTH_URL + '/validate', payload)
    const { valid } = await response.json()
    if (!valid) throw new Error('Invalid email/OTP')
    const url = import.meta.env.VITE_AUTH_URL + '/login?redirect=' + encodeURIComponent(window.location)
    this.#navigateToPost(url, { email: email, otp: otp, marketing: acceptsMarketing })
  }

  #parseHandoutFromUrl() {
    var url = new URL(window.location)
    const ht = url.searchParams.get('ht')
    if (ht && this.#isTokenValid(ht)) {
      this.#handoutToken = ht
      url.searchParams.delete('ht')
      if (url.hash === '#_=_') url.hash = ''
      window.history.replaceState({}, '', url.toString())
    }
  }

  logout() {
    this.#cleanUserDetails()
    localStorage.removeItem('refresh_token')
    window.location.reload()
    // window.location = import.meta.env.VITE_AUTH_URL + '/logout?redirect=' + encodeURIComponent(window.location)
  }

  showLogin(bannerState) {
    let promiseResolve = null

    function closed() {
      document.body.querySelectorAll('seez-sdk-login').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve()
    }

    const loginComponent = document.createElement('seez-sdk-login')
    if (bannerState) loginComponent.setAttribute('brand-banner', bannerState)
    this.customizeStyles(loginComponent)
    document.body.appendChild(loginComponent)
    loginComponent.addEventListener('close', closed)

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }

  closeLogin() { document.body.querySelectorAll('seez-sdk-login').forEach(x => x.parentNode.removeChild(x)) }

  async #refreshAccessToken() {
    try {
      // const refreshResponse = await fetch(import.meta.env.VITE_AUTH_URL + '/refresh', { credentials: 'include', mode: 'cors' })
      if (this.#handoutToken) {
        const payload = { method: 'POST', headers: { 'Accept': '*/*', 'Content-Type': 'application/json', }, body: JSON.stringify({ handoutToken: this.#handoutToken }) }
        const response = await fetch(import.meta.env.VITE_AUTH_URL + '/handout', payload)
        const { refreshToken } = await response.json()
        localStorage.setItem('refresh_token', refreshToken)
      }
      const rt = localStorage.getItem('refresh_token')
      if (rt) {
        const payload = { method: 'POST', headers: { 'Accept': '*/*', 'Content-Type': 'application/json', }, body: JSON.stringify({ refreshToken: rt }) }
        const refreshResponse = await fetch(import.meta.env.VITE_AUTH_URL + '/refresh', payload)
        const newAccessToken = await refreshResponse.text()
        this.#accessToken = this.#isTokenValid(newAccessToken) ? newAccessToken : null
      }
      if (this.#handoutToken) {
        await this.#retrieveUserDetails()
        this.#handoutToken = null
      }
    } catch (error) {
      this.#accessToken = null
      console.error(error)
    }
    this.#alreadyChecked = true
    return this.#accessToken
  }

  #parseJwt(jwt) {
    try {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const claimsString = atob(base64)
      return JSON.parse(claimsString)
    } catch (error) {
      return null
    }
  }

  #isTokenValid(token) {
    try {
      const jwtRegEx = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/
      if (token == null || !jwtRegEx.test(token)) return false
      const { exp } = this.#parseJwt(token)
      const now = Math.floor((new Date().getTime() + 1) / 1000)
      if ((exp - now) < 30) return false
      return true
    } catch (error) {
      return false
    }
  }

  getAccessToken(forceRefresh) { // unify promise
    if (forceRefresh) {
      this.#alreadyChecked = false
      this.#accessToken = null
    }

    if (this.#alreadyChecked && this.#accessToken == null) return null
    if (this.#isTokenValid(this.#accessToken)) return this.#accessToken
    if (this.#refreshPromise == null) {
      this.#refreshPromise = this.#refreshAccessToken()
      this.#refreshPromise.then(() => this.#refreshPromise = null)
    }
    return this.#refreshPromise
  }

  async getCurrentUser() {
    const at = await this.getAccessToken()
    return this.#parseJwt(at)
  }
  //#endregion

  //#region api
  async queryApi(query, variables, signal) {
    const at = await this.getAccessToken()
    const body = { query: query }
    if (variables) body.variables = variables
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { ...this.#baseHeaders }
    }
    if (at) request.headers.authorization = `Bearer ${at}`
    if (signal) request.signal = signal

    const response = await fetch(import.meta.env.VITE_API_URL, request)
    const result = await response.json()
    if (result.errors)
      throw new Error(result.errors.map(e => e.message).join('\r\n'))
    return result.data

  }

  static vueQueryMixin = {
    data() {
      return {
        abortController: new AbortController()
      }
    },
    beforeDestroy() {
      this.abortController?.abort()
    },
    methods: {
      queryApi(query, variables) {
        return new Promise((resolve, reject) => {
          window.seezSdk.queryApi(query, variables, this.abortController?.signal)
            .then(resolve)
            .catch(e => {
              if (e.name !== 'AbortError')
                reject(e)
            })
        })
      },
      async uploadFile(folder, file, isPublic = false) {
        if (folder == null || folder === '') throw new Error('Invalid folder')
        if (file == null) throw new Error('Invalid file')
        const safeFolder = folder.toLowerCase().replace(/[\s-@]+/g, '-').replace(/^-+/, '').replace(/-+$/, '')
        const safeFilename = encodeURIComponent(file.name.toLowerCase().replace(/[\s-@]+/g, '-').replace(/^-+/, '').replace(/-+$/, ''))
        const key = `${safeFolder}/${safeFilename}`
        const { getUploadUrl } = await window.seezSdk.queryApi(`mutation {getUploadUrl(fileName: "${key}", isPublic: ${isPublic})}`)
        await fetch(getUploadUrl, { method: 'PUT', body: file, headers: { 'Content-Type': file.type } })
        return key
      }
    }
  }

  //#endregion

  //#region Favorites
  getFavorites() {
    return this.#favorites
  }

  async #persistFavorites() {
    localStorage.setItem('favorites', JSON.stringify(this.#favorites))
    if (await this.getCurrentUser()) {
      const result = await this.queryApi('mutation sf($ids: [ID]) {saveAllFavorites(listingIds: $ids)}', { ids: this.#favorites })
      const savedIds = result.saveAllFavorites.map(x => parseInt(x))
      localStorage.setItem('favorites', JSON.stringify(savedIds))
    }
  }

  // export function getDb(name, version) {
  //   let dbReq = indexedDB.open(name, version)

  //   return new Promise((resolve, reject) => {
  //     dbReq.onupgradeneeded = (event) => {
  //       let notes = event.target.result.createObjectStore('notes', { autoIncrement: true })
  //       console.log(notes)
  //       resolve(event.target.result)
  //     }
  //     dbReq.onsuccess = (event) => resolve(event.target.result)
  //     dbReq.onerror = reject
  //   })
  // }

  // export async function getFavorites() {
  //   const db = await getDb('seez', 1)
  //   let tx = db.transaction(['favorites'], 'readonly');
  //   let store = tx.objectStore('favorites');
  //   let req = store.get(1);
  // }

  setFavorite(listingId, favorited) { //if not favorited is specified 'toggle' is assumed 
    const id = parseInt(listingId)
    const isFavorited = (typeof favorited === 'boolean') ? favorited : !this.#favorites.includes(id)
    if (isFavorited === false) {
      this.#favorites = this.#favorites.filter(x => x !== id)
      this.#persistFavorites()
    } else if (!this.#favorites.includes(id)) {
      const newList = [...this.#favorites, id]
      newList.sort()
      this.#favorites = newList
      this.#persistFavorites()
    }
  }

  static vueFavoritesMixin = {
    data() {
      return {
        favorites: window.seezSdk.getFavorites()
      }
    },
    methods: {
      setFavorite(listingId, favorited) {
        window.seezSdk.setFavorite(listingId, favorited)
        this.favorites = window.seezSdk.getFavorites()
      }
    }
  }
  //#endregion

  //#region Saved Searches
  getSavedSearches() {
    return this.#savedSearches
  }

  #persistsSavedSearches() {
    localStorage.setItem('searches', JSON.stringify(this.#savedSearches))
  }

  async addSearchInServer(name, filter) {
    const query = 'mutation saveSearch($name: String!, $filter: ListingFiltersInput!) { saveSearch(searchName: $name, filterParameters: $filter) {id} }'
    const { saveSearch } = await window.seezSdk.queryApi(query, { name: name, filter: filter })
    const newId = saveSearch[saveSearch.length - 1].id
    return newId
  }

  async addSearch(name, filter) {
    const minId = this.#savedSearches.reduce((t, c) => Math.min(c.id, t), 0)
    const newList = [...this.#savedSearches, { id: minId - 1, name: name, filterParameters: filter }] // creates negative index so the server knows it should be 'seq'
    this.#savedSearches = newList
    this.#persistsSavedSearches()
    if (await this.getCurrentUser()) {
      newList[newList.length - 1].id = this.addSearchInServer(name, filter)
      this.#savedSearches = newList
      this.#persistsSavedSearches()
    }
  }

  async removeSearch(id) {
    this.#savedSearches = this.#savedSearches.filter(x => x.id === parseInt(id))
    this.#persistsSavedSearches()
    if (await this.getCurrentUser() && id > 0)
      await window.seezSdk.queryApi(`mutation {removeSearch(id: ${id}) {id}}`)
  }

  static vueSavedSearchesMixin = {
    data() {
      return {
        savedSearches: window.seezSdk.getSavedSearches()
      }
    },
    methods: {
      addSearch(name, filter) {
        window.seezSdk.addSearch(name, filter)
        this.savedSearches = window.seezSdk.getSavedSearches()
      },
      removeSearch(id) {
        window.seezSdk.removeSearch(id)
        this.savedSearches = window.seezSdk.getSavedSearches()
      }
    }
  }

  async #retrieveUserDetails() {
    if ((await this.getCurrentUser()) == null) return
    const { user } = await this.queryApi('{user{favorites {listingId},savedSearches{id,name,filterParameters{bodyTypes,brands,colors,driveTypes,engineSizeMax,freeText,fuelTypes,kilometrageMin,kilometrageMax,models,numberOfDoorsMax,numberOfDoorsMin,priceMin,priceMax,priceType,sort,transmissions,yearMin,yearMax}}}}')

    const userFavorites = user.favorites.map(l => parseInt(l.listingId))
    const newLocalFavorites = this.getFavorites().filter(f => !userFavorites.includes(f))
    if (newLocalFavorites.length > 0) {
      userFavorites.push(...newLocalFavorites)
      userFavorites.sort()
      this.#favorites = userFavorites
      this.#persistFavorites()
    } else {
      this.#favorites = userFavorites
      localStorage.setItem('favorites', JSON.stringify(this.#favorites))
    }

    const searches = user.savedSearches
    const newLocalSearches = this.getSavedSearches().filter(s => !searches.some(x => x.name === s.name) && s.id < 0)
    this.#savedSearches = searches
    for (const newSearch of newLocalSearches) {
      this.addSearch(newSearch.name && newSearch.filter)
    }
    this.#persistsSavedSearches()
  }

  #cleanUserDetails() {
    this.#favorites = []
    localStorage.removeItem('favorites')
    this.#savedSearches = []
    localStorage.removeItem('searches')
  }
  //#endregion

  //#region Order
  async createOrder(listingId, external_marketplace_link) {
    if ((await this.getCurrentUser()) == null) throw new Error('User not found')
    const { listing, user: { activeOrder } } = await this.queryApi(`{listing(id:${listingId}){state}user{activeOrder{id,state,listing{id}}}}`)
    if (activeOrder?.listing?.id && parseInt(activeOrder.listing.id) === parseInt(listingId)) return activeOrder.id //the user already started an order for this vehicle
    if (listing.state !== 'available') {
      return this.showUnavailableListingModal(external_marketplace_link)
    }
    if (activeOrder?.listing?.id) {
      const decision = await this.confirmCancelOrder(activeOrder.listing.id)
      if (decision === 'Nothing') return null
      if (decision === 'ResumeCurrent') return activeOrder.id
      if (decision === 'CancelAndStartNew') await this.queryApi(`mutation {cancelOrder(orderId: ${activeOrder.id}) {id} }`)
    }

    const { createOrder } = await this.queryApi(`mutation{createOrder(input:{listingId:${listingId}}){id}}`)
    return createOrder.id
  }

  async confirmCancelOrder(listingId) {
    let promiseResolve = null

    function closed(e) {
      document.body.querySelectorAll('seez-sdk-active-order-cancellation').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve(e.detail[0])
    }

    const orderCancellationComponent = document.createElement('seez-sdk-active-order-cancellation')
    this.customizeStyles(orderCancellationComponent)
    orderCancellationComponent.setAttribute('listing', listingId)
    document.body.appendChild(orderCancellationComponent)
    orderCancellationComponent.addEventListener('close', closed)

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }

  async showUnavailableListingModal(external_marketplace_link) {
    let promiseResolve = null

    const closed = e => {
      document.body.querySelectorAll('seez-sdk-unavailable-listing-modal').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve(e.detail[0])
    }

    const onExternalRedirect = () => {
      return window.location.href = external_marketplace_link
    }

    const modalComponent = document.createElement('seez-sdk-unavailable-listing-modal')
    this.customizeStyles(modalComponent)
    modalComponent.setAttribute('custom', true)
    modalComponent.addEventListener('close', closed)
    modalComponent.addEventListener('search', onExternalRedirect)
    document.body.appendChild(modalComponent)

    return new Promise(function (resolve,) { promiseResolve = resolve }, false)
  }
  //#endregion

  //#region Analytics 
  analytics() {
    return typeof window === 'object' && typeof window?.analytics === 'object'
      ? window.analytics
      : Object.assign(
        {},
        ...Object.entries({ ...['track', 'identify', 'page', 'user'] }).map(
          ([, b]) => ({ [b]: e => e })
        )
      )
  }

  loadSegmentTracking(segmentID) {
    let analytics = window.analytics = window.analytics || []

    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = ['identify', 'track', 'page', 'setAnonymousId']
        analytics.factory = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments)
            t.unshift(e)
            analytics.push(t)
            return analytics
          }
        }
        for (let e = 0; e < analytics.methods.length; e++) {
          let key = analytics.methods[e]; analytics[key] = analytics.factory(key)
        }
        analytics.load = function (key, e) {
          let t = document.createElement('script')
          t.type = 'text/javascript'
          t.async = !0
          t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
          let n = document.getElementsByTagName('script')[0]
          n.parentNode.insertBefore(t, n)
          analytics._loadOptions = e
        }
        analytics._writeKey = segmentID
        analytics.SNIPPET_VERSION = '4.15.3'
        analytics.load(segmentID)
        analytics.page()
      }
  }

  genCarData = ({
    id,
    brand,
    kilometrage,
    year,
    color,
    variant,
    registrationDate,
    model,
    fuelType,
    transmission,
    bodyType,
    dealership


  }) => {
    return {
      vehicle_id: id,
      kmtrage: kilometrage,
      vehicle_year: year,
      vehicle_color: color,
      vehicle_variant: variant,
      vehicle_first_registration_date: registrationDate,
      vehicle_model_name: model.name,
      vehicle_brand_name: brand.name,
      vehicle_fuel_type: fuelType.name,
      vehicle_transmission_type: transmission.name,
      vehicle_body_type: bodyType.name,
      vehicle_dealer_id: dealership.id,
      vehicle_dealer_name: dealership.name,
    }
  }

  track(eventKey, properties, vData) {
    const vehicleData = vData?.vehicle && this.genCarData(vData.vehicle)
    const storage_anonymousId = localStorage.getItem('ajs_anonymous_id')
    const anonymousId = storage_anonymousId && storage_anonymousId.replace('"', '').replace('"', '') || null
    return this.analytics().track(eventKey, { ...properties, vehicleData, anonymousId })
  }

  identify({ userId, name, email, loginStatus }) {
    return this.analytics().identify(userId, {
      userId,
      name,
      email,
      loginStatus,

    })
  }
  //#endregion
}

