/* eslint-disable quote-props */
export const CALCULATOR_COMPONENT_TRANSLATIONS = {
  loan_estimator: 'Loan Calculator',
  get_a_clearer_image: 'Get a clearer picture of what you can afford. Use our loan calculator to get an estimate of your monthly payments with different down payments.',
  car_estimated_price: 'Car’s Estimated Price',
  preferred_down_payment: 'Preferred Down Payment',
  kr: 'Kr.',
  downpayment_is_required: '* Must be at least 20% of the car price',
  loan_is_below_santander: '* Must be at least kr 50,000 for Santander loans',
  preferred_loan_duration_months: 'Preferred Loan Duration',
  months: 'Months',
  loan_amount: 'Loan Amount',
  monthly_payment: 'Monthly Payment',
  kr_month: 'kr. / month',
  hide_loan: 'Hide loan details',
  show_loan: 'Show loan details',
  bank_loan: 'Bank loans',
  financed_by: 'Financing supplied by',
  aop_before_tax: 'AOP before tax',
  down_payment_pct: 'Downpayment pct',
  financed_amount: 'Financed amount',
  nominal_interest: 'Nominal interest',
  total_repay: 'Total repay',
  base_amount: 'Base amount',
  total_loan_costs: 'Total loan costs',
  interest_type: 'Interest type',
  interest_rate: 'Interest rate',
  variable: 'Variable',
  fixed: 'Fixed',
  financing_details: 'Financing details',
  purchase_contract: 'Purchase contract'
}

export const DRIVE_TYPE_FILTER_TRANSLATIONS = {
  drive_type_F: 'Front-wheel',
  drive_type_B: 'Rear-wheel',
  drive_type_4: 'Four-wheel'
}

export const FILTER_PANEL_COMPONENT_TRANSLATIONS = {
  advanced_filters: 'Filters',
  clear_all: 'Clear all',
  brand: 'Brand',
  model: 'Model',
  select_make: 'Select Make',
  select_model: 'Select Model',
  year: 'Year',
  from: 'From',
  to: 'To',
  Monthly: 'Monthly',
  Full: 'Full',
  max_kilometrage: 'Maximum Mileage',
  max_budget: 'Maximum Budget',
  kilometrage: 'Mileage',
  price: 'Price',
  body_type: 'Body type',
  transmission: 'Transmission',
  any: 'Any',
  fuel_type: 'Fuel type',
  color: 'Color',
  all_makes: 'All Makes',
  save_search: 'Save Search',
  save_filter_find_later: 'Save your search filters and easily find them in your profile later',
  save_this_search: 'Save this search',
  my_saved_searches: 'My saved searches',
  grey: 'Grey',
  silver: 'Silver',
  white: 'White',
  black: 'Black',
  blue: 'Blue',
  red: 'Red',
  purple: 'Purple',
  orange: 'Orange',
  brown: 'Brown',
  green: 'Green',
  yellow: 'Yellow',
  Convertible: 'Convertible',
  Coupe: 'Coupe',
  Hatchback: 'Hatchback',
  MPV: 'Minivan',
  Sedan: 'Sedan',
  SUV: 'SUV',
  Truck: 'Truck',
  Van: 'Van',
  Wagon: 'Wagon',
  Cabriolet: 'Convertible',
  Stationcar: 'Wagon',
  number_of_doors: 'Number of doors',
  drive_type: 'Drive type',
  ...DRIVE_TYPE_FILTER_TRANSLATIONS,
  engine_size: 'Maximum Engine Size',
  search_vehicles: 'Search vehicles'
}

export const EMPTY_SEARCH_COMPONENT_TRANSLATIONS = {
  nothing_to_see: 'Nothing to see here!',
  you_have_not_saved_search: 'You haven’t saved any search filters yet',
  you_have_not_favorites: 'You haven’t favorited any listing yet',
  search_now: 'Search Now'
}

export const LISTING_CARD_COMPONENT_TRANSLATIONS = {
  reserved: 'Reserved',
  month: 'month',
  sold: 'Sold'
}

export const LOGIN_COMPONENT_TRANSLATIONS = {
  welcome: 'Welcome!',
  please_login: 'Verify your email to save your favorite searches and access your account details.',
  email_address: 'Enter email address',
  please_check_email: 'Please check your email',
  we_sent_to: 'We\'ve sent a code to ',
  did_not_get_code: 'Didn\'t get a code? ',
  click_to_resend: 'Click to resend',
  sign_in_using: 'Or sign in using',
  google: 'Google',
  facebook: 'Facebook',
  i_agree: 'I agree to Seez\'s',
  terms_conditions: 'Terms and conditions',
  privacy: 'Privacy Policy',
  privacy_policy: 'Privacy Policy',
  please_verify_code: 'Please enter the verification code sent to your email',
  resend_otp: 'Resend code',
  get_otp: 'Get OTP',
  confirm: 'Confirm OTP',
  go_back: 'Go back',
  marketing_terms:
    'I would like to receive marketing communications, namely promotions, events and new mobility products by Seez, whether by e-mail, telephone or SMS.'
}

export const LISTING_DETAILS_COMPONENT_TRANSLATIONS = {
  get_valuation: 'Get your trade-in valuation',
  download_brochure: 'Download Brochure',
  brochure: 'Brochure',
  save: 'Save',
  share: 'Share',
  vat_include: 'Van inc. VAT ',
  vat_plus: 'Van plus VAT',
  van: 'Van',
  cash: 'Cash',
  financing_from: 'Financing from',
  financed_by: 'Financing supplied by',
  month: 'month',
  monthly_payment_info: `Monthly payment 2,721 kr., term 84 months, variable borrowing rate
            1.26%. APR 4,56%. Total credit costs: 32,565 kr. Down payment:
            48.980 kr. Total amount to be paid: 228.485 kr`,
  get_started: 'Get started',
  login_to_buy: 'Login to Buy',
  resume_checkout: 'Resume Checkout',
  car_reserved: 'Vehicle reserved',
  reserved: 'Reserved',
  sold: 'Sold',
  coming_soon: 'Coming Soon',
  color: 'Color',
  kilometrage: 'Kilometrage',
  horsepower: 'Horsepower',
  km: 'km',
  transmission: 'Transmission',
  fuel: 'Fuel',
  registration: 'Registration',
  car_financing: 'Car Financing',
  finance_your_car: 'Finance your car',
  quick_specs: 'Quick Specs',
  completely_online: 'completely online',
  we_made_get_loan_easy_msg: ` We've made getting a car loan as easy and efficient as possible. With
          Seez, you can now apply entirely online and get the best interest
          rates within 24 hours.`,
  enter_your_down_payment: `Enter your down payment and choose your loan duration to estimate your
          monthly payment`,
  seez_benefits_you_get: 'Seez benefits you get with this car',
  quality_assurance: 'Quality Assurance',
  we_believe_value_almost_new: 'We believe in the value of almost new',
  fourteen_days_return: '14-Day Return',
  we_believe_full_transparency: 'We believe in full transparency',
  one_year_warranty: '1 Year Warranty',
  we_believe_full_peace_mind: 'We believe in full peace of mind',
  free_delivery: 'Free Delivery',
  we_believe_full_convenience: 'We believe in full convenience',
  general_information: 'General Information',
  year: 'Year',
  range: 'Range',
  fuel_consumption: 'Fuel Consumption',
  l_km: 'L/100 km',
  km_l: 'km/L',
  green_tax: 'Green tax',
  fuel_type: 'Fuel type',
  reference_number: 'Reference Number',
  technical_specs: 'Technical Specifications',
  hk: 'HK',
  acceleration: 'Acceleration',
  interior_material: 'Interior material',
  top_speed: 'Top Speed',
  airbags: 'Airbags',
  number_of_gears: 'Number of gears',
  abs: 'ABS',
  available: 'Available',
  undisclosed: 'Undisclosed',
  cylinders: 'Cylinders',
  dimension_capacity: 'Dimensions / Capacity',
  weight: 'Weight',
  max_attachment: 'Max. attatchment',
  load_capacity: 'Load Capacity',
  number_of_doors: 'Number of doors',
  comfort_technology: 'Comfort / Technology',
  design: 'Design',
  safety_environment: 'Safety / Environment',
  equipment_details: 'Equipment list',
  you_might_like: 'You might also be interested in',
  equipment: 'Equipment',
  price: 'Price',
  description: 'Description',
  read_more: 'Read More',
  read_less: 'Read Less',
  free_tradein_notes: 'Things to Note',
  free_tradein_explanation:
    '<p>Get a quick trade-in valuation of your old car to get an estimate of how much can go towards your new car.</p><p>The trade-in valuation will be preformed by the certified dealers evaluation experts.</p><p>A final value of your trade-in will be provided once we have collected the fine details of your car.</p>',
  Ford: {
    id: 17,
    name: 'Ford',
    main: 'Ford is a car brand with a long history and thus also a lot of experience when it comes to producing cars. Ford has a strong and attractive selection of cars, which include the Ford Ka, Fiesta, Focus, Kuga as well as the C-Max and S-Max models. Ford is on the list of the most popular car brands in Denmark and is strongly represented in all categories - from the smaller city car to the large SUV.'
  },
  Renault: {
    id: 13,
    name: 'Renault',
    main: 'Renault is a French car brand that was established in 1898 by Louis Renault. Renault is French engineering at its very best. Today, the car brand is behind a large number of lifestyle cars and vans, which have experienced great demand from both domestic and abroad. We fully understand this demand and have therefore gathered a wide selection of used Renault top-drawer cars, carefully selected by our employees. Find exactly the car that you are looking for with Seez.'
  },
  Dacia: {
    id: 30,
    name: 'Dacia',
    main: 'Dacia is a car brand with a long history and thus also a lot of experience when it comes to car production. The first Dacia car saw the light of day in 1966 in Romania, where the goal was to make modern and reliable cars available to the Romanian people. Since then, Dacia has become part of French Renault, but it is only in recent years that the car brand has gained a foothold in the Danish market.'
  },
  Mercedes: {
    id: 23,
    name: 'Mercedes-Benz',
    main: `At Seez we know that Mercedes is the epitome of luxury, stylish design, and drivability of the top drawer. Mercedes is available as MPV, station wagon, SUV, coupé, hatchback, sedan, and convertible, and we sell the most popular models, which include the A-Class, C-Class and CLK-Class. Models that are also available in an AMG version, which offers even higher performance.
Mercedes is a car brand that works under a motto of "The Best or Nothing", and this is very much something they live up to. In other words, with a good used Mercedes, you are guaranteed a car in a stylish design that oozes character, and at the same time has a high performance that ensures you the best driving experience - every time.`
  },
  we_make_your_trade_in_easy: 'We make your Trade-in’s easy',
  fill_out_your_details_and_quick: 'Fill out your details and we’ll give you a quick valuation.',
  start_valuation: 'Start Valuation',
  learn_more_about_trade_in: 'Learn more about trade-in',
  trade_in_your_car: 'Trade-in your car with Seez',
  to_get_your_trade_In_price: 'To get your trade-in price, start by telling us your car reg.',
  car_details_trade_in: 'Car Details',
  fill_in_your_cars_information: 'Fill in your car’s information as accurately as possible.',
  make: 'Make',
  model: 'Model',
  variant: 'Variant',
  modelYear: 'Model Year',
  registrationDate: 'Registration Date',
  registrationType: 'Registration Type',
  more_details: 'More Details',
  has_your_car_been_in: 'Has your car been in an accident?',
  no: 'No',
  yes: 'Yes',
  does_your_car_have_outstanding_financing: 'Does your car have outstanding financing?',
  what_your_car_current_condition: 'What’s your car’s current condition?',
  poor: 'Poor',
  good: 'Good',
  average: 'Average',
  great: 'Great',
  carConditionPoorDef: 'Poor means the car is in a bad condition. There is significant damage to its exterior and interior. It is in need of a good repair or better yet, retirement.',
  carConditionAverageDef:
    'Average means the car’s best years are far behind it. It runs, but the exterior is banged up and the interior is somewhat old and scruffy. Mechanically, the car might have a couple of issues.',
  carConditionGoodDef: 'Good means the car is running smoothly, but it isn’t at its peak anymore. The exterior may have small scratches or dents and the interior shows some wear and tear.',
  carConditionGreatDef:
    'Great means the car is in close to perfect condition. The exterior is perfect or has minor scratches and the interior is clean and has no stains or smells. Mechanically, it runs without a hitch.',
  i_accept_privacy_policy: 'I accept that I will receive emails from Seez about offers and other services provided by seez.',
  get_my_trade_in_value: 'Get my trade-in value',
  how_many_keys: 'How many keys are available',
  number_of_keys: 'Number of keys',
  what_is_your_email_address: 'What\'s your email address?',
  we_ll_send_an_email_you: 'We\'ll send an email of your quote to you',
  please_enter_email: 'Please enter your email address',
  please_enter_outstanding_amount: 'Please enter outstanding financing amount',
  thanks_you_for_submitting: 'Thank you for submitting your trade-in',
  we_ll_contact_you_via_via_email: 'We’ll contact you via email once we have a qoute for you vehicle',
  done: 'Done',
  condition: 'Condition',
  regTypeVanWithoutVat: 'Van without VAT',
  regTypeVanWithVat: 'Van with VAT',
  regTypePersonal: 'Personal',
  regTypeLeasing: 'Leasing',
  please_enter_phone: 'Phone number',
  please_enter_your_name: 'Full name',
  vehicle_images: 'Vehicle Images',
  upload: 'Upload ',
  front_back_interior: 'front, back, interior ',
  and: ',and ',
  damage: 'damage ',
  pictures_of_your_car: 'pictures of your car so we can give you the best offer possible.',
  drag_your_file: 'Drag your trade-in vehicle images to upload them or ',
  browse_file: 'Browse files',
  you_should_upload_images: 'You should upload your car\'s pictures'
}

export const RESULT_PANEL_COMPONENT_TRANSLATIONS = {
  filter: 'Filter',
  sort_by: 'Sort by',
  most_attractive: 'Most Attractive',
  recently_added: 'Recently Added',
  price_ascending: 'Price: Low to high',
  price_descending: 'Price: High to low',
  kilometrage_ascending: 'Mileage: Low to high',
  kilometrage_descending: 'Mileage: High to low',
  age_descending: 'Age: New to old',
  age_ascending: 'Age: Old to new',
  newest: 'Newest',
  lowest_kilometrage: 'Lowest Kilometrage',
  lowest_price: 'Lowest Price',
  there_are_no_vehicles_msg: 'There are no vehicles matching your criteria',
  all_cars: 'All Cars',
  search_results: 'Search Results',
  no_results_found: 'Oops! No results found',
  please_try_different_search: 'Please try a different search',
  get_inspired: 'Get inspired',
  error_listing: 'There was an error searching for listings',
  budget: 'Budget Conscious',
  family_life: 'Family Friendly',
  outdoor_adventure: 'Outdoor Adventure',
  single: 'Luxury Living',
  environmentally_cars: 'Environmentally Friendly Cars',
  economical_cars: 'Economical Cars',
  family_suv: 'Family-Safe SUVs',
  ...DRIVE_TYPE_FILTER_TRANSLATIONS
}

export const MODAL_ACTIVE_ORDER_COMPONENT = {
  active_order_change: 'Active Order Change',
  you_have_active_order: 'You already have an active order in progress!',
  are_you_sure_cancel:
    'Are you sure you want to cancel your current order and start a new one?',
  are_you_sure_cancel_active_order:
    'Are you sure you want to cancel your current order and start a new one?',
  price: 'Price',
  cancel_start_new_order: 'Cancel and Start New Order',
  resume_order: 'Resume Existing Order'
}

export const UNAVAILABLE_LISTING_MODAL = {
  car_is_not_available: 'This car isn\'t available anymore',
  we_got_similar_cars: 'We\'ve got similar cars for you to choose from.',
  back_home: 'Back to Home',
  view_similar: 'View similar cars',
  we_will_notify_you: 'We will notify you when we have a new similar car or if this car becomes available again. ',
  browse_all: 'Browse all',
  search_all: 'Search all',
}

export const SEO_DATA = {
  default: { title: 'Used {brand}' },
  bmw: {
    title: 'Used BMW',
    description:
      'At Seez you will find a selection of almost new BMW cars. For many car lovers, BMW is the ultimate combination of quality, luxury, sporty driving characteristics and design. When you buy a used BMW from Seez, you are guaranteed first-class quality. All our cars are in the "almost new" category, and are certified and thoroughly tested. '
  },
  dacia: {
    title: 'Used Dacia',
    description:
      'Dacia sells quality cars that are packed with innovative technologies and practical solutions - all at competitive prices. Their range ranges from the compact MPV to the smart family car, all of which are built on a concept of rock-solid off-road capacity. However, this does not mean that you have to compromise on design. Dacia is full of beautiful lines and luxury, which makes the driving experience a breeze. Dacia cars are known for being spacious, so you always have room for packing. For example, you get up to 475 liters of luggage space in the iconic Dacia Duster, which means you are guaranteed a car that covers most of the challenges of everyday life. '
  },
  ford: {
    title: 'Used Ford',
    description:
      'At Seez, we sell used Ford top-drawer cars, and we never compromise on quality or service. We are happy to help with financing and delivery of your new car, and we are always available if you have any questions. '
  },
  mercedes: {
    title: 'Used Mercedes-Benz',
    description:
      'Seez dealer uses used Mercedes of the highest quality, and we are happy to help with financing and delivery of your new car. We have a wide selection of newer used Mercedes Benz cars for sale, which means you have the opportunity to find exactly the car you are looking for. '
  },
  peugeot: {
    title: 'Used Peugeot',
    description:
      'Peugeot is one of the most popular car brands in Denmark and with good reason. The French carmaker is known for combining high quality and a modern design with affordable prices. At Seez, we have gathered a selection of the best used Peugeot models on the market. At Seez, you will only find almost new cars, so you are guaranteed a top-class car. '
  },
  renault: {
    title: 'Used Renault',
    description: 'Seez sells used Renault of the highest quality, and we are happy to finance and deliver your new, used car. With a used Renault, you get a car in a class of its own. '
  },
  skoda: {
    title: 'Used Skoda',
    description:
      'At Seez, we sell almost new Skoda cars from certified dealers throughout the country. Our car universe consists of several well-known models, such as the mini car Skoda Citigo, the larger Fabia and Octavia or the well-known Superb, which for many rhymes with spaciousness and comfort. That means you have the opportunity to find the perfect Skoda for your needs. '
  },
  toyota: {
    title: 'Used Toyota',
    description:
      'Are you looking for the next car that can take you on new adventures? Seez sells almost new Toyota cars from certified dealers throughout the country. We have a wide selection of popular Toyota models for sale, which means you have the opportunity to find exactly the car that suits you and your needs. '
  },
  vw: {
    title: 'Used VW',
    description:
      'At Seez, we exclusively sell almost new Volkswagen cars. We select all used VWs with the utmost care and place high demands on their condition. This means that you have the opportunity to find a used car that can almost be considered a new one. With a used Volkswagen, you are guaranteed a quality car that combines efficient horsepower with innovative technology and a modern design from the car\'s interior to exterior. '
  }
}

export const CHECKOUT_COMPONENT_TRANSLATIONS = {
  orderId: 'Order ID',
  carReserved: 'Car Reserved',
  cancelOrder: 'Cancel order',
  tradeIn: 'Trade-in',
  tradeInSubtitle: 'Out with the old, in with the new.\nDrop the trip to the dealer and exchange your car 100% online.',
  tradeInInfoTitle: 'Trading in your car',
  tradeInInfoStep1: '1. Answer a few questions about your swap car',
  tradeInInfoStep1Description: 'If possible, you can upload some pictures of your car so we can give you the most accurate quote.',
  tradeInInfoStep2: '2. Confirm your order',
  tradeInInfoStep2Description: 'It is not binding and you can always cancel your application.',
  tradeInInfoStep3: '3, Receive an offer on your exchange car within one hour(normal working hours)',
  tradeInInfoStep3Description: 'The official dealer will send you an offer that you can choose to reject or accept.',
  tradeInInfoStep4: '4. Relax at home',
  tradeInInfoStep4Description: 'Our experts will review your swap car at your home address when we deliver your new car.',
  tradeInWouldYouLikeToAdd: 'Would you like to add a car to trade in?',
  yes: 'Yes',
  no: 'No',
  addANewTradeIn: 'Add a New Trade-in',
  tradeInAddPlateNumber: 'Add your car’s plate number to look-up its details or fill in the mandatory fields manually.',
  enterPlateNumber: 'Enter plate number',
  carDetails: 'Car Details',
  carDetailsFillInfo: 'Fill in your car’s information as accurately as possible.',
  make: 'Make',
  model: 'Model',
  variant: 'Variant',
  year: 'Year',
  mileage: 'Mileage (km)',
  color: 'Color',
  fuelType: 'Fuel Type',
  registrationDate: 'Registration Date',
  moreDetails: 'More Details',
  registrationType: 'Registration Type',
  regTypeVanWithoutVat: 'Van without VAT',
  regTypeVanWithVat: 'Van with VAT',
  regTypePersonal: 'Personal',
  regTypeLeasing: 'Leasing',
  accidentQuestion: 'Has your car been in an accident?',
  accidentMajorAccidents: 'Cars that have been in major accidents may not be accepted for trade-ins. Dealership terms & conditions apply.',
  oustandingFinancingQuestion: 'Does your car have outstanding financing?',
  outstandingFinancingInfo: 'To find your outstanding financing, you will need to call your bank or check your online banking account.',
  serviceHistory: 'Service History',
  numberOfKeys: 'Number of Keys',
  howManyKeys: 'How many keys are available?',
  serviceHistoryQuestion: 'How much service history does your car have?',
  serviceHistoryComplete: 'Complete service history includes log book stamps with dealer name, date, mileage and no service gaps.',
  currentCondition: 'Current Condition',
  carConditionQuestion: 'What’s your car’s current condition?',
  carQuirks: 'Car Quirks',
  carQuirksHint:
    'We know used cars come with personality, tell us a little bit about yours - did the windshield break in a weird accident, have you or your friends smoked in it, did you lose one of the keys somewhere?',
  serviceHistoryFull: 'Full',
  serviceHistoryPartial: 'Partial',
  serviceHistoryNone: 'None',
  carConditionSelect: 'Select Condition',
  carConditionPoor: 'Poor',
  carConditionGood: 'Good',
  carConditionAverage: 'Average',
  carConditionGreat: 'Great',
  carConditionPoorDef: 'Poor means the car is in a bad condition. There is significant damage to its exterior and interior. It is in need of a good repair or better yet, retirement.',
  carConditionAverageDef:
    'Average means the car’s best years are far behind it. It runs, but the exterior is banged up and the interior is somewhat old and scruffy. Mechanically, the car might have a couple of issues.',
  carConditionGoodDef: 'Good means the car is running smoothly, but it isn’t at its peak anymore. The exterior may have small scratches or dents and the interior shows some wear and tear.',
  carConditionGreatDef:
    'Great means the car is in close to perfect condition. The exterior is perfect or has minor scratches and the interior is clean and has no stains or smells. Mechanically, it runs without a hitch.',
  carPhotos: 'Car Photos',
  carPhotosBestOfferPossible: 'Upload some pictures of your car so we can give you the best offer possible.',
  addFrontPhoto: 'Front',
  addBackPhoto: 'Back',
  addInteriorPhoto: 'Interior',
  addMorePhotos: 'Add more photos',
  damagePhotos: 'Damage pictures (scratches, dents, tearing, etc..)',
  carPhotosMissing: 'If your trade-in application is missing car photos, the final offer may be adjusted based on final inspection.',
  tradeInDiscrepanciesDisclaimer: 'Discrepancies may result in a change to the offer or its complete annulement.',
  paymentMethods: 'Payment Methods',
  financingOneOfTwoWays: 'You can pay for your car in one of two ways.',
  bankLoan: 'Bank Loan',
  bankTransfer: 'Bank Transfer',
  financingBankLoanWay: 'Apply for financing with Santander, our financing partner, online.',
  financingBankTransferWay: 'The full amount will need to be settled via bank transfer.',
  financingThingsToKeepInMind: 'Things to keep in mind',
  financingIfTradeInAdded:
    'If you’ve added a trade-in, you will not be able to transfer payment until after you have received an offer. The offered amount for your trade-in will be deducted from your new car’s price in full.',
  financingQuestion1: 'How will you be paying for your car?',
  reservationEnded: 'Reservation Ended',
  reservationYouMayInitiate: 'You may reinitiate checkout if the car is still \navailable for purchase.',
  reservationOkay: 'Okay',
  reservation45Minutes: 'Your car is reserved for 45 minutes while you complete the purchase process.',
  reservation24Hours: 'Your car is reserved for 24 hours while you complete the purchase process.',
  carReservedForYou: 'This car is now reserved for you.',
  personalInformation: 'Personal Information',
  about3Minutes: 'About 3 minutes',
  about5Minutes: 'About 5 minutes',
  financing: 'Financing',
  welcomeBack: 'Welcome back',
  about1Minute: 'About 1 minute',
  addOns: 'Add-ons',
  delivery: 'Delivery',
  about2Minutes: 'About 2 minutes',
  viewOrderSummary: 'View Order Summary',
  confirmOrderDetails: 'Confirm Order Details',
  notice:
    'Notice: Your car\'s check-out will take approximately 10-15 minutes. The pages are scrollable and you can complete them in whichever order you wish. When everything is filled out and you\'re ready to proceed, the Confirm button will light up and you can click it to finalize your order.',
  backToIntro: 'Back to Intro',
  personalInfoIntro: 'To get this process going as smoothly as possible, we’re going to need some of your details.',
  personalInfoNameHint: 'We can only deliver the car to you if the name you use for your order matches the name on your driver\'s license.',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  asPerDriverLicense: 'As per your driver’s license',
  emailAddress: 'Email address',
  phoneNumber: 'Phone number',
  emailAddressHint: 'To send your order confirmation. We will never use this to send anything unnecessary.',
  cprNumber: 'CPR Number / National ID',
  cprNumberHint: 'To complete your car registration',
  cprNumberInvalid: 'Invalid CPR Number',
  insuranceCompany: 'Insurance company',
  insuranceCompanyOther: 'Insurance Company (other)',
  insuranceCompanyHint: 'If you can’t find yours or don’t have one yet, select "other',
  address: 'Address',
  country: 'Country',
  denmark: 'Denmark',
  city: 'City',
  state: 'State',
  postalCode: 'Postal Code',
  addressStreet: 'Street',
  addressNumberFloor: 'Apt. Number / Floor',
  addAddressLine: 'Add Address Line',
  scrollToContinue: 'Scroll to continue',
  required: 'Required',
  emailInvalid: 'Invalid email address',
  error: 'Error',
  tradeInQuickAndEasy: 'With Seez, trading in your car is quick and easy.',
  howItWorks: 'How it works',
  tradeInFewQuestions: 'Answer a few questions about your trade-in car and get an offer from us within one working hour.',
  tradeInOutstandingFinancingInfo: 'The offer you get, minus outstanding financing, is deducted entirely from the down payment on the new car.',
  tradeInOfferExceeds: 'If your offer exceeds the value of your down payment, we will send you the remaining amount.',
  tradeInDeliveryAndInspection: 'When we deliver your new car, we’ll give the trade-in a quick inspection and cart it off to its new home.',
  tradeInWhatWeAsk: 'What we’ll ask for',
  tradeInRequiredInformation: 'We will ask about your car’s mileage, condition, service history, and any outstanding financing.',
  tradeInRequiredPictures: 'We will also need you to upload some pictures of your car so we can give you the most accurate valuation.',
  plateNumberInvalid: 'Uh-oh! We couldn’t find a car with that registration.',
  plateNumberEnterDifferentOne: 'Enter a different one or fill in the information below manually.',
  clearAll: 'Clear all',
  carPhotosEnsureOnePhoto:
    'The trade-in offer provided will be based on these photos. For the most accurate assessment, make sure that there is 1 clear photo for each of the areas required, and that you highlight any damages.',
  carPhotosUploading: 'Uploading...',
  addExtraPhoto: 'Extra',
  addDamagesPhoto: 'Damages',
  addScratchesPhoto: 'Scratches',
  addDentsPhoto: 'Dents',
  otherDetails: 'Other Details',
  regTypeQuestion: 'What is your car’s registration type?',
  outstandingFinancing: 'Outstanding Financing',
  outstandingAmount: 'Outstanding Amount',
  oustandingFinancingDisclaimer: 'Avoid the hassle of registered outstanding debt. By choosing yes, you give us acceptance to repay the debt.',
  oustandingFinancingDisclaimer1: 'Any difference in this amount will be your responsibility',
  tradeInMakeMeAnOfferDisclaimer:
    'By clicking “Make me an Offer” you declare that you are authorized to sell this vehicle and that all information provided is entirely accurate. Discrepancies may result in a change to the offer or its complete annulement.',
  makeMeAnOffer: 'Make me an Offer',
  tradeInSubmitted: 'Your trade-in request has been submitted',
  tradeInTeamOfExperts: 'Our team of experts will take a look and get back to you with an offer within 1 working hour after you confirm your order.',
  continueToFinancing: 'Continue to Financing',
  tradeInOffer: 'Trade-in Offer',
  continueWithoutTradeIn: 'Continue without Trade-in',
  tradeInOfferIsHere: 'Your trade-in offer is here. As long as your trade-in car is exactly as described, you will receive the exact amount offered with no hidden fees. Check out the details below.',
  photos: 'Photos',
  tradeInTotalValue: 'Trade-in Total Value',
  oustandingFinancing: 'Outstanding Financing',
  receivableTradeInAmount: 'Receivable Trade-in Amount',
  acceptOffer: 'Accept Offer',
  acceptOfferDisclaimer: 'By clicking “Accept Offer” you are agreeing to the Seez Trade-in ',
  financingQuestion: 'How will you be paying for your car?',
  bankTransferTo: 'To',
  bankTransferRegistrationNumber: 'Bank Code (SWIFT)',
  bankTransferAccountNumber: 'IBAN',
  bankTransferReference: 'Reference',
  bankTransferBankName: 'Bank Name',
  bankTransferTotalAmount: 'Total Amount',
  financingInfoTitle: 'Paying for your car',
  financingCanYouReally: 'Can you really apply for a Bank Loan online?',
  financingEasierLife: 'To make your life easier, we’ve made it possible to complete the full financing journey entirely online.',
  financingFillApplicationForm: 'Just fill in the application from the comfort of your home and get a decision within 24 hours.',
  financingGoToApplication: 'Clicking "Go to Application" will forward you to Santander\'s website so you can fill in the application.',
  loanCalculator: 'Loan Calculator',
  loanCalculatorTitle: 'Enter your down payment amount and choose your loan duration to get an estimate of your monthly payment',
  loanCalculatorRedirectedToPortal: 'Once you’re ready, you will be redirected to the application on Santander’s portal.',
  preferredDownPayment: 'Preferred Down Payment',
  downPaymentInvalid: 'Must be at least 20% of the car price',
  preferreedLoanDuration: 'Preferred Loan Duration in Months',
  loanSummary: 'Loan Summary',
  loanAmount: 'Loan amount',
  monthlyPayment: 'Monthly payment',
  monthlyPaymentDisclaimer:
    '**Monthly payment 8,000 kr/month, period 72 months, variable loan interest rate 1.26%. APR 3.82%. Total credit costs: 59,591 kr Payment: 142,000 kr. Total amount to be paid: 568,00 kr.',
  goToApplication: 'Go to Application',
  financingProvidedBy: 'Financing provided by',
  aprDisclaimer: 'The final APR and value of the monthly payments are decided upon by Santander and may vary depending on your credit rating, chosen vehicle, and loan duration.',
  redirectedToSantander: 'You have been redirected to Santander\'s website.',
  confirmApplicationInformation: 'If you have submitted your financing application please confirm below.',
  returnToApplicationInformation: 'If you have not submitted it, please use the the below link to return to the application',
  letUsKnowToProceed: 'Once you’ve filled and submitted the financing application, let us know to proceed.',
  submittedMyApplication: 'I Submitted my Application',
  returnToFinancingApplication: 'Return to financing application',
  loanApplicationSubmitted: 'Your loan application has been submitted',
  loanApplicationOnceCompleted: 'Once you complete the checkout we will contact the finance provider and proceed with your application.',
  loanApplicationSupport: 'If you have any question please contact us on the live support (alle dage 10:00 - 18:00) or Tlf.:',
  supportNumber: '+45 80 82 08 01',
  supportEmail: 'support@seez.dk',
  notifiedOnceDecisionMade: 'You will be notified once a decision has been made.',
  carDelivery: 'Car Delivery',
  getYourCarDelivered: 'Get your car delivered to any location of your choosing completely free of charge.',
  deliveryWhen: 'When would you like your car delivered?',
  date: 'Date',
  time: 'Time',
  deliveryWhere: 'Where would you like to receive it?',
  deliveryAnywhere: 'Get your car delivered to your home, workplace, or anywhere else. We deliver to mainland Denmark and any islands connected by bridges',
  town: 'Town',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  confirm: 'Confirm',
  deliveryTandC: 'Delivery Terms & Conditions apply',
  carefullyReviewDetails: 'Carefully review all the details and confirm that the information provided is entirely correct.',
  personalInfo: 'Personal Info',
  loanApplicationInfoButton: 'When you are ready, you will be redirected to Santanders financial application on their website. Afterwards you will need to contimue your application on Seez.dk',
  confirmAndProceed: 'Confirm & Proceed',
  confirmationPageExplanation:
    'By confirming the information, we can begin processing your inquiry and submitting it to the dealer. This is not binding and you can cancel at any time with a single click.',
  confirmAndProceedDisclaimer: 'By clicking “Confirm & Proceed”, I certify that I have read and agreed to the ',
  termsAndConditions: 'Terms and Conditions',
  errorsResponsibilityDisclaimer: 'Any errors discovered later will be your responsibility to bare.',
  thankYouForConfirming: 'Thank you for Confirming your Order',
  carIsNowReservedFor24: 'Your car is now reserved for ',
  carIsNowReservedFor241: '24 hours.',
  onceYouReceivedTradeInOffer: 'Once you receive a trade-in offer, you will be able to sign the contract and send in your payment.',
  backToHome: 'Back to Home',
  orderSummary: 'Order Summary',
  tradeInAccepted: 'Trade-in Offer Accepted',
  thankYouForAcceptingOffer: 'Thank you for accepting the Trade-in offer! Just a couple more steps and this car is officially yours.',
  tradeInRejecteed: 'Trade-in Offer Rejected',
  youRejectedOffer: 'You’ve turned down the Trade-in offer. You may still proceeed and make this car officially yours.',
  almostThere: 'You’re almost there!',
  justACoupleMore: 'Just a couple more steps and this car is officially yours.',
  payment: 'Payment',
  transferRequiredAmount:
    'Transfer the required amount to the account below. Thank you for choosing us as your online car marketplace. This is not the end, we’ll be here to support you with any questions you have for as long as you need.',
  downPayment: 'Down Payment',
  carPrice: 'Car Price',
  tradeInValue: 'Trade-in Value',
  tradeInAmount: 'Trade-in Amount',
  amountToBePaid: 'Amount to be Paid',
  transferDetails: 'Transfer Details',
  saveDetails: 'Save Details',
  transferComplete: 'Transfer Complete',
  bankTransferNotice1: 'Notice: ',
  bankTransferNotice2: 'We do not have access to your payment details.',
  decideToReturn: 'If you decide to return the car within the 14-day period, the payment is reversed and anything transferred will be refunded.',
  congratulations: 'CONGRATULATIONS',
  youAreNowOwner: 'You are now the proud owner of an almost new car!',
  ourTeamWillPrepareCar: 'Our team will now be taking it to the car spa and getting it ready for your big day.',
  warning: 'Warning',
  cancelWarning: 'Cancelling this order will remove the reservation and delete any unsaved information.',
  cancelAreYouSure: 'Are you sure you wish to proceed?',
  cancelYesImSure: 'Yes, I want to cancel order',
  returnToOrder: 'Return to order',
  activeOrderChange: 'Active Order Change',
  orderAlreadyInProgress: 'You already have an active order in progress',
  cancelAreYouSureToStart: 'Are you sure you want to cancel your current order and start a new one?',
  cancelAndStartNew: 'Cancel and Start New Order',
  resumeOrder: 'Resume Order',
  agreementSigned: 'Agreement Signed',
  carIsOfficiallyYours: 'This car is officially yours.',
  thanksForSigning: 'Thank you for signing the Sales Agreement, you may now proceed to payment.',
  proceedToPayment: 'Proceed to Payment',
  carIsNowReserved: 'This car is now reserved for you.',
  onceFinancingIsApproved: 'Once your financing application is approved, you will be notified by email so you may proceed to payment.',
  completed: 'Completed',
  inProgress: 'In Progress',
  pending: 'Pending',
  processing: 'Processing',
  incomplete: 'Incomplete',
  noTradeInSelected: 'No trade-in selected',
  noAdditionalComments: 'No additional comments',
  noPhotosAdded: 'No photos added',
  overview: 'Overview',
  overviewTitle: 'You will be asked to transfer the amount below via bank transfer after signing your final note.',
  tradeInDescription: 'Our evaluation experts review your swap car and get back to you within a weekday with a quote. If you accept, the offer will be deducted from your full payout.',
  noTradeinAdded: 'No Trade-in Added',
  totalToBePaid: 'Amount to be paid',
  thisOrder: 'This order ',
  hasBeenCancelled: 'has been cancelled.',
  back: 'Back',
  addPhoto: 'Add photo',
  downPaymentPctAbove20Text: 'Purchase contract',
  month: 'Month',
  term: 'Term',
  months: 'Months',
  preferredInterestType: 'Preferred interest type',
  interestTypeFixed: 'Fixed',
  interestTypeVariable: 'Variable',
  financingDetails: 'Financing details',
  showFinancingDetails: 'Show details',
  hideFinancingDetails: 'Hide details',
  aopBeforeTax: 'AOP before tax',
  downPaymentPct: 'Down payment Pct',
  financedAmount: 'Financed amount',
  nominalInterest: 'Nominal interest',
  totalRepay: 'Total repay',
  baseAmount: 'Base amount',
  totalLoanCosts: 'Total loan costs',
  interestType: 'Interest type',
  interestRate: 'Interest rate',
  meanwhileFillLoanApplication: 'Meanwhile, complete and submit your financing application with Santander',
  thankYouForSubmittingYourApplication: 'Thank you for submitting your application',
  viewSummary: 'View summary',
  pendingTradeInOffer: 'Pending trade-in offer',
  pendingCustomerDecision: 'Pending customer decision',
  pendingFinancingApplication: 'Pending financing application ',
  financingApplicationCompleted: 'Financing application completed',
  tradeInCompleted: 'Trade-in completed',
  waitingForTradeInOffer: 'Waiting for trade-in offer',
  tradeInOfferReceived: 'Trade-in offer received',
  reviewYourTradeInOffer: 'Review your trade-in offer ',
  tradeInOfferAccepted: 'Trade-in offer accepted',
  theTradeInAmountWillBeDeductedFromYour: 'The trade-in amount will be deducted from your payment',
  ourAgentsAreReviewingYourTradeInDetails: 'Our agents are reviewing your trade-in details. You will receive an offer within 1 working hour.',
  applyForFinancing: 'Apply for financing',
  meanwhilePleaseCompleteYourFinancingApplication: 'Meanwhile, please complete your financing application',
  youCanStillApplyDirectlyOnline: 'You can still apply directly online',
  insureYourNewCarWithTrygOurInsurancePartner: 'Insure your new car with Tryg, our insurance partner.',
  applyNow: 'Apply now',
  viewOffer: 'View offer',
  financingMeanwhileComplete: 'Meanwhile, please complette your financing application',
  financingApplicationPending: 'Pending financing application',
  financingApplicationSubmitted: 'Financing application submitted',
  financingYouCanReachOut: 'You can reach out to us if you have any questions',
  thankYouForSubmittingTheApplication: 'Thank you for submitting the loan application',
  insuranceTrygIfYouWish: 'If you wish to apply directly online, you can insure your new car with Tryg, our insurance partner',
  insuranceTrygYouCanStill: 'You can still apply directly online and insure your new car with Tryg, our insurance partner',
  insuranceYouHaveSelected: 'You\'ve selected',
  insuranceApplyNow: 'Apply now',
  statusScreenNextSteps: 'Next steps',
  statusScreenTakeCarHome: 'Take the car home',
  statusScreenCustomerSupport: 'If you have any questions, you can contact us on live support (every day between 10:00 - 18:00) or on the phone:',
  backToOverview: 'Back to overview',
  insurance: 'Insurance',
  spaWaitingText: 'Once your Sales Agreement is ready, you will be notified by email so you may sign it.',
  spaTitle: 'Sales Agreement',
  spaExcited: 'We hope you’re as excited as we are! Review the sales agreement.',
  spaBinding: 'This slutseddel will only be binding if your loan application is approved. If you are not approved for a loan or can pay in other ways, this slutseddel will not be valid.',
  spaReadyRedirectToDocusign: 'Once you\'re ready, you will be redirected to DocuSign so you may sign it.',
  spaSignNow: 'Sign Now',
  spaSignAgreement: 'Sign agreement',
  spaOnceAllStepsAreComplete: 'Once all steps are completed, you will be able to sign the contract and transfer your downpayment',
  spaPendingSteps: 'Pending completion of above steps',
  spaAwaitingSignature: 'Awaiting customer signature',
  rejected: 'Rejected',
  notFound: 'is not found',
  contactUs: 'Contact us',
  km: 'km',
  search: 'Search',
  next: 'Next: ',
  myAddress: 'My Address',
  addNewAddress: 'Add new address',
  purchaseJourney: 'Purchase journey',
  errors: {
    licenseplate: 'License Plate not found',
    kmtrage: 'Please fill the Mileage',
    variant: 'Please fill the variant',
    model: 'Please fill the model',
    registrationdate: 'Please fill the registration date',
    year: 'Please fill the year',
    fuel_type: 'Please fill the fuel type',
    condition: 'Please fill the condition',
    registration_type: 'Please fill the registration type',
    color: 'Please fill the color',
    number_of_keys: 'Please fill the number of keys',
    service_type: 'Please fill the service type',
    pictures: 'Please fill the pictures'
  }
}

export const ORDER_CARD_COMPONENT_TRANSLATIONS = {
  km: 'km',
  price: 'Price',
  kr: 'kr.',
  order_summary: 'Order Summary',
  restart_order: 'Restart Order',
  resume_purchase: 'Resume Purchase',
  cancelled: 'cancelled',
  view_order: 'View Order'
}

export const ORDERS_COMPONENT_TRANSLATIONS = {
  my_orders: 'Orders',
  cancel_order: 'Cancel Order',
  current_order: 'Current Order',
  expires_in: 'expires in',
  previous_orders: 'Previous Orders',
  cancel_order_title: 'Cancel Order',
  cancel_order_description: 'Cancelling this order will remove the reservation and delete any unsaved information.<br /><p><b>Are you sure you wish to proceed?</b></p>',
  cancel_order_confirm: 'Yes, I want to cancel order',
  cancel_order_abort: 'Return to Order'
}

export const PROFILE_COMPONENT_TRANSLATIONS = {
  account_settings: 'Account Settings',
  personal_information: 'Personal Information',
  email: 'Email',
  full_name: 'Full Name',
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  phone: 'Phone',
  you_have_to_login: 'You have to login before seeing your profile',
  save: 'Save',
  marketing_consent: 'Marketing consent'
}