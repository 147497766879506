<template>
  <div class="card" data-test-id="overview-step-title">
    <div class="titleRow">
      <div class="title" />
    </div>
    <div :class="getStatusClass + ' cardBox'">
      <div class="icon"><img :src="icon" /></div>
      <div class="info">
        <div class="title">{{ t[title] }}</div>
        <div class="body">{{ t[boxBody] }}</div>
        <div class="status">
          <img :src="getIcon" />
          <span :class="getStatusClass">{{ t[statusText] }}</span>
        </div>
      </div>
      <div class="actions">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { lang } from '../../lang'
import { pendingIcon, completeIcon, rejectedIcon } from '../../../assets/images'

const statuses = Object.freeze({ pending: 'pending', complete: 'complete', action: 'action', rejected: 'rejected' })

export default {
  name: 'StepCard',
  mixins: [lang],
  props: {
    icon: { type: String, required: true },
    statusText: { type: String, required: true },
    status: { type: String, required: true },
    title: { type: String, required: true },
    boxBody: { type: String, required: true },
    iconBg: { type: String, default: 'transparent' }
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    },
    getIcon() {
      if (this.status === statuses.complete) return completeIcon
      if (this.status === statuses.pending) return pendingIcon
      if (this.status === statuses.action) return pendingIcon
      if (this.status === statuses.rejected) return rejectedIcon
      return pendingIcon
    },
    getStatusClass() {
      if (this.status === statuses.complete) return 'complete'
      if (this.status === statuses.pending) return 'pending'
      if (this.status === statuses.action) return 'action'
      if (this.status === statuses.rejected) return 'rejected'
      return 'pending'
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-top: 1.375rem;
  .titleRow {
    display: flex;
    justify-content: space-between;
    > .title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.125rem;

      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
    }
  }
  .cardBox {
    background: white;
    border-radius: 10px;
    display: flex;
    padding: 2.5rem 1.5rem;
    margin: 1.625rem 0;
    gap: 1.5rem;
    .icon {
      background: v-bind(iconBg);
      width: 7.3125rem;
      margin: -2.5rem -2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px 0 0px 10px;
    }
    > .info {
      flex: 2;
      padding-left: 1.375rem;
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }
      > .body {
        margin: 0.4375rem 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      > .status {
        display: flex;
        align-items: center;
        gap: 0.5625rem;
        margin-top: 12px;
        > span {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
        }
        > span.pending {
          color: #ffa200;
        }
        > span.complete {
          color: #009a33;
        }
        > span.rejected {
          color: #9a0000;
        }
        > span.action {
          color: #ffa200;
        }
        > img {
          width: 0.875rem;
        }
      }
    }
    &.pending {
      border: 2px solid #e2e2e2;
      border-radius: 16px;
    }
    &.action {
      background: #eaf3ff;
    }
    &.complete {
      background: #f0f9f3;
    }
    &.rejected {
      background: #f9f0f0;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }
}
</style>
