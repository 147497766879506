import * as en from '../translations/en'
import * as da from '../translations/da'

//deprecated
export const lang = {
  props: { lg: { type: String, default: null } }, //lang is a reserved attribute name
  computed: {
    language() {
      return this.lg || document.querySelector('html').getAttribute('lang') || 'en'
    },
    languageResources() {
      const translations = { en: en, da: da }
      return translations[this.language] ?? {}
    }
  },
}

export const langMixin = compName => ({
  props: { lg: { type: String, default: null } }, //lang is a reserved attribute name
  computed: {
    language() {
      return this.lg || document.querySelector('html').getAttribute('lang') || 'en'
    },
    languageResources() {
      const translations = { en: en, da: da }
      return translations[this.language] ?? {}
    },
    t() {
      return this.languageResources[compName]
    }
  },
})