<template>
  <div class="trade-in-select">
    <div class="radio" v-for="option in options" :key="option.value">
      <input type="radio" :name="name" :value="option.value" @input="changes" :id="name + '-' + option.placeholder" :checked="modelValue === option.value" />
      <label :for="name + '-' + option.placeholder">{{ t[option.placeholder] }}</label>
    </div>
  </div>
</template>
<script>
import { lang } from '../../lang'

export default {
  name: 'RadioSelect',
  mixins: [lang],
  props: {
    error: { type: String, default: '' },
    model: { type: String, default: null },
    options: { type: Array, required: true },
    name: { type: String, required: true },
    modelValue: { type: [Boolean, String], default: null },
  },
  computed: {
    t() {
      return this.languageResources.CHECKOUT_COMPONENT_TRANSLATIONS
    }
  },
  watch: {
    error: {
      handler(val) {
        this.$refs.input.oninvalid = this.$refs.input.setCustomValidity(val)
      }
    }
  },
  mounted() {
    this.clearInvalid()
  },
  methods: {
    clearInvalid() {
      if (this.error) this.$refs.input.oninvalid = this.$refs.input.setCustomValidity('')
    },
    changes(event) {
      this.$emit('update:modelValue', event.target._value)
    }
  }
}
</script>
<style lang="scss" scoped>
/* !important is used because parent's stylings has higher priority (ID is used) */
/* Hacks are tested and it would make them nasty. e.g. using ID will end in having multiple inputs with same ID */
/* TODO: clean up parent css with scoped instead of using ID then remove important */
.trade-in-select {
  display: flex;
  gap: 14px;
  justify-content: center;
  .radio {
    min-width: 7rem;
    flex-grow: 0;
    label {
      background: #fff;
      padding: 0.75rem 1.25rem;
      border-radius: 2em;
      cursor: pointer;
      color: #444;
      transition: box-shadow 400ms ease;
      font-size: 14px;
      border: 1px solid #1a2634;
      display: flex;
      justify-content: center;
      &:hover {
        opacity: 0.8;
      }
    }

    input[type='radio'] {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    input[type='radio']:checked + label {
      background: #0068FF;
      border-radius: 64px;
      border: none;
      border-radius: 2em;
      color: #fff;
    }
  }
  input {
    &:invalid {
      border: 1px solid rgb(248, 67, 67) !important;
    }
    &:valid {
      border: 1px solid #ccc !important;
    }
    &:focus-visible {
      outline: 1px solid rgb(211, 0, 0) !important;
    }
  }
}
</style>
